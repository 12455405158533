/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { ReactNode, useEffect, useReducer } from "react";
import { useSelector } from "react-redux";

//Adobe Internal
import { Flex, Text } from "@adobe/react-spectrum";

import IViewController from "../../../../view/IViewController";
import { CreationsStatus } from "../../../../common/interfaces/creations/CreationTypes";
import Utils from "../../../../utils/Utils";
import { ViewAction } from "../../../../view/IBaseController";
import DocActions from "../../../../stores/actions/DocActions";
import { ZoomLevel } from "../../../../editors/stage/ELFabricStage";
import store from "../../../../stores/store";
import { ELRecommendationWorkflowViewActions } from "../../../../common/interfaces/creations/ELRecommendationsWorkflowTypes";
import { RootState } from "../../../../stores/store";
import { DocumentDirty } from "../../../../common/interfaces/document/DocumentTypes";
import { CreationInAppNotifierAction, RecommendationsInAppNotifierAction } from "../../../../common/interfaces/creations/CreationInAppNotifierTypes";
import ELGenericProgressContentLoop from "../../../../view/components/organism/el-progress-content-loop/ELProgressContentLoop";
import Constants from "../../../../utils/Constants/Constants";
import { useUnloadHandler } from "../../../../utils/hooks/useUnloadHandler";
import { useKeyboardZoomEventListener }  from "../../../../utils/hooks/useKeyboardZoomEventListener";

import "./PatternOverlay.scss"


interface IPatternOverlayViewProps {
    controller: IViewController
}

interface ELPatternOverlayState {
    id: string,
    status: CreationsStatus,
    progressPercentage: number
}

const getInitialState = (): ELPatternOverlayState => {
    return {
        id: Utils.getNilUUID(),
        status: CreationsStatus.requested,
        progressPercentage: 0
    }
}

const PatternOverlayView = (props: IPatternOverlayViewProps): React.ReactElement => {

    const reducer = (state: ELPatternOverlayState, action: ViewAction): ELPatternOverlayState => {
        switch (action.type) {
            case ELRecommendationWorkflowViewActions.recommendationWorkflowStatus: {
                const status = action.payload as (CreationsStatus);
                return {
                    ...state,
                    status: status
                };
            }
            case RecommendationsInAppNotifierAction.recommendationProgressChanged: {
                const progressPercentage = action.payload as number;
                return {
                    ...state,
                    progressPercentage: progressPercentage
                };
            }
            case CreationInAppNotifierAction.creationProgressChanged: {
                const progressPercentage = action.payload as number;
                return {
                    ...state,
                    progressPercentage: progressPercentage
                };
            }
            default: {
                return state;
            }
        }
    }

    const recommendationsStatus = useSelector((state: RootState) => state.recommendationWorkflowReducer.status);
    const projectStatus = useSelector((state: RootState) => state.recommendationWorkflowReducer.projectStatus);

    const [state, viewDispatch] = useReducer(reducer, getInitialState());
    const docStoreData = useSelector((rootState: RootState) => rootState.docStateReducer);

    useEffect(() => {
        props.controller.initialize(viewDispatch);

        return () => {
            props.controller.destroy();
        }
    }, [props.controller]);

    useUnloadHandler(docStoreData.isDirty === DocumentDirty.NON_DIRTY);
    useKeyboardZoomEventListener({
        controller: props.controller,
        unmountCB: () => {
            store.dispatch(DocActions.updateZoomLevel(ZoomLevel.default));
        }
    });

    const showProgressUI = (): boolean => {
        return (state.status === CreationsStatus.requested
            || projectStatus === CreationsStatus.requested
            || recommendationsStatus === CreationsStatus.requested);
    }

    const getProgressBanner = (progressContent = ""): ReactNode => {
        return (
            showProgressUI() &&
            <div id="pattern-overlay-progress-container">
                <ELGenericProgressContentLoop contentHeader={progressContent}
                    progressPercentNode={showPatternOverlayProgressNumber()} />
            </div>
        );
    }

    const showPatternOverlayProgressNumber = (): ReactNode => {
        if (state.progressPercentage) {
            return (<Text marginTop="0.8rem" UNSAFE_className="pattern-overlay__secondary_text">
                {state.progressPercentage}{Constants.PERCENT_SYMBOL}
            </Text>);
        }
        return <></>;
    }

    return (
        <div id="pattern-overlay-container">
            <div>
                {getProgressBanner()}
            </div>
            <div id="pattern-overlay-creations-header-container" />
            <Flex direction="column">
                <div className="pattern-overlay-main-content-box">
                    <div id="pattern-overlay-left-panel-container" />
                    <div id="pattern-overlay-edit-container" />
                    <div id="pattern-overlay-right-panel-container" />
                    <div className="pattern-overlay-feedback-icon-container" id="feedback-popover-container" />
                </div>
            </Flex>
            <div id="open-deeplink-container" data-testid="open-deeplink-container" />
        </div>
    )
}

export default PatternOverlayView;