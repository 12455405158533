/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//ThirdParty
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import axios from "axios";

//Adobe Internal
import {
    ContentType,
    OutputType,
    CreationOperationTypeInfo,
    TemplateId,
    Resolution,
    ContentEntity,
    MagicalBackdropCreationOperationSubTypeInfo,
    UserDefinedDataType,
    UserDefinedInfoType,
} from "@elements/elementswebcommon";

//Application Specific
import store from "../../../../stores/store";
import Logger, { LogLevel } from "../../../../utils/Logger";
import { ViewAction } from "../../../../view/IBaseController";
import { ControllerAction } from "../../../../view/IViewController";
import IBaseWorkspace, { WorkspaceActionType, WorkspacePayload } from "../../../IBaseWorkspace";
import IWorkflow, { WorkflowAction, WorkflowActionType, WorkflowsName } from "../../../IWorkflow";
import ReplaceBackgroundUtils from "./utils/ReplaceBackgroundUtils";
import ReplaceBackgroundView from "./ReplaceBackgroundView";
import {
    CSAssetWithData,
    CreationWorkflowActions,
    CreationsJobProjectSubType,
    CreationsJobStorageType,
    CreationsStatus,
    CreationsStatusPayload,
    ELViewType,
    CreationsDownloadFileType,
    UNTITLED_INTL_KEY,
    ELCreationWorkflowPayload,
    CreationsMode,
} from "../../../../common/interfaces/creations/CreationTypes";
import {
    DialogContext,
    ELReplaceBackgroundCreationRequestParams,
    ELReplaceBackgroundProjectActionMode,
    ELReplaceBackgroundUpdateDocumentPayload,
    LayerOperation,
    LayerOperationsForCurrentType
} from "../../../../common/interfaces/creations/ELReplaceBackgroundTypes";
import {
    ELRecommendationWorkflowControllerActions,
    ELRecommendationsOutputJsonConfigData,
    ELThumbUpdateProps,
    ELPreviewCreationThumbData,
    ELCreateAndEditProjectParams,
    ELCreationContentPayload
} from "../../../../common/interfaces/creations/ELRecommendationsWorkflowTypes";
import CollageUtils from "./../collage/utils/CollageUtils";
import IDoc, { DocumentType } from "../../../../editors/document/IDoc";
import ELAdobeAssetDoc, { ELAdobeAssetDocPayload } from "../../../../editors/adobeAsset/ELAdobeAssetDoc";
import { DocumentActions, DocumentDirty, DocumentSaveStatus } from "../../../../common/interfaces/document/DocumentTypes";
import DocumentFactory, { DocumentFactoryPayload } from "../../../../editors/document/DocumentFactory";
import { ToastUtils } from "../../../../utils/ToastUtils";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { ELAdobeAssetControllerAction } from "../../../../common/interfaces/creations/templates/ELAdobeAssetDocTypes";
import { ELCreationsHeaderControllerAction } from "../../../../common/interfaces/creations/ELCreationsHeaderTypes";
import { CanvasZoomLevelAction, ELImageData, ELStageObject } from "../../../../common/interfaces/stage/StageTypes";
import { IngestUtils } from "../../../../utils/IngestUtils";
import { IngestEventSubTypes, IngestEventTypes, IngestLogObjectCustomKey, IngestLogObjectKey, IngestLogObjectValue, IngestWorkflowTypes } from "../../../../utils/IngestConstants";
import Constants from "../../../../utils/Constants/Constants";
import DocActions from "../../../../stores/actions/DocActions";
import { RecommendationWorkflowAction } from "../../../../stores/actions/RecommendationWorkflowAction";
import { ELContentCacheDownloader } from "../../utils/ELContentCacheDownloader";
import RecommendationsInAppNotifier from "../../utils/RecommendationsInAppNotifier";
import {
    CreationAppSubscriberType,
    CreationInAppNotifierAction,
    RecommendationsAppSubscriberType
} from "../../../../common/interfaces/creations/CreationInAppNotifierTypes";
import CreationInAppNotifier from "../../utils/CreationInAppNotifier";
import { HistoryUtils } from "../../../../utils/HistoryUtils";
import { StorageService } from "../../../../services/StorageServiceWrapper";
import CreationUtils from "../../utils/CreationUtils";
import { ReplaceMediaManagerMode, ReplaceMediaManagerWorkflowAction } from "../../../../common/interfaces/workflows/ReplaceMediaManagerTypes";
import { ReplaceAssetInfo } from "../../../../common/interfaces/creations/ELCollageTypes";
import ELOpenInDesktopManager from "../../../../view/components/templates/el-open-in-desktop-manager/ELOpenInDesktopManager";
import ELOpenInDesktopCreationObserver from "../../../../view/components/templates/el-open-in-desktop-manager/ELOpenInDesktopCreationObserver";
import { ELOpenDeeplinkAction, ELOpenInDesktopDeeplinkAction, ELOpenInDesktopManagerViewAction, ELOpenInDesktopOpenAssetPayload } from "../../../../common/interfaces/creations/ELOpenInDesktopTypes";
import FullResMediaAction from "../../../../stores/actions/FullResMediaAction";
import Utils from "../../../../utils/Utils";
import { FeatureName } from "../../../../services/Floodgate/FloodgateConstants";
import { AssetStorageUtils } from "../../../../utils/AssetStorageUtils";
import ELMediaRecommendationHeader from "../../../../view/components/templates/el-creations-header/ELMediaRecommendationHeader";
import { ELDualDocumentView } from "../../../../editors/document/dualDocumentView/ELDualDocumentView";
import { CreationMediaActionType } from "../../../../view/components/templates/el-creation-media-panel/ELCreationMediaView";
import ELClientRecommendations from "../client/ELClientRecommendations";
import { DocumentDataType } from "../../../../editors/document/dataResolver/ELAdobeAssetDataResolver";
import { ELContentCreationMediaData, ELDocumentDataConfig } from "../../../../common/interfaces/creations/client/ELContentCreationsCreatorTypes";
import { ELLayerKind, ELStageLayerData, ELStageLayerDataOptions } from "../../../../common/interfaces/editing/layer/ELStageLayerTypes";
import { PhotoshopService } from "../../../../services/editing/PhotoshopService";
import { PSFeatureName } from "../../../../common/interfaces/services/PhotoshopServiceTypes";
import { ELAdobeAsset } from "../../../../common/interfaces/storage/AssetTypes";
import ELPSDConvertorFactory from "../../../../editors/pie/psdConvertor/ELPSDConvertorFactory";
import PIEUtils, { PSD_NAME_INTL_KEY } from "../../../../editors/pie/utils/PIEUtils";
import { MagicalBackdropJobCreator } from "./utils/MagicalBackdropJobCreator";
import ELPanelManager from "../../../../view/components/templates/el-panel-manager/ELPanelManager";
import ELReplaceBackgroundPanelProvider from "../../utils/panelProvider/ELReplaceBackgroundPanelProvider";
import { ELTabPanelKey, ELTabPanelType } from "../../../../common/interfaces/tabpanel/ELTabPanelTypes";
import { CreationsJobCreator } from "../../utils/CreationsJobCreator";
import { ELStageDocActions, ELStageDocPayload } from "../../../../common/interfaces/document/ELStageDocTypes";
import ELClientUploadHandler from "../../../../modules/clientUploadHandler/ELClientUploadHandler";
import ELStageDocUtils from "../../../../utils/stageDoc/ELStageDocUtils";
import ELStageDocSaveManager from "../../../../editors/document/documentSaveManager/ELStageDocSaveManager";
import { ELError } from "../../../../modules/error/ELError";
import ImageUtils from "../../../../utils/ImageUtils";
import { StockServiceUtils } from "../../../../utils/StockServiceUtils";
import { ELStockWorkflowActions, StockDialogTriggerSource } from "../../../../common/interfaces/services/StockServiceTypes";
import StockServiceConstants from "../../../../utils/Constants/StockServiceConstants";
import { ContentDataManagerFactory } from "../../../../modules/contenDatatManager/ContentDataManagerFactory";
import { AssetDataInfo, BackgroundLayerRequestParams, contentDataTypeMap, contentSourceMap, GenericContentDataType, GenericContentSource } from "../../../../modules/contenDatatManager/ContentDataManagerTypes";
import { DEFAULT_SELECTED_COLOR, ELBackgroundContentPanelControllerAction } from "../../../../common/interfaces/creations/templates/ELBackgroundContentPanelTypes";
import { CollageBackgroundData } from "../../../../common/interfaces/creations/ElementsContentTypes";

interface ReplaceBackgroundCreateAndEditRequestParams extends ELCreateAndEditProjectParams {
    overlayId: string,
    createOrEditMode: ELReplaceBackgroundProjectActionMode,
    title?: string,
    layerDataOptionsList?: ELStageLayerDataOptions[],
    backgroundContentData: ELCreationContentPayload,
}

class ReplaceBackground extends ELClientRecommendations<ELAdobeAssetDocPayload> {
    private _creationsHeader!: ELMediaRecommendationHeader;
    private _leftTabPanel!: ELPanelManager;
    private _rightTabPanel!: ELPanelManager;
    private _replaceBackgroundPayload!: ELCreationWorkflowPayload;
    private _openInDesktopCreationObserver!: ELOpenInDesktopCreationObserver;
    private _progressTimer?: NodeJS.Timer;
    private _contentLayerId?: string;
    private _subjectLayerId?: string;
    private _backgroundContentData?: ELCreationContentPayload;

    private readonly _leftTabPanelContainer = "replace-background-left-panel-container";
    private readonly _rightTabPanelContainer = "replace-background-right-panel-container";
    private readonly _creationsHeaderContainer = "replace-background-creations-header-container";
    private readonly _openDeeplinkContainer = "open-deeplink-container";
    private readonly _feedbackContainer = "feedback-popover-container";
    private readonly _defaultContentResolution = Resolution._1080p;

    constructor(owner: IBaseWorkspace) {
        super(owner, WorkflowsName.replaceBackground);
        this.mediaGridConfig = ReplaceBackgroundUtils.getReplaceBackgroundMediaGridConfig();
    }

    protected getJobCreator(): CreationsJobCreator {
        return new MagicalBackdropJobCreator();
    }

    private _getContentLoggingInfo(): string | undefined {
        if (this._backgroundContentData) {
            const contentType = this._backgroundContentData.contentDataType;
            switch (contentType) {
                case GenericContentDataType.recommendation:
                case GenericContentDataType.graphics:
                case GenericContentDataType.transparent:
                    return `${contentType}`;
                case GenericContentDataType.userDefined: {
                    const userDefinedInfo = this._backgroundContentData.contentData as UserDefinedInfoType;
                    return `${contentType}_${userDefinedInfo.type}`;
                }
                case GenericContentDataType.http:
                    return `${contentType}_${this._backgroundContentData.contentSource}`;
            }
        }
        return undefined;
    }

    protected logIngestData(creationStatus: string, errorInfo?: string): void {
        try {
            const allMedia = store.getState().selectedMediaListReducer;

            const customEntries: Record<string, string> = IngestUtils.getMediaLoggingInfo(allMedia);
            customEntries[IngestLogObjectCustomKey.totalCount] = allMedia.length.toString();

            const selectedOverlayName = store.getState().recommendationWorkflowReducer.selectedOverlayName;

            if (selectedOverlayName) {
                customEntries[IngestLogObjectCustomKey.overlay] = selectedOverlayName;
            }

            customEntries[IngestLogObjectCustomKey.moveToolUsed] = this.moveToolIngestParams.toolUsed ? "yes" : "no";
            customEntries[IngestLogObjectCustomKey.moveToolTransformationUsed] = this.moveToolIngestParams.transformationUsed ? "yes" : "no";

            const eventContextId = Utils.getRandomUUID();
            const additionalLogInfo: Record<string, string> = {};
            additionalLogInfo[IngestLogObjectKey.eventContextGuid] = eventContextId;

            if (errorInfo)
                additionalLogInfo[IngestLogObjectKey.errorDescription] = errorInfo;

            if (!this.ingestParams.subType)
                this.ingestParams.subType = this.mode;

            const timeElapsed = Utils.getDateDifference(this.startDate, new Date(), "second");
            customEntries[IngestLogObjectCustomKey.timeTaken] = timeElapsed.toString();

            if (this.ingestParams.subType === CreationsMode.save && this.ingestParams.eventViewType) {
                additionalLogInfo[IngestLogObjectCustomKey.viewType] = this.ingestParams.eventViewType;
            }

            for (const key in customEntries) {
                const additionalLogInfoTemp = { ...additionalLogInfo };
                additionalLogInfoTemp[IngestLogObjectKey.eventContextGuid] = eventContextId;
                additionalLogInfoTemp[IngestLogObjectKey.contentName] = key;
                additionalLogInfoTemp[IngestLogObjectKey.eventCount] = customEntries[key];
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                    IngestEventSubTypes.info, this.ingestParams.subType, CreationsJobProjectSubType.replaceBackground, additionalLogInfoTemp));
            }

            const contentLog = this._getContentLoggingInfo();

            if (contentLog) {
                additionalLogInfo[IngestLogObjectKey.contentSource] = contentLog;
            }

            this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                creationStatus, this.ingestParams.subType, CreationsJobProjectSubType.replaceBackground, additionalLogInfo));
        }
        catch (e) {
            Logger.log(LogLevel.WARN, `ReplaceBackground:logIngestData:${this.mode}: `, `Dunamis Logging Error:${e as string}`);
        }
    }

    protected async createExportDocumentForContent(contentId: string): Promise<IDoc> {
        try {
            await this.createExportData();

            if (!this.exportInputData || !this.exportCutoutData) {
                return Promise.reject("exportInputData or exportCutoutData not set!");
            }

            const backgroundData: ImageData[] = [];
            backgroundData.push(this.exportInputData);

            const layerDataOptionsList = this.doc ? await ELStageDocUtils.getLayerDataOptionsList(this.doc) : undefined;
            const selectedExportDocument = (await this.createRecommendationDocuments(backgroundData, layerDataOptionsList ? layerDataOptionsList[0] : undefined))[0];

            const selectedContentData = await this._fetchContentData(contentId);
            await selectedExportDocument.addLayer(layerDataOptionsList ? { data: selectedContentData, ...layerDataOptionsList[1] } : { data: selectedContentData, fitToBackground: true, layerKind: ELLayerKind.pixel });

            await this.addLayerToRecommendationDocuments([selectedExportDocument], layerDataOptionsList ? { data: this.exportCutoutData, ...layerDataOptionsList[layerDataOptionsList.length - 1] } : { data: this.exportCutoutData, layerKind: ELLayerKind.pixel, hasAlpha: true });
            return Promise.resolve(selectedExportDocument);
        } catch (error) {
            return Promise.reject("Could not create export document for content " + contentId + error);
        }
    }

    //TODO: gmahal rename it to createExportDocumentURLForContentRecommendationContent in base class
    protected async createExportDocumentURLForContent(contentId: string): Promise<string> {
        const exportDocument = await this.createExportDocumentForContent(contentId);
        const exportURLs = await this.getRecommendationsDocumentURL([exportDocument]);

        this.exportDocumentsURLMap.set(contentId, exportURLs[0]);

        return Promise.resolve(exportURLs[0]);
    }

    //TODO: gmahal rename it to createExportDocumentsForRecommendationContent in base class
    protected async createExportDocuments(): Promise<void> {
        try {
            const contentData = await ELContentCacheDownloader.getContentForContentType(ContentType.replaceBackground);
            for (const content of contentData) {
                await this.createExportDocumentURLForContent(content.props.id);
            }
        } catch (error) {
            return Promise.reject("Could not create export document " + error);
        }
    }

    protected async createRecommendationDocuments(backgroundData: ImageData[], layerDataOptions?: ELStageLayerDataOptions): Promise<IDoc[]> {
        const recommendationDocs = [];
        for (const background of backgroundData) {
            let docPayload: ELStageDocPayload | undefined;
            if (layerDataOptions)
                docPayload = { ...layerDataOptions, data: background };
            const doc = await this.createTemporaryDocument(background, docPayload);
            recommendationDocs.push(doc);
        }
        return Promise.resolve(recommendationDocs);
    }

    private async _createStageDocument(layerDataOptionsList?: ELStageLayerDataOptions[]): Promise<IDoc> {
        if (!this.inputMedia || !this.maskAsset || !this._backgroundContentData) {
            return Promise.reject();
        }

        const documentConfig: ELDocumentDataConfig = { documentDataType: DocumentDataType.fullRes };
        const contentMediaData: ELContentCreationMediaData = { ...documentConfig, media: this.inputMedia };
        const imageData = await this.getMediaLayerData(contentMediaData);
        const docPayload: ELStageDocPayload = layerDataOptionsList ? { data: imageData, visible: false, ...layerDataOptionsList[0] } : { data: imageData, layerKind: ELLayerKind.pixel, visible: false };
        const docFactoryPayload: DocumentFactoryPayload = {
            docPayload: docPayload,
            stagePayload: {
                showReplaceMediaButton: false,
                showDeleteButton: false,
                objectHoverColor: "rgb(0, 255, 255)",
                viewType: ELViewType.after,
                addCanvasHandlers: true
            }
        };

        const doc = await DocumentFactory.createDocumentWithStage(DocumentType.stageDoc, this, docFactoryPayload);

        const contentDataManager = ContentDataManagerFactory.createManager(this._backgroundContentData.contentDataType);
        if (this._backgroundContentData.contentDataType !== GenericContentDataType.transparent) {
            const backgroundLayerPayloadRequestParams: BackgroundLayerRequestParams = {
                layerDataOptionsList: layerDataOptionsList,
                backgroundContentData: this._backgroundContentData,
                recommendationWidth: this.recommendationWidth,
                contentCreationCreator: this.contentCreationsCreator
            };
            const backgroundLayerPayload = await contentDataManager.getBackgroundLayerPayload(backgroundLayerPayloadRequestParams);
            this._contentLayerId = await doc.addLayer(backgroundLayerPayload);
        }

        const contentMaskData: ELContentCreationMediaData = { ...documentConfig, media: this.maskAsset };
        const cutoutData = await this.createCutoutForAsset(contentMaskData);

        this._subjectLayerId = await doc.addLayer(layerDataOptionsList ? { data: cutoutData, ...layerDataOptionsList[layerDataOptionsList.length - 1] } : { data: cutoutData, layerKind: ELLayerKind.pixel, hasAlpha: true, selectable: true });

        return doc;
    }

    private _updatedSelectedTabKey(): Promise<void> {
        if (!this._backgroundContentData) {
            return Promise.reject();
        }
        switch (this._backgroundContentData.contentDataType) {
            case GenericContentDataType.recommendation:
                this._leftTabPanel.updateSelectedTabKey(ELTabPanelKey.overlays);
                this._rightTabPanel.updateSelectedTabKey(ELTabPanelKey.empty);
                break;
            case GenericContentDataType.graphics:
            case GenericContentDataType.transparent:
            case GenericContentDataType.userDefined:
                this._leftTabPanel.updateSelectedTabKey(ELTabPanelKey.empty);
                this._rightTabPanel.updateSelectedTabKey(ELTabPanelKey.background);
                break;
            case GenericContentDataType.http:
                this._leftTabPanel.updateSelectedTabKey(ELTabPanelKey.empty);
                this._rightTabPanel.updateSelectedTabKey(ELTabPanelKey.adobeStock);
                break;
        }
        return Promise.resolve();
    }

    protected async onRecommendationsGenerated(recommendationDocs: IDoc[], contentData: ContentEntity[], layerDataOptionsList?: ELStageLayerDataOptions[]): Promise<void> {
        const recommendationURLs = await this.getRecommendationsDocumentURL(recommendationDocs);

        const mapOfOverlayIdAssetPath = await this._getClientRecommendedAssetsForOverlays(contentData, recommendationURLs);
        if (this._backgroundContentData === undefined) {
            const selectedOverlayId = await this._getFirstOverlayIdToRender();
            this._backgroundContentData = {
                contentDataType: GenericContentDataType.recommendation,
                backgroundId: selectedOverlayId,
            };
        }

        this._updatePanelData(this._backgroundContentData);

        await this._updatedSelectedTabKey();
        await this._updateOverlayPanelViewForRequestedOverlays(mapOfOverlayIdAssetPath);

        this.doc?.destroy();
        this.doc = await this._createStageDocument(layerDataOptionsList);
        await this._renderAfterDoc();
        this.doc?.markAndNotifyDocumentDirty(this.mode === CreationsMode.open ? DocumentDirty.NON_DIRTY : DocumentDirty.DIRTY);

        const backgroundId = this._backgroundContentData.backgroundId;
        const isRecommendationContent = this._backgroundContentData.contentDataType === GenericContentDataType.recommendation && backgroundId;

        if (isRecommendationContent) {
            this._reorderPanelThumbnails(backgroundId);
        }
        this.logIngestData(IngestEventSubTypes.success);
        this.createExportDocuments();

        store.dispatch(RecommendationWorkflowAction.updateRecommendationStatus(CreationsStatus.success));
        this.updateViewStatus(CreationsStatus.success);
        this.updateView(ELViewType.after);
    }

    protected async generateRecommendations(contentType: ContentType, layerDataOptionsList?: ELStageLayerDataOptions[]): Promise<void> {
        if (!this.inputMedia) {
            return Promise.reject("inputMedia not set!");
        }
        try {
            const contentData = await ELContentCacheDownloader.getContentForContentType(contentType);

            const documentConfig: ELDocumentDataConfig = { documentDataType: DocumentDataType.rendition, size: this.recommendationWidth };
            const contentMediaData: ELContentCreationMediaData = { ...documentConfig, media: this.inputMedia };
            const imageData = await this.getMediaLayerData(contentMediaData);

            const contentsData = await this.getContentsData(contentData);

            const backgroundData: ImageData[] = [];
            for (let index = 0; index < contentData.length; index++) {
                backgroundData.push(imageData);
            }

            const recommendationDocs = await this.createRecommendationDocuments(backgroundData);

            const addLayerPromises = [];

            for (const [index, content] of contentsData.entries()) {
                addLayerPromises.push(recommendationDocs[index].addLayer({ data: content, fitToBackground: true, layerKind: ELLayerKind.pixel }));
            }

            await Promise.all(addLayerPromises);

            const maskURL = await this.maskPathPromise;
            if (maskURL) {
                this.maskAsset = { isLocal: true, url: maskURL };

                Logger.log(LogLevel.DEBUG, "generateRecommendations - maskPath and maskPathPromise : ", maskURL, this.maskPathPromise);

                await this.createExportPromises();

                const contentMaskData: ELContentCreationMediaData = { ...documentConfig, media: this.maskAsset };
                const cutoutData = await this.createCutoutForAsset(contentMaskData);

                await this.addLayerToRecommendationDocuments(recommendationDocs, { data: cutoutData, layerKind: ELLayerKind.pixel, hasAlpha: true });
                await this.onRecommendationsGenerated(recommendationDocs, contentData, layerDataOptionsList);
            } else {
                throw new Error("maskPath couldn't be resolved");
            }
        } catch (error) {
            const elError = new ELError("Replace background - Unable to generate recommendations", this.getWorkflowConfiguration(), error as Error);
            Logger.log(LogLevel.ERROR, elError);
            ToastUtils.error(IntlHandler.getInstance().formatMessage("subject-not-found-error"));
            this.startPreviousWorkflow();
            this.logIngestData(IngestEventSubTypes.error, "Couldn't generate recommendations");
        }

        this._updateProgressPercentageToView(0);
        clearInterval(this._progressTimer);
        return Promise.resolve();
    }

    // TODO: gmahal check if we need to remove documentType from the method signature
    protected async exportPSD(documentType: DocumentType, doResize = false): Promise<IDoc> {
        const psdConvertor = ELPSDConvertorFactory.createConvertor(DocumentType.stageDoc);
        if (this.doc) {
            const psdDoc = await psdConvertor.convertToPSD(this.doc, doResize);
            return Promise.resolve(psdDoc);
        }
        return Promise.reject("Couldn't create PSD document!");
    }

    //TODO: gmahal remove asset if it was not required
    private async _createReplaceBackground(asset: ELAdobeAsset, layerDataOptionsList?: ELStageLayerDataOptions[]): Promise<void> {
        this.startDate = new Date();

        if (!this.inputMedia) {
            return Promise.reject("inputMedia not set!");
        }
        this.maskPathPromise = PhotoshopService.applyEdit({ featureName: PSFeatureName.generateMask, inputPath: await AssetStorageUtils.getDownloadURI(this.inputMedia) });
        this.generateRecommendations(ContentType.replaceBackground, layerDataOptionsList);
    }


    protected updateRecommendationWorkflowRoute(): void {
        if (!this.projectId) {
            const elError = new ELError("ReplaceBackground:updateRecommendationWorkflowRoute, project id not valid", this.getWorkflowConfiguration());
            Logger.log(LogLevel.ERROR, elError);
            return;
        }

        HistoryUtils.replaceHistory(ReplaceBackgroundUtils.getReplaceBackgroundHistoryState(this.projectId));
    }

    protected async editRecommendationsProject(editRequestParams: ReplaceBackgroundCreateAndEditRequestParams): Promise<void> {
        const { asset, createOrEditMode, overlayId, title, layerDataOptionsList, backgroundContentData } = editRequestParams;

        const intlHandler = IntlHandler.getInstance();
        const trueTitle = this.getTrueTitleForRequest(title ?? intlHandler.formatMessage(UNTITLED_INTL_KEY));

        if (!this.projectId || !this.projectData) {
            const elError = new ELError("ReplaceBackground:_editReplaceBackgroundProject project id or project data not valid", this.getWorkflowConfiguration());
            Logger.log(LogLevel.ERROR, elError);
            return Promise.reject();
        }

        const contentDataManager = ContentDataManagerFactory.createManager(backgroundContentData.contentDataType);
        const populatedContentData = await contentDataManager.getBackgroundContentData(backgroundContentData);

        let projectRequestParams: ELReplaceBackgroundCreationRequestParams = {
            assets: [asset],
            backgroundId: [overlayId],
            title: trueTitle,
            outputTypes: [OutputType.preview],
            contentResolution: this._defaultContentResolution,
            layerDataOptionsList: layerDataOptionsList,
            contentDataType: backgroundContentData.contentDataType,
            contentData: populatedContentData
        };

        try {
            projectRequestParams = this._getUpdatedParamsBasedOnActionMode(createOrEditMode, projectRequestParams);
            this.projectParams = projectRequestParams;
            const requestJson = this.createRequestJson(projectRequestParams);

            this.projectId = await this.editCreation(this.projectId, requestJson);
            this.projectData = await this.getProjectData(this.projectId);
            await this._saveAssetAndMaskToProjectPath(requestJson);
        } catch (error) {
            Logger.log(LogLevel.WARN, "ReplaceBackground:_editReplaceBackgroundProject: , project edit failed!" + error);
            return Promise.reject();
        }
        if (createOrEditMode === ELReplaceBackgroundProjectActionMode.openInDesktop)
            this.pollOpenInDesktopProjectStatus(this._openInDesktopCreationObserver);
        else
            this.pollProjectStatus();
    }

    private _getUpdatedParamsBasedOnActionMode = (createMode: ELReplaceBackgroundProjectActionMode,
        projectRequestParams: ELReplaceBackgroundCreationRequestParams): ELReplaceBackgroundCreationRequestParams => {
        const updatedProjectRequestParams = projectRequestParams;
        if (createMode === ELReplaceBackgroundProjectActionMode.openInDesktop) {
            updatedProjectRequestParams.outputTypes.push(OutputType.full);
        }
        return updatedProjectRequestParams;
    };

    private async _saveMaskAsset(outputPath: string): Promise<void> {
        if (this.maskAsset) {
            const downloadURI = await AssetStorageUtils.getDownloadURI(this.maskAsset);
            const response = await axios.get(downloadURI, { responseType: "blob" });
            const blob = response.data as Blob;

            const maskPathPrefix = outputPath.substring(0, outputPath.lastIndexOf("/"));
            const maskPath = maskPathPrefix + Constants.DIR_SEPERATOR + this.maskAssetName;

            const clientUploadHandler = new ELClientUploadHandler();
            await clientUploadHandler.upload({
                assetPath: maskPath,
                contentType: "image/png",
                saveInfo: { blob: blob }
            });
        }
    }

    private async _saveAssetAndMaskToProjectPath(projectInfo: unknown): Promise<void> {
        try {
            if (!this.projectId || !this.projectData || !this.doc) {
                const elError = new ELError("ReplaceBackground: (_saveAssetAndMaskToProjectPath) project id or data or doc not valid", this.getWorkflowConfiguration());
                Logger.log(LogLevel.ERROR, elError);
                return Promise.reject();
            }
            const outputAssetPath = (await CreationUtils.getCreationOutputAssetPathOrId(this.projectData)).path;
            if (!outputAssetPath) {
                return Promise.reject();
            }
            const saveManager = new ELStageDocSaveManager();
            const success = await saveManager.save(this.doc, outputAssetPath, projectInfo as Record<string, unknown>);
            if (success) {
                await CreationUtils.updateCreationStatus(this.projectId, CreationsStatus.success);
                this.doc.markAndNotifyDocumentDirty(DocumentDirty.NON_DIRTY);
                this._saveMaskAsset(outputAssetPath);
            }
        } catch (error) {
            const elError = new ELError("ReplaceBackground:_saveAssetAndMaskToProjectPath", this.getWorkflowConfiguration(), error as Error);
            Logger.log(LogLevel.ERROR, elError);
            return Promise.reject("Couldn't save auto background document");
        }
    }

    protected async createRecommendationProject(createProjectParams: ReplaceBackgroundCreateAndEditRequestParams): Promise<void> {
        const { asset, createOrEditMode, overlayId, title, layerDataOptionsList, backgroundContentData } = createProjectParams;
        const intlHandler = IntlHandler.getInstance();
        const trueTitle = this.getTrueTitleForRequest(title ?? intlHandler.formatMessage(UNTITLED_INTL_KEY));

        const contentDataManager = ContentDataManagerFactory.createManager(backgroundContentData.contentDataType);
        const populatedContentData = await contentDataManager.getBackgroundContentData(backgroundContentData);

        let projectRequestParams: ELReplaceBackgroundCreationRequestParams = {
            assets: [asset],
            title: trueTitle,
            backgroundId: [overlayId],
            outputTypes: [OutputType.preview],
            contentResolution: this._defaultContentResolution,
            layerDataOptionsList: layerDataOptionsList,
            contentDataType: backgroundContentData.contentDataType,
            contentData: populatedContentData,
            contentSource: backgroundContentData.contentSource
        };

        try {
            projectRequestParams = this._getUpdatedParamsBasedOnActionMode(createOrEditMode, projectRequestParams);
            this.projectParams = projectRequestParams;
            const requestJson = this.createRequestJson(projectRequestParams);

            this.projectId = await this.createCreation(requestJson);
            this.projectData = await this.getProjectData(this.projectId);
            await this._saveAssetAndMaskToProjectPath(requestJson);
            this._notifySubViews();
        } catch (error) {
            const elError = new ELError("ReplaceBackground project creation failed!", this.getWorkflowConfiguration(), error as Error);
            Logger.log(LogLevel.ERROR, elError);
            const message = IntlHandler.getInstance().formatMessage("failed-recommendation-project");
            ToastUtils.error(message);
            this.notify({ type: ELRecommendationWorkflowControllerActions.backRecommendationWorkflowView });
            this.logIngestData(IngestEventSubTypes.error, "ReplaceBackground project creation failed");
            return Promise.reject();
        }

        this.updateRecommendationWorkflowRoute();
    }

    protected async createAndRenderBeforeDoc(replaceBackgroundDocPayload: ELAdobeAssetDocPayload): Promise<void> {
        this.beforeDoc?.destroy();

        const beforeDocPayload: ELAdobeAssetDocPayload = {
            assetInfo: { ...replaceBackgroundDocPayload.assetInfo, objectURL: undefined }
        };

        const docFactoryPayload: DocumentFactoryPayload = {
            docPayload: beforeDocPayload,
            stagePayload: {
                showReplaceMediaButton: false,
                showDeleteButton: false,
                objectHoverColor: "rgb(0, 255, 255)",
                viewType: ELViewType.before
            }
        };

        this.beforeDoc = await DocumentFactory.createDocumentWithStage(DocumentType.adobeAsset, this, docFactoryPayload) as ELAdobeAssetDoc;

        this.dualView = new ELDualDocumentView(this.beforeDoc);

        try {
            await this.dualView.createView(this.ensureHTMLElement("replace-background-edit-container"));
        } catch (error) {
            const elError = new ELError("ReplaceBackground:_createAndRenderBeforeDoc: Couldn't render document", this.getWorkflowConfiguration(), error as Error);
            Logger.log(LogLevel.ERROR, elError);
            ToastUtils.error(IntlHandler.getInstance().formatMessage("creations-render-error", { workflow: IntlHandler.getInstance().formatMessage("replace-background-creation") }));
            this.startPreviousWorkflow();
            this.logIngestData(IngestEventSubTypes.error, "Document render failed");
        }

        Logger.log(LogLevel.INFO, "Document Object: ", this.beforeDoc);
    }

    private _shouldGenerateExportDocument(): boolean {
        return (this.exportPromises.length > 0);
    }

    private async _renderAfterDoc(): Promise<void> {
        if (!this.doc) {
            return Promise.reject();
        }

        try {
            await this.dualView?.renderAfterDoc(this.doc);
        } catch (error) {
            const elError = new ELError("ReplaceBackground:_renderAfterDoc: Couldn't render document", this.getWorkflowConfiguration(), error as Error);
            Logger.log(LogLevel.ERROR, elError);
            ToastUtils.error(IntlHandler.getInstance().formatMessage("creations-render-error", { workflow: IntlHandler.getInstance().formatMessage("replace-background-creation") }));
            this.startPreviousWorkflow();
            this.logIngestData(IngestEventSubTypes.error, "Document render failed");
        }
    }

    protected async createAndRenderAfterDoc(replaceBackgroundDocPayload: ELAdobeAssetDocPayload, documentDirty: DocumentDirty): Promise<void> {
        this.doc?.destroy();

        const docFactoryPayload: DocumentFactoryPayload = {
            docPayload: replaceBackgroundDocPayload,
            stagePayload: {
                showReplaceMediaButton: false,
                showDeleteButton: false,
                objectHoverColor: "rgb(0, 255, 255)",
                viewType: ELViewType.after,
                addCanvasHandlers: true
            }
        };

        this.doc = await DocumentFactory.createDocumentWithStage(DocumentType.adobeAsset, this, docFactoryPayload) as ELAdobeAssetDoc;
        this.doc.markAndNotifyDocumentDirty(documentDirty);
        await this._renderAfterDoc();
        Logger.log(LogLevel.INFO, "Document Object: ", this.doc);
    }

    private async _getDocumentPayload(asset: ELAdobeAsset): Promise<ELAdobeAssetDocPayload> {
        const assetId = asset.assetId;

        let cachedObjectURL: string | undefined = undefined;

        const backgroundContentData = this._backgroundContentData;
        const isRecommendationContent = backgroundContentData && backgroundContentData.contentDataType === GenericContentDataType.recommendation;
        if (isRecommendationContent) {
            const id = backgroundContentData.backgroundId;
            if (id && this.exportDocumentsURLMap.has(id)) {
                cachedObjectURL = this.exportDocumentsURLMap.get(id);
            } else if (id && this._shouldGenerateExportDocument()) {
                cachedObjectURL = await this.createExportDocumentURLForContent(id);
            }
        }

        const objectURL: string | undefined = cachedObjectURL ?? CollageUtils.getAssetFullResObjectURL(assetId ?? "");
        const tempAsset: CSAssetWithData = {
            id: Utils.getRandomUUID(),
            assetURN: asset.assetId ?? "",
            storageType: CreationsJobStorageType.RAPI,
            mimeType: asset.format ?? "",
            objectURL: objectURL
        };
        const replaceBackgroundDocPayload: ELAdobeAssetDocPayload = {
            assetInfo: tempAsset
        };
        return replaceBackgroundDocPayload;
    }

    private async _fetchContentData(contentID: string): Promise<ImageData> {
        const contentData = await ELContentCacheDownloader.getContentForContentType(ContentType.replaceBackground);
        for (const content of contentData) {
            if (contentID === content.props.id) {
                const imageData = (await this.getContentsData([content]))[0];
                return imageData;
            }
        }
        return Promise.reject("contentID invalid");
    }

    private async _removeContentLayer(deleteLayerId?: string): Promise<void> {
        if (deleteLayerId) {
            await this.doc?.notify({ type: ELStageDocActions.removeLayer, payload: { layerId: deleteLayerId, redraw: true } });
        }
    }

    private async _updateContentLayerData(contentData: unknown): Promise<void> {
        const payload = { layerId: this._contentLayerId, data: contentData };
        await this.doc?.notify({ type: ELStageDocActions.updateLayerData, payload: payload });
    }

    private async getContentsDataFromUrl(contentUrl: string): Promise<ImageData[]> {
        const contentDataPromises = [];
        contentDataPromises.push(ImageUtils.createImageData(contentUrl));

        const contentsData = await Promise.all(contentDataPromises);

        return Promise.resolve(contentsData);
    }

    private async _fetchContentDataFromUrl(contentUrl: string): Promise<ImageData> {
        if (contentUrl) {
            const imageData = (await this.getContentsDataFromUrl(contentUrl))[0];
            return imageData;
        }
        return Promise.reject("Content url is invalid");
    }

    private async _addContentLayer(contentData: ELStageLayerData): Promise<void> {
        this._contentLayerId = await this.doc?.addLayerByIndex(contentData, 1);
    }

    private _updateViewStatusAndProgressText(contentPayload: ELCreationContentPayload): void {
        switch (contentPayload.contentDataType) {
            case GenericContentDataType.http: {
                if (contentPayload.contentSource === GenericContentSource.adobeStock) {
                    this.updateViewStatusAndProgressText(
                        CreationsStatus.applyingCustom,
                        IntlHandler.getInstance().formatMessage("generating-preview-licensed-image")
                    );
                }
                break;
            }
            case GenericContentDataType.graphics:
                this.updateViewStatusAndProgressText(
                    CreationsStatus.requested,
                    IntlHandler.getInstance().formatMessage("collage-changing-background")
                );
                break;
        }
    }

    private async _applyImage(imageId: string): Promise<void> {
        const downloadedImageData = await StockServiceUtils.downloadImage(imageId, StockServiceConstants.STOCK_IMAGE_SIZE_FOR_REPLACE_BACKGROUND as number);
        const imageUrl = window.URL.createObjectURL(downloadedImageData);
        const imageData = await this._fetchContentDataFromUrl(imageUrl);

        if (this._contentLayerId === undefined) {
            await this._addContentLayer({ data: imageData, fitToBackground: true, layerKind: ELLayerKind.pixel });
        }
        else {
            await this._updateContentLayerData(imageData);
        }
    }

    private _getLayerOperations(currentType: GenericContentDataType, previousType: GenericContentDataType): LayerOperation | undefined {
        const previousLayerMap = LayerOperationsForCurrentType.get(currentType);
        if (previousLayerMap) {
            return previousLayerMap.get(previousType);
        }
        return undefined;
    }


    private async _applyBackground(currentContentPayload: ELCreationContentPayload): Promise<void> {
        if (!this._backgroundContentData) {
            return Promise.reject();
        }
        this.startDate = new Date();

        this.doc?.markAndNotifyDocumentDirty(DocumentDirty.DIRTY);
        this._updateViewStatusAndProgressText(currentContentPayload);

        this._updatePanelData(currentContentPayload);

        const previousContentType = this._backgroundContentData.contentDataType;
        const contentManager = ContentDataManagerFactory.createManager(currentContentPayload.contentDataType);

        const layerOperation = this._getLayerOperations(currentContentPayload.contentDataType, previousContentType);
        switch (layerOperation) {
            case LayerOperation.UPDATE_LAYER: {
                const contentData = await contentManager.getUpdateContentData(currentContentPayload);
                await this._updateContentLayerData(contentData);
                break;
            }
            case LayerOperation.REPLACE_LAYER: {
                const deleteLayerId = this._contentLayerId;
                const contentLayer = await contentManager.getContentLayerData(currentContentPayload);
                await this._addContentLayer(contentLayer);
                await this._removeContentLayer(deleteLayerId);
                break;
            }
            case LayerOperation.REMOVE_LAYER:
                await this._removeContentLayer(this._contentLayerId);
                this._contentLayerId = undefined;
                break;
            case LayerOperation.ADD_LAYER: {
                const contentLayer = await contentManager.getContentLayerData(currentContentPayload);
                await this._addContentLayer(contentLayer);
                break;
            }
        }

        this._backgroundContentData = currentContentPayload;

        this.updateView(ELViewType.after);

        this.ingestParams.subType = CreationsMode.update;
        this.updateViewStatus(CreationsStatus.success);
        this.logIngestData(IngestEventSubTypes.success);
    }

    private async _populatePanelData(projectData: ELRecommendationsOutputJsonConfigData): Promise<void> {
        await this.populateSelectedMediaList(projectData.assets);
    }

    private async _processFirstRender(outputJsonData: ELRecommendationsOutputJsonConfigData): Promise<void> {
        if (!this._backgroundContentData) {
            return Promise.reject();
        }
        this._updatePanelData(this._backgroundContentData);

        const replaceBackgroundDocPayload: ELAdobeAssetDocPayload = {
            assetInfo: outputJsonData.assets[0]
        };
        await this.createAndRenderBeforeDoc(replaceBackgroundDocPayload);
    }

    protected async _getBackgroundContentData(outputJsonData: unknown): Promise<ELCreationContentPayload | undefined> {
        const requestObj = await this.getSerializedRequestObject(outputJsonData);
        const operation = requestObj.operations[0];
        const operationTypeInfo = operation.operationTypeInfo as CreationOperationTypeInfo;
        const operationSubTypeInfo = operationTypeInfo.operationSubTypeInfo as MagicalBackdropCreationOperationSubTypeInfo;
        const backgroundContentInfo = operationSubTypeInfo.configurations[0].configurationInfo?.configurationInfo;
        if (backgroundContentInfo) {
            const backgroundContentPayload: ELCreationContentPayload = {
                contentDataType: GenericContentDataType.recommendation
            };
            backgroundContentPayload.contentSource = backgroundContentInfo.contentSource ? contentSourceMap[backgroundContentInfo.contentSource] : undefined;
            if (backgroundContentInfo.contentDataType === undefined) {
                const template = backgroundContentInfo.template;
                backgroundContentPayload.backgroundId = template?.operationContentData?.id;
                backgroundContentPayload.contentDataType = (backgroundContentPayload.backgroundId === undefined) ? GenericContentDataType.transparent : GenericContentDataType.recommendation;
            } else {
                backgroundContentPayload.contentDataType = contentDataTypeMap[backgroundContentInfo.contentDataType];
                backgroundContentPayload.contentSource = backgroundContentInfo.contentSource ? contentSourceMap[backgroundContentInfo.contentSource] : undefined;
                backgroundContentPayload.contentData = backgroundContentInfo.contentDataInfo?.serializeOut();
            }
            return backgroundContentPayload;
        }
        return undefined;
    }

    private async _processUIRenderForProject(): Promise<void> {
        if (!this.projectData || !this.projectData.outputs) {
            const message = IntlHandler.getInstance().formatMessage("failed-to-get-project-outputs");
            ToastUtils.warning(message);
            this.notify({ type: ELRecommendationWorkflowControllerActions.backRecommendationWorkflowView });
            this.logIngestData(IngestEventSubTypes.error, "ReplaceBackground get project outputs failed");
            return;
        }

        if (!this.projectData.outputs.preview || !this.projectData.outputs.preview.assetURN) {
            Logger.log(LogLevel.WARN, "ReplaceBackground:_processUIRenderForProject, not able to find preview assetURN in project data.");
            return Promise.reject();
        }
        const assetId = this.projectData.outputs.preview.assetURN;
        const projectOutputAsset = await StorageService.getInstance().resolveAsset({ assetId: assetId }, "id");
        const json = await StorageService.getInstance().getAppMetadata(projectOutputAsset);

        const outputConfigJson = json as unknown as ELRecommendationsOutputJsonConfigData;
        const layerDataOptionsList = await this.getLayerDataOptionsList(json);

        this._backgroundContentData = await this._getBackgroundContentData(json);

        await this._populatePanelData(outputConfigJson);

        this.inputMedia = store.getState().selectedMediaListReducer[0];

        try {
            if (!this.projectData.assets) {
                const message = IntlHandler.getInstance().formatMessage("creation-asset-not-found");
                ToastUtils.error(message);
                this.notify({ type: ELRecommendationWorkflowControllerActions.backRecommendationWorkflowView });
                return Promise.reject();
            }
            const assetId = this.projectData.assets[0].assetURN;
            const projectAsset = await StorageService.getInstance().resolveAsset({ assetId }, "id");

            this._createReplaceBackground(projectAsset, layerDataOptionsList);
        } catch (error) {
            const elError = new ELError("ReplaceBackground:_openSavedReplaceBackground, not able to resolve asset, might be deleted", this.getWorkflowConfiguration(), error as Error);
            Logger.log(LogLevel.ERROR, elError);
            const message = IntlHandler.getInstance().formatMessage("failed-to-resolve-asset-for-doc");
            ToastUtils.error(message);
            this.notify({ type: ELRecommendationWorkflowControllerActions.backRecommendationWorkflowView });
            this.logIngestData(IngestEventSubTypes.error, "Not able to resolve asset");
            return Promise.reject();
        }
        this._notifySubViews();
        this._processFirstRender(outputConfigJson);
    }

    private async _openSavedReplaceBackground(): Promise<void> {
        this.ingestParams.subType = this.mode;
        this._processUIRenderForProject();
        this.pollProjectStatus();
    }

    private async _getAndSetProjectData(projectId: string): Promise<void> {
        this.projectId = projectId;
        try {
            this.projectData = await this.getProjectData(this.projectId);
            this.updateRecommendationWorkflowRoute();
        } catch (error) {
            const elError = new ELError("ReplaceBackground:_getAndSetProjectData, failed to get projectData for projectId", this.getWorkflowConfiguration(), error as Error);
            Logger.log(LogLevel.ERROR, elError);
            ToastUtils.error(IntlHandler.getInstance().formatMessage("failed-to-get-project-outputs"));
            this.notify({ type: ELRecommendationWorkflowControllerActions.backRecommendationWorkflowView });
            this.logIngestData(IngestEventSubTypes.error, "ReplaceBackground get project outputs failed");
            return Promise.reject();
        }
    }

    private _updateProgressValueWithTimer(): void {
        let timerSeed = 1;
        let lastProgressValue = 0;
        const slowUpdateBreakpoint = 90;
        const intervalDuration = 1000;
        this._progressTimer = setInterval(() => {
            let progressValue = Math.floor((timerSeed * 10) - Math.random() * 10);

            if (progressValue > slowUpdateBreakpoint) {
                progressValue = slowUpdateBreakpoint + (timerSeed % 10);
                progressValue = (progressValue < lastProgressValue) ? lastProgressValue : progressValue;
            }
            lastProgressValue = progressValue;
            this._updateProgressPercentageToView(progressValue);
            timerSeed++;
        }, intervalDuration);
    }

    protected async openProject(projectId: string): Promise<void> {
        await this._getAndSetProjectData(projectId);
        this._openSavedReplaceBackground();
    }

    protected async enterProject(replaceBackgroundPayload: ELCreationWorkflowPayload): Promise<void> {
        try {
            store.dispatch(RecommendationWorkflowAction.updateRecommendationStatus(CreationsStatus.requested));
            this._updateProgressValueWithTimer();
            const isCreationActive = this.shouldAllowCreationToOpen(FeatureName.eReplaceBackground);
            if (!isCreationActive) {
                ToastUtils.error(IntlHandler.getInstance().formatMessage("creation-disabled-via-feature-flag-message"));
                this.notify({ type: ELRecommendationWorkflowControllerActions.backRecommendationWorkflowView });
                return;
            }
            switch (replaceBackgroundPayload.initMode) {
                case CreationsMode.create: {
                    this.projectId = undefined;
                    this.updateStoreStateOnEnter();
                    const asset = (replaceBackgroundPayload.payload as ELAdobeAsset[])[0];

                    if (!asset) {
                        throw new Error("Media not found!");
                    }

                    this.inputMedia = asset;
                    const documentPayload = await this._getDocumentPayload(asset);
                    this.createAndRenderBeforeDoc(documentPayload);
                    store.dispatch(DocActions.updateDocumentDirty(DocumentDirty.DIRTY));
                    const defaultTitle = IntlHandler.getInstance().formatMessage(UNTITLED_INTL_KEY);
                    store.dispatch(RecommendationWorkflowAction.updateProjectTitle(defaultTitle));
                    this._createReplaceBackground(asset);
                    break;
                }
                case CreationsMode.open: {
                    const replaceBackgroundStatusPayload = replaceBackgroundPayload.payload as CreationsStatusPayload;
                    this.openProject(replaceBackgroundStatusPayload.projectId);
                    break;
                }
                default: {
                    Logger.log(LogLevel.WARN, "ReplaceBackground (_enterReplaceBackground) - Invalid replaceBackground mode");
                    break;
                }
            }
        } catch (error) {
            Logger.log(LogLevel.DEBUG, "Unable to enter replace background - ", error);
            this.startPreviousWorkflow();
        }
    }

    private async _getClientRecommendedAssetsForOverlays(contents: ContentEntity[], receommendationURLs: string[]): Promise<Map<TemplateId, ELAdobeAsset>> {
        try {
            const mapOfTemplateIdToAssets: Map<TemplateId, ELAdobeAsset> = new Map();

            for (const [index, url] of receommendationURLs.entries()) {
                const tempAsset: ELAdobeAsset = { assetId: Utils.getRandomUUID(), url: url, isLocal: true };
                mapOfTemplateIdToAssets.set(contents[index].props.id, tempAsset);
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                store.dispatch(FullResMediaAction.updateData({ assetId: tempAsset.assetId!, objectURL: url }));
            }

            return mapOfTemplateIdToAssets;
        } catch (error) {
            const elError = new ELError("ReplaceBackground:_getRecommendedAssetsForOverlays, failed to process OutputResultConfig.json", this.getWorkflowConfiguration(), error as Error);
            Logger.log(LogLevel.ERROR, elError);
            const message = IntlHandler.getInstance().formatMessage("failed-to-get-project-outputs");
            ToastUtils.error(message);
            this.notify({ type: ELRecommendationWorkflowControllerActions.backRecommendationWorkflowView });
            this.logIngestData(IngestEventSubTypes.error, "ReplaceBackground get project outputs failed");
            return Promise.reject();
        }
    }

    protected async updateDocumentWithOverlay(payload: ELReplaceBackgroundUpdateDocumentPayload): Promise<void> {
        const mapOfTemplateIdToAsset = payload.mapOfTemplateIdToAsset;
        const overlayId = payload.overlayId;
        const asset = mapOfTemplateIdToAsset.get(overlayId);
        if (asset) {
            const isDocumentDirty = store.getState().docStateReducer.isDirty;
            const docPayload = await this._getDocumentPayload(asset);
            await this.createAndRenderAfterDoc(docPayload, isDocumentDirty);
            this.updateView(ELViewType.after);
        }
    }

    private async _getFirstOverlayIdToRender(): Promise<string> {
        let selectedOverlayId = store.getState().recommendationWorkflowReducer.selectedOverlayId;
        const projectId = store.getState().recommendationWorkflowReducer.id;

        if (!selectedOverlayId) {
            const overlayContent = await ELContentCacheDownloader.getContentForContentType(ContentType.replaceBackground);
            const randomOverlayId = Math.floor(Math.random() * overlayContent.length);
            selectedOverlayId = overlayContent[randomOverlayId].props.id;
            if (!projectId) this.ingestParams.subType = CreationsMode.create;
        }

        return selectedOverlayId;
    }

    private _reorderPanelThumbnails(overlayId: string): void {
        this._leftTabPanel.notify({
            type: ELRecommendationWorkflowControllerActions.keepThumbIdOnTop,
            payload: overlayId
        });
    }

    private async _updateOverlayPanelViewForRequestedOverlays(mapOfOverlayIdAssetPath: Map<TemplateId, ELAdobeAsset>): Promise<void> {
        const overlayData = await ELContentCacheDownloader.getContentForContentType(ContentType.replaceBackground);
        overlayData.forEach(data => {
            if (mapOfOverlayIdAssetPath.has(data.props.id)) {
                const updatedThumbData: ELThumbUpdateProps = {
                    id: data.props.id,
                    asset: mapOfOverlayIdAssetPath.get(data.props.id)
                };
                this._leftTabPanel.notify({
                    type: ELRecommendationWorkflowControllerActions.updateSingleOverlayData,
                    payload: updatedThumbData
                });
            }
        });
    }

    private _notifySubViews(): void {
        this._creationsHeader.notify({ type: ELCreationsHeaderControllerAction.updateCreationsData, payload: this.projectData });
    }

    private _shouldContinueFunctionality(): boolean {
        const contentSource = this._backgroundContentData ? this._backgroundContentData.contentSource : undefined;
        if (contentSource && contentSource === GenericContentSource.adobeStockPreview) {
            return false;
        }
        return true;
    }

    private async _save(): Promise<void> {
        try {
            store.dispatch(DocActions.updateDocumentSaveStatus(DocumentSaveStatus.saveInProgress));

            this.updateViewStatusAndProgressText(
                CreationsStatus.requested,
                IntlHandler.getInstance().formatMessage("saving-creation")
            );

            if (!this._backgroundContentData) {
                return Promise.reject();
            }

            const contentSource = this._backgroundContentData ? this._backgroundContentData.contentSource : undefined;
            await this._checkAndApplyLicensedImage(contentSource);

            this.startDate = new Date();
            this._updateProgressPercentageToView(Constants.ZERO as number);
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const selectedOverlayId = store.getState().recommendationWorkflowReducer.selectedOverlayId!;

            if (contentSource === GenericContentSource.adobeStock) {
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.replaceBackground, IngestEventTypes.click, IngestEventSubTypes.stock, "Save"));
            }
            const asset = store.getState().selectedMediaListReducer[0];
            const message = IntlHandler.getInstance().formatMessage("saving-creation");
            this.updateProgressText(message);
            const title = store.getState().recommendationWorkflowReducer.title;
            const layerDataOptionsList = this.doc ? await ELStageDocUtils.getLayerDataOptionsList(this.doc) : undefined;

            if (!this.projectId) {
                Logger.log(LogLevel.INFO, "ReplaceBackground:_save, project is not created yet, so no projectId found");
                await this.createRecommendationProject({ asset: asset, overlayId: selectedOverlayId, createOrEditMode: ELReplaceBackgroundProjectActionMode.regular, title: title, layerDataOptionsList: layerDataOptionsList, backgroundContentData: this._backgroundContentData });
            } else {
                const asset = store.getState().selectedMediaListReducer[0];
                await this.editRecommendationsProject({ asset: asset, overlayId: selectedOverlayId, createOrEditMode: ELReplaceBackgroundProjectActionMode.regular, title: title, layerDataOptionsList: layerDataOptionsList, backgroundContentData: this._backgroundContentData });
            }
        } catch (error) {
            const elError = new ELError("Unable to save auto background Creation!", this.getWorkflowConfiguration());
            Logger.log(LogLevel.ERROR, elError);
        } finally {
            this.updateViewStatus(CreationsStatus.success);
            store.dispatch(DocActions.updateDocumentSaveStatus(DocumentSaveStatus.saved));
            this.doc?.markAndNotifyDocumentDirty(DocumentDirty.NON_DIRTY);
        }
    }

    protected async openDeeplink(): Promise<void> {
        const shouldContinueOpenInDesktop = this._shouldContinueFunctionality();
        if (shouldContinueOpenInDesktop) {
            const defaultTitle = IntlHandler.getInstance().formatMessage(PSD_NAME_INTL_KEY);
            const psdName = store.getState().recommendationWorkflowReducer.title ?? defaultTitle;
            this.createPSDAndOpenDeeplinkOnClient(DocumentType.adobeAsset, CreationsJobProjectSubType.magicalBackdrop, psdName);
        } else {
            const dialogContext: DialogContext = {
                showAdobeStockLicenseDialog: true,
                triggerSource: StockDialogTriggerSource.DEEPLINK
            }
            this._updateAdobeStockLicenseDialogState(dialogContext);
        }
    }

    private async _checkAndApplyLicensedImage(contentSource?: GenericContentSource): Promise<void> {
        if (contentSource && contentSource === GenericContentSource.adobeStockPreview) {
            const contentData = this._backgroundContentData ? this._backgroundContentData.contentData as AssetDataInfo : undefined;
            if (contentData && contentData.id) {
                const stockImageId = contentData.id;
                await StockServiceUtils.licenseStockImage(stockImageId);
                await this._applyImage(stockImageId);
                store.dispatch(RecommendationWorkflowAction.updateContentSource(GenericContentSource.adobeStock));
            }
        }
    }

    private async _updateProjectData(projectId: string): Promise<boolean> {
        try {
            this.projectData = await this.getProjectData(projectId);
        } catch (error) {
            Logger.log(LogLevel.WARN, "ReplaceBackground:_updateProjectData: Could not fetch ReplaceBackground project data! " + error);
            return false;
        }
        return true;
    }

    private async _onReplaceBackgroundTitleChange(): Promise<void> {
        if (this.projectId) {
            await this._updateProjectData(this.projectId);
        }
    }

    async initialize(dispatch?: React.Dispatch<ViewAction>): Promise<void> {
        super.initialize(dispatch);

        const panelProvider = new ELReplaceBackgroundPanelProvider(this);

        this._leftTabPanel = panelProvider.getTabPanel(ELTabPanelType.leftTabPanel);
        this._leftTabPanel.createView(this.ensureHTMLElement(this._leftTabPanelContainer));

        this._rightTabPanel = panelProvider.getTabPanel(ELTabPanelType.rightTabPanel);
        await this._rightTabPanel.createView(this.ensureHTMLElement(this._rightTabPanelContainer));

        const backButtonDialogHeading = IntlHandler.getInstance().formatMessage("replace-background-creation");
        const downloadOptions = [CreationsDownloadFileType.jpeg, CreationsDownloadFileType.png];
        const hasClientPSDSupport = PIEUtils.isPIEWasmSupported();
        if (hasClientPSDSupport) {
            downloadOptions.push(CreationsDownloadFileType.psd);
        }

        this._creationsHeader = new ELMediaRecommendationHeader(this, this.shareOptions, backButtonDialogHeading,
            CreationsJobProjectSubType.replaceBackground, downloadOptions, hasClientPSDSupport);
        await this._creationsHeader.createView(this.ensureHTMLElement(this._creationsHeaderContainer));

        this.createFeedbackView(this.ensureHTMLElement(this._feedbackContainer));

        this.openInDesktopManager = new ELOpenInDesktopManager(this, WorkflowsName.replaceBackground);
        this.openInDesktopManager.createView(this.ensureHTMLElement(this._openDeeplinkContainer));

        this._openInDesktopCreationObserver = new ELOpenInDesktopCreationObserver(this);

        this.enterProject(this._replaceBackgroundPayload);
    }

    createView(container: HTMLElement): void {
        super.createView(container);

        const element = React.createElement(ReplaceBackgroundView, {
            controller: this
        });

        const provider = React.createElement(Provider, { store }, element);

        ReactDOM.render(
            provider,
            container
        );
    }

    destroyView(): void {
        if (this.container) {
            ReactDOM.unmountComponentAtNode(this.container);
        }
        super.destroyView();
    }

    destroy(): void {
        this.renditionHandler.clearRenditions();

        CreationInAppNotifier.unsubscribe(this, CreationAppSubscriberType.statusChange);
        RecommendationsInAppNotifier.unsubscribe(this, RecommendationsAppSubscriberType.statusChange);

        clearInterval(this._progressTimer);

        this.beforeDoc?.destroy();
        this.doc?.destroy();
        super.destroy();
    }

    startWorkflow(containerId: string, prevWorkflow?: IWorkflow, action?: WorkflowAction): void {
        super.startWorkflow(containerId, prevWorkflow, action);

        const workflowPayload = action?.payload;
        this.mode = action?.initMode as CreationsMode;

        const replaceBackgroundPayload: ELCreationWorkflowPayload = {
            initMode: this.mode,
            payload: workflowPayload
        };

        this._replaceBackgroundPayload = replaceBackgroundPayload;

        this.createView(this.ensureHTMLElement(containerId));
    }

    endWorkflow(): void {
        super.endWorkflow();
    }

    private _ingest(payload: Record<string, string>): void {
        this._owner.notify({
            type: WorkspaceActionType.ingest,
            payload: payload
        });
    }

    private async _download(imageData: ELImageData): Promise<void> {
        this.startDate = new Date();
        const additionalLogInfo: Record<string, string> = {};
        additionalLogInfo[IngestLogObjectCustomKey.viewType] = IngestLogObjectValue.workspace;
        const autoBackgroundContentSource = store.getState().recommendationWorkflowReducer.contentSource;
        if (autoBackgroundContentSource === GenericContentSource.adobeStock) {
            this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.replaceBackground, IngestEventTypes.click, IngestEventSubTypes.stock, "Download"));
        }
        try {
            const message = IntlHandler.getInstance().formatMessage("file-download-in-background",
                { media: IntlHandler.getInstance().formatMessage("replace-background-creation").toLowerCase() });
            ToastUtils.info(message);

            const format = (imageData.format as unknown as CreationsDownloadFileType);
            const defaultTitle = IntlHandler.getInstance().formatMessage(UNTITLED_INTL_KEY);
            switch (format) {
                case CreationsDownloadFileType.psd: {
                    const psdName = store.getState().recommendationWorkflowReducer.title;
                    this.downloadPSD(DocumentType.pie, psdName ?? defaultTitle, false);
                    break;
                }
                default: {
                    const projectTitle = store.getState().recommendationWorkflowReducer.title;
                    this.doc?.notify({ type: DocumentActions.download, payload: { name: projectTitle ?? defaultTitle, imageData: imageData } });
                    break;
                }
            }

            this.ingestParams.subType = IngestEventSubTypes.download;
            this.logIngestData(IngestEventSubTypes.success);
        } catch (e) {
            setTimeout(() => {
                ToastUtils.error(IntlHandler.getInstance().formatMessage("download-fail-toast-msg"), {
                    closable: true,
                    timeout: 0
                });
            }, Constants.TOAST_DEFAULT_TIME_OUT_LIMIT as number);
            this.ingestParams.subType = IngestEventSubTypes.download;
            this.logIngestData(IngestEventSubTypes.error, e as string);
        }
    }

    private _resetPreviousPanelData(currentContentType: GenericContentDataType): void {
        if (!this._backgroundContentData) {
            return;
        }
        if (this._backgroundContentData.contentDataType === currentContentType) {
            return;
        }
        switch (this._backgroundContentData.contentDataType) {
            case GenericContentDataType.transparent: {
                this._rightTabPanel.notify({
                    type: ELBackgroundContentPanelControllerAction.setIsTransparentSelected,
                    payload: false
                });
                return;
            }
            case GenericContentDataType.recommendation: {
                store.dispatch(RecommendationWorkflowAction.updateSelectedOverlayId(undefined));
                this._leftTabPanel.notify({
                    type: ELRecommendationWorkflowControllerActions.setSelectedOverlayId,
                    payload: ""
                });
                return;
            }
            case GenericContentDataType.http: {
                this._rightTabPanel.notify({
                    type: ELStockWorkflowActions.setSelectedStockId,
                    payload: ""
                });
                return;
            }
            case GenericContentDataType.graphics: {
                this._rightTabPanel.notify({
                    type: ELBackgroundContentPanelControllerAction.setSelectedGraphicId,
                    payload: ""
                });
                return;
            }
            case GenericContentDataType.userDefined: {
                this._rightTabPanel.notify({
                    type: ELBackgroundContentPanelControllerAction.setSelectedColorValue,
                    payload: DEFAULT_SELECTED_COLOR
                });
                return;
            }
        }
    }

    private _updatePanelData(currentContentPayload: ELCreationContentPayload): void {
        const contentDataType = currentContentPayload.contentDataType;
        this._resetPreviousPanelData(contentDataType);
        switch (contentDataType) {
            case GenericContentDataType.transparent: {
                this._rightTabPanel.notify({
                    type: ELBackgroundContentPanelControllerAction.setIsTransparentSelected,
                    payload: true
                });
                return;
            }
            case GenericContentDataType.recommendation: {
                store.dispatch(RecommendationWorkflowAction.updateSelectedOverlayId(currentContentPayload.backgroundId));
                this._leftTabPanel.notify({
                    type: ELRecommendationWorkflowControllerActions.setSelectedOverlayId,
                    payload: currentContentPayload.backgroundId
                });
                return;
            }
            case GenericContentDataType.http: {
                this._rightTabPanel.notify({
                    type: ELStockWorkflowActions.setSelectedStockId,
                    payload: ""
                });
                return;
            }
            case GenericContentDataType.graphics: {
                const graphicId = (currentContentPayload.contentData as CollageBackgroundData).id;
                this._rightTabPanel.notify({
                    type: ELBackgroundContentPanelControllerAction.setSelectedGraphicId,
                    payload: graphicId
                });
                return;
            }
            case GenericContentDataType.userDefined: {
                const userDefineType = currentContentPayload.contentData as UserDefinedInfoType;
                // TODO: @gmahal send events according to the user defined data type
                this._rightTabPanel.notify({
                    type: ELBackgroundContentPanelControllerAction.setSelectedColorValue,
                    payload: userDefineType.data
                });
                return;
            }
        }
    }

    private async _fetchAdobeStockLicense(imageId: string): Promise<void> {
        this.updateViewStatusAndProgressText(
            CreationsStatus.applyingCustom,
            IntlHandler.getInstance().formatMessage("fetching-stock-license")
        );
        await StockServiceUtils.licenseStockImage(imageId);
        if (this._backgroundContentData) {
            this._backgroundContentData.contentSource = GenericContentSource.adobeStock;
        }
        this.updateViewStatus(CreationsStatus.success);
    }

    private async _onReplaceMedia(replaceAssetInfo: ReplaceAssetInfo): Promise<void> {
        this.cleanUpData();
        this._updateProgressValueWithTimer();
        const asset = replaceAssetInfo.assetToReplaceWith;
        this.ingestParams.subType = CreationsMode.update;
        store.dispatch(RecommendationWorkflowAction.updateRecommendationStatus(CreationsStatus.requested));
        this.updateViewStatus(CreationsStatus.requested);
        this.updateProgressText(IntlHandler.getInstance().formatMessage("generating-replace-background"));

        const docPayload = await this._getDocumentPayload(asset);
        this.createAndRenderBeforeDoc(docPayload);
        store.dispatch(DocActions.updateDocumentDirty(DocumentDirty.DIRTY));
        this.mode = CreationsMode.create;
        this.dualView?.showDoc(ELViewType.before);

        this.inputMedia = asset;
        this._createReplaceBackground(asset);
    }

    private async _updateHeaderLicenseAndSaveState(): Promise<void> {
        const shouldShowLicenseAndSave = this._backgroundContentData?.contentSource === GenericContentSource.adobeStockPreview;
        await this._creationsHeader.notify({
            type: ELCreationsHeaderControllerAction.updateLicenseAndSaveState,
            payload: shouldShowLicenseAndSave
        });
    }

    private _updateAdobeStockLicenseDialogState(dialogContext: DialogContext): void {
        this.viewDispatcher?.({
            type: ELCreationsHeaderControllerAction.updateAdobeStockLicenseDialogState,
            payload: dialogContext
        })
    }

    private _isSubjectLayerObject(object?: ELStageObject): boolean {
        if (object && object.data && object.data.payload === this._subjectLayerId) {
            return true;
        }
        return false;
    }

    private _isTransformationEvent(objectEvent: DocumentActions): boolean {
        if (objectEvent === DocumentActions.objectScaled || objectEvent === DocumentActions.objectRotated) {
            return true;
        }
        return false;
    }

    private _handleObjectEvent(objectEvent: DocumentActions, object?: ELStageObject): void {
        if (this._isSubjectLayerObject(object)) {
            if (!this.moveToolIngestParams.toolUsed) {
                this.moveToolIngestParams.toolUsed = true;
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
                    IngestEventTypes.click, IngestEventSubTypes.moveTool, CreationsJobProjectSubType.replaceBackground));
            }
            if (this._isTransformationEvent(objectEvent) && !this.moveToolIngestParams.transformationUsed) {
                this.moveToolIngestParams.transformationUsed = true;
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
                    IngestEventTypes.click, IngestEventSubTypes.moveToolTransformationUsed, CreationsJobProjectSubType.replaceBackground));
            }
        }
    }

    private _onDocumentDirty(dirty: DocumentDirty): void {
        if (this.doc) {
            store.dispatch(DocActions.updateDocumentError(this.doc.hasRenderingError()));
        }

        store.dispatch(DocActions.updateDocumentDirty(dirty));
    }

    private async _onReplaceBackgroundRendered(status: CreationsStatus): Promise<void> {
        this.updateViewStatus(status);
    }

    private async _onOpenInDesktopStatusChanged(payload: ELOpenInDesktopOpenAssetPayload): Promise<void> {
        this.openInDesktopManager?.notify({ type: ELOpenDeeplinkAction.openDeepLinkForAsset, payload: payload });
    }

    private async _updateProgressPercentageToView(progress: number): Promise<void> {
        this.viewDispatcher?.({
            type: CreationInAppNotifierAction.creationProgressChanged,
            payload: progress
        });
    }

    private async _continueClickedFunctionality(dialogContext: DialogContext): Promise<void> {
        const dialogTriggerSource = dialogContext.triggerSource;
        switch (dialogTriggerSource) {
            case StockDialogTriggerSource.SAVE: {
                await this._save();
                break;
            }
            case StockDialogTriggerSource.DEEPLINK: {
                await this.openDeeplink();
                break;
            }
            case StockDialogTriggerSource.DOWNLOAD: {
                const imageData = dialogContext.data as ELImageData;
                await this._download(imageData);
                break;
            }
            default: {
                Logger.log(LogLevel.ERROR, "ReplaceBackground(continueClickedFunctionality): Invalid dialog trigger source");
                throw new Error("Invalid dialog trigger source");
            }
        }
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case DocumentActions.markDocumentDirty: {
                this._onDocumentDirty(action.payload as DocumentDirty);
                handled = true;
                break;
            }
            case CreationWorkflowActions.creationRenderStatus: {
                this._onReplaceBackgroundRendered(action.payload as CreationsStatus);
                handled = true;
                break;
            }
            case ELCreationsHeaderControllerAction.download: {
                const imageData = action.payload as ELImageData;
                const shouldContinueDownload = this._shouldContinueFunctionality();
                if (shouldContinueDownload) {
                    await this._download(imageData);
                } else {
                    const dialogContext: DialogContext = {
                        showAdobeStockLicenseDialog: true,
                        triggerSource: StockDialogTriggerSource.DOWNLOAD,
                        data: imageData
                    }
                    this._updateAdobeStockLicenseDialogState(dialogContext);
                }
                handled = true;
                break;
            }
            case ELRecommendationWorkflowControllerActions.overlayClicked: {
                const thumbInfo = action.payload as ELPreviewCreationThumbData;
                const creationContentPayload: ELCreationContentPayload = {
                    backgroundId: thumbInfo.id,
                    contentDataType: GenericContentDataType.recommendation
                };
                await this._applyBackground(creationContentPayload);
                handled = true;
                break;
            }
            case ELStockWorkflowActions.stockThumbnailClicked: {
                const payload = action.payload as ELCreationContentPayload;
                this._applyBackground(payload);
                handled = true;
                break;
            }
            case ELStockWorkflowActions.applyStockLicensedImage: {
                const actionPayload = action.payload as ELCreationContentPayload;
                await this._applyBackground(actionPayload);
                handled = true;
                break;
            }
            case ELBackgroundContentPanelControllerAction.transparentClicked: {
                const thumbInfo = action.payload as ELPreviewCreationThumbData;
                const creationContentPayload: ELCreationContentPayload = {
                    backgroundId: thumbInfo.id,
                    contentDataType: GenericContentDataType.transparent
                };
                await this._applyBackground(creationContentPayload);
                handled = true;
                break;
            }
            case ELBackgroundContentPanelControllerAction.imageBackgroundChanged: {
                const creationContentPayload: ELCreationContentPayload = {
                    contentDataType: GenericContentDataType.graphics,
                    contentData: action.payload
                };
                await this._applyBackground(creationContentPayload);
                handled = true;
                break;
            }
            case ELBackgroundContentPanelControllerAction.colorBackgroundChanged: {
                const creationContentPayload: ELCreationContentPayload = {
                    contentDataType: GenericContentDataType.userDefined,
                    contentData: {
                        type: UserDefinedDataType.color,
                        data: action.payload
                    }
                };
                await this._applyBackground(creationContentPayload);
                handled = true;
                break;
            }
            case ELRecommendationWorkflowControllerActions.backRecommendationWorkflowView: {
                const workspacePayload = {
                    startWorkflow: WorkflowsName.creationsHome
                };
                const workspaceAction = { type: WorkspaceActionType.startWorkflow, ...workspacePayload };
                handled = await this._owner.notify(workspaceAction);
                break;
            }
            case ELAdobeAssetControllerAction.showAfterImage: {
                this.dualView?.showDoc(ELViewType.after);
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
                    IngestEventTypes.click, IngestEventSubTypes.after, CreationsJobProjectSubType.replaceBackground));
                handled = true;
                break;
            }
            case ELAdobeAssetControllerAction.showBeforeImage: {
                this.dualView?.showDoc(ELViewType.before);
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
                    IngestEventTypes.click, IngestEventSubTypes.before, CreationsJobProjectSubType.replaceBackground));
                handled = true;
                break;
            }
            case CanvasZoomLevelAction.zoomInEvent:
            case CanvasZoomLevelAction.zoomOutEvent: {
                this.doc?.notify(action);
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.replaceBackground, IngestEventTypes.click, IngestEventSubTypes.zoom, "Zoom-In-Out"));
                handled = true;
                break;
            }
            case CreationMediaActionType.replaceMedia: {
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.replaceBackground, IngestEventTypes.click, IngestEventSubTypes.replaceMedia));
                const workspacePayload: WorkspacePayload = {
                    startWorkflow: WorkflowsName.replaceMediaManager,
                    payload: {
                        assetId: (this.beforeDoc?.getData() as ELAdobeAssetDocPayload).assetInfo.assetURN,
                        mode: ReplaceMediaManagerMode.replacingMedia,
                        mediaGridConfig: this.mediaGridConfig
                    }
                };
                const workspaceAction = { type: WorkspaceActionType.startWorkflow, ...workspacePayload };
                handled = await this._owner.notify(workspaceAction);
                break;
            }
            case ReplaceMediaManagerWorkflowAction.replaceMediaSelection: {
                const replaceAssetInfo = action.payload as ReplaceAssetInfo;
                this._onReplaceMedia(replaceAssetInfo);
                handled = true;
                break;
            }
            case ELOpenInDesktopDeeplinkAction.deeplinkStatusChanged: {
                const payload = action.payload as ELOpenInDesktopOpenAssetPayload;
                this._onOpenInDesktopStatusChanged(payload);
                handled = true;
                break;
            }
            case ELCreationsHeaderControllerAction.save: {
                this.ingestParams.subType = CreationsMode.save;
                this.ingestParams.eventViewType = IngestLogObjectValue.workspace;
                const shouldContinueSave = this._shouldContinueFunctionality();
                if (shouldContinueSave) {
                    await this._save();
                } else {
                    const dialogContext: DialogContext = {
                        showAdobeStockLicenseDialog: true,
                        triggerSource: StockDialogTriggerSource.SAVE
                    }
                    this._updateAdobeStockLicenseDialogState(dialogContext);
                }
                handled = true;
                break;
            }
            case ELCreationsHeaderControllerAction.dontSave: {
                this.notify({ type: ELRecommendationWorkflowControllerActions.backRecommendationWorkflowView });
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
                    IngestEventTypes.click, IngestEventSubTypes.backDialogDontSave, CreationsJobProjectSubType.replaceBackground));
                handled = true;
                break;
            }
            case WorkflowActionType.ingest: {
                this._ingest(action.payload as Record<string, string>);
                handled = true;
                break;
            }
            case ELOpenInDesktopDeeplinkAction.deeplinkCallbackStatus: {
                this.viewDispatcher?.call(this.viewDispatcher, { type: ELOpenInDesktopManagerViewAction.inProgressStatus, payload: false });
                handled = true;
                break;
            }
            case ELStockWorkflowActions.stockSearchTriggered: {
                const searchQuery = action.payload as string;
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.replaceBackground, IngestEventTypes.search, IngestEventSubTypes.stock, searchQuery));
                handled = true;
                break;
            }
            case ELStockWorkflowActions.stockBackButtonClicked: {
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.replaceBackground, IngestEventTypes.click, IngestEventSubTypes.stock, "Back Button"));
                handled = true;
                break;
            }
            case ELStockWorkflowActions.stockImageClicked: {
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.replaceBackground, IngestEventTypes.click, IngestEventSubTypes.stock, "Image Clicked"));
                handled = true;
                break;
            }
            case ELStockWorkflowActions.discardLicense: {
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.replaceBackground, IngestEventTypes.click, IngestEventSubTypes.stock, "Discard License"));
                handled = true;
                break;
            }
            case ELStockWorkflowActions.licenseForFree: {
                const imageId = action.payload as string;
                await this._fetchAdobeStockLicense(imageId);
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.replaceBackground, IngestEventTypes.click, IngestEventSubTypes.stock, "License for free"));
                handled = true;
                break;
            }
            case ELStockWorkflowActions.stockDialogLicenseForFree: {
                const dialogContext = action.payload as DialogContext;
                const contentData = this._backgroundContentData?.contentData as AssetDataInfo;
                const imageId = contentData.id;
                const updatedDialogContext: DialogContext = {
                    showAdobeStockLicenseDialog: false
                }
                this._updateAdobeStockLicenseDialogState(updatedDialogContext);
                await this._fetchLicenseAndApplyBackground(imageId);
                await this._continueClickedFunctionality(dialogContext);
                handled = true;
                break;
            }
            case ELOpenInDesktopDeeplinkAction.deeplinkTryAgain: {
                await this.tryAgainForOpenDeeplink(action.payload as ELOpenInDesktopOpenAssetPayload);
                handled = true;
                break;
            }
            case DocumentActions.objectMoved:
            case DocumentActions.objectRotated:
            case DocumentActions.objectScaled: {
                this._handleObjectEvent(action.type, action.payload ? action.payload as ELStageObject : undefined);
                handled = true;
                break;
            }
            default: {
                Logger.log(LogLevel.DEBUG, "ReplaceBackground(ControllerAction): Invalid action" + action);
                break;
            }
        }
        if (!handled) {
            handled = await super.notify(action);
        }
        return handled;
    }

    private async _fetchLicenseAndApplyBackground(imageId?: string): Promise<void> {
        if (imageId) {
            await this._fetchAdobeStockLicense(imageId);
        }
        else {
            Logger.log(LogLevel.ERROR, "ReplaceBackground(fetchLicenseAndApplyBackground): Image ID is undefined");
        }
        this._rightTabPanel.notify({ type: ELStockWorkflowActions.updateActionRegionState, payload: false })
        if (this._backgroundContentData) {
            await this._applyBackground(this._backgroundContentData);
        } else {
            Logger.log(LogLevel.ERROR, "ReplaceBackground(fetchLicenseAndApplyBackground): Background content data is undefined");
        }
    }

    private async _waitForSaveComplete(waitCount = 0, maxWaitCount = 10): Promise<void> {
        if (waitCount >= maxWaitCount) {
            return;
        }
        const saveStatus = store.getState().docStateReducer.saveStatus;
        if (saveStatus === DocumentSaveStatus.saveInProgress) {
            await Utils.wait(2000);
            await this._waitForSaveComplete(waitCount + 1);
        }
    }

    private async _waitForDocumentDirtyStatus(waitCount = 0, maxWaitCount = 10): Promise<void> {
        if (waitCount >= maxWaitCount) {
            return;
        }
        const isDocDirty = store.getState().docStateReducer.isDirty;
        if (isDocDirty === DocumentDirty.DIRTY) {
            await Utils.wait(2000);
            await this._waitForDocumentDirtyStatus(waitCount + 1);
        }
    }

    private async _saveAndStartPreviousWorkflow(): Promise<void> {
        this.ingestParams.subType = CreationsMode.save;
        this.ingestParams.eventViewType = IngestLogObjectValue.dialog;
        const saveStatus = store.getState().docStateReducer.saveStatus;
        if (saveStatus === DocumentSaveStatus.saveInProgress) {
            this.updateViewStatusAndProgressText(CreationsStatus.requested, IntlHandler.getInstance().formatMessage("saving-creation"));
            await this._waitForSaveComplete();
        } else if (this.doc?.isDocumentDirty === DocumentDirty.DIRTY) {
            await this._save();
            await this._waitForDocumentDirtyStatus();
        }
        if (this.projectId) {
            this.preprocessCreationEdit(this.projectId);
        }
        this.startPreviousWorkflow();
    }

    protected async notifyWorkflow<T extends WorkflowAction>(action: T): Promise<boolean> {
        let handled = false;

        switch (action.type) {
            case WorkspaceActionType.startPreviousWorkflow: {
                this._saveAndStartPreviousWorkflow();
                handled = true;
                break;
            }
            case ELCreationsHeaderControllerAction.showBackButtonDialog: {
                await this._updateHeaderLicenseAndSaveState();
                handled = true;
                break;
            }
            case CanvasZoomLevelAction.changeZoomValue: {
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.replaceBackground, IngestEventTypes.click, IngestEventSubTypes.zoom, action.payload as string));
                this.dualView?.synchronizeZoom(action);
                handled = true;
                break;
            }
            case CanvasZoomLevelAction.zoomToFill: {
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.replaceBackground, IngestEventTypes.click, IngestEventSubTypes.zoom, "Fill"));
                this.dualView?.synchronizeZoom(action);
                handled = true;
                break;
            }
            case CanvasZoomLevelAction.zoomToFit: {
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.replaceBackground, IngestEventTypes.click, IngestEventSubTypes.zoom, "Fit"));
                this.dualView?.synchronizeZoom(action);
                handled = true;
                break;
            }
            case CreationWorkflowActions.renameCreationTitle: {
                this._onReplaceBackgroundTitleChange();
                handled = true;
                break;
            }
            default:
                Logger.log(LogLevel.WARN, "ReplaceBackground(notifyWorkflow): Invalid action" + action);
                break;
        }
        return handled;
    }
}

export default ReplaceBackground;