/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//thirdparty
import React from "react";
import ReactDOM from "react-dom";
import _ from "lodash";
import { Provider as ReactReduxProvider } from "react-redux";

//utils
import Constants, { PROMISE_FULFILLED } from "../../../../utils/Constants/Constants";
import { ToastUtils } from "../../../../utils/ToastUtils";
import { IngestUtils } from "../../../../utils/IngestUtils";
import { IngestEventContentNameTypes, IngestEventCountTypes, IngestEventSubTypes, IngestEventTypes, IngestLogObjectCustomKey, IngestLogObjectKey, IngestLogObjectValue, IngestWorkflowTypes, ShareInvocationPoint } from "../../../../utils/IngestConstants";
import { ShareUtils } from "../../../../utils/ShareUtils";
import { ViewportProvider } from "../../../../utils/hooks/useViewport";
import Logger, { LogLevel } from "../../../../utils/Logger";

//application specific
import { ViewAction } from "../../../../view/IBaseController";
import MediaGridView from "./MediaGridView";
import IBaseWorkspace, { WorkspaceActionType } from "../../../IBaseWorkspace";
import IWorkflow, { WorkflowAction, WorkflowActionType, WorkflowsName } from "../../../IWorkflow";
import MediaOrganizerAction from "../../../../stores/actions/mediaOrganizerActions";
import ELMediaGrid, { ELMediaGridControllerAction, ELMediaGridData, SelectedMediaListType } from "../../../../view/components/organism/el-mediagrid/ELMediaGrid";
import useMediaFetch, { MediaSource } from "../../../../utils/hooks/useMediaFetch";
import IViewController, { ControllerAction } from "../../../../view/IViewController";
import { ShareOptions } from "../../../../view/components/organism/el-share-options/ELShareOptions";
import { StorageService } from "../../../../services/StorageServiceWrapper";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { ModalWorkspaceName } from "../../../IModalWorkspace";
import store from "../../../../stores/store";
import SelectedMediaListAction, { SelectedMediaListPayload } from "../../../../stores/actions/selectedMediaListActions";
import { ShareAction } from "../../../../stores/actions/ShareAction";
import { ELEmptyBannerMediaGrid } from "../../../../view/components/organism/el-empty-banner-mediagrid/ELEmptyBannerMediaGrid";
import ELCreatePopover from "../../../../view/components/organism/el-create-popover/ELCreatePopover";
import { ELCreateOnDemandAction, ELCreateOnDemandData } from "../../../../common/interfaces/creations/ELCreateOnDemandTypes";
import CreationLauncher from "../../../creations/utils/CreationLauncher";
import ELMediaUploader from "../../../../view/components/templates/el-media-uploader/ELMediaUploader";
import { ELMediaSelectionMode } from "../../../../common/interfaces/media/ELThumbTypes";
import Utils from "../../../../utils/Utils";
import { EditWorkspaceAction } from "../../../../common/interfaces/editing/editWorkspace/EditWorkspaceTypes";
import { EditWorkflowLauncher } from "../../../edit/utils/EditWorkflowLauncher";
import { MediaExistUtil } from "../../../creations/utils/MediaExistUtils";

export const CONTAINER_TRANSITION_TIMEOUT = 1500;

export enum MediaGridControllerAction {
    delete = "DELETE"
}

export enum MediaGridWorkflowActions {
    startImport = "startImport",
    retryUpload = "retryUpload",
    stopUpload = "stopUpload",
    startImportWithDragDrop = "startImportWithDragDrop"
}

class MediaGridWorkflow extends IWorkflow {

    private _grid: ELMediaGrid | undefined;
    private _toolbar: IViewController | undefined;
    private _selectedAssets: SelectedMediaListType;
    private shareOptions: ShareOptions | undefined;
    private _createPopover: ELCreatePopover;
    private _creationLauncher: CreationLauncher;
    private _mediaUploader?: ELMediaUploader;
    private readonly _importProgressContainer = "import-progress-container";

    constructor(owner: IBaseWorkspace) {
        super(owner, WorkflowsName.mediaGrid);
        this._selectedAssets = [];
        this._createPopover = new ELCreatePopover(this);
        this._creationLauncher = new CreationLauncher();
    }

    set shareOptionsController(shareOptions: ShareOptions | undefined) {
        this.shareOptions = shareOptions;
    }

    get getShareOptionsController(): ShareOptions | undefined {
        return this.shareOptions;
    }

    get getImportProgressState(): string {
        return this._mediaUploader?.getImportProgressState ?? "notStarted";
    }

    set toolbar(toolbar: IViewController) {
        this._toolbar = toolbar;
    }

    private _refreshMediaGridAfterClearingSelection(): void {
        if (!this._grid)
            return;
        store.dispatch(SelectedMediaListAction.updateSelectedMediaList([]));
        store.dispatch(MediaOrganizerAction.reset(Constants.ELEMENTS_PHOTOS_PATH as string));
        return;
    }

    private _ingestDeleteMediaLogInfo(allMedia: SelectedMediaListPayload, additionalLogInfo: Record<string, string>): void {
        const customEntries = IngestUtils.getMediaLoggingInfo(allMedia);

        const eventContextId = Utils.getRandomUUID();

        for (const key in customEntries) {
            const additionalLogInfoTemp = { ...additionalLogInfo };
            additionalLogInfoTemp[IngestLogObjectKey.eventContextGuid] = eventContextId;
            additionalLogInfoTemp[IngestLogObjectKey.contentName] = key;
            additionalLogInfoTemp[IngestLogObjectKey.eventCount] = customEntries[key];
            this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations, IngestEventSubTypes.info,
                IngestEventTypes.delete, null, additionalLogInfoTemp));
        }
    }

    private async _handleDeleteMediaWorkflow(): Promise<void> {
        const message = IntlHandler.getInstance().formatMessage("file-deletion-in-background", { media: "Media" });
        ToastUtils.info(message);
        const allMedia = store.getState().selectedMediaListReducer;

        const deletedAssetPromises: Promise<boolean>[] = [];
        let deletedAssetCount = 0;
        for (const asset of this._selectedAssets) {
            deletedAssetPromises.push(StorageService.getInstance().deleteAsset(asset));
        }
        try {
            const response = await Promise.allSettled(deletedAssetPromises);
            response.forEach((element, i) => {
                if (element.status === PROMISE_FULFILLED && element.value === true)
                    deletedAssetCount++;
                else
                    Logger.log(LogLevel.WARN, "Failed to delete ", this._selectedAssets[i]);
            })
        } catch (err) {
            Logger.log(LogLevel.ERROR, "MediaGrid:_handleDeleteMediaWorkflow: ", "Failed to Delete Whole Batch");
        }

        const intlHandler = IntlHandler.getInstance();
        if (deletedAssetCount === 0) {
            ToastUtils.error(intlHandler.formatMessage("no-media-deleted"));
        } else {
            this._refreshMediaGridAfterClearingSelection();
            const message = intlHandler.formatMessage("items-deleted", {
                itemCount: deletedAssetCount,
                media: IntlHandler.getInstance().formatMessage("media").toLowerCase()
            });
            ToastUtils.success(message);
        }

        const additionalLogInfo: Record<string, string> = {};
        additionalLogInfo[IngestLogObjectKey.eventContextGuid] = Utils.getRandomUUID();
        additionalLogInfo[IngestLogObjectCustomKey.viewType] = IngestLogObjectValue.grid;
        const status = deletedAssetCount ? IngestEventSubTypes.success : IngestEventSubTypes.error;
        this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations, status, IngestEventTypes.delete,
            deletedAssetCount, additionalLogInfo));
        this._ingestDeleteMediaLogInfo(allMedia, additionalLogInfo);
        return;
    }

    private _ingest(payload: Record<string, string>): void {
        this.notify({
            type: WorkflowActionType.ingest,
            payload: payload
        });
    }

    private _ingestCreateOnDemandActions(workflowName: WorkflowsName): void {
        this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.createOnDemand, IngestEventTypes.info,
            IngestEventSubTypes.start, "Media Grid"));
        this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.createOnDemand, IngestEventTypes.click,
            IngestEventSubTypes.start, workflowName));
    }

    private async _handleEditMediaWorkflow(): Promise<void> {
        this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.mediaGrid, IngestEventTypes.click,
            IngestEventSubTypes.edit, true));
        const editWorkflowLauncher = new EditWorkflowLauncher();
        //GLIA_REVISIT - vib - Will need to have handling here if video/other edit workflows are introduced.
        editWorkflowLauncher.startImageEditWorkflow(this._selectedAssets);
    }

    private async _createOnDemandActions(createOnDemandData: ELCreateOnDemandData): Promise<void> {
        let success = false;
        const nextWorkflow = createOnDemandData.thumbId;
        switch (nextWorkflow) {
            case WorkflowsName.slideshow:
                {
                    success = await this._creationLauncher.startSlideshow(this._selectedAssets);
                    break;
                }
            case WorkflowsName.collage:
                {
                    success = await this._creationLauncher.startCollage(this._selectedAssets);
                    break;
                }
            case WorkflowsName.patternOverlay:
                {
                    success = await this._creationLauncher.startPatternOverlay(this._selectedAssets);
                    break;
                }
            case WorkflowsName.peekThrough:
                {
                    success = await this._creationLauncher.startPeekThrough(this._selectedAssets);
                    break;
                }
            case WorkflowsName.replaceBackground:
                {
                    success = await this._creationLauncher.startReplaceBackground(this._selectedAssets);
                    break;
                }
            case WorkflowsName.movingOverlay:
                {
                    success = await this._creationLauncher.startMovingOverlay(this._selectedAssets);
                    break;
                }
            case WorkflowsName.photoText:
                {
                    success = await this._creationLauncher.startPhotoText(this._selectedAssets);
                    break;
                }
            default:
                {
                    Logger.log(LogLevel.WARN, "MediaGrid:_createOnDemandActions: ", "Invalid nextWorkflow in _createOnDemandActions");
                    break;
                }
        }
        if (success) {
            this._ingestCreateOnDemandActions(nextWorkflow);
        }
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
        store.dispatch(SelectedMediaListAction.updateSelectedMediaList([]));
    }

    private _getEmptyBanner(): React.ReactElement {
        const noMediaBanner = new ELEmptyBannerMediaGrid(this);
        return noMediaBanner.getView() as React.ReactElement;
    }

    private _isMediaImportInProgress(): boolean {
        if (store.getState().mediaGridToolbarStateReducer.importInProgress)
            return true;
        return false;
    }

    private _logIngestImportWithDragDropMediaPresent(): void {
        const additionalLogInfo: Record<string, string> = {};
        additionalLogInfo[IngestLogObjectKey.contentName] = IngestEventContentNameTypes.importMode;
        additionalLogInfo[IngestLogObjectKey.eventCount] = IngestEventCountTypes.mediaAvailableImport;
        this.notify({
            type: WorkflowActionType.ingest,
            payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations, IngestEventTypes.success,
                IngestEventSubTypes.import, undefined, additionalLogInfo)
        });
    }

    private async _onMediaGridRendered(): Promise<void> {
        const dirPath = Constants.ELEMENTS_PHOTOS_PATH as string;
        const mediaSourceType = MediaSource.repoDirDataFetch;
        const repoDirFetchHook = _.partial(useMediaFetch, _, mediaSourceType);

        const setSelectedAssets = (arr: SelectedMediaListType): void => {
            this._selectedAssets = arr;

        }

        let mediaGridRenderedCallback: (() => void) | undefined = undefined;
        if (ShareUtils.isDesktopToWebShareHandover(window.location.href)) {
            try {
                await ShareUtils.setupDesktopToWebShareWorkflow();
                mediaGridRenderedCallback = () => {
                    ShareUtils.ingestShareStart(ShareInvocationPoint.desktopWebShare, this.notify.bind(this));
                    this._startShareWorkflow();
                }
            } catch (err) {
                Logger.log(LogLevel.ERROR, "MediaGrid:createView: ", "Error setting up share workflow");
            }
        }
        const emptyBanner = this._getEmptyBanner();
        const mediaGridData: ELMediaGridData = {
            workflow: this,
            mediaFetchHookFunc: repoDirFetchHook,
            dirPath: dirPath,
            createTracks: true,
            emptyGridBanner: emptyBanner,
            selectionEnabled: true,
            setSelectedMediaAssetsInWorkflow: setSelectedAssets,
            componentRenderedCallback: mediaGridRenderedCallback,
            selectionMode: ELMediaSelectionMode.DEFAULT
        };
        this._grid = new ELMediaGrid(mediaGridData);
        this._grid.createView(this.ensureHTMLElement("media-grid"));
        this._toolbar?.createView(this.ensureHTMLElement("media-grid-toolbar"));
        if (this._isMediaImportInProgress())
            this._mediaUploader?.createView(this.ensureHTMLElement(this._importProgressContainer));
    }

    createView(container: HTMLElement): void {
        super.createView(container);

        const mediaGrid: React.ReactElement = React.createElement(MediaGridView, {
            controller: this,
            createPopoverView: this._createPopover.getView()
        });

        const reduxProviderMediaGrid = React.createElement(ReactReduxProvider, { store: store }, mediaGrid);
        const viewportReduxProviderMediaGrid = React.createElement(ViewportProvider, {}, reduxProviderMediaGrid);

        ReactDOM.render(
            viewportReduxProviderMediaGrid,
            container,
            async (): Promise<void> => {
                try {
                    await this._onMediaGridRendered();
                } catch (err: unknown) {
                    Logger.log(LogLevel.ERROR, "MediaGrid:createView: Error rendering media grid " + err);
                }
            }
        )
    }

    private _startShareWorkflow(): void {
        const selectedAssets = store.getState().selectedMediaListReducer;
        const selectedAssetIds = selectedAssets.map((asset) => asset.assetId).filter((assetId) => assetId !== undefined) as string[];

        store.dispatch(ShareAction.updateAssetsToShare(selectedAssetIds));
        const workspaceAction = { type: WorkspaceActionType.startModalWorkspace, startModalWorkspace: ModalWorkspaceName.share, payload: { overlayDiv: "root" } };
        this._owner.notify(workspaceAction);
    }

    destroyView(): void {
        this._grid?.destroyView();
        this._toolbar?.destroyView();
        this._mediaUploader?.destroyView();
        if (this.container)
            ReactDOM.unmountComponentAtNode(this.container);
        super.destroyView();
    }

    destroy(): void {
        super.destroy();
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case MediaGridControllerAction.delete:
                this._handleDeleteMediaWorkflow();
                handled = true;
                break;
            case ELCreateOnDemandAction.workflowThumb: {
                this._createOnDemandActions((action.payload as ELCreateOnDemandData));
                handled = true;
                break;
            }
            case EditWorkspaceAction.startImageEditWorkflow: {
                this._handleEditMediaWorkflow();
                handled = true;
                break;
            }
            default:
                break;
        }

        if (!handled)
            handled = await this.notifyWorkflow(action as WorkflowAction);

        return handled;
    }

    startWorkflow<T extends WorkflowAction>(containerId: string, prevWorkflow?: IWorkflow, action?: T): void {
        super.startWorkflow<T>(containerId, prevWorkflow, action);
        this.createView(this.ensureHTMLElement(containerId));
    }

    protected async notifyWorkflow<T extends WorkflowAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case MediaGridWorkflowActions.startImport: {
                this._mediaUploader = new ELMediaUploader(this);
                this._mediaUploader.createView(this.ensureHTMLElement(this._importProgressContainer));
                this._mediaUploader.handleImport(Array.from(action.payload as FileList), IngestLogObjectValue.media);
                handled = true;
                break;
            }
            case MediaGridWorkflowActions.startImportWithDragDrop: {
                const mediaExist = await MediaExistUtil.getInstance().doesMediaExist();
                if (mediaExist) {
                    this._mediaUploader = new ELMediaUploader(this);
                    this._mediaUploader.createView(this.ensureHTMLElement(this._importProgressContainer));
                    this._mediaUploader.handleImport(Array.from(action.payload as FileList), IngestLogObjectValue.media);
                    this._logIngestImportWithDragDropMediaPresent();
                }
                handled = true;
                break;
            }
            case MediaGridWorkflowActions.stopUpload: {
                if (this._mediaUploader) {
                    this._mediaUploader.stopImport();
                }
                handled = true;
                break;
            }
            case MediaGridWorkflowActions.retryUpload: {
                if (this._mediaUploader) {
                    this._mediaUploader.retryImport();
                }
                handled = true;
                break;
            }
            case ELMediaGridControllerAction.startSIV:
                {
                    const workspaceAction = { type: WorkspaceActionType.startWorkflow, startWorkflow: WorkflowsName.singleImageView, payload: action.payload };
                    handled = await this._owner.notify(workspaceAction);
                    break;
                }
            default:
                handled = await super.notifyWorkflow(action);
                break
        }
        return handled;
    }
}

export default MediaGridWorkflow;
