/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { AdobeStockActionTypes } from "../../common/interfaces/services/StockServiceTypes"
import IAction from "../actions/IAction"

interface AdobeStockReducerData {
    stockThumbnailUrl?: string,
    selectedStockIds?: string,
    isAdobeStockDialogVisible?: string
}

const getInitialState = (): AdobeStockReducerData => {
    return {
        isAdobeStockDialogVisible: "false",
    }
}

const updateSelectedStockThumbnail = (state: AdobeStockReducerData, action: IAction<string>): AdobeStockReducerData => {
    return {
        ...state,
        stockThumbnailUrl: action.payload
    }
}

const updateSelectedStockIds = (state: AdobeStockReducerData, action: IAction<string>): AdobeStockReducerData => {
    return {
        ...state,
        selectedStockIds: action.payload
    }
}

const adobeStockReducer = (state: AdobeStockReducerData = getInitialState(), action: IAction<string>): AdobeStockReducerData => {
    switch (action.type) {
        case AdobeStockActionTypes.UPDATE_SELECTED_STOCK_THUMBNAIL:
            return updateSelectedStockThumbnail(state, action);
        case AdobeStockActionTypes.UPDATE_SELECTED_STOCK_IDS:
            return updateSelectedStockIds(state, action);
        default:
            return state;
    }
}

export default adobeStockReducer;