/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/


import { ContentDataType, ContentSource, IBaseInfo } from "@elements/elementswebcommon";

import Logger, { LogLevel } from "../../utils/Logger";
import { ContentDataManager } from "./ContentDataManager";
import { ELStageLayerData } from "../../common/interfaces/editing/layer/ELStageLayerTypes";
import { ELCreationContentPayload } from "../../common/interfaces/creations/ELRecommendationsWorkflowTypes";
import { BackgroundLayerRequestParams } from "./ContentDataManagerTypes";


export class TransparentDataManager extends ContentDataManager {

    async getBackgroundContentData(): Promise<IBaseInfo | undefined> {
        return Promise.resolve(undefined);
    }

    getContentSource(): ContentSource | undefined {
        return undefined;
    }

    getContentDataType(): ContentDataType | undefined {
        return undefined;
    }

    public async getContentLayerData(contentPayload: ELCreationContentPayload): Promise<ELStageLayerData> {
        Logger.log(LogLevel.WARN, "TransparentDataManager: getContentLayerData: No layer data to update");
        throw new Error("No layer data to update");
    }

    public async getUpdateContentData(contentPayload: ELCreationContentPayload): Promise<ImageData> {
        Logger.log(LogLevel.WARN, "TransparentDataManager: getUpdateContentData: No content to update");
        throw new Error("No content to update");
    }

    async getBackgroundLayerPayload(backgroundContentPayloadRequestParam: BackgroundLayerRequestParams): Promise<ELStageLayerData> {
        Logger.log(LogLevel.WARN, "TransparentDataManager: getBackgroundLayerPayload: No layer data to add");
        throw new Error("No layer data to update");
    }

}