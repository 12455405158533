/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

// Third party
import React, { Key } from "react";

// Adobe internal
import { ComboBox, Item, Section } from '@adobe/react-spectrum'
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";

import "./ELComboBox.scss";

export interface ELComboBoxOptions {
    name: string,
    children: { keyName: string }[]
}

export interface ELComboBoxProps {
    options: ELComboBoxOptions[],
    onSelectionChange: (key: Key) => void,
    defaultValue: string,
    inputValue?: string,
    styleProps?: React.CSSProperties,
    handleKeyEvent?: (event: React.KeyboardEvent<HTMLElement>) => void,
    dataTestId?: string
}


const ELComboBox = (props: ELComboBoxProps): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();

    return (
        <ComboBox inputValue={props.inputValue} selectedKey={props.inputValue} defaultItems={props.options} UNSAFE_className="el-combo-box" onSelectionChange={props.onSelectionChange}
            defaultInputValue={props.defaultValue} UNSAFE_style={props.styleProps} data-testid={props.dataTestId}>
            {item => (
                <Section key={item.name} items={item.children} data-testid={`${props.dataTestId}-combo-box-item`}>
                    {item => <Item key={item.keyName}>{intlHandler.formatMessage(item.keyName)}</Item>}
                </Section>
            )}
        </ComboBox >
    );
}

export default ELComboBox;
