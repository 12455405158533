/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { Key, useState } from "react";
import { Provider } from "react-redux";

//Application Specific
import store from "../../../../stores/store";
import { ELTabPanelManager } from "../../organism/el-tab-panel-manager/ELTabPanelManager";
import ELScoopedTabButton from "../../atoms/el-scooped-tab-button/ELScoopedTabButton";
import { IconType } from "../../../../assets/IconConstants";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import CollagePanel from "./ELCollagePanel";
import ELCollageLayoutView from "../el-collage-layout/ELCollageLayoutView";
import ELCollageMediaView from "../el-collage-media-panel/ELCollageMediaView";
import { ELTabData, ELTabItem, ELTabPanel } from "../../../../common/interfaces/tabpanel/ELTabPanelTypes";

import "./ELCollagePanelView.scss";

interface ICollagePanelViewProps {
    controller: CollagePanel
}

const CollagePanelView = (props: ICollagePanelViewProps): React.ReactElement => {
    const emptySelectedKey = "", defaultSelectedKey = "media";
    const [sSelectedKey, setSelectedKey] = useState(emptySelectedKey);

    const intlHandler = IntlHandler.getInstance();

    const mediaKey = "media", layoutKey = "layout", backgroundKey = "background";

    const getMediaTabItem = (): ELTabItem => {
        const tabItem: ELTabItem = {
            item: (<ELScoopedTabButton iconkey={IconType.mediaManagerTab} selected={sSelectedKey === mediaKey}
                text={intlHandler.formatMessage("media")} variant={"secondary"} />)
        };
        return tabItem;
    }

    const getLayoutTabItem = (): ELTabItem => {
        const tabItem: ELTabItem = {
            item: (<ELScoopedTabButton iconkey={IconType.slideshowTemplate} selected={sSelectedKey === layoutKey}
                text={intlHandler.formatMessage("layout")} variant={"secondary"} />)
        };
        return tabItem;
    }

    const getBackgroundTabItem = (): ELTabItem => {
        const tabItem: ELTabItem = {
            item: (<ELScoopedTabButton iconkey={IconType.background} selected={sSelectedKey === backgroundKey}
                text={intlHandler.formatMessage("background")} variant={"secondary"} />)
        };
        return tabItem;
    }

    const getCollageMediaTabBody = (): ELTabPanel => {
        const tabBody: ELTabPanel = {
            panel: (<ELCollageMediaView controller={props.controller.getCollageMedia} />)
        };
        return tabBody;
    }

    const getCollageLayoutTabBody = (): ELTabPanel => {
        const tabBody: ELTabPanel = { panel: (<ELCollageLayoutView controller={props.controller.getCollageLayout} />) };
        return tabBody;
    }

    const getCollageBackgroundTabBody = (): ELTabPanel => {
        const tabBody: ELTabPanel = { panel: props.controller.getCollageBackground.getView({
            panelHeader: intlHandler.formatMessage("graphic"),
            showNoneOption: false
        })};
        return tabBody;
    }

    const getTabData = (): ELTabData[] => {
        const mediaTab: ELTabData = { key: mediaKey, item: getMediaTabItem(), panel: getCollageMediaTabBody() };
        const layoutTab: ELTabData = { key: layoutKey, item: getLayoutTabItem(), panel: getCollageLayoutTabBody() };
        const backgroundTab: ELTabData = { key: backgroundKey, item: getBackgroundTabItem(), panel: getCollageBackgroundTabBody() };

        const tabItems = [mediaTab, layoutTab, backgroundTab] as ELTabData[];
        return tabItems;
    }

    const onSelectionChange = (key: Key): void => {
        if (sSelectedKey === key)
            setSelectedKey(emptySelectedKey as string);
        else
            setSelectedKey(key as string);
    }

    return (
        <Provider store={store}>
            <ELTabPanelManager dataTestId="test-panel-tab-manager" variant="non-modal" tabData={getTabData()} orientation="vertical"
                defaultSelectedKey={defaultSelectedKey}
                onSelectionChange={onSelectionChange}
                panelItemDirection="row-reverse"
                showTabContent={sSelectedKey !== emptySelectedKey} />
        </Provider>
    )
}

export default CollagePanelView;
