/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { ELStageObject, ELStageObjectOptions, ELStageShapeAndObjectOptions, ELStageShapesType } from "../../../common/interfaces/stage/StageTypes";
import Logger, { LogLevel } from "../../../utils/Logger";
import IGraphicsStage from "../IGraphicsStage";

export default abstract class IStageShapes {
    protected type: ELStageShapesType;
    protected errorShapeAndOptionsList: ELStageShapeAndObjectOptions[];
    protected object?: ELStageObject;
    protected errorObject?: ELStageObject;

    constructor(type: ELStageShapesType) {
        this.type = type;
        this.errorShapeAndOptionsList = [];
    }

    protected isErrorInDrawing(): boolean {
        return false;
    }

    protected drawError<T extends ELStageObjectOptions>(stage: IGraphicsStage, options: T): Promise<void> {
        throw new Error("drawError not implemented for this shape " + this.type);
    }

    protected removeError(stage: IGraphicsStage): void {
        if (this.errorObject) {
            stage.removeObject(this.errorObject);
            this.errorObject = undefined;
        } else {
            Logger.log(LogLevel.WARN, "IStageShapes - (removeErrorObject) ", "called without errorObject");
        }
    }

    get getErrorShapeAndOptionsList(): ELStageShapeAndObjectOptions[] {
        return this.errorShapeAndOptionsList;
    }

    set setErrorShapeAndOptionsList(errorShapeAndOptionsList: ELStageShapeAndObjectOptions[]) {
        this.errorShapeAndOptionsList = errorShapeAndOptionsList;
    }

    async drawOrRemoveError<T extends ELStageObjectOptions>(stage: IGraphicsStage, options: T): Promise<void> {
        return Promise.reject("drawOrRemoveError not implemented for this shape " + this.type);
    }

    hasError(): boolean {
        return !!this.errorObject;
    }

    async update<T extends ELStageObjectOptions>(stage: IGraphicsStage, options: T): Promise<ELStageObject> {
        return Promise.reject("update API not implemented for this shape " + this.type);
    }

    remove(stage: IGraphicsStage): void {
        return this.object && stage.removeObject(this.object);
    }

    abstract draw<T extends ELStageObjectOptions>(stage: IGraphicsStage, options: T): Promise<ELStageObject>;
}
