/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React, { useEffect, useState, useReducer, Key } from "react";
import { useSelector } from "react-redux";

// Adobe internal
import { Flex, Text } from "@adobe/react-spectrum";

//Application Specific
import { ViewAction } from "../../../IBaseController";
import Utils from "../../../../utils/Utils";
import { RootState } from "../../../../stores/store";
import { ViewportProvider } from "../../../../utils/hooks/useViewport";
import ELComboBox from "../../organism/el-combo-box/ELComboBox";
import { CanvasZoomLevelAction, DEFAULT_ZOOM_PERCENTAGE, ZoomDropdownStrings, ZoomDropdownValues } from "../../../../common/interfaces/stage/StageTypes";
import { useNetwork } from "../../../../utils/hooks/useNetwork";
import { AssetStorageUtils } from "../../../../utils/AssetStorageUtils";
import {
    BackButton,
    DownloadView,
    ELSwitch,
    NoInternetSaveView,
    NoSpaceSaveView,
    SaveView,
    ShareView
} from "../../organism/el-creations-header/ELCreationsHeaderComponents";
import { DocumentDirty } from "../../../../common/interfaces/document/DocumentTypes";
import { ELReactSpectrumV3Provider } from "../../atoms/el-react-spectrum-provider/ELReactSpectrumV3Provider";
import IViewController from "../../../IViewController";
import { ShareOptions } from "../../organism/el-share-options/ELShareOptions";
import { CreationsDownloadFileType } from "../../../../common/interfaces/creations/CreationTypes";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { EditWorkspaceUtils } from "../../../../workspaces/edit/utils/EditWorkspaceUtils";
import { ELImageEditWorkflowHeaderViewAction } from "../../../../common/interfaces/editing/editWorkspace/ELImageEditWorkflowTypes";

import "./ELImageEditWorkflowHeader.scss";

export interface ELImageEditWorkflowHeaderViewProps {
    controller: IViewController;
    backButtonDialogHeading: string;
    radioButtonList: CreationsDownloadFileType[];
    shareOptionController: ShareOptions;
}

interface ELImageEditWorkflowHeaderState {
    name: string;
    isBackDialogOpen?: boolean;
}

const initialState: ELImageEditWorkflowHeaderState = {
    name: "",
    isBackDialogOpen: false
}

const reducer = (state: ELImageEditWorkflowHeaderState, action: ViewAction): ELImageEditWorkflowHeaderState => {
    const payload = action.payload as any;
    switch (action.type) {
        case ELImageEditWorkflowHeaderViewAction.changeName:
            return {
                ...state,
                name: payload
            };
        case ELImageEditWorkflowHeaderViewAction.updateBackDialogState:
            return {
                ...state,
                isBackDialogOpen: payload
            };
        default:
            return state;
    }
};

const ELImageEditWorkflowHeaderView = (props: ELImageEditWorkflowHeaderViewProps): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();
    const [state, dispatch] = useReducer(reducer, initialState);
    const docStoreData = useSelector((rootState: RootState) => rootState.docStateReducer);
    const [zoomValue, setZoomValue] = useState(DEFAULT_ZOOM_PERCENTAGE);
    const networkStatus = useNetwork(false);

    const zoomDropdownOptions = [
        { name: "Zoom Values(%)", children: ZoomDropdownValues },
        { name: "Zoom ShortCuts", children: ZoomDropdownStrings }
    ];

    useEffect(() => {
        props.controller.initialize(dispatch);
        return () => {
            props.controller.destroy();
        };
    }, [props.controller]);

    useEffect(() => {
        setZoomValue(Utils.getPercentageFromNumber(docStoreData.zoomLevel));
    }, [docStoreData]);

    const isDocReady = (): boolean => {
        return true;
    }

    const onSelectionChange = (value: Key): void => {
        if (value !== null) {
            switch (value) {
                case CanvasZoomLevelAction.zoomToFit:
                    {
                        props.controller.notify({ type: CanvasZoomLevelAction.zoomToFit });
                        break;
                    }
                case CanvasZoomLevelAction.zoomToFill:
                    {
                        props.controller.notify({ type: CanvasZoomLevelAction.zoomToFill });
                        break;
                    }
                default:
                    {
                        props.controller.notify({ type: CanvasZoomLevelAction.changeZoomValue, payload: value });
                        break;
                    }
            }
        }
    }

    const addAsteriskIfSaveEnable = (): string => {
        if (docStoreData.isDirty === DocumentDirty.DIRTY) {
            return "*";
        } else return "";
    }

    return (
        <ELReactSpectrumV3Provider>
            <ViewportProvider>
                <Flex UNSAFE_className="edit-workflow-header" gap="0.8rem" direction="row" alignItems="center">
                    <BackButton notify={props.controller.notify.bind(props.controller)}
                        hasDialog={docStoreData.isDirty === DocumentDirty.DIRTY}
                        headingDescription={props.backButtonDialogHeading} isDialogOpen={state.isBackDialogOpen}/>
                    <Flex direction="row" gap="size-250" alignItems="center" UNSAFE_className="edit-workflow-header__left-panel">
                        <Flex alignItems={"center"} gap="0">
                            <span className="edit-workflow-header__file-name">
                                {state.name}
                            </span>
                            <Text UNSAFE_className="edit-workflow-header__asterisk" data-testid="edit-workflow-header__asterisk">{addAsteriskIfSaveEnable()}</Text>
                        </Flex>
                        <ELSwitch controller={props.controller} />
                    </Flex>

                    <Flex isHidden={!isDocReady()}>
                        <ELComboBox options={zoomDropdownOptions} onSelectionChange={(key: Key) => onSelectionChange(key)} defaultValue={zoomValue} inputValue={zoomValue} styleProps={{ caretColor: "transparent" }} dataTestId="zoom-dropdown"/>
                    </Flex>
                    <Flex isHidden={!isDocReady()} UNSAFE_className="edit-workflow-header__cta-box"
                        direction="row" gap="1rem">
                        <Flex isHidden={networkStatus.online} data-testid="no-internet-save-container">
                            <NoInternetSaveView />
                        </Flex>

                        <Flex isHidden={!AssetStorageUtils.hasQuotaExceeded() || !networkStatus.online} data-testid="no-space-save-container">
                            <NoSpaceSaveView />
                        </Flex>

                        <Flex isHidden={!EditWorkspaceUtils.isSaveAvailable(networkStatus.online)} data-testid="save-container">
                            <SaveView controller={props.controller} docStoreData={docStoreData} saveButtonName={intlHandler.formatMessage("save-copy")} />
                        </Flex>

                        <DownloadView notify={props.controller.notify.bind(props.controller)} radioButtonList={props.radioButtonList} />

                        <ShareView controller={props.controller} shareOptionController={props.shareOptionController} docStoreData={docStoreData} />
                    </Flex>
                </Flex>
            </ViewportProvider>
        </ELReactSpectrumV3Provider >
    );
};
export default ELImageEditWorkflowHeaderView;
