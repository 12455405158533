/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { ELPIEDocPayload } from "../../../common/interfaces/editing/pie/ELPIEDocTypes";
import ImageUtils from "../../../utils/ImageUtils";
import DocumentFactory, { DocumentFactoryPayload } from "../../document/DocumentFactory";
import IDoc, { DocumentType } from "../../document/IDoc";
import { PIEEditingEngine } from "../../editingEngines/PIEEditingEngine";
import ELPIEDoc from "../models/ELPIEDoc";


export default abstract class IPSDConvertor {
    abstract convertToPSD(doc: IDoc, doResize: boolean): Promise<IDoc>;

    protected async createDocWithWhiteBackgroundLayer(inputDoc: IDoc, pieEditingEngine: PIEEditingEngine): Promise<IDoc> {
        let doc: IDoc;
        const size = await inputDoc.getSize();
        const canvas = ImageUtils.createHTMLCanvasElementWithFill(size);

        return new Promise((resolve, reject) => {
            const ctx = canvas.getContext("2d");
            if (ctx) {
                canvas.toBlob(async (blob) => {
                    if (blob) {
                        const buffer = await ImageUtils.readArrayBufferFromBlob(blob);

                        const docPayload: ELPIEDocPayload = { filename: "temp doc", buffer: new Uint8Array(buffer), engine: pieEditingEngine };
                        const pieDocPayload: DocumentFactoryPayload = {
                            docPayload: docPayload
                        };
                        doc = await DocumentFactory.createDocument(DocumentType.pie, inputDoc.getOwner, pieDocPayload);
                        return resolve(doc);
                    } else {
                        return reject(doc);
                    }
                })
            }
        });
    }

    protected shouldResize(): Promise<boolean> {
        return Promise.resolve(true);
    }

    protected async resizeDocument(psdDoc: ELPIEDoc, pieEditingEngine: PIEEditingEngine): Promise<ELPIEDoc> {
        const tmp_image = (psdDoc.getData() as any).duplicate(); //PIE_WASM_REVISIT - image data is pie image here

        const maxWidth = 1240;
        const pieInstance = pieEditingEngine.getModule();
        const width = tmp_image.get_bounds().width();

        if (width > maxWidth) {
            const resize_details = new pieInstance.dict('');
            resize_details.put_bool('rasterize', true);
            resize_details.put_unit_real('width', maxWidth, 'pixelsUnit');
            resize_details.put_enum(
                'resample',
                new pieInstance.enumeration_t(
                    'interpolationType',
                    `${pieInstance.interpolation_type_e.bicubicSharper.value}`
                )
            );
            resize_details.put_bool('constrainProportions', true);
            tmp_image.set_size(resize_details);
        }

        psdDoc.setData(tmp_image);
        return Promise.resolve(psdDoc);
    }
}