/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import { v4 as uuid } from "uuid";

//utils
import Logger, { LogLevel } from "../../../../utils/Logger";
import { ToastUtils } from "../../../../utils/ToastUtils";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";

//Application specific
import {
    ELPeekThroughDocAction, ELPeekThroughDocPayload, ELPeekThroughProjectRequestParams,
    ELPeekThroughShapeData, ELSavedPeekThroughData, PeekThroughViewActions,
    ELPeekThroughOverlayInfoType, ELPeekThroughCornerData
} from "../../../../common/interfaces/creations/ELPeekThroughTypes";
import ELPeekThroughDoc from "../../../../editors/peekThrough/ELPeekThroughDoc";
import { ControllerAction } from "../../../../view/IViewController";
import IBaseWorkspace, { WorkspaceActionType, WorkspacePayload } from "../../../IBaseWorkspace";
import IWorkflow, { WorkflowAction, WorkflowActionType, WorkflowsName } from "../../../IWorkflow";
import CreationWorkflow from "../CreationWorkflow";
import PeekThroughView from "./PeekThroughView";
import store from "../../../../stores/store";
import { ViewAction } from "../../../../view/IBaseController";
import { DocumentActions, DocumentDirty, DocumentSaveStatus } from "../../../../common/interfaces/document/DocumentTypes";
import DocumentFactory, { DocumentFactoryPayload } from "../../../../editors/document/DocumentFactory";
import { DocumentType } from "../../../../editors/document/IDoc";
import {
    CSAssetWithData, CreationWorkflowActions, CreationsData, CreationsJobProjectSubType, CreationsJobStorageType,
    CreationsMode,
    CreationsStatus, CreationsStatusPayload, ELCreationWorkflowPayload, ELViewType, UNTITLED_INTL_KEY
} from "../../../../common/interfaces/creations/CreationTypes";
import CollageUtils from "../collage/utils/CollageUtils";
import CreationUtils from "../../utils/CreationUtils";
import ELPeekThroughHeader from "../../../../view/components/templates/el-creations-header/ELPeekThroughHeader";
import { ReplaceAssetInfo } from "../../../../common/interfaces/creations/ELCollageTypes";
import { CanvasZoomLevelAction, ELFabricConfig, ELImageData, ELLayoutPanelControllerAction, ELStageObjectData } from "../../../../common/interfaces/stage/StageTypes";
import {
    IngestEventSubTypes, IngestEventTypes, IngestLogObjectCustomKey, IngestLogObjectKey,
    IngestLogObjectValue, IngestWorkflowTypes
} from "../../../../utils/IngestConstants";
import { IngestUtils } from "../../../../utils/IngestUtils";
import Constants from "../../../../utils/Constants/Constants";
import Utils from "../../../../utils/Utils";
import DocActions from "../../../../stores/actions/DocActions";
import { ELCreationsHeaderControllerAction } from "../../../../common/interfaces/creations/ELCreationsHeaderTypes";
import PeekThroughAction from "../../../../stores/actions/PeekThroughAction";
import { HistoryUtils } from "../../../../utils/HistoryUtils";
import PeekThroughUtils from "./utils/PeekThroughUtils";
import { ELPeekThroughOverlayPanelControllerActions } from "../../../../common/interfaces/creations/templates/ELPeekThroughOverlayPanelTypes";
import { PeekThroughJobCreator } from "./utils/PeekThroughJobCreator";
import ELAdobeAssetDoc, { ELAdobeAssetDocPayload } from "../../../../editors/adobeAsset/ELAdobeAssetDoc";
import { ELDualDocumentView } from "../../../../editors/document/dualDocumentView/ELDualDocumentView";
import { ELAdobeAssetControllerAction } from "../../../../common/interfaces/creations/templates/ELAdobeAssetDocTypes";
import DualViewAction from "../../../../stores/actions/DualViewAction";
import SelectedMediaListAction from "../../../../stores/actions/selectedMediaListActions";
import { ReplaceMediaManagerMode, ReplaceMediaManagerWorkflowAction } from "../../../../common/interfaces/workflows/ReplaceMediaManagerTypes";
import LayoutAction from "../../../../stores/actions/LayoutAction";
import { ZoomLevel } from "../../../../editors/stage/ELFabricStage";
import { ELLayoutInfo } from "../../../../common/interfaces/creations/ELSocialLayoutTypes";
import CanvasAction, { CanvasMode } from "../../../../stores/actions/CanvasAction";
import { StorageService } from "../../../../services/StorageServiceWrapper";
import { StageUtils } from "../../../../utils/stage/StageUtils";
import { FeatureName } from "../../../../services/Floodgate/FloodgateConstants";
import { FeaturesManager } from "../../../../modules/floodgate/Featuresmanager";
import { CreationMediaActionType } from "../../../../view/components/templates/el-creation-media-panel/ELCreationMediaView";
import CreationsAction, { CreationsThumb } from "../../../../stores/actions/CreationsAction";
import { ELAdobeAsset } from "../../../../common/interfaces/storage/AssetTypes";
import ELOpenInDesktopManager from "../../../../view/components/templates/el-open-in-desktop-manager/ELOpenInDesktopManager";
import { ELOpenInDesktopDeeplinkAction, ELOpenInDesktopManagerViewAction, ELOpenInDesktopOpenAssetPayload } from "../../../../common/interfaces/creations/ELOpenInDesktopTypes";
import ELPanelManager from "../../../../view/components/templates/el-panel-manager/ELPanelManager";
import ELPeekThroughPanelProvider from "../../utils/panelProvider/ELPeekThroughPanelProvider";
import { ELTabPanelType } from "../../../../common/interfaces/tabpanel/ELTabPanelTypes";
import { CreationsJobCreator } from "../../utils/CreationsJobCreator";
import { ELError } from "../../../../modules/error/ELError";

class PeekThrough extends CreationWorkflow<ELPeekThroughDocPayload> {
    protected openProject(projectId: string): Promise<void> {
        throw new Error("Method not implemented.");
    }
    private _peekThroughPayload!: ELCreationWorkflowPayload;
    private _peekThroughPanel!: ELPanelManager;
    private _peekThroughHeader: ELPeekThroughHeader;
    private _intlHandler = IntlHandler.getInstance();
    private _overlayPlacementId: number;

    private readonly _feedbackContainer = "feedback-popover-container";
    private readonly _peekThroughEditContainer = "peekThrough-edit-container";
    private readonly _openDeeplinkContainer = "open-deeplink-container";

    constructor(owner: IBaseWorkspace) {
        super(owner, WorkflowsName.peekThrough);
        this._peekThroughHeader = new ELPeekThroughHeader(this, this.shareOptions);
        this._overlayPlacementId = -1;
        this.mediaGridConfig = PeekThroughUtils.getPeekThroughMediaGridConfig();
    }

    protected getJobCreator(): CreationsJobCreator {
        return new PeekThroughJobCreator();
    }

    private async _getAndSetProjectMetadata(projectId: string): Promise<string> {
        this.projectId = projectId;
        try {
            this.projectData = await this.getProjectData(this.projectId);
            const appMetadata = await CreationUtils.getAppMetadata(this.projectData) as string;
            return appMetadata;
        } catch (error) {
            throw new Error("couldn't get project data or app metadata in the output asset not set " + error);
        }
    }

    private async _populatePanelData(savedPeekThroughdata: ELSavedPeekThroughData): Promise<void> {
        await this.populateSelectedMediaList([savedPeekThroughdata.backgroundAsset]);
    }

    private async _openSavedPeekThrough(savedPeekThroughdata: ELSavedPeekThroughData): Promise<void> {
        const backgroundAsset = savedPeekThroughdata.backgroundAsset;
        backgroundAsset.objectURL = undefined;
        const overlayInfoList = savedPeekThroughdata.overlayInfoList;
        const layoutInfo = savedPeekThroughdata.layoutInfo;

        const peekThroughDocPayload: ELPeekThroughDocPayload = {
            backgroundAsset: backgroundAsset,
            overlayInfoList: overlayInfoList,
            layoutInfo: layoutInfo
        };
        await this.createAndRenderDoc(peekThroughDocPayload, DocumentDirty.NON_DIRTY);
    }

    private async _openPeekThrough(peekThroughPayload: ELCreationWorkflowPayload): Promise<void> {
        this.startDate = new Date();
        this.mode = CreationsMode.render;
        const additionalLogInfo: Record<string, string> = {};
        additionalLogInfo[IngestLogObjectKey.eventContextGuid] = Utils.getRandomUUID();
        const peekThroughStatusPayload: CreationsStatusPayload = peekThroughPayload.payload as CreationsStatusPayload;
        this.updateViewStatusAndProgressText(CreationsStatus.requested, this._intlHandler.formatMessage("fetching-peekThrough"));
        try {
            const appMetadata = await this._getAndSetProjectMetadata(peekThroughStatusPayload.projectId);
            const savedPeekThroughdata = JSON.parse(JSON.stringify(appMetadata)) as ELSavedPeekThroughData;
            await this._openSavedPeekThrough(savedPeekThroughdata);
            await this._populatePanelData(savedPeekThroughdata);
            this._notifySubViews();
            this._updatePeekThroughRoute();
            this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                IngestEventSubTypes.success, this.mode, CreationsJobProjectSubType.peekThrough, additionalLogInfo));
        } catch (error) {
            const elError = new ELError("PeekThrough:_openPeekThrough", this.getWorkflowConfiguration(), error as Error);
            Logger.log(LogLevel.ERROR, elError);
            ToastUtils.error(IntlHandler.getInstance().formatMessage("open-creation-error-try-again"));
            this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                IngestEventSubTypes.error, this.mode, CreationsJobProjectSubType.peekThrough, additionalLogInfo));
            await this.startPreviousWorkflow();
        }
    }

    protected logIngestData(operationStatus: string, errorInfo?: string): void {
        try {
            const timeElapsed = Utils.getDateDifference(this.startDate, new Date(), "second");
            const allMedia = store.getState().selectedMediaListReducer;

            const customEntries: Record<string, string> = IngestUtils.getMediaLoggingInfo(allMedia);
            customEntries[IngestLogObjectCustomKey.timeTaken] = timeElapsed.toString();
            customEntries[IngestLogObjectCustomKey.totalCount] = allMedia.length.toString();

            const overlayEntries: Record<string, string> = {};
            const peekThroughDocumentData = this.doc?.getData() as ELPeekThroughDocPayload;
            peekThroughDocumentData.overlayInfoList?.forEach((overlayInfo, index) => {
                if (overlayInfo) {
                    overlayEntries[index.toString()] = overlayInfo.asset.assetURN;
                }
            })

            if (peekThroughDocumentData.layoutInfo) {
                customEntries[IngestLogObjectCustomKey.socialLayoutId] = peekThroughDocumentData.layoutInfo.layoutId;
                customEntries[IngestLogObjectCustomKey.socialLayoutScaling] = peekThroughDocumentData.layoutInfo.scaleX.toString();
            }

            const eventContextId = Utils.getRandomUUID();
            const additionalLogInfo: Record<string, string> = {};
            additionalLogInfo[IngestLogObjectKey.eventContextGuid] = eventContextId;

            if (errorInfo)
                additionalLogInfo[IngestLogObjectKey.errorDescription] = errorInfo;

            if (!this.ingestParams.subType)
                this.ingestParams.subType = this.mode;

            for (const key in customEntries) {
                const additionalLogInfoTemp = { ...additionalLogInfo };
                additionalLogInfoTemp[IngestLogObjectKey.eventContextGuid] = eventContextId;
                additionalLogInfoTemp[IngestLogObjectKey.contentName] = key;
                additionalLogInfoTemp[IngestLogObjectKey.eventCount] = customEntries[key];
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                    IngestEventSubTypes.info, this.ingestParams.subType, CreationsJobProjectSubType.peekThrough, additionalLogInfoTemp));
            }

            for (const key in overlayEntries) {
                const additionalLogInfoTemp = { ...additionalLogInfo };
                additionalLogInfoTemp[IngestLogObjectKey.eventContextGuid] = eventContextId;
                additionalLogInfoTemp[IngestLogObjectKey.contentName] = IngestLogObjectCustomKey.overlay;
                additionalLogInfoTemp[IngestLogObjectKey.eventCount] = overlayEntries[key];
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                    IngestEventSubTypes.info, this.ingestParams.subType, CreationsJobProjectSubType.peekThrough, additionalLogInfoTemp));
            }

            this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                operationStatus, this.ingestParams.subType, CreationsJobProjectSubType.peekThrough, additionalLogInfo));
        }
        catch (e) {
            Logger.log(LogLevel.WARN, `PeekThrough:logIngestData:${this.mode}: `, `Dunamis Logging Error:${e as string}`);
        }
    }

    protected updateViewStatusAndProgressText(status: CreationsStatus, progressText: string): void {
        this.viewDispatcher?.call(this.viewDispatcher, {
            type: PeekThroughViewActions.peekThroughStatus,
            payload: status
        });

        this.viewDispatcher?.call(this.viewDispatcher, {
            type: PeekThroughViewActions.peekThroughProgressText,
            payload: progressText
        });
    }

    protected async createAndRenderDoc(peekThroughDocPayload: ELPeekThroughDocPayload, documentDirty: DocumentDirty): Promise<void> {
        this.beforeDoc?.destroy();
        this.doc?.destroy();

        const peekThroughAfterStagePayload: ELFabricConfig = {
            showReplaceMediaButton: false,
            showDeleteButton: true,
            objectHoverColor: "rgb(0, 255, 255)",
            viewType: ELViewType.after
        };

        const peekThroughPayload: DocumentFactoryPayload = {
            docPayload: peekThroughDocPayload,
            stagePayload: peekThroughAfterStagePayload
        };

        this.doc = await DocumentFactory.createDocumentWithStage(DocumentType.peekThrough, this, peekThroughPayload) as ELPeekThroughDoc;
        this.doc.markAndNotifyDocumentDirty(documentDirty);

        const beforeDocPayload: ELAdobeAssetDocPayload = {
            assetInfo: peekThroughDocPayload.backgroundAsset
        }

        const peekThroughBeforeStagePayload: ELFabricConfig = {
            showReplaceMediaButton: false,
            showDeleteButton: true,
            objectHoverColor: "rgb(0, 255, 255)",
            viewType: ELViewType.before
        };

        const beforePayload: DocumentFactoryPayload = {
            docPayload: beforeDocPayload,
            stagePayload: peekThroughBeforeStagePayload
        }

        this.beforeDoc = await DocumentFactory.createDocumentWithStage(DocumentType.adobeAsset, this, beforePayload) as ELAdobeAssetDoc;

        this.dualView = new ELDualDocumentView(this.beforeDoc);

        try {
            await this.dualView.createView(this.ensureHTMLElement(this._peekThroughEditContainer));
            await this.dualView.renderAfterDoc(this.doc);
        } catch (error) {
            const elError = new ELError("PeekThrough:_createAndRenderDoc: Couldn't render document", this.getWorkflowConfiguration(), error as Error);
            Logger.log(LogLevel.ERROR, elError);
            ToastUtils.error(this._intlHandler.formatMessage("failed-to-resolve-asset-for-doc"));
            await this.startPreviousWorkflow();
            this.logIngestData(IngestEventSubTypes.error, "Document render failed");
        }
        this.updateLayoutStore(peekThroughDocPayload.layoutInfo);
        store.dispatch(DualViewAction.updateView(ELViewType.after));
        Logger.log(LogLevel.INFO, "Document Object: ", this.doc);
    }

    private async _renderPeekThroughDocument(asset: ELAdobeAsset, documentDirty: DocumentDirty, overlayInfoList?: ELPeekThroughOverlayInfoType[], layoutInfo?: ELLayoutInfo): Promise<void> {
        const objectURL: string | undefined = CollageUtils.getAssetFullResObjectURL(asset.assetId ?? "");

        const csAsset: CSAssetWithData = {
            id: uuid(),
            assetURN: asset.assetId ?? "",
            storageType: CreationsJobStorageType.RAPI,
            mimeType: asset.format ?? "",
            objectURL: objectURL
        }

        const peekThroughDocPayload: ELPeekThroughDocPayload = {
            backgroundAsset: csAsset,
            overlayInfoList: overlayInfoList,
            layoutInfo: layoutInfo
        }

        await this.createAndRenderDoc(peekThroughDocPayload, documentDirty);
    }

    private async _createPeekThrough(assets: ELAdobeAsset[]): Promise<void> {
        this.ingestParams.subType = CreationsMode.create;
        this.startDate = new Date();
        try {
            const asset = assets[0];
            await this._renderPeekThroughDocument(asset, DocumentDirty.NON_DIRTY);
            this.logIngestData(IngestEventSubTypes.success);
        } catch (error) {
            Logger.log(LogLevel.WARN, "PeekThrough - (_createPeekThrough) Couldn't create peekThrough ", error);
            ToastUtils.error(IntlHandler.getInstance().formatMessage("create-output-error-try-again"));
            this.logIngestData(IngestEventSubTypes.error);
            await this.startPreviousWorkflow();
        }
    }


    protected _shouldAllowCreationToOpen(): boolean {
        const featureFlagName = FeatureName.ePeekThrough;
        const isCreationFeatureActive = FeaturesManager.getInstance().IsFeatureActive(featureFlagName);
        return isCreationFeatureActive;
    }

    async enterProject(peekThroughPayload: ELCreationWorkflowPayload): Promise<void> {
        const isCreationActive = this._shouldAllowCreationToOpen();
        if (!isCreationActive) {
            ToastUtils.error(IntlHandler.getInstance().formatMessage("creation-disabled-via-feature-flag-message"));
            this.startPreviousWorkflow();
            return;
        }
        switch (peekThroughPayload.initMode) {
            case CreationsMode.render: {
                this._openPeekThrough(peekThroughPayload);
                break;
            }
            case CreationsMode.create:
            default: {
                this._createPeekThrough(peekThroughPayload.payload as ELAdobeAsset[]);
                break;
            }
        }
    }

    private _ingest(payload: Record<string, string>): void {
        this._owner.notify({
            type: WorkspaceActionType.ingest,
            payload: payload
        });
    }

    private _onReplaceMedia(replaceAssetInfo: ReplaceAssetInfo): void {
        this.updateViewStatusAndProgressText(CreationsStatus.requested, IntlHandler.getInstance().formatMessage("collage-replacing-image"));
        const asset = replaceAssetInfo.assetToReplaceWith;
        this._renderPeekThroughDocument(asset, DocumentDirty.DIRTY);
    }

    private async _download(imageData: ELImageData): Promise<void> {
        this.startDate = new Date();
        this.ingestParams.subType = IngestEventSubTypes.download;
        const additionalLogInfo: Record<string, string> = {};
        additionalLogInfo[IngestLogObjectCustomKey.viewType] = IngestLogObjectValue.workspace;
        try {
            const message = this._intlHandler.formatMessage("file-download-in-background",
                { media: this._intlHandler.formatMessage("creation").toLowerCase() });
            ToastUtils.info(message);
            const title = this.projectData ? this.projectData.title : this.defaultTitle;
            this.doc?.notify({ type: DocumentActions.download, payload: { name: title, imageData: imageData } });
            this.logIngestData(IngestEventSubTypes.success);
        } catch (error) {
            setTimeout(() => {
                ToastUtils.error(this._intlHandler.formatMessage("download-fail-toast-msg"), {
                    closable: true,
                    timeout: 0
                });
            }, Constants.TOAST_DEFAULT_TIME_OUT_LIMIT as number);
            this.logIngestData(IngestEventSubTypes.error);
        }
    }

    private async _isDocumentSavedBefore(): Promise<boolean> {
        let isDocumentSavedBefore = false;
        if (this.projectData) {
            try {
                const outputAsset = await CreationUtils.getCreationOutputAsset(this.projectData);
                isDocumentSavedBefore = !!outputAsset;
            } catch (error) {
                isDocumentSavedBefore = false;
            }
        }
        return isDocumentSavedBefore;
    }

    private _getEditCreationRequestParams(projectId: string, projectData: CreationsData): ELPeekThroughProjectRequestParams {
        const requestParams = {
            assets: store.getState().selectedMediaListReducer,
            title: this.getTrueTitleForRequest(projectData.title)
        }
        return requestParams;
    }

    private _preprocessPeekThroughEdit(): void {
        if (!this.projectId) {
            const elError = new ELError("PeekThrough:_preprocessPeekThroughEdit: project id not valid", this.getWorkflowConfiguration());
            Logger.log(LogLevel.ERROR, elError);
            return;
        }

        const creationsThumb: CreationsThumb = { id: this.projectId, objectURL: "" };
        store.dispatch(CreationsAction.updateThumb(creationsThumb));
    }

    private async _editPeekThroughCreation(): Promise<void> {
        this.mode = CreationsMode.update;
        this.startDate = new Date();
        if (!this.projectId || !this.projectData) {
            const elError = new ELError("PeekThrough:_editPeekThroughCreation: project id or project data not valid", this.getWorkflowConfiguration());
            Logger.log(LogLevel.ERROR, elError);
            return Promise.reject();
        }

        const requestParams = this._getEditCreationRequestParams(this.projectId, this.projectData);

        try {
            this._preprocessPeekThroughEdit();
            const requestJson = this.createRequestJson(requestParams);
            this.projectId = await this.editCreation(this.projectId, requestJson);
        } catch (error) {
            Logger.log(LogLevel.WARN, "PeekThrough:_editPeekThroughCreation: , project edit failed!" + error);
            return Promise.reject();
        }
        this.projectData = await this.getProjectData(this.projectId);
    }

    private async _save(showProgress = true): Promise<void> {
        const saveStatus = store.getState().docStateReducer.saveStatus;
        if (saveStatus === DocumentSaveStatus.saveInProgress)
            return;

        store.dispatch(DocActions.updateDocumentSaveStatus(DocumentSaveStatus.saveInProgress));

        if (this.doc?.hasRenderingError()) {
            store.dispatch(DocActions.updateDocumentSaveStatus(DocumentSaveStatus.saveError));
            ToastUtils.error(this._intlHandler.formatMessage("collage-save-failed"));
            return;
        }

        if (showProgress) {
            this.updateViewStatusAndProgressText(CreationsStatus.requested, this._intlHandler.formatMessage("saving-creation"));
        }

        try {
            const isDocumentSavedBefore = await this._isDocumentSavedBefore();
            this.startDate = new Date();

            if (isDocumentSavedBefore) {
                this.ingestParams.subType = CreationsMode.update;
                await this._editPeekThroughCreation();
            } else {
                this.ingestParams.subType = CreationsMode.create;
                await this._createPeekThroughCreation(this._peekThroughPayload.payload as ELAdobeAsset[]);
            }

            this._notifySubViews();
            await this.setCloudAssetPathForDoc();
            await this.doc?.save();
            store.dispatch(DocActions.updateDocumentSaveStatus(DocumentSaveStatus.saved));
            this.logIngestData(IngestEventSubTypes.success);
        } catch (error) {
            store.dispatch(DocActions.updateDocumentSaveStatus(DocumentSaveStatus.saveError));
            const elError = new ELError("PeekThrough:_save: error in saving document", this.getWorkflowConfiguration(), error as Error);
            Logger.log(LogLevel.ERROR, elError);
            this.logIngestData(IngestEventSubTypes.error);
        }

        if (showProgress) {
            this.updateViewStatusAndProgressText(CreationsStatus.success, this._intlHandler.formatMessage("saving-creation"));
        }
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);

        const panelProvider = new ELPeekThroughPanelProvider(this);

        this._peekThroughPanel = panelProvider.getTabPanel(ELTabPanelType.rightTabPanel);
        this.defaultTitle = this.defaultTitle ?? IntlHandler.getInstance().formatMessage(UNTITLED_INTL_KEY);
        this._peekThroughPanel.createView(this.ensureHTMLElement("peekThrough-right-panel-container"));

        this._peekThroughHeader.createView(this.ensureHTMLElement("peekThrough-header-container"));
        this.createFeedbackView(this.ensureHTMLElement(this._feedbackContainer));

        this.openInDesktopManager = new ELOpenInDesktopManager(this, WorkflowsName.peekThrough);
        this.openInDesktopManager.createView(this.ensureHTMLElement(this._openDeeplinkContainer));

        this.enterProject(this._peekThroughPayload);
    }

    createView(container: HTMLElement): void {
        super.createView(container);

        const element = React.createElement(PeekThroughView, {
            controller: this
        });

        const provider = React.createElement(Provider, { store }, element);

        ReactDOM.render(provider, container);
    }

    destroyView(): void {
        if (this.container)
            ReactDOM.unmountComponentAtNode(this.container);
        this._peekThroughHeader.destroyView();
        this._peekThroughPanel?.destroyView();
        super.destroyView();
    }

    destroy(): void {
        super.destroy();
        this.beforeDoc?.destroy();
        this.beforeDoc = undefined;
        this.doc?.destroy();
        this.doc = undefined;
        this.projectData = undefined;
    }

    startWorkflow(containerId: string, prevWorkflow?: IWorkflow, action?: WorkflowAction): void {
        super.startWorkflow(containerId, prevWorkflow, action);
        const peekThroughPayload: ELCreationWorkflowPayload = {
            initMode: action?.initMode as CreationsMode,
            payload: action?.payload
        }
        this._peekThroughPayload = peekThroughPayload;
        this.createView(this.ensureHTMLElement(containerId));
    }

    endWorkflow(): void {
        super.endWorkflow();
        this.renditionHandler.clearRenditions();
        store.dispatch(SelectedMediaListAction.updateSelectedMediaList([]));
        this.destroyView();
    }

    private async _waitForSaveComplete(waitCount = 0, maxWaitCount = 10): Promise<void> {
        if (waitCount >= maxWaitCount)
            return;

        const saveStatus = store.getState().docStateReducer.saveStatus;
        if (saveStatus === DocumentSaveStatus.saveInProgress) {
            await Utils.wait(2000);
            await this._waitForSaveComplete(waitCount + 1);
        }
    }


    protected async ingestCreationFeedback(eventSubType: string): Promise<void> {
        try {
            const customEntries: Record<string, string> = {};
            const overlayEntries: Record<string, string> = {};
            const peekThroughDocumentData = this.doc?.getData() as ELPeekThroughDocPayload;
            peekThroughDocumentData.overlayInfoList?.forEach((overlayInfo, index) => {
                if (overlayInfo) {
                    overlayEntries[index.toString()] = overlayInfo.asset.assetURN;
                }
            })

            if (peekThroughDocumentData.layoutInfo) {
                customEntries[IngestLogObjectCustomKey.layoutName] = peekThroughDocumentData.layoutInfo.layoutId;
            }

            const additionalLogInfo: Record<string, string> = {};
            for (const key in customEntries) {
                const additionalLogInfoTemp = { ...additionalLogInfo };
                additionalLogInfoTemp[IngestLogObjectKey.contentName] = key;
                additionalLogInfoTemp[IngestLogObjectKey.eventCount] = customEntries[key];
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                    IngestEventSubTypes.info, eventSubType, IngestUtils.getIngestCreationsWorkflowName(this.workflowName), additionalLogInfoTemp));
            }

            for (const key in overlayEntries) {
                const additionalLogInfoTemp = { ...additionalLogInfo };
                additionalLogInfoTemp[IngestLogObjectKey.contentName] = IngestLogObjectCustomKey.overlay;
                additionalLogInfoTemp[IngestLogObjectKey.eventCount] = overlayEntries[key];
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                    IngestEventSubTypes.info, eventSubType, IngestUtils.getIngestCreationsWorkflowName(this.workflowName), additionalLogInfoTemp));
            }
        }
        catch (e) {
            Logger.log(LogLevel.WARN, `PeekThrough:_ingestCreationFeedback:${this.mode}: `, `Dunamis Logging Error:${e as string}`);
        }
    }

    protected async openDeeplink(): Promise<void> {
        if (!this.defaultTitle) {
            this.defaultTitle = IntlHandler.getInstance().formatMessage(UNTITLED_INTL_KEY);
        }
        const psdName = this.projectData?.title ?? this.defaultTitle;
        this.createPSDAndOpenDeeplinkOnClient(DocumentType.peekThrough, CreationsJobProjectSubType.peekThrough, psdName);
    }

    private _canSaveDocument(): boolean {
        return this.doc?.isDocumentDirty === DocumentDirty.DIRTY && !this.doc?.hasRenderingError();
    }

    private async _saveAndStartPreviousWorkflow(): Promise<void> {
        const saveStatus = store.getState().docStateReducer.saveStatus;
        if (saveStatus === DocumentSaveStatus.saveInProgress) {
            this.updateViewStatusAndProgressText(CreationsStatus.requested, this._intlHandler.formatMessage("saving-creation"));
            await this._waitForSaveComplete();
        } else if (this._canSaveDocument()) {
            await this._save();
        }
        await this.startPreviousWorkflow();
    }

    private _onPeekThroughOverlayChanged(action: ControllerAction): void {
        const overlayId = (action.payload as ELPeekThroughCornerData).commonData.id;
        const additionalLogInfo: Record<string, string> = {};
        additionalLogInfo[IngestLogObjectKey.eventCount] = overlayId;
        this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
            IngestEventTypes.click, IngestEventSubTypes.overlay, CreationsJobProjectSubType.peekThrough, additionalLogInfo));

        if (this.doc?.hasRenderingError()) {
            ToastUtils.error(this._intlHandler.formatMessage("collage-missing-photo-error"));
            return;
        }

        const cornerOverlayData = action.payload as ELPeekThroughCornerData;
        const assetId = cornerOverlayData.commonData.id;
        const objectURL: string = cornerOverlayData.commonData.contentUrl;

        const tempAsset: CSAssetWithData = {
            id: assetId,
            assetURN: assetId ?? "",
            storageType: CreationsJobStorageType.RAPI,
            mimeType: "",
            objectURL: objectURL
        };

        const defaultOverlayCorner = cornerOverlayData.defaultCorner - 1;
        const peekThroughShapeData: ELPeekThroughShapeData = {
            asset: tempAsset,
            overlayPlacementId: (this._overlayPlacementId === -1) ? defaultOverlayCorner : this._overlayPlacementId
        };
        this._overlayPlacementId = -1;

        this.doc?.notify({ type: ELPeekThroughDocAction.overlayUpdated, payload: peekThroughShapeData });
        store.dispatch(PeekThroughAction.updateOverlayData(assetId));
    }

    private _onPeekThroughOverlayRevert(): void {
        for (let corner = 0; corner < 4; corner++) {
            const removeOverlayData: ELPeekThroughShapeData = {
                overlayPlacementId: corner
            };
            this.doc?.notify({
                type: ELPeekThroughDocAction.removeOverlay,
                payload: removeOverlayData
            });
        }
    }

    private _onPeekThroughOverlayRemove(): void {
        const removeOverlayData: ELPeekThroughShapeData = {
            overlayPlacementId: this._overlayPlacementId
        };
        this.doc?.notify({
            type: ELPeekThroughDocAction.removeOverlay,
            payload: removeOverlayData
        });
    }

    private _updatePeekThroughRoute(): void {
        if (!this.projectId) {
            const elError = new ELError("PeekThrough:_updatePeekThroughRoute: project id not valid", this.getWorkflowConfiguration());
            Logger.log(LogLevel.ERROR, elError);
            return;
        }

        HistoryUtils.replaceHistory(PeekThroughUtils.getPeekThroughHistoryState(this.projectId));
    }

    private async _createPeekThroughCreation(assetList: ELAdobeAsset[]): Promise<void> {
        const intlHandler = IntlHandler.getInstance();
        const requestParams: ELPeekThroughProjectRequestParams = {
            assets: assetList,
            title: this.getTrueTitleForRequest(this.defaultTitle ?? intlHandler.formatMessage(UNTITLED_INTL_KEY))
        };

        try {
            const requestJson = this.createRequestJson(requestParams);
            this.projectId = await this.createCreation(requestJson);
        } catch (error) {
            Logger.log(LogLevel.WARN, "PeekThrough:_createPeekThroughCreation: project creation failed!" + error);
            return Promise.reject();
        }

        this.projectData = await this.getProjectData(this.projectId);
        this._updatePeekThroughRoute();
    }

    private _notifySubViews(): void {
        this._peekThroughHeader.notify({ type: ELCreationsHeaderControllerAction.updateCreationsData, payload: this.projectData });
    }

    private async _onPeekThroughRendered(status: CreationsStatus): Promise<void> {
        this.viewDispatcher?.call(this.viewDispatcher, {
            type: PeekThroughViewActions.peekThroughStatus,
            payload: status
        });
        store.dispatch(PeekThroughAction.updateStatus(status));
    }

    private _onDocumentDirty(dirty: DocumentDirty): void {
        if (this.doc) {
            store.dispatch(DocActions.updateDocumentError(this.doc.hasRenderingError()));
        }

        store.dispatch(DocActions.updateDocumentDirty(dirty));
    }

    private _setActiveOverlay(overlayPlacementId: number): void {
        this._overlayPlacementId = overlayPlacementId;
    }

    private _handleActiveOverlayChange(stageObjectData: ELStageObjectData): void {
        if (stageObjectData) {
            const peekThroughShapeData = (stageObjectData).payload as ELPeekThroughShapeData;
            this._setActiveOverlay(peekThroughShapeData.overlayPlacementId);
        } else {
            this._setActiveOverlay(-1);
        }
    }

    private async _updateProjectData(projectId: string): Promise<boolean> {
        try {
            this.projectData = await this.getProjectData(projectId);
        } catch (error) {
            Logger.log(LogLevel.WARN, "PeekThrough:_updateProjectData: Could not fetch PeekThrough project data! " + error);
            return false;
        }
        return true;
    }

    private async _onPeekThroughTitleChange(): Promise<void> {
        if (this.projectId) {
            await this._updateProjectData(this.projectId);
        }
    }

    private async _updateCreationsStatus(status: CreationsStatus): Promise<void> {
        if (!this.projectId) {
            Logger.log(LogLevel.WARN, "PeekThrough:_updateCreationsStatus: ", "project id empty");
        } else {
            await CreationUtils.updateCreationStatus(this.projectId, status);
        }
    }

    private async _commitLayoutWorkflow(layoutInfo: ELLayoutInfo): Promise<void> {
        store.dispatch(CanvasAction.updateMode(CanvasMode.render));
        const docPayload = this.doc?.getData() as ELPeekThroughDocPayload;
        const assetId = docPayload.backgroundAsset.assetURN;
        const asset = await StorageService.getInstance().resolveAsset({ assetId }, "id");
        const isDocumentDirty = store.getState().docStateReducer.isDirty;
        await this._renderPeekThroughDocument(asset, isDocumentDirty, docPayload.overlayInfoList, layoutInfo);
    }

    private async _layoutPanelApplyPress(): Promise<void> {
        await this.doc?.notify({ type: ELLayoutPanelControllerAction.commit });
        this.doc?.markAndNotifyDocumentDirty(DocumentDirty.DIRTY);
    }

    private async _layoutPanelCancelPress(): Promise<void> {
        await this.doc?.notify({ type: ELLayoutPanelControllerAction.cancel });
    }

    private _layoutScaleChange<T extends ControllerAction>(action: T): void {
        this.doc?.notify(action);
        store.dispatch(LayoutAction.updateScale(action.payload as number));
    }

    private _layoutRevert<T extends ControllerAction>(action: T): void {
        this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
            IngestEventTypes.click, IngestEventSubTypes.socialLayoutRevert, CreationsJobProjectSubType.peekThrough));

        this.doc?.notify(action);
        store.dispatch(LayoutAction.updateScale(0));
        store.dispatch(LayoutAction.updateSelectedLayout(""));
    }

    private async _layoutChange<T extends ControllerAction>(action: T): Promise<void> {
        const additionalLogInfo: Record<string, string> = {};
        additionalLogInfo[IngestLogObjectKey.eventCount] = store.getState().layoutReducer.selectedLayout;
        this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
            IngestEventTypes.click, IngestEventSubTypes.socialLayoutName, CreationsJobProjectSubType.peekThrough, additionalLogInfo));

        const viewType = store.getState().dualViewReducer.viewType;
        if (viewType === ELViewType.before) {
            this.dualView?.showDoc(ELViewType.after);
            store.dispatch(DualViewAction.updateView(ELViewType.after));
        }

        const zoomValue = store.getState().docStateReducer.zoomLevel;
        if (zoomValue !== ZoomLevel.default) {
            this.dualView?.synchronizeZoom({ type: CanvasZoomLevelAction.changeZoomValue, payload: "100%" });
        }

        await this.doc?.notify(action);
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        if (StageUtils.isLayoutModeOn() && !StageUtils.isLayoutOperation(action.type)) {
            this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
                IngestEventTypes.click, IngestEventSubTypes.socialLayoutAutoCancel, CreationsJobProjectSubType.peekThrough));

            await this._layoutPanelCancelPress();
        }

        let handled = false;
        switch (action.type) {
            case DocumentActions.markDocumentDirty: {
                this._onDocumentDirty(action.payload as DocumentDirty);
                handled = true;
                break;
            }
            case ELCreationsHeaderControllerAction.save: {
                await this._save();
                handled = true;
                break;
            }
            case ELCreationsHeaderControllerAction.download: {
                const imageData = action.payload as ELImageData;
                this._download(imageData);
                handled = true;
                break;
            }
            case ELCreationsHeaderControllerAction.dontSave: {
                await this.startPreviousWorkflow();
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
                    IngestEventTypes.click, IngestEventSubTypes.backDialogDontSave, CreationsJobProjectSubType.peekThrough));
                handled = true;
                break;
            }
            case CanvasZoomLevelAction.zoomInEvent:
            case CanvasZoomLevelAction.zoomOutEvent: {
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.peekThrough, IngestEventTypes.click, IngestEventSubTypes.zoom, "Zoom-In-Out"));
                this.doc?.notify(action);
                handled = true;
                break;
            }
            case ELPeekThroughOverlayPanelControllerActions.overlayChanged: {
                this._onPeekThroughOverlayChanged(action);
                handled = true;
                break;
            }
            case ELPeekThroughOverlayPanelControllerActions.revert: {
                this._onPeekThroughOverlayRevert();
                handled = true;
                break;
            }
            case ELPeekThroughDocAction.removeOverlay: {
                this._onPeekThroughOverlayRemove();
                handled = true;
                break;
            }
            case DocumentActions.activeObjectChange: {
                this._handleActiveOverlayChange(action.payload as ELStageObjectData);
                handled = true;
                break;
            }
            case CreationMediaActionType.replaceMedia: {
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.peekThrough, IngestEventTypes.click, IngestEventSubTypes.replaceMedia));
                const docPayload = this.doc?.getData() as ELPeekThroughDocPayload;
                const workspacePayload: WorkspacePayload = {
                    startWorkflow: WorkflowsName.replaceMediaManager,
                    payload: {
                        assetId: docPayload.backgroundAsset.assetURN,
                        mode: ReplaceMediaManagerMode.replacingMedia,
                        mediaGridConfig: this.mediaGridConfig
                    }
                };
                const workspaceAction = { type: WorkspaceActionType.startWorkflow, ...workspacePayload };
                handled = await this._owner.notify(workspaceAction);
                break;
            }
            case ReplaceMediaManagerWorkflowAction.replaceMediaSelection: {
                const replaceAssetInfo = action.payload as ReplaceAssetInfo;
                this._onReplaceMedia(replaceAssetInfo);
                handled = true;
                break;
            }
            case ELAdobeAssetControllerAction.showAfterImage: {
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
                    IngestEventTypes.click, IngestEventSubTypes.after, CreationsJobProjectSubType.peekThrough));
                this.dualView?.showDoc(ELViewType.after);
                handled = true;
                break;
            }
            case ELAdobeAssetControllerAction.showBeforeImage: {
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
                    IngestEventTypes.click, IngestEventSubTypes.before, CreationsJobProjectSubType.peekThrough));
                this.dualView?.showDoc(ELViewType.before);
                handled = true;
                break;
            }
            case WorkflowActionType.ingest: {
                this._ingest(action.payload as Record<string, string>);
                handled = true;
                break;
            }
            case DocumentActions.commitLayout: {
                await this._commitLayoutWorkflow(action.payload as ELLayoutInfo);
                handled = true;
                break;
            }
            case ELLayoutPanelControllerAction.commit: {
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
                    IngestEventTypes.click, IngestEventSubTypes.socialLayoutApply, CreationsJobProjectSubType.peekThrough));

                await this._layoutPanelApplyPress();
                handled = true;
                break;
            }
            case ELLayoutPanelControllerAction.cancel: {
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
                    IngestEventTypes.click, IngestEventSubTypes.socialLayoutCancel, CreationsJobProjectSubType.peekThrough));

                await this._layoutPanelCancelPress();
                handled = true;
                break;
            }
            case ELLayoutPanelControllerAction.scale: {
                this._layoutScaleChange(action);
                handled = true;
                break;
            }
            case ELLayoutPanelControllerAction.change: {
                await this._layoutChange(action);
                handled = true;
                break;
            }
            case ELLayoutPanelControllerAction.revert: {
                this._layoutRevert(action);
                handled = true;
                break;
            }
            case ELCreationsHeaderControllerAction.changeName: {
                this.defaultTitle = action.payload as string;
                handled = true;
                break;
            }
            case ELOpenInDesktopDeeplinkAction.deeplinkCallbackStatus: {
                this.viewDispatcher?.call(this.viewDispatcher, { type: ELOpenInDesktopManagerViewAction.inProgressStatus, payload: false });
                handled = true;
                break;
            }
            case ELOpenInDesktopDeeplinkAction.deeplinkTryAgain: {
                await this.tryAgainForOpenDeeplink(action.payload as ELOpenInDesktopOpenAssetPayload);
                handled = true;
                break;
            }
            default: {
                Logger.log(LogLevel.WARN, "PeekThrough(ControllerAction): Invalid action" + action);
                break;
            }
        }
        if (!handled)
            handled = await super.notify(action);

        return handled;
    }

    protected async notifyWorkflow<T extends WorkflowAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case CreationWorkflowActions.creationRenderStatus: {
                this._onPeekThroughRendered(action.payload as CreationsStatus);
                handled = true;
                break;
            }
            case WorkspaceActionType.startPreviousWorkflow: {
                this._saveAndStartPreviousWorkflow();
                handled = true;
                break;
            }
            case CanvasZoomLevelAction.changeZoomValue: {
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.peekThrough, IngestEventTypes.click, IngestEventSubTypes.zoom, action.payload as string));
                this.dualView?.synchronizeZoom(action);
                handled = true;
                break;
            }
            case CanvasZoomLevelAction.zoomToFill: {
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.peekThrough, IngestEventTypes.click, IngestEventSubTypes.zoom, "Fill"));
                this.dualView?.synchronizeZoom(action);
                handled = true;
                break;
            }
            case CanvasZoomLevelAction.zoomToFit: {
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.peekThrough, IngestEventTypes.click, IngestEventSubTypes.zoom, "Fit"));
                this.dualView?.synchronizeZoom(action);
                handled = true;
                break;
            }
            case CreationWorkflowActions.renameCreationTitle: {
                await this._onPeekThroughTitleChange();
                handled = true;
                break;
            }
            case CreationWorkflowActions.updateCreationStatus: {
                await this._updateCreationsStatus(action.payload as CreationsStatus);
                handled = true;
                break;
            }
            default: {
                handled = await super.notifyWorkflow(action as WorkflowAction);
                break;
            }
        }
        return handled;
    }
}

export default PeekThrough;
