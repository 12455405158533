/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

//Application Specific
import CreationPreviewView from "./CreationPreviewView";
import IBaseWorkspace, { WorkspaceActionType } from "../../../IBaseWorkspace";
import IWorkflow, { WorkflowAction, WorkflowActionType, WorkflowsName } from "../../../IWorkflow";
import { ViewAction } from "../../../../view/IBaseController";
import { ControllerAction } from "../../../../view/IViewController";
import Logger, { LogLevel } from "../../../../utils/Logger";
import {
    CreationsStatus,
    CreationsStatusPayload,
    CreationsMode,
    ELCreationWorkflowPayload,
} from "../../../../common/interfaces/creations/CreationTypes";
import Utils from "../../../../utils/Utils";
import store from "../../../../stores/store";
import CreationsAction from "../../../../stores/actions/CreationsAction";
import CreationWorkflow, { DocTypeUnset } from "../CreationWorkflow";
import { ShareOptions } from "../../../../view/components/organism/el-share-options/ELShareOptions";
import CreationPreviewUtils from "./utils/CreationPreviewUtils";
import {
    ELCreationsHeaderControllerAction
} from "../../../../common/interfaces/creations/ELCreationsHeaderTypes";
import CreationUtils from "../../utils/CreationUtils";
import { IngestUtils } from "../../../../utils/IngestUtils";
import { IngestEventSubTypes, IngestEventTypes, IngestLogObjectCustomKey, IngestLogObjectKey, IngestLogObjectValue, IngestWorkflowTypes } from "../../../../utils/IngestConstants";
import ELCreationsPreviewHeader from "../../../../view/components/templates/el-creations-header/ELCreationPreviewHeader";
import { CreationPreviewActions, ELCreationPreviewState } from "../../../../common/interfaces/creations/ELCreationPreviewTypes";
import { elDeserializeAsset } from "../../../../common/interfaces/storage/AssetTypes";
import { IBaseRequestParams } from "../../../../common/interfaces/creations/ELCreationsJobTypes";
import { CreationsJobCreator } from "../../utils/CreationsJobCreator";
import { ELRenditionType } from "@elements/elementswebcommon";

class CreationPreview extends CreationWorkflow<DocTypeUnset> {
    protected getJobCreator(): CreationsJobCreator {
        throw new Error("Method not implemented.");
    }
    protected ingestCreationFeedback(eventSubType: string): Promise<void> {
        throw new Error("Method not implemented.");
    }

    constructor(owner: IBaseWorkspace) {
        super(owner, WorkflowsName.creationsPreview);
        this.shareOptions = new ShareOptions(owner);
    }

    protected createRequestJson(payload: IBaseRequestParams): Promise<unknown> {
        throw new Error("Method not implemented.");
    }

    private async _getCreationRendition(): Promise<void> {
        try {
            if (this.projectData?.status !== CreationsStatus.success) {
                Logger.log(LogLevel.INFO, "Creation Preview  : opened creation is not yet success - " + JSON.stringify(this.projectData));
                return;
            }
            Logger.log(LogLevel.INFO, "Creation Preview  : fetching creation rendition - " + JSON.stringify(this.projectData));
            const startDate = new Date();
            const creationOutputAsset = await CreationUtils.getCreationOutputAsset(this.projectData);
            const deserializedAsset = elDeserializeAsset(creationOutputAsset);
            const isVideoMimeType = Utils.isVideoMimeType(deserializedAsset);
            const renditionType = isVideoMimeType ? ELRenditionType.VIDEO_METADATA : ELRenditionType.IMAGE_JPG;

            const renditionData = await this.getOriginalDataOrPollAssetRendition(creationOutputAsset, renditionType);
            this.ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.preview, IngestEventTypes.rendition,
                IngestEventSubTypes.success, Utils.getDateDifference(startDate, new Date(), "second")));
            const creationDataPayload = {
                id: this.projectId,
                renditionData: renditionData,
                renditionType: renditionType,
            };
            this.viewDispatcher?.call(this.viewDispatcher, {
                type: CreationPreviewActions.creationPreviewData,
                payload: creationDataPayload,

            });
        } catch (e) {
            Logger.log(LogLevel.WARN, "Creation Preview:_getCreationRendition: Unable to fetch creation renditions -  " + JSON.stringify(this.projectData) + " " + e);
            this.ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.preview, IngestEventTypes.rendition,
                IngestEventSubTypes.error));
            const creationDataPayload = { isRenditionFailed: true, status: CreationsStatus.error };
            this.viewDispatcher?.call(this.viewDispatcher,
                { type: CreationPreviewActions.renditionStatus, payload: creationDataPayload as ELCreationPreviewState });
        }
    }

    private _modifyOutdatedCreationsStatusToRenderPreview(): void {
        if (this.projectData && CreationUtils.isCreationOutdated(this.projectData)) {
            this.projectData = {
                ...this.projectData,
                status: CreationsStatus.success
            }
        }
    }

    protected enterProject(creationWorkflowPayload: ELCreationWorkflowPayload): void {
        switch (creationWorkflowPayload.initMode) {
            case CreationsMode.preview: {
                const creationData = creationWorkflowPayload.payload as CreationsStatusPayload;
                this.openProject(creationData.projectId);
                break;
            }
            default: {
                Logger.log(LogLevel.WARN, "CreationPreview (enterProject) - Invalid creation mode ");
                break;
            }
        }
    }

    protected logIngestData(projectStatus: string): void {
        const additionalLogInfo: Record<string, string> = {};
        additionalLogInfo[IngestLogObjectKey.eventContextGuid] = Utils.getRandomUUID();
        additionalLogInfo[IngestLogObjectCustomKey.viewType] = IngestLogObjectValue.workspace;
        if (!this.projectData) {
            Logger.log(LogLevel.ERROR, "Creation Preview: logIngestData: project data is not available");
            return;
        }
        const workflowName = CreationUtils.getWorkflowNameFromOperationSubtype(this.projectData);
        const ingestWorkflowName = IngestUtils.getIngestCreationsWorkflowName(workflowName);
        this.ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
            IngestEventSubTypes.editWorkspaceRender, IngestEventTypes.success, ingestWorkflowName, additionalLogInfo));
    }

    protected async openProject(projectId: string): Promise<void> {
        this.projectId = projectId;
        await this._getCreationRendition();
        if (this.projectData?.status)
            this.logIngestData(this.projectData.status)
        const previewUrl = CreationPreviewUtils.getCreationHistoryState(projectId, this.workflowName);
        this.updateHistoryPath(previewUrl);
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
        if (!this.projectData) {
            Logger.log(LogLevel.ERROR, "Creation Preview: initialize: project data is not available");
            return;
        }
        this.creationsHeader = new ELCreationsPreviewHeader(this, this.shareOptions, this.projectData);
        this.creationsHeader.createView(this.ensureHTMLElement("creation-preview-header-container"));
    }

    createView(container: HTMLElement): void {
        super.createView(container)
        const element = React.createElement(CreationPreviewView, {
            controller: this,
            projectData: this.projectData
        });

        const provider = React.createElement(Provider, { store }, element);

        ReactDOM.render(
            provider,
            container
        );
    }

    destroyView(): void {
        if (this.container)
            ReactDOM.unmountComponentAtNode(this.container);
        super.destroyView();
    }

    destroy(): void {
        super.destroy();
    }

    async startWorkflow(containerId: string, prevWorkflow?: IWorkflow, action?: WorkflowAction): Promise<void> {
        try {
            super.startWorkflow(containerId, prevWorkflow, action);
            const workflowPayload = action?.payload as CreationsStatusPayload;
            this.projectData = workflowPayload.data;
            this.mode = action?.initMode as CreationsMode;
            this._modifyOutdatedCreationsStatusToRenderPreview();
            this.createView(this.ensureHTMLElement(containerId));
            const previewWorkflowPayload = {
                initMode: CreationsMode.preview,
                payload: workflowPayload
            }
            this.enterProject(previewWorkflowPayload);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "CreationPreview (startWorkflow) - error ", error);
            const additionalLogInfo: Record<string, string> = {};
            additionalLogInfo[IngestLogObjectKey.eventContextGuid] = Utils.getRandomUUID();
            additionalLogInfo[IngestLogObjectCustomKey.viewType] = IngestLogObjectValue.workspace;
            const ingestWorkflowName = IngestUtils.getIngestCreationsWorkflowName(this.workflowName);
            this.ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                IngestEventSubTypes.editWorkspaceRender, IngestEventTypes.error, ingestWorkflowName, additionalLogInfo));
        }
    }

    endWorkflow(): void {
        super.endWorkflow();
        this.destroyView();
        this.projectId && store.dispatch(CreationsAction.resetCreationsData(this.projectId));
    }
    private ingestEditLog(): void {
        const additionalLogInfo: Record<string, string> = {};
        additionalLogInfo[IngestLogObjectKey.eventContextGuid] = Utils.getRandomUUID();
        additionalLogInfo[IngestLogObjectCustomKey.viewType] = IngestLogObjectValue.workspace;
        if (!this.projectData) {
            Logger.log(LogLevel.ERROR, "Creation Preview: ingestEditLog: project data is not available");
            return;
        }
        const workflowName = CreationUtils.getWorkflowNameFromOperationSubtype(this.projectData);
        const ingestWorkflowName = IngestUtils.getIngestCreationsWorkflowName(workflowName);
        this.ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
            IngestEventSubTypes.edit, IngestEventTypes.click, ingestWorkflowName, additionalLogInfo));
    }

    /**
     * Handles ui events generated by views rendered in the workflow
     * @param action ControllerAction
     */
    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {

            case ELCreationsHeaderControllerAction.edit: {
                this.ingestEditLog();
                this.notifyWorkflow({ type: WorkspaceActionType.startWorkflow });
                handled = true;
                break;
            }

            case ELCreationsHeaderControllerAction.download: {
                this.download();
                handled = true;
                break;
            }

            default: {
                Logger.log(LogLevel.DEBUG, "Creation Preview - ControllerAction: Invalid action" + action);
                break;
            }
        }

        if (!handled)
            handled = await this.notifyWorkflow(action as WorkflowAction);

        return handled;
    }

    /**
     * Handles core actions
     * @param action WorkflowAction
     */
    protected async notifyWorkflow<T extends WorkflowAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case WorkspaceActionType.startPreviousWorkflow:
                {
                    const workspacePayload = {
                        startWorkflow: WorkflowsName.creationsHome
                    };
                    const workspaceAction = { type: WorkspaceActionType.startWorkflow, ...workspacePayload };
                    handled = await this._owner.notify(workspaceAction);
                    break;
                }

            case WorkspaceActionType.startWorkflow:
                {
                    if (this.projectData) {
                        const workflowName = CreationUtils.getWorkflowNameFromOperationSubtype(this.projectData);
                        const workspacePayload = {
                            startWorkflow: workflowName,
                            initMode: CreationsMode.open,
                            payload: {
                                projectId: this.projectId,
                                data: {
                                    ...this.projectData,
                                    status: CreationUtils.isCreationOutdated(this.projectData) ? CreationsStatus.oldVersion : this.projectData.status
                                }
                            }
                        };

                        const workspaceAction = { type: WorkspaceActionType.startWorkflow, ...workspacePayload };
                        handled = await this._owner.notify(workspaceAction);
                    }
                    break;
                }

            case WorkflowActionType.ingest:
                {
                    this.ingest(action.payload as Record<string, string>);
                    handled = true;
                    break;
                }

            case CreationPreviewActions.backCreationView: {
                const workspacePayload = {
                    startWorkflow: WorkflowsName.creationsHome
                };
                const workspaceAction = { type: WorkspaceActionType.startWorkflow, ...workspacePayload };
                handled = await this._owner.notify(workspaceAction);
                break;
            }
            default:
                Logger.log(LogLevel.WARN, "Creation Preview(notifyWorkflow): Invalid action" + action);
                break;
        }
        return handled;
    }
}

export default CreationPreview;
