/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { ReactNode, useEffect, useReducer } from "react";
import { useSelector } from "react-redux";

//Adobe Internal
import { Flex, Text } from "@adobe/react-spectrum";

import IViewController from "../../../../view/IViewController";
import { CreationsStatus } from "../../../../common/interfaces/creations/CreationTypes";
import Utils from "../../../../utils/Utils";
import { ViewAction } from "../../../../view/IBaseController";
import DocActions from "../../../../stores/actions/DocActions";
import { ZoomLevel } from "../../../../editors/stage/ELFabricStage";
import store from "../../../../stores/store";
import { ELRecommendationWorkflowViewActions } from "../../../../common/interfaces/creations/ELRecommendationsWorkflowTypes";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { RootState } from "../../../../stores/store";
import { DocumentDirty } from "../../../../common/interfaces/document/DocumentTypes";
import { CreationInAppNotifierAction, RecommendationsInAppNotifierAction } from "../../../../common/interfaces/creations/CreationInAppNotifierTypes";
import ELProgressContent from "../../../../view/components/organism/el-progress-content/ELProgressContent";
import Constants from "../../../../utils/Constants/Constants";
import { useUnloadHandler } from "../../../../utils/hooks/useUnloadHandler";
import { useKeyboardZoomEventListener } from "../../../../utils/hooks/useKeyboardZoomEventListener";
import { ELOpenInDesktopManagerViewAction } from "../../../../common/interfaces/creations/ELOpenInDesktopTypes";
import { ELCreationsHeaderControllerAction } from "../../../../common/interfaces/creations/ELCreationsHeaderTypes";
import { ELStockWorkflowActions } from "../../../../common/interfaces/services/StockServiceTypes";
import ELStockLicenseAndDownloadDialog from "../../../../view/components/organism/el-stock-license-dialog/ELStockLicenseDialog";
import { DialogContext } from "../../../../common/interfaces/creations/ELReplaceBackgroundTypes";

import "./ReplaceBackgroundView.scss"

interface IReplaceBackgroundViewProps {
    controller: IViewController
}

interface ELReplaceBackgroundState {
    id: string,
    status: CreationsStatus,
    progressText: string,
    progressPercentage: number,
    openInDesktopInProgress: boolean,
    dialogContext?: DialogContext
}

const getInitialState = (): ELReplaceBackgroundState => {
    return {
        id: Utils.getNilUUID(),
        status: CreationsStatus.requested,
        progressText: IntlHandler.getInstance().formatMessage("generating-replace-background"),
        progressPercentage: 0,
        openInDesktopInProgress: false,
        dialogContext: {
            showAdobeStockLicenseDialog: false,
            triggerSource: undefined,
            data: undefined
        }
    }
}

const ReplaceBackgroundView = (props: IReplaceBackgroundViewProps): React.ReactElement => {

    const intlHandler = IntlHandler.getInstance();

    const reducer = (state: ELReplaceBackgroundState, action: ViewAction): ELReplaceBackgroundState => {
        switch (action.type) {
            case ELRecommendationWorkflowViewActions.recommendationWorkflowStatus: {
                const status = action.payload as (CreationsStatus);
                return {
                    ...state,
                    status: status
                };
            }
            case ELRecommendationWorkflowViewActions.recommendationWorkflowProgressText: {
                const progressText = action.payload as string;
                return {
                    ...state,
                    progressText: progressText
                };
            }
            case RecommendationsInAppNotifierAction.recommendationProgressChanged: {
                const progressPercentage = action.payload as number;
                return {
                    ...state,
                    progressPercentage: progressPercentage
                };
            }
            case CreationInAppNotifierAction.creationProgressChanged: {
                const progressPercentage = action.payload as number;
                return {
                    ...state,
                    progressPercentage: progressPercentage
                };
            }
            case ELOpenInDesktopManagerViewAction.inProgressStatus: {
                const openInDesktopInProgress = action.payload as boolean;
                return {
                    ...state,
                    openInDesktopInProgress: openInDesktopInProgress
                }
            }
            case ELCreationsHeaderControllerAction.updateAdobeStockLicenseDialogState: {
                const adobeStockDialogContext = action.payload as DialogContext;
                return {
                    ...state,
                    dialogContext: adobeStockDialogContext
                }
            }
            default: {
                return state;
            }
        }
    }

    const recommendationsStatus = useSelector((state: RootState) => state.recommendationWorkflowReducer.status);
    const projectStatus = useSelector((state: RootState) => state.recommendationWorkflowReducer.projectStatus);

    const [state, viewDispatch] = useReducer(reducer, getInitialState());
    const docStoreData = useSelector((rootState: RootState) => rootState.docStateReducer);

    useEffect(() => {
        props.controller.initialize(viewDispatch);

        return () => {
            props.controller.destroy();
        }
    }, [props.controller]);

    useUnloadHandler(docStoreData.isDirty === DocumentDirty.NON_DIRTY || state.openInDesktopInProgress);

    useKeyboardZoomEventListener({
        controller: props.controller,
        unmountCB: () => {
            store.dispatch(DocActions.updateZoomLevel(ZoomLevel.default));
        }
    });

    const showProgressUI = (): boolean => {
        return (state.status === CreationsStatus.requested
            || projectStatus === CreationsStatus.requested
            || recommendationsStatus === CreationsStatus.requested);
    }

    const showCustomBackgroundProgressUI = (): boolean => {
        return (state.status === CreationsStatus.applyingCustom)
    }

    const getCustomBackgroundProgressBanner = (progressContent = ""): ReactNode => {
        return (
            showCustomBackgroundProgressUI() &&
            <div id="replace-background-progress-container">
                <ELProgressContent contentHeader={progressContent}
                    progressText={state.progressText} />
            </div>
        )
    }

    const getProgressBanner = (progressContent = ""): ReactNode => {
        return (
            showProgressUI() &&
            <div id="replace-background-progress-container">
                <ELProgressContent contentHeader={progressContent}
                    progressText={state.progressText}
                    progressPercentNode={showReplaceBackgroundProgressNumber()} />
            </div>
        );
    }

    const showReplaceBackgroundProgressNumber = (): ReactNode => {
        if (state.progressPercentage) {
            return (<Text marginTop="0.8rem" UNSAFE_className="replace-background__secondary_text">
                {state.progressPercentage}{Constants.PERCENT_SYMBOL}
            </Text>);
        }
        return <></>;
    }

    const handleStockDialogDismiss = (): void => {
        viewDispatch({
            type: ELCreationsHeaderControllerAction.updateAdobeStockLicenseDialogState,
            payload: {
                showAdobeStockLicenseDialog: false
            }
        });
    }

    const handleStockLicenseForFreeClick = (): void => {
        props.controller.notify({ type: ELStockWorkflowActions.stockDialogLicenseForFree, payload: state.dialogContext });
    }

    const handleStockDiscardClick = (): void => {
        viewDispatch({
            type: ELCreationsHeaderControllerAction.updateAdobeStockLicenseDialogState,
            payload: {
                showAdobeStockLicenseDialog: false
            }
        })
        props.controller.notify({ type: ELStockWorkflowActions.discardLicense })
    }

    const renderStockLicenseDialog = (): ReactNode => {
        if (!state.dialogContext?.showAdobeStockLicenseDialog) {
            return null;
        }

        return (
            <ELStockLicenseAndDownloadDialog
                heading={intlHandler.formatMessage("license-download")}
                headingDescription={intlHandler.formatMessage("adobe-stock")}
                dialogDescription={intlHandler.formatMessage("license-region-text")}
                discardButtonText={intlHandler.formatMessage("discard")}
                licenseButtonText={intlHandler.formatMessage("license-free")}
                onDismiss={handleStockDialogDismiss}
                onLicenseForFree={handleStockLicenseForFreeClick}
                onDiscard={handleStockDiscardClick}
            />
        );
    };

    return (
        <div id="replace-background-container">
            <div>
                {getProgressBanner()}
                {getCustomBackgroundProgressBanner()}
            </div>
            <div id="replace-background-creations-header-container" />
            <Flex direction="column">
                <div className="replace-background-main-content-box">
                    <div id="replace-background-left-panel-container" />
                    <div id="replace-background-edit-container" />
                    <div id="replace-background-right-panel-container" />
                    <div className="replace-background-feedback-icon-container" id="feedback-popover-container" />
                </div>
            </Flex>
            <div id="open-deeplink-container" data-testid="open-deeplink-container" />
            {renderStockLicenseDialog()}
        </div>
    )
}

export default ReplaceBackgroundView;