/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { AdobeStockActionTypes } from "../../common/interfaces/services/StockServiceTypes"
import IAction from "./IAction"

export class AdobeStockAction {

    public static updateSelectedStockThumbnail(thumbnailUrl?: string): IAction<string> {
        return {
            type: AdobeStockActionTypes.UPDATE_SELECTED_STOCK_THUMBNAIL,
            payload: thumbnailUrl
        }
    }

    public static updateSelectedStockId(stockContentIds?: string[]): IAction<string> {
        return {
            type: AdobeStockActionTypes.UPDATE_SELECTED_STOCK_IDS,
            payload: JSON.stringify(stockContentIds)
        }
    }
}