/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Adobe Internal
import { AdobeIMS } from "@identity/imslib";
import { IAdobeIdData } from "@identity/imslib/adobe-id/IAdobeIdData";
import { IEnvironment } from "@identity/imslib/adobe-id/IEnvironment";
import { IErrorType } from "@identity/imslib/adobe-id/IErrorType";
import { IDictionary } from "@identity/imslib/facade/IDictionary";

//Application Specific
import Logger, { LogLevel } from "../utils/Logger";
import { LocaleType } from "../common/interfaces/intl/LocaleTypes";

declare global {
    interface Window {
        adobeIMS: AdobeIMS
        adobeid: IAdobeIdData
    }
}


enum BroadcastMessage {
    logout = "logout"
}

/*
GUID (e.g.: 0E7957E8502B5F7F0A490D35@AdobeID)
* A GUID as Adobe understands it contains several fields, all being hex encodings of the following:
* characters 0-3 : sequence ID
* characters 4-7 : proc ID
* characters 8-15 : time *in seconds* of creation of the GUID. This can be computed without the need for an api call in Renga or IMS.
* characters 16-23 : the IP from which the GUID was created, with characters two by two.
*/
export interface UserProfile extends IDictionary {
    account_type: string
    countryCode: string
    userId: string
    name: string
    email: string
    displayName: string,
    preferred_languages: string[] | null
    serviceAccounts: [
        {
            serviceCode: string
            serviceStatus: string
            serviceLevel: string
            ident: string
            params: [
                {
                    pn: string
                    pv: string
                }
            ]
        }
    ]
}

export type SignInOptions = {
    locale?: string;
};

// https://git.corp.adobe.com/pages/IMS/imslib.js/adobeIMS.html
export default class IMS {
    private static instance?: IMS;
    private _adobeIMS: AdobeIMS;
    private _userProfile?: UserProfile;
    private _readyPromise: Promise<void>;
    private _readyPromiseResolve?: () => void;
    private _readyPromiseReject?: (reason?: unknown) => void;
    private _locale = LocaleType.enUS;

    static getInstance(): IMS {
        if (!IMS.instance) {
            IMS.instance = new IMS();
        }
        return IMS.instance;
    }

    private broadCastChannel?: BroadcastChannel;

    private constructor() {
        if (!process.env.REACT_APP_IMS_API_KEY)
            throw new Error("REACT_APP_IMS_API_KEY missing");

        this._readyPromise = new Promise((resolve, reject) => {
            this._readyPromiseResolve = resolve;
            this._readyPromiseReject = reject;
        });

        const adobeData: IAdobeIdData = {
            locale: LocaleType.enUS,
            client_id: process.env.REACT_APP_IMS_API_KEY,
            scope: "AdobeID,openid,creative_cloud,creative_sdk,elements_service_cxp,tk_platform_sync,tk_platform,pps.read",
            environment: process.env.REACT_APP_IMS_ENV === "prod" ? IEnvironment.PROD : IEnvironment.STAGE,
            useLocalStorage: true,
            autoValidateToken: true,
            api_parameters: {
                authorize: {
                    dctx_id: process.env.REACT_APP_IMS_STATIC_CONTEXT_ID
                }
            },
            onReady: (applicationState: unknown) => {
                Logger.log(LogLevel.INFO, "IMS.onReady");
                if (this._readyPromiseResolve) {
                    this._readyPromiseResolve();
                }
            },
            onAccessToken: (data: unknown) => {
                Logger.log(LogLevel.INFO, "IMS.onAccessToken");
            },
            onAccessTokenHasExpired: () => {
                Logger.log(LogLevel.INFO, "IMS.onAccessTokenHasExpired");
            },
            onReauthAccessToken: (data: unknown) => {
                Logger.log(LogLevel.INFO, "IMS.onReauthAccessToken");
            },
            onError: (errorType: IErrorType, message: unknown) => {
                Logger.log(LogLevel.WARN, `Error while initializing IMS: ${errorType} ${message}`);
                if (this._readyPromiseReject) {
                    this._readyPromiseReject();
                }
            },
        };

        window.adobeid = adobeData;
        this._adobeIMS = new AdobeIMS(adobeData);

        if ('BroadcastChannel' in window) {
            this.broadCastChannel = new BroadcastChannel('ims')
            this.broadCastChannel.onmessage = ev => {
                if (ev.data === BroadcastMessage.logout) {
                    this.logoutUser(false);
                }
            }
        }
    }

    async load(): Promise<void> {
        await this._adobeIMS.initialize();
        return Promise.resolve();
    }

    get locale(): LocaleType {
        return this._locale;
    }

    set locale(locale: LocaleType) {
        this._locale = locale;
    }

    async initialize(): Promise<void> {
        if (this.isSignedInUser()) {
            await this.validateAndRefreshToken();
            await this.fetchUserProfile();
        }

        window.adobeIMS = this._adobeIMS;

        return this._readyPromise;
    }

    async ready(): Promise<void> {
        return this._readyPromise;
    }

    protected async fetchUserProfile(): Promise<UserProfile | undefined> {
        if (!this._userProfile) {
            this._userProfile = await this._adobeIMS.getProfile();
        }

        return this._userProfile;
    }

    getUserProfile(): UserProfile | undefined {
        if (this.isSignedInUser() && this._userProfile) {
            return this._userProfile;
        }

        return undefined;
    }

    getUserId(): string | undefined {
        if (this.isSignedInUser() && this._userProfile) {
            return this._userProfile.userId;
        }
        return undefined;
    }

    getUserName(): string | undefined {
        if (this.isSignedInUser() && this._userProfile) {
            return this._userProfile.name;
        }
        return undefined;
    }

    getUserEmail(): string | undefined {
        if (this.isSignedInUser() && this._userProfile) {
            return this._userProfile.email;
        }
        return undefined;
    }

    isSignedInUser(): boolean {
        return this._adobeIMS.isSignedInUser();
    }

    loginUser(options: SignInOptions): void {
        if (!this.isSignedInUser()) {
            this._adobeIMS.signIn({ locale: options.locale || this._locale });
        }
    }

    logoutUser(broadcastLogout = true): void {
        if (this.isSignedInUser()) {
            if (broadcastLogout) {
                this.broadCastChannel?.postMessage(BroadcastMessage.logout)
            }
            this._adobeIMS.signOut();
        }
    }

    signUpUser(): void {
        if (!this.isSignedInUser()) {
            this._adobeIMS.signUp();
        }
    }

    getUserAccessToken(): string {
        const tokenInformation = this._adobeIMS.getAccessToken();
        return tokenInformation ? tokenInformation.token : "";
    }

    getUserPictureURL(): string | undefined {
        return this._userProfile?.userId ? this._adobeIMS.avatarUrl(this._userProfile?.userId) : undefined;
    }

    validateToken(): Promise<boolean> {
        return this._adobeIMS.validateToken();
    }

    refreshToken(): Promise<void> {
        return this._adobeIMS.refreshToken();
    }

    async validateAndRefreshToken(): Promise<boolean> {
        const isTokenValid = await this.validateToken();
        
        if (!isTokenValid) {
            await this.refreshToken();
        }

        return true;
    }
}
