/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application specific
import { AppProfile, AppProfileFeatureIds, ELAccessProfileStatus } from "../../common/interfaces/services/AccessProfileTypes";
import { AccessProfileService } from "../../services/AccessProfileService";
import AccessProfileAction from "../../stores/actions/AccessProfileAction";
import store from "../../stores/store";
import Constants from "../../utils/Constants/Constants";
import Logger, { LogLevel } from "../../utils/Logger";

export class AccessProfileManager {
    // private readonly ELEMENTS_NGL_APP_NAME = "ElementsWeb1";
    static _instance: AccessProfileManager;
    private _isEducationalUser = false;

    private _readyPromise: Promise<void>;
    private _readyPromiseResolve?: () => void;
    private _readyPromiseReject?: (reason?: any) => void;

    private constructor() {
        this._readyPromise = new Promise((resolve, reject) => {
            this._readyPromiseResolve = resolve;
            this._readyPromiseReject = reject;
        });
    }

    static getInstance(): AccessProfileManager {
        if (!AccessProfileManager._instance) {
            AccessProfileManager._instance = new AccessProfileManager();
        }
        return AccessProfileManager._instance;
    }

    private _updateEducationalUserStatus(appProfile: AppProfile): void {
        try {
            const featureIds = appProfile.summary?.fulfillable_items?.adobe_elements_web?.feature_ids;
            if (featureIds?.includes(AppProfileFeatureIds.EDU_D) || featureIds?.includes(AppProfileFeatureIds.EDU_IN)) {
                this._isEducationalUser = true;
            }
        } catch (err) {
            Logger.log(LogLevel.ERROR, "Error in updating educational user status", err);
        }
    }

    async refreshUserAccessProfile(): Promise<void> {
        try {
            const response = await AccessProfileService.getInstance().getUserAccessProfile();

            if (response.status === Constants.HTTP_RESPONSE_OK_200) {
                const base64EncodedPayload = response.data.asnp.payload;
                const base64DecodedPayload = Buffer.from(base64EncodedPayload, 'base64').toString('utf8');
                const payload = JSON.parse(base64DecodedPayload);
                store.dispatch(AccessProfileAction.updateAccessProfileStatus(payload.profileStatus));
                this._updateEducationalUserStatus(payload.appProfile);
                if (this._readyPromiseResolve)
                    this._readyPromiseResolve();
            } else {
                if (this._readyPromiseReject)
                    this._readyPromiseReject();
                throw new Error("Error in fetching user access profile" + response);
            }
        } catch (err) {
            if (this._readyPromiseReject)
                this._readyPromiseReject();
            Promise.reject(err);
        }
    }

    async ready(): Promise<void> {
        return this._readyPromise;
    }

    getUserProfileStatus(): string {
        return store.getState().accessProfileReducer;
    }

    isPaidUser(): boolean {
        const userAccessProfile = this.getUserProfileStatus();
        if (userAccessProfile === ELAccessProfileStatus.PROFILE_AVAILABLE)
            return true;
        return false;
    }

    isTermExpired(): boolean {
        const userAccessProfile = this.getUserProfileStatus();
        if (userAccessProfile === ELAccessProfileStatus.PROFILE_EXPIRED)
            return true;
        return false;
    }

    isNonNGLProfile(): boolean {
        const userAccessProfile = this.getUserProfileStatus();
        if (userAccessProfile === ELAccessProfileStatus.PROFILE_UNAVAILABLE)
            return true;
        return false;
    }

    isEducationalUser(): boolean {
        return this._isEducationalUser;
    }
}