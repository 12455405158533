/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React from "react";
import ReactDOM from "react-dom";
import { Provider as ReactReduxProvider } from "react-redux";

//Application Specific
import ELUserHomeBannerView from "./ELUserHomeBannerView";
import IViewController, { ControllerAction } from "../../../IViewController";
import { ViewAction } from "../../../../view/IBaseController";
import ITemplateViewController from "../../../ITemplateViewController";
import { EliveContentManager } from "../../../../services/ElementsServices/EliveContentManager";
import MovingHomeBanner from "../../../../assets/home/MovingHomeBanner.mp4";
import { BannerData, EliveContentType, UserState } from "../../../../common/interfaces/elive/EliveTypes";
import { ELUserHomeBannerActions, ELUserHomeBannerViewActions } from "../../../../common/interfaces/home/UserHomeTypes";
import store from "../../../../stores/store";
import IMS from "../../../../services/IMS";
import { WorkflowActionType } from "../../../../workspaces/IWorkflow";

//Utils
import { EliveUtils } from "../../../../utils/EliveUtils";
import { TrialUtils } from "../../../../utils/TrialUtils";
import { LoginUtils } from "../../../../utils/LoginUtils";
import Logger, { LogLevel } from "../../../../utils/Logger";
import Utils from "../../../../utils/Utils";
import { IngestUtils } from "../../../../utils/IngestUtils";
import { IngestEventSubTypes, IngestEventTypes, IngestWorkflowTypes } from "../../../../utils/IngestConstants";
import { UserUtils } from "../../../../utils/UserUtils";
import { AssetMimeType } from "../../../../common/interfaces/storage/AssetTypes";
import previewConfig from "../../../../common/interfaces/home/InteractivePreviewBannerConfig.json";

const FETCH_BANNERS_FROM_SERVER = 0;
const BANNER_HTML_FILE_NAME = "bannerWeb.html";
const BANNERS_FOLDER_NAME = "banners";

class ELUserHomeBanner extends ITemplateViewController {
    private _banners: BannerData[] = [];
    private _bannersReadyPromise: Promise<void>;
    private _bannersReadyPromiseResolve?: () => void;
    private _bannersReadyPromiseReject?: (reason: any) => void;
    private _currentBanner?: BannerData;

    constructor(owner: IViewController) {
        super(owner);
        this._bannersReadyPromise = new Promise((resolve, reject) => {
            this._bannersReadyPromiseResolve = resolve;
            this._bannersReadyPromiseReject = reject;
        });
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
        this._initBanners();
    }

    private _initBanners(): void {
        if (FETCH_BANNERS_FROM_SERVER) {
            this._fetchBanners();
        } else {
            this._banners = Object.entries(UserState).map(([key, value]): BannerData => {
                const bannerRelativePath = `/${BANNERS_FOLDER_NAME}/${Utils.getCurrentLocaleInSnakeCase()}/${key}/${BANNER_HTML_FILE_NAME}`;

                return {
                    bannerID: Utils.getRandomUUID(),
                    bannerURL: window.location.origin + "/" + process.env.PUBLIC_URL + bannerRelativePath,
                    userStates: [value]
                }
            });
            this._bannersReadyPromiseResolve?.();
        }
    }

    private async _fetchBanners(): Promise<void> {
        try {
            this._banners = await EliveContentManager.instance.fetchListing(EliveContentType.banner) as BannerData[];
            this._bannersReadyPromiseResolve?.();
        } catch (error) {
            this._bannersReadyPromiseReject?.(error);
        }
    }

    private _filterBanners(banners: BannerData[], userState: UserState): BannerData {
        const filteredBanners = banners.filter((banner) => {
            return (banner.userStates.includes(userState))
        });
        return filteredBanners[0];
    }

    private async _setViewBannerSrc(userState: UserState): Promise<void> {
        try {
            await this._bannersReadyPromise;
            const filteredBanner = this._filterBanners(this._banners, userState);
            if (filteredBanner !== undefined && this._currentBanner !== filteredBanner) {
                this._currentBanner = filteredBanner;
                this.viewDispatcher?.call(this.viewDispatcher, { type: ELUserHomeBannerViewActions.bannerLoadComplete, payload: false });
                this.viewDispatcher?.call(this.viewDispatcher, { type: ELUserHomeBannerViewActions.updateBannerSrc, payload: filteredBanner.bannerURL });
            }
        } catch (error: any) {
            Logger.log(LogLevel.ERROR, "ELUserHomeBanner: _setViewBannerSrc: Error while fetching banner for user state", { error: error, userState: userState });
        }
    }

    private _receiveMessage(event: MessageEvent<any>): void {
        this.notify({ type: event.data.message, payload: event.data.payload });
    }

    private _loadEventMessageHandler = (event: MessageEvent<any>): any => {
        if (event.data.message === ELUserHomeBannerActions.bannerLoadComplete)
            this._receiveMessage(event);
    }

    private _getDefaultBannerSrc(): string {
        const BANNER_SMALL = "/PSE2018_eLive_BannerBackground_3200x400.webp";
        const BANNER_LARGE = "/PSE2018_eLive_BannerBackground_3200x560.webp";
        const isSmallDeviceBreakpoint = window.matchMedia("(max-device-height: 768px)").matches;

        return process.env.PUBLIC_URL + (isSmallDeviceBreakpoint ? BANNER_SMALL : BANNER_LARGE);
    }

    private _ingest(payload: Record<string, string>): void {
        this.notify({
            type: WorkflowActionType.ingest,
            payload: payload

        });
    }

    private _ingestCTAClick(ctaNameToIngest: string): void {
        const ctaClickIngestPayload = IngestUtils.getPseudoLogObject(IngestWorkflowTypes.eliveBanner, IngestEventTypes.click,
            ctaNameToIngest);

        this._ingest(ctaClickIngestPayload);
    }

    private async _startTrial(): Promise<void> {
        this._ingestCTAClick(IngestEventSubTypes.startTrial);

        if (!IMS.getInstance().isSignedInUser())
            LoginUtils.signIn();

        await TrialUtils.startTrial();
    }

    createView(container: HTMLElement): void {
        super.createView(container);

        const userHomeBanner = React.createElement(ELUserHomeBannerView, {
            controller: this,
            defaultBannerSrc: this._getDefaultBannerSrc(),
            previewType: AssetMimeType.video,
            previewSrc: MovingHomeBanner,
            previewConfig: previewConfig.desktop,
            allEventsMessageHandler: this._receiveMessage.bind(this),
            loadEventMessageHandler: this._loadEventMessageHandler.bind(this)
        });
        const providerHydratedBanner = React.createElement(ReactReduxProvider, { store: store }, userHomeBanner);
        ReactDOM.render(
            providerHydratedBanner,
            container
        );
    }

    destroyView(): void {
        if (this.container)
            ReactDOM.unmountComponentAtNode(this.container);
        super.destroyView();
    }

    destroy(): void {
        super.destroy();
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case ELUserHomeBannerActions.bannerLoadComplete: {
                const loadComplete = action.payload as boolean;
                this.viewDispatcher?.call(this.viewDispatcher, { type: ELUserHomeBannerViewActions.bannerLoadComplete, payload: loadComplete });
                handled = true;
                break;
            }
            case ELUserHomeBannerActions.refreshBannerSrc: {
                const daysInTrial = action.payload as number;
                this._setViewBannerSrc(EliveUtils.getUserState(daysInTrial));
                handled = true;
                break;
            }
            case ELUserHomeBannerActions.startTrial: {
                this._startTrial();
                handled = true;
                break;
            }
            case ELUserHomeBannerActions.continueTrial: {
                TrialUtils.continueTrial();
                handled = true;
                break;
            }
            case ELUserHomeBannerActions.buyNow: {
                this._ingestCTAClick(IngestEventSubTypes.buyNow);

                const url = action.payload as string;
                UserUtils.buyDesktop(url);
                handled = true;
                break;
            }
            case ELUserHomeBannerActions.learnMore: {
                this._ingestCTAClick(IngestEventSubTypes.learnMore);

                const url = action.payload as string;
                Utils.openInNewTab(url);
                handled = true;
                break;
            }
            case ELUserHomeBannerActions.watchVideo: {
                this._ingestCTAClick(IngestEventSubTypes.watchVideo);
                break;
            }
            case ELUserHomeBannerActions.getStarted: {
                this._ingestCTAClick(IngestEventSubTypes.getStarted);

                if (!IMS.getInstance().isSignedInUser())
                    LoginUtils.signIn();
                handled = true;
                break;
            }
        }
        if (!handled)
            handled = await this._owner.notify(action);

        return handled;
    }
}

export default ELUserHomeBanner;
