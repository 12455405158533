/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

// Adobe internal
import { Button, Content, Dialog, DialogContainer, Divider, Flex, Heading, Switch } from "@adobe/react-spectrum";
import { ProgressCircle } from "@react-spectrum/progress";
import { Text, RadioGroup, Radio } from "@adobe/react-spectrum";

//Application Specific
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { IconType } from "../../../../assets/IconConstants";
import { Theme } from "../../../../utils/Theme";
import ELIconButton from "../../molecules/el-icon-button/ELIconButtonView";
import Utils from "../../../../utils/Utils";
import IViewController, { ControllerAction } from "../../../IViewController";
import { useViewport } from "../../../../utils/hooks/useViewport";
import ELPopover from "../../molecules/el-popover/ELPopoverView";
import {
    ELBackButtonDialogProps,
    ELBackButtonProps,
    ELCreationsHeaderControllerAction,
} from "../../../../common/interfaces/creations/ELCreationsHeaderTypes";
import ELButton from "../../atoms/el-button/ELButtonView";
import { DocActionsPayload } from "../../../../stores/actions/DocActions";
import { ShareOptionsView } from "../el-share-options/ELShareOptionsView";
import { DocumentDirty, DocumentSaveStatus } from "../../../../common/interfaces/document/DocumentTypes";
import { ShareOptions } from "../el-share-options/ELShareOptions";
import ELCustomIcon from "../../molecules/el-custom-icon/ELCustomIcon";
import { CreationsDownloadFileType, ELViewType } from "../../../../common/interfaces/creations/CreationTypes";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../stores/store";
import DualViewAction from "../../../../stores/actions/DualViewAction";
import { ELAdobeAssetControllerAction } from "../../../../common/interfaces/creations/templates/ELAdobeAssetDocTypes";

interface DownloadViewProps {
    notify: (param: ControllerAction) => void,
    radioButtonList?: CreationsDownloadFileType[]
}

export const DownloadView = (props: DownloadViewProps): React.ReactElement => {
    const { notify } = props;
    const [fileType, setFileType] = useState(CreationsDownloadFileType.jpeg);
    const intlHandler = IntlHandler.getInstance();

    const { isMobile } = useViewport();
    const [isOpen, setIsOpen] = useState(false);

    const startDownload = (): void => {
        notify({ type: ELCreationsHeaderControllerAction.download, payload: { format: fileType } });
        setIsOpen(false);
    }

    const location = useLocation();
    useEffect(() => {
        setIsOpen(false);
    }, [location]);

    return (
        <ELPopover isOpen={isOpen} type={Utils.getSpectrumPopoverType((isMobile as boolean))}
            onOpenChange={(isOpen: boolean) => setIsOpen(isOpen)} >
            <ELIconButton size="L" iconkey={IconType.download} iconColor={Theme.dark.gray_controls_color_N}
                iconHoverColor={Theme.dark.gray_controls_color_N} iconWidth={"1.375rem"} iconHeight={"1.375rem"}>
                <span className="workflow-header__cta-text">{intlHandler.formatMessage("download")}</span>
            </ELIconButton>
            <Flex direction="column" gap="0.625rem" marginStart="1.25rem" marginEnd="1.25rem" marginTop="0.8rem" marginBottom="0.8rem"
                width="fit-content" height="fit-content" UNSAFE_className="el-download-option-panel" data-testid="el-download-option-panel">
                <Text UNSAFE_className="el-download-option-panel-heading">{intlHandler.formatMessage("file-type")}</Text>
                <RadioGroup
                    value={fileType}
                    onChange={(id) => setFileType(id as CreationsDownloadFileType)} >
                    <Flex direction="column" >
                        {props.radioButtonList?.map(item => {
                            return (
                                <Radio value={item} key={item}>
                                    {item}
                                </Radio>
                            );
                        })}
                    </Flex>
                </RadioGroup>
                <ELButton size="L" variant="cta" onClick={() => startDownload()}>
                    <Text UNSAFE_className="el-download-option-panel-text">{intlHandler.formatMessage("download")}</Text>
                </ELButton>
            </Flex>
        </ELPopover>
    );
}

DownloadView.defaultProps = {
    radioButtonList: [CreationsDownloadFileType.jpeg, CreationsDownloadFileType.png]
}


export const DownloadViewForMovingOverlay = (props: { notify: (param: ControllerAction) => void }): React.ReactElement => {
    const { notify } = props;
    const intlHandler = IntlHandler.getInstance();
    const startDownload = (): void => {
        notify({ type: ELCreationsHeaderControllerAction.download, payload: { format: CreationsDownloadFileType.mp4 } });
    }

    return (
        <ELIconButton size="L" iconkey={IconType.download} iconColor={Theme.dark.gray_controls_color_N}
            onClick={() => startDownload()} iconHoverColor={Theme.dark.gray_controls_color_N} iconWidth={"1.375rem"} iconHeight={"1.375rem"}>
            <span className="workflow-header__cta-text">{intlHandler.formatMessage("download")}</span>
        </ELIconButton>
    );
}

export interface ShareViewProps {
    docStoreData: DocActionsPayload,
    controller: IViewController,
    shareOptionController: ShareOptions,
    isDisabled?: boolean
}

export const ShareView = (props: ShareViewProps): React.ReactElement => {
    const { isMobile } = useViewport();
    const intlHandler = IntlHandler.getInstance();
    const [isOpen, setIsOpen] = useState(false);
    const sharePopoverClicked = useRef(false);
    const location = useLocation();

    useEffect(() => {
        setIsOpen(false);
    }, [location]);

    useEffect(() => {
        if (props.docStoreData.isDirty === DocumentDirty.NON_DIRTY && sharePopoverClicked.current === true) {
            props.controller.notify({ type: ELCreationsHeaderControllerAction.createShareQR });
            sharePopoverClicked.current = false;
            setIsOpen(true);
        }
    }, [props.docStoreData.isDirty])

    const onShare = async (): Promise<void> => {
        if (props.docStoreData.hasError) {
            props.controller.notify({ type: ELCreationsHeaderControllerAction.documentError });
        }
        else if (props.docStoreData.isDirty === DocumentDirty.DIRTY) {
            setIsOpen(false);
            sharePopoverClicked.current = true;
            await props.controller.notify({ type: ELCreationsHeaderControllerAction.save });
        }
        else {
            props.controller.notify({ type: ELCreationsHeaderControllerAction.sharePopoverClicked });
        }
    }

    const getPrimaryShareButton = (): React.ReactElement => {
        return (
            <ELIconButton size="L" variant="cta" iconkey={IconType.share} iconColor={Theme.light.scooped_button_S}
                iconHoverColor={Theme.light.scooped_button_S} iconWidth={"1.375rem"} iconHeight={"1.375rem"}
                onClick={() => onShare()} isDisabled={props.isDisabled ?? false}>
                {<span className={!props.isDisabled ? "creation-header__share-text" : "creation-header__share-text--disabled"}>
                    {intlHandler.formatMessage("share")}</span>}
            </ELIconButton>
        )
    }

    const getSharePopOver = (): React.ReactElement => {
        return (
            <ELPopover type={Utils.getSpectrumPopoverType((isMobile as boolean))} closeOnPress isOpen={isOpen}
                onOpenChange={(isOpen: boolean) => setIsOpen(isOpen)}>
                {getPrimaryShareButton()}
                <ShareOptionsView controller={props.shareOptionController} onPress={() => {
                    props.controller.notify({ type: ELCreationsHeaderControllerAction.share });
                }} showQRView={true} />
            </ELPopover>
        )
    }

    return getSharePopOver()
}

export interface SaveViewProps {
    docStoreData: DocActionsPayload,
    controller: IViewController,
    saveButtonName?: string
}

export const SaveView = (props: SaveViewProps): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();
    const getSaveTooltip = (): string => {
        if (props.docStoreData.saveStatus === DocumentSaveStatus.saveInProgress) {
            return IntlHandler.getInstance().formatMessage("saving");
        } else if (props.docStoreData.saveStatus === DocumentSaveStatus.saveError) {
            return IntlHandler.getInstance().formatMessage("unable-to-reach-server");
        } else if (props.docStoreData.isDirty === DocumentDirty.DIRTY) {
            return IntlHandler.getInstance().formatMessage("save");
        }

        return IntlHandler.getInstance().formatMessage("saved");
    }

    const getSaveIcon = (): IconType => {
        if (props.docStoreData.saveStatus === DocumentSaveStatus.saveInProgress) {
            return IconType.creationsHeaderSaving;
        } else if (props.docStoreData.saveStatus === DocumentSaveStatus.saveError) {
            return IconType.creationsHeaderServerErrorIcon;
        } else if (props.docStoreData.saveStatus === DocumentSaveStatus.unsaved) {
            return IconType.creationsHeaderSave;
        }

        return IconType.creationsHeaderSave;
    }

    const isSaveDisabled = (): boolean => {
        return props.docStoreData.isDirty === DocumentDirty.NON_DIRTY || props.docStoreData.saveStatus === DocumentSaveStatus.saveInProgress;
    }

    const getDefaultSaveButton = (): React.ReactElement => {
        return (
            <ELIconButton size="L" iconkey={getSaveIcon()} iconColor={Theme.dark.gray_controls_color_N}
                iconHoverColor={Theme.dark.gray_controls_color_N} iconWidth={"1.375rem"} iconHeight={"1.375rem"}
                onClick={() => props.controller.notify({ type: ELCreationsHeaderControllerAction.save })}
                isDisabled={isSaveDisabled()}>
                <span className={(props.docStoreData.isDirty === DocumentDirty.DIRTY) ? "workflow-header__cta-text" : "workflow-header__cta-text disabled"}>{props.saveButtonName ?? intlHandler.formatMessage("save")}</span>
            </ELIconButton>
        )
    }

    return (
        <div title={getSaveTooltip()}>
            {getDefaultSaveButton()}
        </div>
    );
}

export const NoInternetSaveView = (): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();
    return (
        <div title={intlHandler.formatMessage("no-internet")}>
            <ELIconButton size="L" variant="cta" iconkey={IconType.creationsHeaderCloudOffIcon} iconColor={Theme.light.scooped_button_S}
                iconHoverColor={Theme.light.scooped_button_S} iconWidth={"1.375rem"} iconHeight={"1.375rem"} isDisabled={true}>
                <span className={"creation-header__share-text--disabled"}>{intlHandler.formatMessage("save")}</span>
            </ELIconButton>
        </div>
    )
}

export const NoSpaceSaveView = (): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();
    return (
        <div title={intlHandler.formatMessage("free-up-space")}>
            <ELIconButton size="L" variant="cta" iconkey={IconType.creationsHeaderCloudErrorIcon} iconColor={Theme.light.scooped_button_S}
                iconHoverColor={Theme.light.scooped_button_S} iconWidth={"1.375rem"} iconHeight={"1.375rem"} isDisabled={true}>
                <span className={"creation-header__share-text--disabled"}>{intlHandler.formatMessage("save")}</span>
            </ELIconButton>
        </div>
    )
}

export const PencilPlaceholder = (props: {
    isSyncingEdit: boolean,
    editingTitle: boolean,
    setEditing: () => void
}): React.ReactElement => {
    const { isSyncingEdit, editingTitle, setEditing } = props;
    let placeHolder = <></>;
    if (isSyncingEdit) {
        // This is for in-transit of rename
        placeHolder = (<ProgressCircle size={"S"} isIndeterminate={true} />);
    } else {
        if (!editingTitle) {
            // Normal pencil to nudge user to rename
            placeHolder = (<ELCustomIcon onPress={setEditing} variant="independent" iconkey={IconType.creationsHeaderRename}
                color={Theme.dark.gray_controls_color_N} hoverColor={Theme.dark.gray_controls_color_N} width="1.5rem" height="1.5rem" />);
        }
    }
    return placeHolder;
}

export const BackButton = (props: ELBackButtonProps): React.ReactElement => {
    const { notify, launchFeedbackDialogOnDontSave, isDialogOpen } = props;
    const [dialogOpen, setDialogOpen] = React.useState(false);

    const shouldShowDialog = (): boolean => {
        return (props.hasDialog ?? false) && dialogOpen;
    }

    const onPress = (): void => {
        if (props.hasDialog === undefined || props.hasDialog === false) {
            notify({ type: ELCreationsHeaderControllerAction.back });
        }
        else {
            notify({ type: ELCreationsHeaderControllerAction.showBackButtonDialog });
            setDialogOpen(true);
        }
    }

    const onDialogSave = (): void => {
        notify({ type: ELCreationsHeaderControllerAction.back });
        setDialogOpen(false);
    }

    const onDialogDontSave = (): void => {
        if (launchFeedbackDialogOnDontSave) {
            notify({ type: ELCreationsHeaderControllerAction.dontSaveAskFeedback });
        } else {
            notify({ type: ELCreationsHeaderControllerAction.dontSave });
        }
        setDialogOpen(false);
    }

    const onDialogDismiss = (): void => {
        setDialogOpen(false)
    }

    useEffect(() => {
        if (isDialogOpen !== undefined)
            setDialogOpen(isDialogOpen);
    }, [isDialogOpen]);

    return (
        <div className="creation-header-back-button">
            <ELCustomIcon variant="independent" iconkey={IconType.creationsHeaderBack} color={Theme.dark.gray_controls_color_N}
                hoverColor={Theme.dark.gray_controls_color_N} width={"2rem"} height={"2rem"}
                onPress={onPress} />
            {
                shouldShowDialog() &&
                <BackButtonDialog headingDescription={props.headingDescription ?? ""}
                    onDismiss={onDialogDismiss}
                    onSave={onDialogSave}
                    onDontSave={onDialogDontSave}
                    shouldShowLicenseAndSave={props.shouldShowLicenseAndSave}
                />
            }
        </div>
    );
}

export const BackButtonDialog = (props: ELBackButtonDialogProps): React.ReactElement => {

    const intlHandler = IntlHandler.getInstance();
    const firstRenderRef = useRef(false);
    const location = useLocation();

    useEffect(() => {
        if (firstRenderRef.current) {
            props.onDismiss();
        } else {
            firstRenderRef.current = true;
        }
    }, [location]);

    return (
        <DialogContainer onDismiss={props.onDismiss} isDismissable>
            <Dialog UNSAFE_className="creation-header-back-dialog-container">
                <Heading>
                    <div className="creation-header-back-dialog-heading">{intlHandler.formatMessage("unsaved-changes")}</div>
                </Heading>
                <Content>
                    <Flex marginTop="size-200" gap="size-400">
                        <div className="creation-header-back-dialog-heading-description" >{props.headingDescription}</div>
                    </Flex>
                    <Divider size="S" margin={"4px 0px 4px 0px"} />
                    <Flex gap="size-500" direction="column" alignItems={"center"}>
                        <Flex marginTop="size-200" justifyContent={"center"}>
                            <div className="creation-header-back-dialog-description" >
                                {intlHandler.formatMessage("back-dialog-description")}
                            </div>
                        </Flex>
                        <Flex gap="size-100" alignSelf="end">
                            <Button margin={"0px 4px 0px 4px"} variant="secondary" onPress={props.onDontSave}>
                                <div className="creation-header-back-dialog-save">
                                    {intlHandler.formatMessage("dont-save")}
                                </div>
                            </Button>
                            {props.shouldShowLicenseAndSave ? (
                                <ELButton variant="cta" onClick={props.onSave}>
                                    <div className="creation-header-back-dialog-save">
                                        {intlHandler.formatMessage("license-save")}
                                    </div>
                                </ELButton>
                            ) : (
                                <ELButton size="S" onClick={props.onSave}>
                                    <div className="creation-header-back-dialog-save">
                                        {intlHandler.formatMessage("save")}
                                    </div>
                                </ELButton>
                            )}
                        </Flex>
                    </Flex>
                </Content>
            </Dialog>
        </DialogContainer>
    )
}

export interface ELSwitchProps {
    controller: IViewController,
}

export const ELSwitch = (props: ELSwitchProps): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();
    const dualViewState = useSelector((rootState: RootState) => rootState.dualViewReducer).viewType;
    const reduxDispatch = useDispatch();

    const onViewChange = (): void => {
        if (dualViewState === ELViewType.before) {
            reduxDispatch(DualViewAction.updateView(ELViewType.after));
            props.controller.notify({ type: ELAdobeAssetControllerAction.showAfterImage });
        } else {
            reduxDispatch(DualViewAction.updateView(ELViewType.before));
            props.controller.notify({ type: ELAdobeAssetControllerAction.showBeforeImage });
        }
    }
    const getSwitchText = (): React.ReactElement => {
        return (
            <>
                <div className="creation-header__switch-text-ipad">
                    {dualViewState === ELViewType.after ? intlHandler.formatMessage("after") : intlHandler.formatMessage("before")}
                </div>
                <div className="creation-header__switch-text">
                    {intlHandler.formatMessage("before") + " / " + intlHandler.formatMessage("after")}
                </div>
            </>
        )
    }

    return (
        <Switch onChange={onViewChange} isSelected={dualViewState === ELViewType.after}>
            {getSwitchText()}
        </Switch>
    )
}