/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { EditingEngineType } from "../../../common/interfaces/editing/editingEngines/EditingEnginesTypes";
import { CSLayerEditInfo, ELLayerKind, ELStageLayerData } from "../../../common/interfaces/editing/layer/ELStageLayerTypes";
import { ELLayerBlendModes } from "../../../common/interfaces/editing/pie/ELPIELayerTypes";
import { ELSize } from "../../../common/interfaces/geometry/ELGeometry";
import { WHITE_COLOR_HEXA_VALUE } from "../../../utils/Constants/Constants";
import ImageUtils from "../../../utils/ImageUtils";
import Logger, { LogLevel } from "../../../utils/Logger";
import IDoc from "../../document/IDoc";
import ILayer from "../../document/layer/ILayer";
import { EditingEngineManager } from "../../editingEngines/EditingEngineManager";
import { PIEEditingEngine } from "../../editingEngines/PIEEditingEngine";
import ELPIEDoc from "../models/ELPIEDoc";
import IPSDConvertor from "./IPSDConvertor";

export default class ELStageDocPSDConvertor extends IPSDConvertor {
    private _getRatio(inSize: ELSize, outSize: ELSize): number {
        const hRatio = outSize.width / inSize.width;
        const vRatio = outSize.height / inSize.height;
        const ratio = Math.max(hRatio, vRatio);

        return ratio;
    }

    private async _getEditInfoForFitToBackground(layer: ILayer, inputDoc: IDoc): Promise<CSLayerEditInfo> {
        const layerSize = await layer.getSize();
        const docSize = await inputDoc.getSize();
        const scale = this._getRatio(layerSize, docSize);
        const editInfo: CSLayerEditInfo = {
            transform: {
                quadrilateral: {
                    left_top: 0,
                    right_top: 0,
                    left_bottom: layerSize.width,
                    right_bottom: layerSize.height,
                },
                scale: {
                    horizontal: scale,
                    vertical: scale
                },
                center: {
                    horizontal: (layerSize.width * scale) / 2,
                    vertical: (layerSize.height * scale) / 2
                },
                rotate: 0,
                offset: {
                    horizontal: 0,
                    vertical: 0
                }
            }
        };
        return Promise.resolve(editInfo);
    }

    private async _getLayerEditInfo(layer: ILayer, inputDoc: IDoc): Promise<CSLayerEditInfo | undefined> {
        const editInfo = layer.getEditInfo();

        if (layer.getFitToBackground()) {
            const additionalEditInfo = await this._getEditInfoForFitToBackground(layer, inputDoc);
            const updatedEditInfo = {
                ...editInfo,
                ...additionalEditInfo
            };
            return updatedEditInfo;
        }

        return editInfo;
    }

    private async _getLayerImageData(layer: ILayer): Promise<ImageData> {
        // because edit info only comes in case of color background. Hence the condition
        const isRectLayerWithFill = layer.getLayerKind() === ELLayerKind.rectangle && layer.getEditInfo()?.fill;
        if (isRectLayerWithFill) {
            const fillInfo = layer.getEditInfo()?.fill ?? WHITE_COLOR_HEXA_VALUE;
            const layerSize = await layer.getSize();
            return Promise.resolve(ImageUtils.createImageDataWithFill(layerSize, fillInfo));
        }
        return Promise.resolve(layer.getData() as ImageData);
    }

    private async _addLayers(inputDoc: IDoc, psdDoc: ELPIEDoc): Promise<ELPIEDoc> {
        const layers = await inputDoc.getLayers();
        for (const layer of layers.values()) {
            const data = await this._getLayerImageData(layer);

            const buffer = await ImageUtils.getArrayBufferFromImageData(data);
            const editInfo = await this._getLayerEditInfo(layer, inputDoc);

            const layerData: ELStageLayerData = {
                data: buffer,
                layerParams: { layerBlendMode: ELLayerBlendModes.normal },
                redraw: false,
                layerKind: ELLayerKind.pixel,
                editInfo: editInfo,
                visible: layer.getVisibility()
            };

            await psdDoc.addLayer(layerData);
        }
        return Promise.resolve(psdDoc);
    }

    async convertToPSD(inputDoc: IDoc, doResize = false): Promise<IDoc> {
        try {
            const pieEditingEngine = await EditingEngineManager.getEditingEngine(EditingEngineType.pie) as PIEEditingEngine;
            await pieEditingEngine.ready();
            let psdDoc: IDoc = await this.createDocWithWhiteBackgroundLayer(inputDoc, pieEditingEngine);
            psdDoc.setLayerVisibility(0, false);
            psdDoc = await this._addLayers(inputDoc, psdDoc as ELPIEDoc);

            if (doResize && (await this.shouldResize())) {
                psdDoc = await this.resizeDocument(psdDoc as ELPIEDoc, pieEditingEngine);
            }

            return Promise.resolve(psdDoc);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "Unable to create PSD", error);
            return Promise.reject("PSD couldn't be created!");
        }
    }
}