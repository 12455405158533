/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2025 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//ThirdParty
import React from "react";

//Adobe Internal
import { Flex, Text } from "@adobe/react-spectrum";

import "./ELImageAndText.scss";

interface ELImageAndTextProps {
    imageSrc?: string;
    text: string;
    onClick: () => void;
    altText?: string;
    isSelected: boolean;
}

export const ELImageAndText = (props: ELImageAndTextProps): React.ReactElement => {
    const getClassName = (): string => {
        return `${props.isSelected ? "el-image-and-text__container--selected" : "el-image-and-text__container"}`;
    };

    return (
        <Flex UNSAFE_className="el-image-and-text" direction="row" gap="size-50" alignItems="center" justifyContent="center">
            <div className={getClassName()} onClick={props.onClick} role="button">
                {props.imageSrc && <img className={`${props.isSelected ? "el-image-and-text__image--selected" : "el-image-and-text_image"}`} src={props.imageSrc} alt={props.altText} />}
                <Text UNSAFE_className={`${props.isSelected ? "el-image-and-text__text--selected" : "el-image-and-text__text"}`}>{props.text}</Text>
            </div>
        </Flex>
    );
};

ELImageAndText.defaultProps = {
    isSelected: false,
    altText: ""
}