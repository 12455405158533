/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2025 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { useEffect } from "react";

//Application Specific
import { IngestLogging } from "../../../../services/IngestWrapper";
import { IngestEventSubTypes, IngestEventTypes, IngestWorkflowTypes, INGEST_APP_WORKSPACE } from "../../../../utils/IngestConstants";
import { IngestUtils } from "../../../../utils/IngestUtils";
import Logger, { LogLevel } from "../../../../utils/Logger";
import Utils from "../../../../utils/Utils";
import { LoginUtils } from "../../../../utils/LoginUtils";
import IMS from "../../../../services/IMS";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { PlatformUtils } from "../../../../utils/PlatformUtils";

//CSS
import "./ELUniversalNav.scss";

//TODO: Theme
type UniversalNavProps = {
    signedIn: boolean,
    theme: string,
    containerDivId: string,
};

declare global {
    interface Window {
        universalNavConfig: Record<string, unknown>,
        UniversalNav: (navConfig: Record<string, unknown>) => Promise<void>,
        reload: (navConfig: Record<string, unknown>) => Promise<void>,
    }
}

const setConfigInWindow = (signedIn: boolean, containerDivId: string, theme: string, locale: string, accessToken: string, profile: any): void => {
    // Constants for magic strings
    const SECTION_TITLE_ELEMENTS = "ELEMENTS";
    const LINK_TYPE_EXTERNAL = "external";
    const LINK_URL_GET_DESKTOP_APP = "https://www.adobe.com/products/photoshop-elements.html";
    const LINK_CUSTOM_ACTION_GET_DESKTOP_APP = "getDesktopApp";
    const ANALYTICS_NAME_GET_DESKTOP_APP = "getDesktopApp";

    const ACCOUNT_TYPE = "account_type";
    const NOT_APPLICABLE = "na";
    const PLATFORM_WEB = "Web";

    const intlHandler = IntlHandler.getInstance();

    const containerDiv = document.getElementById(containerDivId); // DOM element within which UniversalNav should render
    if (!containerDiv) {
        return;
    }

    const signOutIngestObject = IngestUtils.addWorkspaceDetail(INGEST_APP_WORKSPACE, IngestUtils.getPseudoLogObject(IngestWorkflowTypes.app,
        IngestEventSubTypes.success, IngestEventTypes.signOut, true));

    const getNavChildren = (): Array<Record<string, any>> => {
        const elUnavConfig = {
            localSectionExperience: {
                enableSophia: false,
                staticLocalSection: [
                    {
                        sectionTitle: SECTION_TITLE_ELEMENTS,
                        sectionItems: [
                            {
                                sectionLinkText: intlHandler.formatMessage("get-desktop-app"),
                                sectionLinkType: LINK_TYPE_EXTERNAL,
                                sectionLinkUrl: LINK_URL_GET_DESKTOP_APP,
                                sectionLinkCustomAction: LINK_CUSTOM_ACTION_GET_DESKTOP_APP,
                                analyticsName: ANALYTICS_NAME_GET_DESKTOP_APP
                            }
                        ]
                    },
                ]
            }
        };

        navChildren.push(
            {
                name: "profile",
                attributes: {
                    messageEventListener: async (event: any) => {
                        const { name, payload, executeDefaultAction } = event.detail;
                        if (name === "System" && payload.subType === "SignOut") {
                            executeDefaultAction(); //Default handler provided by account menu
                            IngestLogging.getInstance().logEvent(signOutIngestObject);
                            LoginUtils.handleUserSignOut();
                        }
                    },
                    componentLoaderConfig: {
                        config: {
                            enableLocalSection: true,
                        },
                    },
                    complexConfig: elUnavConfig
                }
            }
        );
        return navChildren;
    };

    let navChildren: Array<Record<string, any>> = [];
    if (signedIn) {
        navChildren = getNavChildren();
    }

    window.universalNavConfig = {
        theme: theme, // theme can take light or dark value. default value is light.
        env: process.env.REACT_APP_ENV,
        locale,
        target: containerDiv,

        accessToken: accessToken,
        authId: profile.authId,
        email: profile.email,
        accountType: profile[ACCOUNT_TYPE],
        userId: profile.authId,
        imsClientId: process.env.REACT_APP_IMS_API_KEY,

        analyticsContext: {
            hasUserConsent: true,
            consumer: {
                name: process.env.REACT_APP_PRODUCT_NAME,
                version: process.env.REACT_APP_VERSION,
                platform: PLATFORM_WEB,
                client_id: process.env.REACT_APP_IMS_API_KEY,
                device: NOT_APPLICABLE,
                os_version: PlatformUtils.osVersion() ?? NOT_APPLICABLE,
            },
            event: {
                visitor_guid: profile.authId
            }
        },
        children: navChildren,
    };
};

const renderUniversalNav = (): void => {
    window.UniversalNav(window.universalNavConfig);
};

const reloadExistingUNAV = (config?: any): void => {
    (window.UniversalNav as any)?.reload(config ? config : window.universalNavConfig);
    return;
};

const ELUniversalNav = (props: UniversalNavProps): React.ReactElement => {
    const locale = Utils.getCurrentLocaleInSnakeCase();
    const { signedIn, theme, containerDivId } = props;

    const accessToken = IMS.getInstance().getUserAccessToken();
    const profile = IMS.getInstance().getUserProfile();

    useEffect(() => {
        // Universal NAV DOM element
        const unavContainer = document.getElementById("universal-nav");
        if (!unavContainer) {
            // set config for first time
            setConfigInWindow(signedIn, containerDivId, theme, locale, accessToken, profile);
        } else {
            // relaod the existing UNAV
            const containerDiv = document.getElementById(containerDivId);
            const config = { ...window.universalNavConfig, target: containerDiv };
            reloadExistingUNAV(config);
        }

        // This reload is necessary when creations are created from media room
        const defaultContainerDiv = document.getElementById(containerDivId);
        if (defaultContainerDiv) {
            const defauConfig = { ...window.universalNavConfig, target: defaultContainerDiv };
            reloadExistingUNAV(defauConfig);
        }

        const getUniversalNavJsUrl = (): string => {
            if (process.env.REACT_APP_UNIVERSALNAV_JS_URL) {
                return process.env.REACT_APP_UNIVERSALNAV_JS_URL;
            }
            else {
                Logger.log(LogLevel.WARN, "UniversalNav:useEffect: ", "JS URL not defined");
                return "";
            }
        };

        const universalnavJSPromise = Utils.loadScript(
            "univeralnavjs",
            getUniversalNavJsUrl(),
            true
        );

        const getUniversalNavCssUrl = (): string => {
            if (process.env.REACT_APP_UNIVERSALNAV_CSS_URL) {
                return process.env.REACT_APP_UNIVERSALNAV_CSS_URL;
            }
            else {
                Logger.log(LogLevel.WARN, "UniversalNav:useEffect: ", "CSS URL not defined");
                return "";
            }
        };

        const universalnavCSSPromise = Utils.loadCSS(
            "univeralnavcss",
            getUniversalNavCssUrl()
        );

        Promise.all([universalnavJSPromise, universalnavCSSPromise])
            .then(() => {
                renderUniversalNav();
            }, () => {
                Logger.log(LogLevel.ERROR, "UniversalNav:useEffect: ", "Unable to resolve");
            })
            .catch(error => {
                Logger.log(LogLevel.ERROR, "UniversalNav:useEffect: ", error);
            });
    }, []);

    return (
        <div id={containerDivId} className={signedIn ? "global-utilnav-signin" : "global-utilnav-signout"} />
    );
};

export default ELUniversalNav;
