/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import StockServiceConstants from '../../utils/Constants/StockServiceConstants';
import { StockServiceUtils } from '../../utils/StockServiceUtils';
import { ContentDataManager } from './ContentDataManager';
import { AssetDataInfo, BackgroundLayerRequestParams, GenericContentSource } from './ContentDataManagerTypes';
import { Asset, AssetData, ContentDataType, ContentSource, StorageType } from '@elements/elementswebcommon';
import { AssetStorageUtils } from '../../utils/AssetStorageUtils';
import ImageUtils from '../../utils/ImageUtils';
import { ELLayerKind, ELStageLayerData } from '../../common/interfaces/editing/layer/ELStageLayerTypes';
import { StorageService } from '../../services/StorageServiceWrapper';
import { ELContentCreationMediaData, ELDocumentDataConfig } from '../../common/interfaces/creations/client/ELContentCreationsCreatorTypes';
import { DocumentDataType } from '../../editors/document/dataResolver/ELAdobeAssetDataResolver';
import ELContentCreationsCreator from '../../workspaces/creations/workflows/client/utils/ELContentCreationsCreator';
import { ELCreationContentPayload } from "../../common/interfaces/creations/ELRecommendationsWorkflowTypes";

export class AssetDataManager extends ContentDataManager {

    private _contentSource?: GenericContentSource;

    constructor(contentSource?: GenericContentSource) {
        super();
        this._contentSource = contentSource ? contentSource : undefined;
    }

    async getBackgroundContentData(contentPayload: ELCreationContentPayload): Promise<Asset> {
        if (contentPayload.contentSource === undefined) {
            throw new Error("Content Source is not provided");
        }

        const imageData = await this.getUpdateContentData(contentPayload) as ImageData;
        const mimeType = "image/jpeg";
        const contentData = contentPayload.contentData as AssetDataInfo;
        const uploadedAssetUrn = await AssetStorageUtils.uploadOrGetAssetId(imageData, mimeType, contentData.id ? contentData.id : "");
        const asset = new Asset();
        const assetData: AssetData = {
            assetURN: uploadedAssetUrn ? uploadedAssetUrn : "",
            storageType: StorageType.RAPI,
            mimeType: mimeType,
        };
        asset.assetData = assetData;
        return Promise.resolve(asset);
    }

    getContentSource(): ContentSource | undefined {
        switch (this._contentSource) {
            case GenericContentSource.adobeStock:
                return ContentSource.stock;
            default:
                throw new Error("Invalid Content Source");
        }
    }

    getContentDataType(): ContentDataType {
        return ContentDataType.rapi;
        //Currently its only RPI we can update method in future if there are different types.
    }

    public async getContentLayerData(contentPayload: ELCreationContentPayload): Promise<ELStageLayerData> {
        const contentData = await this.getUpdateContentData(contentPayload) as ImageData;
        return {
            data: contentData,
            fitToBackground: true,
            layerKind: ELLayerKind.pixel
        };
    }

    public async getUpdateContentData(contentPayload: ELCreationContentPayload): Promise<unknown> {
        const contentData = contentPayload.contentData as AssetDataInfo;
        if (contentData === undefined) {
            throw new Error("Content data is not provided");
        }
        let imageData: ImageData;
        switch (contentPayload.contentSource) {
            case GenericContentSource.adobeStock: {
                if (contentData.id === undefined) {
                    throw new Error("Stock image id is not provided");
                }
                const downloadedImageData = await StockServiceUtils.downloadImage(contentData.id, StockServiceConstants.STOCK_IMAGE_SIZE_FOR_REPLACE_BACKGROUND as number);
                const imageUrl = window.URL.createObjectURL(downloadedImageData);
                imageData = await this._fetchContentDataFromUrl(imageUrl);
                break;
            }
            case GenericContentSource.adobeStockPreview: {
                if (contentData.url === undefined) {
                    throw new Error("Stock image thumbnail url is not provided");
                }
                const stockImageUrl = contentData.url;
                imageData = await this._fetchContentDataFromUrl(stockImageUrl);
                break;
            }
            default: {
                throw new Error("Invalid content Source");
            }
        }
        return Promise.resolve(imageData);
    }

    async getBackgroundLayerPayload(backgroundLayerPayloadRequestParams: BackgroundLayerRequestParams): Promise<ELStageLayerData> {
        if (backgroundLayerPayloadRequestParams.backgroundContentData === undefined) {
            throw new Error("Content data is not provided");
        }
        const contentData = backgroundLayerPayloadRequestParams.backgroundContentData.contentData as AssetData;
        if (contentData.assetURN === undefined) {
            throw new Error("Asset is is not present");
        }
        const backgroundAsset = await StorageService.getInstance().resolveAsset({ assetId: contentData.assetURN }, "id");
        const backgroundDocumentConfig: ELDocumentDataConfig = { documentDataType: DocumentDataType.fullRes, size: backgroundLayerPayloadRequestParams.recommendationWidth };
        const backgroundMediaData = { ...backgroundDocumentConfig, media: backgroundAsset };
        const backgroundImageData = await this._getMediaLayerData(backgroundMediaData, backgroundLayerPayloadRequestParams.contentCreationCreator);
        const backgroundLayerPayload: ELStageLayerData = backgroundLayerPayloadRequestParams.layerDataOptionsList ?
            {
                data: backgroundImageData,
                ...backgroundLayerPayloadRequestParams.layerDataOptionsList[1]
            } :
            {
                data: backgroundImageData,
                fitToBackground: true,
                layerKind: ELLayerKind.pixel
            };
        return Promise.resolve(backgroundLayerPayload);
    }

    private async _getMediaLayerData(contentMediaData: ELContentCreationMediaData, contentCreationCreator?: ELContentCreationsCreator): Promise<ImageData> {
        if (contentCreationCreator === undefined) {
            throw new Error("Content creation creator is not initialized");
        }
        const mediaData = await contentCreationCreator.getImageDataFromAsset(contentMediaData);
        return Promise.resolve(mediaData);
    }

    private async _fetchContentDataFromUrl(contentUrl: string): Promise<ImageData> {
        if (contentUrl) {
            const imageData = (await this.getContentsDataFromUrl(contentUrl))[0];
            return imageData;
        }
        return Promise.reject("Content url is invalid");
    }

    private async getContentsDataFromUrl(contentUrl: string): Promise<ImageData[]> {
        const contentDataPromises = [];
        contentDataPromises.push(ImageUtils.createImageData(contentUrl));

        const contentsData = await Promise.all(contentDataPromises);

        return Promise.resolve(contentsData);
    }
}