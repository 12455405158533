/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2025 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { useState } from "react";

//Adobe Internal
import { Flex, Button } from "@adobe/react-spectrum";

//Application Specific
import { ELInteractivePreviewActionGroup } from "../el-interactive-preview-action-group/ELInteractivePreviewActionGroup";
import { FileType, FileUtils } from "../../../../utils/FileUtils";
import IViewController from "../../../IViewController";
import { ELUserHomeBannerActions } from "../../../../common/interfaces/home/UserHomeTypes";

import "./ELInteractiveBannerView.scss";

interface PreviewOption {
    src: string;
    swatchSrc: string;
}

export interface PreviewGroup {
    name: string;
    iconUrl?: string;
    options?: PreviewOption[];
}

export interface PreviewConfig {
    groups: PreviewGroup[];
    foregroundSrc?: string;
    defaultBackgroundSrc?: string;
}

interface ELInteractiveBannerViewProps {
    controller: IViewController;
    previewConfig: PreviewConfig;
    heading?: string;
    subHeading?: string;
    descriptionText?: string;
    buttonText?: string;
    tryItSrc?: string;
    tryItText?: string;
}

const ELInteractiveBannerView = (props: ELInteractiveBannerViewProps): React.ReactElement => {
    const {
        previewConfig,
        heading = "",
        subHeading = "",
        descriptionText = "",
        buttonText = "",
    } = props;

    const absolutePathPrefix = window.location.origin + "/" + process.env.PUBLIC_URL;

    const getAbsolutePath = (relativePath: string): string => {
        if (!relativePath)
            return "";
        return absolutePathPrefix + relativePath;
    };

    const [currentBackground, setCurrentBackground] = useState<string>(getAbsolutePath(previewConfig.defaultBackgroundSrc || ""));

    const getMediaElement = (src?: string): React.ReactElement | null => {
        if (!src)
            return null;

        const fileType = FileUtils.getFileType(src);

        switch (fileType) {
            case FileType.video:
                return <video crossOrigin="anonymous" onContextMenu={(e) => e.preventDefault()} className="interactive-banner__video-banner" src={src} playsInline loop autoPlay muted />;
            default:
                return <img src={src} className="interactive-banner__image-banner" alt="" />;
        }
    };

    const handlePreviewChange = (src: string): void => {
        setCurrentBackground(src);
    };

    const interactiveBannerLeftView = (): React.ReactElement => {
        return (
            <>
                <div className="interactive-banner__left-view"></div>
                <div className="interactive-banner__text-content">
                    <div className="interactive-banner__banner-heading">{heading}</div>
                    <div className="interactive-banner__sub-heading">{subHeading}</div>
                    <div className="interactive-banner__description-text">{descriptionText}</div><br />
                    <Button variant="cta" onPress={() => props.controller.notify({ type: ELUserHomeBannerActions.getStarted })} UNSAFE_className="interactive-banner-container__banner-cta">{buttonText}</Button>
                </div>
            </>
        );
    };

    return (
        <Flex UNSAFE_className="interactive-banner-container" data-testid="interactive-banner-container">
            {getMediaElement(currentBackground)}
            {getMediaElement(getAbsolutePath(previewConfig.foregroundSrc || ""))}
            <div className="interactive-banner__action-group">
                <ELInteractivePreviewActionGroup groups={previewConfig.groups} onPreviewChange={handlePreviewChange} />
            </div>
            {interactiveBannerLeftView()}
        </Flex>
    );
};

export default ELInteractiveBannerView;