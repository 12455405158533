/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { ContentDataType, ContentSource, UserDefinedDataInfo, UserDefinedInfoType } from '@elements/elementswebcommon';

import { ContentDataManager } from './ContentDataManager';
import { ELLayerKind, ELStageLayerData, FillDataType } from '../../common/interfaces/editing/layer/ELStageLayerTypes';
import { ELCreationContentPayload } from "../../common/interfaces/creations/ELRecommendationsWorkflowTypes";
import { BackgroundLayerRequestParams } from './ContentDataManagerTypes';


const imageSize2K = Object.freeze({
    width: 2560,
    height: 1440
});

export class UserDefinedDataManager extends ContentDataManager {

    async getBackgroundContentData(contentPayload: ELCreationContentPayload): Promise<UserDefinedDataInfo> {
        const { type, data } = contentPayload.contentData as UserDefinedInfoType;

        const userDefinedDataInfo = new UserDefinedDataInfo();
        Object.assign(userDefinedDataInfo, { type, data });
        return userDefinedDataInfo;
    }

    getContentSource(): ContentSource | undefined {
        return undefined;
    }

    getContentDataType(): ContentDataType {
        return ContentDataType.userDefined;
    }

    public async getContentLayerData(contentPayload: ELCreationContentPayload): Promise<ELStageLayerData> {
        const dummyImageData = new ImageData(imageSize2K.width, imageSize2K.height);
        const colorData = await this.getUpdateContentData(contentPayload) as FillDataType;
        return {
            data: dummyImageData,
            selectable: false,
            fitToBackground: true,
            layerKind: ELLayerKind.rectangle,
            editInfo: {
                fill: colorData,
                opacity: 1
            }
        };
    }

    public getUpdateContentData(contentPayload: ELCreationContentPayload): Promise<unknown> {
        const contentData = contentPayload.contentData as UserDefinedInfoType;
        return Promise.resolve(contentData.data);
    }

    async getBackgroundLayerPayload(backgroundLayerPayloadRequestParams: BackgroundLayerRequestParams): Promise<ELStageLayerData> {
        if (backgroundLayerPayloadRequestParams.backgroundContentData === undefined) {
            throw new Error("No content data provided");
        }
        const dummyImageData = new ImageData(imageSize2K.width, imageSize2K.height);
        const colorData = await this.getUpdateContentData(backgroundLayerPayloadRequestParams.backgroundContentData) as FillDataType;

        const backgroundLayerPayload: ELStageLayerData = backgroundLayerPayloadRequestParams.layerDataOptionsList ?
            {
                data: dummyImageData,
                ...backgroundLayerPayloadRequestParams.layerDataOptionsList[1]
            } :
            {
                data: dummyImageData,
                selectable: false,
                fitToBackground: true,
                layerKind: ELLayerKind.rectangle,
                editInfo: {
                    fill: colorData,
                    opacity: 1
                }
            };

        return Promise.resolve(backgroundLayerPayload);
    }

}