/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Adobe Internal
import {
    PIE,
    pixels,
    write_png_options_t,
    image_mode_e,
} from "@piewasm/pie-web-npm-package";

//Application Specific
import { ControllerData } from "../../../common/interfaces/editing/ControllerTypes";
import ILayer from "../../document/layer/ILayer";
import { ELPIEPixelActions } from "../../../common/interfaces/editing/pie/ELPIEPixelTypes";
import { DocumentFormat } from "../../../common/interfaces/document/DocumentTypes";
import IPixel from "../../document/pixel/IPixel";

export class ELPIEPixel extends IPixel {
    private _pixels?: pixels;
    private _pieInstance?: PIE;

    public constructor(layer?: ILayer) {
        super(layer);
    }

    async initialize<T>(payload?: T): Promise<boolean> {
        const pixels = payload as unknown as pixels;
        this._pixels = pixels;

        if (!(this.layer?.getEngine())) {
            return Promise.reject("Couldn't create ELPIEPixel as no valid engine found!");
        }

        this._pieInstance = (this.layer?.getEngine()?.getModule() as PIE);

        return new Promise<boolean>((resolve) => {
            resolve(true);
        });
    }

    destroy(): void {
        throw new Error("Method not implemented.");
    }

    async notify(action: ControllerData): Promise<boolean> {
        let handled = false;

        switch (action.type) {
            case ELPIEPixelActions.saveAsPNG: {
                this.savePixelAsPNG(action.payload as string);
                handled = true;
                break;
            }
        }

        return handled;
    }

    getData(): unknown {
        return this._pixels;
    }

    protected savePixelAsPNG(outputFileName: string): boolean {
        if (!this._pieInstance || !this._pixels) {
            return false;
        }

        const save_options: write_png_options_t =
            new (this._pieInstance.write_png_options_t)();

        save_options._method = this._pieInstance.png_method.quick.value as any;
        (save_options as any)._quick_compression = 0;

        try {
            this.performColorConversion(
                this._pixels.get_color_space().get_image_mode(),
                this._pixels.get_depth(),
                DocumentFormat.PNG
            );
            this._pixels.write_png(outputFileName, save_options);
            return true;
        } catch (err) {
            return false;
        }
    }

    protected performColorConversion(image_mode: image_mode_e, bit_depth: number, _format: DocumentFormat): void {
        if (this.requireConversion(image_mode, bit_depth, _format)) {
            this.convertPixels(image_mode, bit_depth, _format);
        }
    }

    protected requireConversion(image_mode: image_mode_e, depth: number, format: DocumentFormat): boolean {
        let result = false;
        switch (format) {
            case DocumentFormat.PNG: {
                    if (
                        !(
                            image_mode ===
                            this._pieInstance?.image_mode_e.rgb ||
                            image_mode ===
                            this._pieInstance?.image_mode_e.greyscale ||
                            image_mode ===
                            this._pieInstance?.image_mode_e.bitmap ||
                            image_mode ===
                            this._pieInstance?.image_mode_e.indexed
                        ) ||
                        (image_mode === this._pieInstance?.image_mode_e.rgb &&
                            !(depth === 8 || depth === 16)) ||
                        (image_mode ===
                            this._pieInstance?.image_mode_e.greyscale &&
                            !(depth === 8 || depth === 16))
                    ) {
                        result = true;
                    }

                break;
            }
            case DocumentFormat.JPG: {
                    if (
                        !(
                            image_mode ===
                            this._pieInstance?.image_mode_e.rgb ||
                            image_mode ===
                            this._pieInstance?.image_mode_e.greyscale ||
                            image_mode === this._pieInstance?.image_mode_e.cmyk
                        ) ||
                        depth !== 8
                    )
                        result = true;
                break;
            }
            default:
                break;
        }
        return result;
    }

    protected convertPixels(image_mode: image_mode_e, depth: number, format: DocumentFormat): void {
        if (!this._pieInstance || !this._pixels) {
            return;
        }

        let new_image_mode: any = image_mode;
        let new_depth = depth;

        switch (format) {
            case DocumentFormat.PNG: {
                    if (
                        !(
                            image_mode ===
                            this._pieInstance?.image_mode_e.rgb ||
                            image_mode ===
                            this._pieInstance?.image_mode_e.greyscale ||
                            image_mode ===
                            this._pieInstance?.image_mode_e.bitmap ||
                            image_mode ===
                            this._pieInstance?.image_mode_e.indexed
                        )
                    ) {
                        new_image_mode = this._pieInstance?.image_mode_e.rgb;
                    }

                    if (
                        (new_image_mode ===
                            this._pieInstance?.image_mode_e.rgb ||
                            new_image_mode ===
                            this._pieInstance?.image_mode_e.greyscale) &&
                        !(depth === 8 || depth === 16)
                    ) {
                        new_depth = 16;
                    }

                break;
            }
            case DocumentFormat.JPG: {
                    if (
                        !(
                            image_mode ===
                            this._pieInstance?.image_mode_e.rgb ||
                            image_mode ===
                            this._pieInstance?.image_mode_e.greyscale ||
                            image_mode === this._pieInstance?.image_mode_e.cmyk
                        )
                    ) {
                        new_image_mode = this._pieInstance?.image_mode_e.rgb;
                    }

                if (depth !== 8) {
                    new_depth = 8;
                }
                break;
            }
            default:
                break;
        }

        if (new_image_mode !== image_mode) {
            if (new_depth !== depth) {
                this._pixels.convert_color_space_and_depth(
                    this._pieInstance.create_color_space(new_image_mode),
                    new_depth
                );
            } else {
                this._pixels.convert_image_mode(new_image_mode);
            }
        } else if (new_depth !== depth) {
            this._pixels.convert_depth(new_depth);
        }
    }
}
