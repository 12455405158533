/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import ReactDOM from "react-dom";
import ITemplateViewController from "../../../ITemplateViewController";
import { ViewAction } from "../../../IBaseController";
import React from "react";
import IViewController, { ControllerAction } from "../../../IViewController";
import { ELAdobeStockPanelView } from "./ELAdobeStockPanelView";
import { ELStockThumbData, ELStockWorkflowActions } from "../../../../common/interfaces/services/StockServiceTypes";
import { RecommendationWorkflowAction } from "../../../../stores/actions/RecommendationWorkflowAction";
import { AdobeStockAction } from "../../../../stores/actions/AdobeStockAction";
import store from "../../../../stores/store";
import { GenericContentDataType, GenericContentSource } from "../../../../modules/contenDatatManager/ContentDataManagerTypes";
import Constants from "../../../../utils/Constants/Constants";

export class ELAdobeStockPanel extends ITemplateViewController {

    private _selectedStockId: string;

    constructor(owner: IViewController) {
        super(owner);
        this._selectedStockId = Constants.EMPTY_STRING as string;
    }

    createView(container: HTMLElement, viewProps?: unknown): void {
        throw new Error("It is not called currently, viewProps need to be added to createView for using this.");
    }

    destroyView(): void {
        if (this.container) {
            ReactDOM.unmountComponentAtNode(this.container);
        }
        super.destroyView();
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
        this._updateSelectedStockId();
    }

    destroy(): void {
        super.destroy();
    }

    getView(viewProps?: unknown): React.ReactNode {
        return React.createElement(ELAdobeStockPanelView, {
            controller: this
        })
    }

    private _updateSelectedStockId(): void {
        this.viewDispatcher?.({
            type: ELStockWorkflowActions.setSelectedStockId,
            payload: this._selectedStockId
        });
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case ELStockWorkflowActions.stockThumbnailClicked: {
                const payload = action.payload as ELStockThumbData;
                store.dispatch(RecommendationWorkflowAction.updateContentSource(GenericContentSource.adobeStockPreview));
                store.dispatch(RecommendationWorkflowAction.updateContentDataType(GenericContentDataType.http));
                store.dispatch(AdobeStockAction.updateSelectedStockThumbnail(payload.thumbnailUrl));
                store.dispatch(AdobeStockAction.updateSelectedStockId([payload.id]));
                handled = await this._owner.notify(action);
                break;
            }
            case ELStockWorkflowActions.applyStockLicensedImage: {
                store.dispatch(RecommendationWorkflowAction.updateContentSource(GenericContentSource.adobeStock));
                store.dispatch(RecommendationWorkflowAction.updateContentDataType(GenericContentDataType.http));
                store.dispatch(AdobeStockAction.updateSelectedStockId([action.payload as string]));
                handled = await this._owner.notify(action);
                break;
            }
            case ELStockWorkflowActions.setSelectedStockId: {
                const selectedStockId = action.payload as string;
                this._selectedStockId = selectedStockId;
                this._updateSelectedStockId();
                handled = true;
                break;
            }
            case ELStockWorkflowActions.updateActionRegionState: {
                this.viewDispatcher?.({
                    type: ELStockWorkflowActions.updateActionRegionState,
                    payload: action.payload as boolean
                });
                handled = true;
                break;
            }
        }
        if (!handled)
            handled = await this._owner.notify(action);

        return handled;
    }
}