/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { ContentDataManager } from './ContentDataManager';
import { AssetDataManager } from './AssetDataManager';
import { UserDefinedDataManager } from './UserDefinedDataManager';
import { RecommendationDataManager } from './RecommendationDataManager';
import { GenericContentDataType } from './ContentDataManagerTypes';
import { GraphicsDataManager } from "./GraphicsDataManager";
import { TransparentDataManager } from "./TransparentDataManager";

export const ContentDataMap: { [key in GenericContentDataType]: any } = {
    [GenericContentDataType.http]: AssetDataManager,
    [GenericContentDataType.userDefined]: UserDefinedDataManager,
    [GenericContentDataType.graphics]: GraphicsDataManager,
    [GenericContentDataType.transparent]: TransparentDataManager,
    [GenericContentDataType.recommendation]: RecommendationDataManager
};

export class ContentDataManagerFactory {
    static createManager(
        contentType: GenericContentDataType = GenericContentDataType.recommendation,
    ): ContentDataManager {
        return new ContentDataMap[contentType]();
    }
}
