/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

// Application Specific
import { ELAdobeAsset } from "@elements/elementswebcommon";
import { ContentEntity } from "@elements/elementswebcommon";
import { RecommendationsStatusData } from "../../../common/interfaces/creations/CreationInAppNotifierTypes";
import { CreationsData, CreationsJobProjectSubType, CreationsStatus, RecommendationErrorCode, RecommendationErrorMsg, RecommendationsData, UNTITLED_INTL_KEY } from "../../../common/interfaces/creations/CreationTypes";
import ELAssetExportHandler from "../../../modules/assetExporter/AssetExporter";
import { IntlHandler } from "../../../modules/intlHandler/IntlHandler";
import ElementsCreationsService from "../../../services/ElementsServices/ElementsCreationsService";
import store from "../../../stores/store";
import Constants from "../../../utils/Constants/Constants";
import { IngestEventSubTypes, IngestEventTypes, IngestLogObjectCustomKey, IngestLogObjectKey, IngestLogObjectValue, IngestWorkflowTypes } from "../../../utils/IngestConstants";
import { IngestUtils } from "../../../utils/IngestUtils";
import Logger, { LogLevel } from "../../../utils/Logger";
import { ToastUtils } from "../../../utils/ToastUtils";
import { WorkspaceActionType, WorkspacePayload } from "../../IBaseWorkspace";
import CreationUtils from "../utils/CreationUtils";
import RecommendationsInAppNotifier from "../utils/RecommendationsInAppNotifier";
import CreationWorkflow from "./CreationWorkflow";
import { WorkflowActionType, WorkflowsName } from "../../IWorkflow";
import { DocumentDirty, DocumentSaveStatus } from "../../../common/interfaces/document/DocumentTypes";
import Utils from "../../../utils/Utils";
import { ELRecommendationWorkflowViewActions, ELCreateAndEditProjectParams } from "../../../common/interfaces/creations/ELRecommendationsWorkflowTypes";
import { RecommendationWorkflowAction } from "../../../stores/actions/RecommendationWorkflowAction";
import { ELExportOption, ELExportInfo } from "../../../common/interfaces/export/ELExportTypes";

abstract class RecommendationsWorkflow<DocPayloadType> extends CreationWorkflow<DocPayloadType> {
    protected savedProjectOverlayId?: string;

    protected updateStoreStateOnEnter(): void {
        super.updateStoreStateOnEnter();
        store.dispatch(RecommendationWorkflowAction.updateProjectTitle(IntlHandler.getInstance().formatMessage(UNTITLED_INTL_KEY)));
        store.dispatch(RecommendationWorkflowAction.updateSelectedOverlayId(undefined));
        store.dispatch(RecommendationWorkflowAction.updateSelectedOverlayName(undefined));
    }

    protected isFailedRecommendation(recommendationStatusData: RecommendationsStatusData): boolean {
        return recommendationStatusData.status !== CreationsStatus.success;
    }

    protected pollRecommendationStatus(requestId: string): void {
        if (requestId) {
            RecommendationsInAppNotifier.pollStatus(requestId);
        }
    }

    protected async getRecommendationRequestData(requestId: string): Promise<RecommendationsData> {
        try {
            const requestData = await ElementsCreationsService.getInstance().getRecommendationsRequestData(requestId);
            return requestData;
        } catch (error) {
            Logger.log(LogLevel.ERROR, "RecommendationsWorkflow: getRecommendationRequestData, failed to get requestData where requestId is ", requestId);
            return Promise.reject(error);
        }
    }

    protected async deleteRecommendedCreations(requestId?: string): Promise<boolean> {
        if (!requestId) {
            Logger.log(LogLevel.INFO, "RecommendationsWorkflow: deleteRecommendedCreations, not able to find requestId for deleting recommendations.");
            return Promise.reject(new Error("Request id is undefined"));
        }
        try {
            const deletedCreations = CreationUtils.deleteRecommendations(requestId);
            return deletedCreations;
        } catch (error) {
            Logger.log(LogLevel.ERROR, "RecommendationsWorkflow: deleteRecommendedCreations, not able to delete recommendations");
            return Promise.reject(error);
        }
    }

    protected getFormattedTitleForRequest(): string {
        const intlHandler = IntlHandler.getInstance();
        const title = this.getTrueTitleForRequest(store.getState().recommendationWorkflowReducer.title ?? intlHandler.formatMessage(UNTITLED_INTL_KEY));
        return title;
    }

    protected async createRecommendations(recommendationJob: unknown): Promise<string> {
        try {
            const requestId = await ElementsCreationsService.getInstance().createRecommendations(recommendationJob);
            return Promise.resolve(requestId);
        } catch (error) {
            Logger.log(LogLevel.WARN, "RecommendationsWorkflow: createRecommendationCreation: ", RecommendationErrorMsg.recommendationsFailed + error);
            return Promise.reject(RecommendationErrorCode.recommendationsFailed);
        }
    }

    protected async downloadSavedProject(projectData: CreationsData, creationsJobProjectSubType: CreationsJobProjectSubType, toastMessage: string): Promise<void> {
        const message = IntlHandler.getInstance().formatMessage("file-download-in-background",
            { media: toastMessage });
        ToastUtils.info(message);

        const additionalLogInfo: Record<string, string> = {};
        additionalLogInfo[IngestLogObjectCustomKey.viewType] = IngestLogObjectValue.workspace;

        try {
            await CreationUtils.downloadCreation(projectData);
            this._owner.notify({
                type: WorkspaceActionType.ingest,
                payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                    IngestEventSubTypes.success, IngestEventTypes.download, creationsJobProjectSubType, additionalLogInfo)
            });
        } catch (e) {
            //wait for download start message to go away then show the failed msg
            setTimeout(() => {
                ToastUtils.error(IntlHandler.getInstance().formatMessage("download-fail-toast-msg"), {
                    closable: true,
                    timeout: 0
                });
            }, Constants.TOAST_DEFAULT_TIME_OUT_LIMIT as number);
            additionalLogInfo[IngestLogObjectKey.errorDescription] = e as string;

            this._owner.notify({
                type: WorkspaceActionType.ingest,
                payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                    IngestEventSubTypes.error, IngestEventTypes.download, creationsJobProjectSubType, additionalLogInfo)
            });
        }
    }

    protected async downloadSelectedRecommendation(asset: ELAdobeAsset, fileName: string, exportOption: ELExportOption = ELExportOption.native): Promise<void> {
        const downloadHandler = new ELAssetExportHandler();
        const exportInfo: ELExportInfo = {
            asset: asset,
            defaultName: fileName
        };

        try {
            await downloadHandler.downloadFile(exportInfo, exportOption);
            return Promise.resolve();
        }
        catch (error) {
            Logger.log(LogLevel.WARN, "RecommendationsWorkflow.ts:_downloadSelectedRecommendation: ", error);
            return Promise.reject();
        }
    }

    protected async startPreviousWorkflow(): Promise<void> {
        this._owner.notify({ type: WorkspaceActionType.endWorkflow });

        const workspacePayload: WorkspacePayload = {
            startWorkflow: WorkflowsName.creationsHome
        };
        const workspaceAction = { type: WorkspaceActionType.startWorkflow, ...workspacePayload };
        this._owner.notify(workspaceAction);
    }

    protected async waitForSaveComplete(waitCount = 0, maxWaitCount = 10): Promise<void> {
        if (waitCount >= maxWaitCount) {
            return;
        }
        const saveStatus = store.getState().docStateReducer.saveStatus;
        if (saveStatus === DocumentSaveStatus.saveInProgress) {
            await Utils.wait(2000);
            await this.waitForSaveComplete(waitCount + 1);
        }
    }

    protected async waitForDocumentDirtyStatus(waitCount = 0, maxWaitCount = 10): Promise<void> {
        if (waitCount >= maxWaitCount) {
            return;
        }
        const isDocDirty = store.getState().docStateReducer.isDirty;
        if (isDocDirty === DocumentDirty.DIRTY) {
            await Utils.wait(2000);
            await this.waitForDocumentDirtyStatus(waitCount + 1);
        }
    }
    protected getAllOverlayIds(overlayList: ContentEntity[]): string[] {
        const overlayIds = overlayList.map(data => data.props.id);
        return overlayIds;
    }

    protected updateViewStatusAndProgressText(status: CreationsStatus, progressText: string): void {
        this.updateProgressText(progressText);
        this.updateViewStatus(status);
    }

    protected updateViewStatus(status: CreationsStatus): void {
        this.viewDispatcher?.call(this.viewDispatcher, {
            type: ELRecommendationWorkflowViewActions.recommendationWorkflowStatus,
            payload: status,
        });
    }

    protected updateProgressText(progressText: string): void {
        this.viewDispatcher?.call(this.viewDispatcher, {
            type: ELRecommendationWorkflowViewActions.recommendationWorkflowProgressText,
            payload: progressText
        });
    }

    protected async ingestCreationFeedback(eventSubType: string): Promise<void> {
        try {
            const customEntries: Record<string, string> = {};
            const selectedOverlayName = store.getState().recommendationWorkflowReducer.selectedOverlayName;
            const selectedLayoutName = store.getState().layoutReducer.selectedLayout;
            customEntries[IngestLogObjectCustomKey.layoutName] = selectedLayoutName;
            if (selectedOverlayName) {
                customEntries[IngestLogObjectCustomKey.overlay] = selectedOverlayName;
            }
            for (const key in customEntries) {
                const additionalLogInfo: Record<string, string> = {};
                additionalLogInfo[IngestLogObjectKey.contentName] = key;
                additionalLogInfo[IngestLogObjectKey.eventCount] = customEntries[key];

                await this._owner.notify({
                    type: WorkflowActionType.ingest,
                    payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations, IngestEventTypes.info, eventSubType,
                        IngestUtils.getIngestCreationsWorkflowName(this.workflowName), additionalLogInfo)
                });
            }
        }
        catch (e) {
            Logger.log(LogLevel.WARN, `RecommendationsWorkflow:ingestCreationFeedback: Dunamis Logging Error:${e as string}`);
        }
    }

    protected abstract updateRecommendationWorkflowRoute(): void;

    protected abstract createRecommendationProject(createProjectParams: ELCreateAndEditProjectParams): Promise<void>;

    protected abstract editRecommendationsProject(editProjectParams: ELCreateAndEditProjectParams): Promise<void>;

    protected abstract updateDocumentWithOverlay(payload: unknown): Promise<void>;
}

export default RecommendationsWorkflow;