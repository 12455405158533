/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { ContentDataType, ContentSource } from "@elements/elementswebcommon";
import { ELStageLayerDataOptions } from "../../common/interfaces/editing/layer/ELStageLayerTypes";
import { ELCreationContentPayload } from "../../common/interfaces/creations/ELRecommendationsWorkflowTypes";
import ELContentCreationsCreator from "../../workspaces/creations/workflows/client/utils/ELContentCreationsCreator";

export interface AssetDataInfo {
    id?: string;
    url?: string;
    urn?: string;
    mimeType?: string;
}

export interface BackgroundLayerRequestParams {
    layerDataOptionsList?: ELStageLayerDataOptions[];
    backgroundContentData?: ELCreationContentPayload;
    recommendationWidth?: number;
    contentCreationCreator?: ELContentCreationsCreator;
}

export enum GenericContentDataType {
    transparent = "transparent",
    http = "http",
    userDefined = "userDefined",
    recommendation = "recommendation",
    graphics = "graphics"
}

export enum GenericContentSource {
    adobeStock = "adobeStock",
    adobeStockPreview = "adobeStockPreview",
    userUpload = "userUpload",
    firefly = "firefly"
}

export const contentDataTypeMap: Record<ContentDataType, GenericContentDataType> = Object.freeze({
    [ContentDataType.rapi]: GenericContentDataType.http,
    [ContentDataType.userDefined]: GenericContentDataType.userDefined,
    [ContentDataType.graphics]: GenericContentDataType.graphics,
});

export const contentSourceMap: Record<ContentSource, GenericContentSource> = Object.freeze({
    [ContentSource.stock]: GenericContentSource.adobeStock,
    [ContentSource.firefly]: GenericContentSource.firefly,
    [ContentSource.userUpload]: GenericContentSource.userUpload
});

export const genericContentSourceMap: Record<GenericContentSource, ContentSource> = Object.freeze({
    [GenericContentSource.adobeStock]: ContentSource.stock,
    [GenericContentSource.firefly]: ContentSource.firefly,
    [GenericContentSource.userUpload]: ContentSource.userUpload,
    [GenericContentSource.adobeStockPreview]: ContentSource.stock
});