/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

export enum ELStockWorkflowActions {
    showStockPanel = "SHOW_STOCK_PANEL",
    applyStockLicensedImage = "APPLY_STOCK_LICENSED_IMAGE",
    stockThumbnailClicked = "STOCK_THUMBNAIL_CLICKED",
    stockSearchTriggered = "STOCK_SEARCH_TRIGGERED",
    stockBackButtonClicked = "STOCK_BACK_BUTTON_CLICKED",
    discardLicense = "DISCARD_LICENSE",
    licenseForFree = "LICENSE_FOR_FREE",
    stockImageClicked = "STOCK_IMAGE_CLICKED",
    setSelectedStockId = "SET_SELECTED_STOCK_ID",
    stockDialogLicenseForFree = "STOCK_DIALOG_LICENSE_FOR_FREE",
    updateActionRegionState = "UPDATE_ACTION_REGION_STATE"
}

export interface StockImageData {
    id: string,
    title: string,
    premium_level_id: string,
    thumbnail_url: string
}

export interface StockImagesResponse {
    nb_results: number,
    files: StockImageData[]
}

export interface ELStockThumbData {
    id: string,
    thumbnailUrl: string
}

export interface StockImagePurchaseDetails {
    state: string,
    license: string,
    date: string
}

export interface StockImageContentDetails {
    content_id: string,
    size: string,
    purchase_details: StockImagePurchaseDetails
}

export interface StockImageLicenseInfo {
    member: Record<string, string>,
    contents: Record<string, StockImageContentDetails>
}

export enum AdobeStockActionTypes {
    UPDATE_SELECTED_STOCK_THUMBNAIL = "UPDATE_SELECTED_STOCK_THUMBNAIL",
    UPDATE_SELECTED_STOCK_IDS = "UPDATE_SELECTED_STOCK_IDS"
}

export enum StockDialogTriggerSource {
    SAVE = "SAVE",
    DOWNLOAD = "DOWNLOAD",
    DEEPLINK = "DEEPLINK"
}