/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2025 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

// Third party
import React from "react";

import "./ELSwatch.scss";

interface ELSwatchProps {
    imageSrc: string;
    alt: string;
    onClick: () => void;
    isSelected: boolean;
    size: "S" | "M" | "L";
    dataTestId?: string;
}

const ELSwatch = (props: ELSwatchProps): React.ReactElement => {
    const getSizeStyle = (size: "S" | "M" | "L"): React.CSSProperties => {
        const sizeMap = {
            S: { width: "2rem", height: "2rem" },
            M: { width: "3rem", height: "3rem" },
            L: { width: "4rem", height: "4rem" }
        };
        return sizeMap[size];
    };

    const getClassName = (): string => {
        return `${props.isSelected ? "el-swatch--selected" : "el-swatch"}`;
    }

    const sizeStyle = getSizeStyle(props.size || "M");

    return (
        <div className={getClassName()} onClick={props.onClick} data-testid={props.dataTestId} role="button" style={sizeStyle}>
            <img src={props.imageSrc} alt={props.alt} className="el-swatch__image" />
        </div>
    );
};

ELSwatch.defaultProps = {
    alt: "",
    isSelected: false,
    size: "M"
}

export default ELSwatch;