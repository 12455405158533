/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Adobe Internal
import { ContentType } from "@elements/elementswebcommon";

//Application Specific
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import ELRecommendationWorkflowThumbPanel from "../../../../view/components/templates/el-overlay-panel/ELRecommendationWorkflowThumbPanel";
import ELPanelManager, { ELPanelManagerConfig } from "../../../../view/components/templates/el-panel-manager/ELPanelManager";
import IPanelProvider from "../../../common/IPanelProvider";
import { ELTabPanelKey, ELTabPanelType, ITabPanelItem, TabPanelButtonTypes } from "../../../../common/interfaces/tabpanel/ELTabPanelTypes";
import { IconType } from "../../../../assets/IconConstants";
import { ELSingleImageMediaManager } from "../../../../view/components/templates/el-single-image-media-manager/ELSingleImageMediaManager";
import { FeaturesManager } from "../../../../modules/floodgate/Featuresmanager";
import { FeatureName } from "../../../../services/Floodgate/FloodgateConstants";
import { ELAdobeStockPanel } from "../../../../view/components/templates/el-adobe-stock-content-panel/ELAdobeStockPanel";
import ELBackgroundContentPanel from "../../../../view/components/templates/el-background-content-panel/ELBackgroundContentPanel";
import ElementsContentService from "../../../../services/ElementsServices/ElementsContentService";

export default class ELReplaceBackgroundPanelProvider extends IPanelProvider {
    private _getLeftTabPanel(): ELPanelManager {
        const intlHandler = IntlHandler.getInstance();
        const thumbPanelHeadingText = intlHandler.formatMessage("replace-background-presets");
        const replaceBackgroundPanel = new ELRecommendationWorkflowThumbPanel(this.owner, ContentType.replaceBackground, thumbPanelHeadingText);

        const listOfTabItems: ITabPanelItem[] = [{
            buttonInfo: {
                iconType: IconType.replaceBackgroundTab,
                name: intlHandler.formatMessage("presets"),
                buttonType: TabPanelButtonTypes.scoopedTabButton
            },
            key: ELTabPanelKey.overlays,
            controller: replaceBackgroundPanel,
            view: replaceBackgroundPanel.getView()
        }
        ];

        const config: ELPanelManagerConfig = {
            selectedKey: ELTabPanelKey.overlays,
            panelItemDirection: "row"
        };

        return new ELPanelManager(this.owner, listOfTabItems, config);
    }

    private _getRightTabPanel(): ELPanelManager {
        const intlHandler = IntlHandler.getInstance();
        const mediaManager = new ELSingleImageMediaManager(this.owner);
        const replaceBackgroundContentPanel = new ELBackgroundContentPanel(this.owner, ElementsContentService.getInstance());
        const adobeStockPanel = new ELAdobeStockPanel(this.owner);

        const listOfTabItems: ITabPanelItem[] = [{
            buttonInfo: {
                iconType: IconType.mediaManagerTab,
                name: intlHandler.formatMessage("media"),
                buttonType: TabPanelButtonTypes.scoopedTabButton
            },
            key: ELTabPanelKey.media,
            controller: mediaManager,
            view: mediaManager.getView("recommendationWorkflowReducer")
        }, {
            buttonInfo: {
                iconType: IconType.background,
                name: intlHandler.formatMessage("basic"),
                buttonType: TabPanelButtonTypes.scoopedTabButton
            },
            key: ELTabPanelKey.background,
            controller: replaceBackgroundContentPanel,
            view: replaceBackgroundContentPanel.getView({
                panelHeader: intlHandler.formatMessage("background"),
                showNoneOption: true
            })
        }
        ];

        this._shouldShowAdobeStockPanel() && listOfTabItems.push({
            buttonInfo: {
                iconType: IconType.adobeStock,
                name: intlHandler.formatMessage("adobe-stock"),
                buttonType: TabPanelButtonTypes.scoopedTabButton
            },
            key: ELTabPanelKey.adobeStock,
            controller: adobeStockPanel,
            view: adobeStockPanel.getView()
        });

        const config: ELPanelManagerConfig = {
            selectedKey: FeaturesManager.getInstance().IsFeatureActive(FeatureName.eStockInAutoBackground) ? ELTabPanelKey.background : ELTabPanelKey.empty,
            panelItemDirection: "row-reverse"
        };

        return new ELPanelManager(this.owner, listOfTabItems, config);
    }

    getTabPanel(panelType: ELTabPanelType): ELPanelManager {
        if (panelType === ELTabPanelType.leftTabPanel) {
            return this._getLeftTabPanel();
        } else if (panelType === ELTabPanelType.rightTabPanel) {
            return this._getRightTabPanel();
        } else {
            throw new Error("ELReplaceBackgroundPanelProvider::getTabPanel panelType not valid");
        }
    }

    private _shouldShowAdobeStockPanel(): boolean {
        return FeaturesManager.getInstance().IsFeatureActive(FeatureName.eStockInAutoBackground);
    }
}