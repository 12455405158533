/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import Logger, { LogLevel } from "../utils/Logger";
import IMS from "./IMS";
import { StockImageLicenseInfo, StockImagesResponse } from "../common/interfaces/services/StockServiceTypes";
import StockServiceConstants from "../utils/Constants/StockServiceConstants";
import { API } from "./APIServiceWrapper";

const ELEMENTS_API_KEY = process.env.REACT_APP_STOCK_SERVICE_API_KEY;
const ELEMENTS_PRODUCT_NAME = process.env.REACT_APP_PRODUCT_NAME;

export class ElementsStockServiceWrapper {
    private static _singletonInstance: ElementsStockServiceWrapper | null = null;
    private _serviceAPI: API;

    static getInstance(): ElementsStockServiceWrapper {
        if (ElementsStockServiceWrapper._singletonInstance !== null) return ElementsStockServiceWrapper._singletonInstance;
        ElementsStockServiceWrapper._singletonInstance = new ElementsStockServiceWrapper();
        return ElementsStockServiceWrapper._singletonInstance;
    }

    private constructor() {
        if (process.env.REACT_APP_STOCK_SERVICE_URL === undefined) {
            throw new Error("Stock Service URL not found in env");
        }

        if (ELEMENTS_API_KEY === undefined) {
            throw new Error("API Key is not set");
        }

        if (ELEMENTS_PRODUCT_NAME === undefined) {
            throw new Error("Product name is not set");
        }

        const axiosRetryConfig = {
            retries: 3,
            retryCondition: () => true,
            retryDelay: (retryCount: unknown) => {
                const count = retryCount as number;
                return Math.min(1000 * Math.pow(2, count), 10000);
            },
            onRetry: (retryCount: unknown, error: unknown) => {
                const count = retryCount as number;
                // Changing it to ERROR from WARN to monitor it using New Relic for some time
                // @vmiddha Please change it back to WARN after monitoring is done.
                Logger.log(LogLevel.ERROR, `Retry attempt ${count} for Stock API due to error:`, error);
            },
        };

        this._serviceAPI = new API(`${process.env.REACT_APP_STOCK_SERVICE_URL}`, undefined, axiosRetryConfig);
    }

    private _getHeaders(): Record<string, string> {
        return {
            "x-api-key": ELEMENTS_API_KEY || "",
            "x-product": ELEMENTS_PRODUCT_NAME || "",
            "Authorization": `Bearer ${IMS.getInstance().getUserAccessToken()}`
        };
    }

    private getStockSearchServiceAPIConfig(searchQuery: string, locale: string, offset: number, limit: number): any {
        return {
            params: {
                "search_parameters[words]": searchQuery,
                "search_parameters[filters][content_type:photo]": "1",
                "locale": locale,
                "search_parameters[filters][free]": 'true',
                "search_parameters[filters][orientation]": "horizontal",
                "search_parameters[offset]": offset,
                "search_parameters[limit]": limit,
                "result_columns": [
                    "id",
                    "title",
                    "premium_level_id",
                    "thumbnail_url",
                    "nb_results"
                ]
            },
            headers: this._getHeaders()
        }
    }

    private getStockLicenseAPIConfig(imageId: string): any {
        return {
            params: {
                content_id: imageId,
                license: StockServiceConstants.STOCK_STANDARD_LICENSE
            },
            headers: this._getHeaders()
        }
    }

    private getStockDownloadAPIConfig(imageSize: number): any {
        return {
            params: {
                size: imageSize
            },
            headers: this._getHeaders()
        }
    }

    async getStockImagesData(searchQuery: string, locale: string, offset: number, limit: number): Promise<StockImagesResponse> {
        try {
            Logger.log(LogLevel.INFO, `Fetching stock images for query: ${searchQuery}`);
            const dirResult = await this._serviceAPI.get(`${StockServiceConstants.STOCK_SEARCH_SERVICE_ENDPOINT}`, this.getStockSearchServiceAPIConfig(searchQuery, locale, offset, limit))
                .then((response) => {
                    Logger.log(LogLevel.INFO, `Successfully fetched stock images for query: ${searchQuery}`);
                    return response.data as unknown as StockImagesResponse;
                });
            return Promise.resolve(dirResult);
        }
        catch (error) {
            Logger.log(LogLevel.ERROR, "ElementsStockServiceWrapper: getStockImagesData", error);
            return Promise.reject(error);
        }
    }

    async getStockImageLicenseInfo(imageId: string): Promise<StockImageLicenseInfo> {
        try {
            Logger.log(LogLevel.INFO, `Fetching stock image license info for imageId: ${imageId}`);
            const response = await this._serviceAPI.get(`${StockServiceConstants.STOCK_LICENSE_INFO_ENDPOINT}`, this.getStockLicenseAPIConfig(imageId));
            Logger.log(LogLevel.INFO, `Successfully fecthed stock image license info for imageId: ${imageId}`);
            return Promise.resolve(response.data as unknown as StockImageLicenseInfo);
        }
        catch (error) {
            Logger.log(LogLevel.ERROR, "ElementsStockServiceWrapper: getStockImageLicenseInfo", error);
            return Promise.reject(error);
        }
    }

    async acquireStockImageLicense(imageId: string): Promise<any> {
        try {
            Logger.log(LogLevel.INFO, `Acquiring stock image license for imageId: ${imageId}`);
            const response = await this._serviceAPI.get(`${StockServiceConstants.STOCK_ACQUIRE_LICENSE_ENDPOINT}`, this.getStockLicenseAPIConfig(imageId));
            Logger.log(LogLevel.INFO, `Successfully acquired stock image license for imageId: ${imageId}`);
            return Promise.resolve(response);
        }
        catch (error) {
            Logger.log(LogLevel.ERROR, "ElementsStockServiceWrapper: acquireStockImageLicense", error);
            return Promise.reject(error);
        }
    }

    async downloadStockImage(imageId: string, imageSize: number): Promise<any> {
        try {
            Logger.log(LogLevel.INFO, `Downloading stock image for imageId: ${imageId}`);
            const response = await this._serviceAPI.get(`${StockServiceConstants.STOCK_DOWNLOAD_IMAGE_ENDPOINT}/${imageId}/1`, {
                ...this.getStockDownloadAPIConfig(imageSize),
                responseType: 'blob'
            });
            Logger.log(LogLevel.INFO, `Successfully downloaded stock image for imageId: ${imageId}`);
            return Promise.resolve(response.data);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ElementsStockServiceWrapper: downloadStockImage", error);
            return Promise.reject(error);
        }
    }
}