/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React from "react";

//Adobe Internal
import { Divider, Flex, Text } from '@adobe/react-spectrum';
import { StyleProps } from "@react-types/shared";
import { usePress } from "@react-aria/interactions";

//Application Specific
import { AssetMimeType } from "../../../../common/interfaces/storage/AssetTypes";

import "./ELCreateOnDemandThumb.scss";

export interface ELThumbContentProps extends StyleProps {
    thumbId: unknown,
    previewSrc: string,
    previewType: AssetMimeType,
    headerText: string,
    descriptiveText: string,
    dataTestId: string,
    onPress?: ((thumbId: unknown) => void),
    footer?: React.ReactElement,
    isHighlighted?: boolean,
    altText?: string
}

const ELCreateOnDemandThumbView = (props: ELThumbContentProps): React.ReactElement => {
        const { pressProps } = usePress({
        onPress: () => {
            if (props.onPress)
                props.onPress(props.thumbId);
        }
    });

    const getFooter = (): React.ReactElement => {
        return (
            props.footer ?
                <>
                    <Divider size="S" UNSAFE_className="create-on-demand-box__divider"></Divider>
                    {props.footer}
                </> : <></>
        );
    }

    const getCardBody = (): React.ReactElement => {
        return (
            <Flex direction="column" UNSAFE_className="create-on-demand-box__body">
                <Text UNSAFE_className="create-on-demand-box__heading">{props.headerText}</Text>
                <div title={props.descriptiveText}>
                    <Text UNSAFE_className="create-on-demand-box__description">{props.descriptiveText}</Text>
                </div>
            </Flex>
        )
    }

    const getClassName = (): string => {
        return props.isHighlighted ? "create-on-demand-box__highlight" : "create-on-demand-box";
    }

    return (
        <div data-testid={props.dataTestId} {...pressProps}>
            <Flex UNSAFE_className={getClassName()} width={props.width} height={props.height} UNSAFE_style={{"cursor": props.onPress ? "pointer" : "initial"}}>
                {props.previewType === AssetMimeType.video &&
                    <video crossOrigin="anonymous" onContextMenu={(e) => e.preventDefault()} className="create-on-demand-box-media" src={props.previewSrc} playsInline loop autoPlay muted />}
                {props.previewType === AssetMimeType.image &&
                    <img crossOrigin="anonymous" onContextMenu={(e) => e.preventDefault()} className="create-on-demand-box-media" alt={props.altText || "feature-data"} src={props.previewSrc} />}
                <Flex UNSAFE_className="create-on-demand-box__content">
                    {getCardBody()}
                    {getFooter()}
                </Flex>
            </Flex>
        </div>
    )
}

export default ELCreateOnDemandThumbView;
