/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import IMS from "../../../../services/IMS";
import React, { useEffect, useReducer, useState } from "react";
import { useSelector } from "react-redux";

//Adobe Internal
import { Flex, Button } from "@adobe/react-spectrum";

//Application Specific
import IViewController from "../../../IViewController";
import { ViewAction } from "../../../IBaseController";
import { ELUserHomeBannerActions, ELUserHomeBannerViewActions } from "../../../../common/interfaces/home/UserHomeTypes";
import { RootState } from "../../../../stores/store";
import { ELIframe } from "../../atoms/el-iframe/ELIframe";
import { AssetMimeType } from "../../../../common/interfaces/storage/AssetTypes";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { PreviewConfig } from "../../../../common/interfaces/home/PreviewTypes";
import ELInteractiveBannerView from "../../organism/el-interactive-banner/ELInteractiveBannerView";
import { useViewport } from "../../../../utils/hooks/useViewport";
import { PlatformUtils } from "../../../../utils/PlatformUtils";

import "./ELUserHomeBannerView.scss";

interface ELUserHomeBannerProps {
    controller: IViewController,
    previewType?: AssetMimeType,
    previewSrc?: string,
    defaultBannerSrc: string,
    allEventsMessageHandler: (event: MessageEvent<any>) => any,
    loadEventMessageHandler: (event: MessageEvent<any>) => any,
    previewConfig: PreviewConfig
}

interface ELUserHomeBannerState {
    src: string,
    bannerLoadComplete: boolean
}

const initialState: ELUserHomeBannerState = {
    src: "",
    bannerLoadComplete: false
}

enum Banner {
    duplicateBanner = "DUPLICATE_BANNER",
    originalBanner = "ORIGINAL_BANNER"
}

export const ANIMATION_DURATION_IN_MS = 1000;

const ELUserHomeBannerView = (props: ELUserHomeBannerProps): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();
    const { width, height } = useViewport();
    const isAHandheldDevice = PlatformUtils.isAHandheldDevice((width as number), (height as number)) as boolean;
    const daysInTrial = useSelector((state: RootState) => state.appReducer.daysInTrial);
    const [bannerSrc, setBannerSrc] = useState("");
    const [frontBanner, setFrontBanner] = useState<HTMLElement | null>(null);

    const reducer = (state: ELUserHomeBannerState, action: ViewAction): ELUserHomeBannerState => {
        switch (action.type) {
            case ELUserHomeBannerViewActions.updateBannerSrc: {
                const payload = action.payload as string;
                return (
                    {
                        ...state,
                        src: payload
                    }
                );
            }
            case ELUserHomeBannerViewActions.bannerLoadComplete: {
                const payload = action.payload as boolean;
                return (
                    {
                        ...state,
                        bannerLoadComplete: payload
                    }
                );
            }
            default:
                return state;
        }
    }
    const [state, viewDispatch] = useReducer(reducer, initialState);

    const bringBannerToFront = (banner: Banner): void => {
        const duplicateBanner = document.getElementById("duplicate-banner");
        const originalBanner = document.getElementById("original-banner");
        if (duplicateBanner && originalBanner) {
            duplicateBanner.style.opacity = banner === Banner.duplicateBanner ? "1" : "0";
            originalBanner.style.opacity = banner === Banner.originalBanner ? "1" : "0";
            setFrontBanner(banner === Banner.duplicateBanner ? duplicateBanner : originalBanner);
        }
    }

    const handleTransitionEnd = (): void => {
        bringBannerToFront(Banner.originalBanner);
        setBannerSrc(state.src);
    }

    const transitionBetweenBanners = (): void => {
        const originalBanner = document.getElementById("original-banner");
        if (originalBanner) {
            const animation = originalBanner.animate({
                opacity: [0, 1]
            }, {
                duration: ANIMATION_DURATION_IN_MS
            });

            if (animation) {
                animation.onfinish = () => {
                    handleTransitionEnd();
                }
            }
        }
    }

    const getMessageHandler = (canHandleEvents: boolean): (((event: MessageEvent<any>) => any) | undefined) => {
        return canHandleEvents ? props.allEventsMessageHandler : props.loadEventMessageHandler;
    }

    const isFrontBanner = (id: string): boolean => {
        const banner = document.getElementById(id);
        return frontBanner === banner;
    }

    useEffect(() => {
        props.controller.initialize(viewDispatch);
        return () => {
            props.controller.destroy();
        }
    }, [props.controller]);

    useEffect(() => {
        props.controller.notify({ type: ELUserHomeBannerActions.refreshBannerSrc, payload: daysInTrial });
    }, [daysInTrial]);

    useEffect(() => {
        if (bannerSrc !== state.src && !state.bannerLoadComplete) {
            bringBannerToFront(Banner.duplicateBanner);
        }
        if (bannerSrc !== state.src && state.bannerLoadComplete) {
            transitionBetweenBanners();
        }
    }, [state.src, state.bannerLoadComplete]);

    const getDuplicateBanner = (): React.ReactElement => {
        return (
            bannerSrc ?
                <ELIframe allowFullScreen scrolling="no" key={"duplicate" + bannerSrc} src={bannerSrc} title="user-home-banner" className="user-home-banner" messageHandler={getMessageHandler(isFrontBanner("duplicate-banner"))} id="duplicate-banner" data-testid="duplicate-banner" />
                : <div key={"duplicate" + bannerSrc} style={{ backgroundImage: `url(${props.defaultBannerSrc})`, opacity: "1" }} className="user-home-banner user-home-banner__img" id="duplicate-banner" data-testid="default-banner" />
        )
    }

    const getOriginalBanner = (): React.ReactElement => {
        return (
            state.src ?
                <ELIframe allowFullScreen scrolling="no" key={"original" + state.src} src={state.src} title="user-home-banner" className="user-home-banner" messageHandler={getMessageHandler(isFrontBanner("original-banner"))} id="original-banner" data-testid="original-banner" />
                : <></>
        );
    }

    const getSignedOutViewBanner = (): React.ReactElement => {
        return (
            <Flex UNSAFE_className="moving-banner-container" data-testid="signed-out-moving-banner">
                {props.previewType === AssetMimeType.video &&
                    <video crossOrigin="anonymous" onContextMenu={(e) => e.preventDefault()} className="moving-banner-container__video-banner" src={props.previewSrc} playsInline loop autoPlay muted />
                }
                <div className="moving-banner-container__overlay">
                    <div className="moving-banner-container__text-content">
                        <div className="moving-banner-container__banner-heading">{intlHandler.formatMessage("home-welcome-message")}</div>
                        <div className="moving-banner-container__product-text">{intlHandler.formatMessage("home-product-text")}</div>
                        <div className="moving-banner-container__banner-content">{intlHandler.formatMessage("create-photo-video-banner-text")}</div><br />
                        <Button variant="cta" onPress={() => props.controller.notify({ type: ELUserHomeBannerActions.getStarted })} UNSAFE_className="moving-banner-container__banner-cta">{intlHandler.formatMessage("user-get-started")}</Button>
                    </div>
                </div>
                <div className="moving-banner-container__creation-tag">
                    {intlHandler.formatMessage("moving-overlay-creation-tag")}
                </div>
            </Flex>
        )
    }

    const getInteractiveBannerView = (): React.ReactElement => {
        return <ELInteractiveBannerView controller={props.controller} previewConfig={props.previewConfig} heading={intlHandler.formatMessage("home-welcome-message")} subHeading={intlHandler.formatMessage("home-product-text")}
            descriptionText={intlHandler.formatMessage("create-photo-video-banner-text")} buttonText={intlHandler.formatMessage("user-get-started")} />;
    }

    const userHomeBannerView = (): React.ReactElement => {
        if (!IMS.getInstance().isSignedInUser()) {
            if (!isAHandheldDevice) {
                return getInteractiveBannerView();
            } else {
                return (
                    <div data-testid="user-moving-home-banner-container">
                        {getSignedOutViewBanner()}
                    </div>
                );
            }
        } else {
            return (
                <Flex UNSAFE_className="home-banner-container">
                    <div data-testid="user-home-banner-container">
                        {getDuplicateBanner()}
                        {getOriginalBanner()}
                    </div>
                </Flex>
            );
        }
    }
    return userHomeBannerView();
}

export default ELUserHomeBannerView;