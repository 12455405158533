/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import ImageUtils from "../../utils/ImageUtils";
import { ContentDataType, ContentSource, MagicalBackdropCreationOperationGraphics } from "@elements/elementswebcommon";
import { ContentDataManager } from "./ContentDataManager";
import { ELStageLayerData, ELLayerKind } from "../../common/interfaces/editing/layer/ELStageLayerTypes";
import { BackgroundLayerRequestParams } from "./ContentDataManagerTypes";
import { ELCreationContentPayload } from "../../common/interfaces/creations/ELRecommendationsWorkflowTypes";
import { CollageBackgroundData } from "../../common/interfaces/creations/ElementsContentTypes";

export class GraphicsDataManager extends ContentDataManager {

    async getBackgroundContentData(contentPayload: ELCreationContentPayload): Promise<MagicalBackdropCreationOperationGraphics> {
        if (contentPayload.contentData === undefined) {
            return Promise.reject("No content data provided");
        }
        const contentData = contentPayload.contentData as CollageBackgroundData;

        const graphicsData = new MagicalBackdropCreationOperationGraphics();
        graphicsData.graphicContentData = {
            contentUrl: contentData.contentUrl,
            id: contentData.id,
        };
        graphicsData.name = contentData.name;

        return graphicsData;
    }

    getContentSource(): ContentSource | undefined {
        return undefined;
    }

    getContentDataType(): ContentDataType | undefined {
        return ContentDataType.graphics;
    }

    public async getContentLayerData(contentPayload: ELCreationContentPayload): Promise<ELStageLayerData> {
        const contentData = await this.getUpdateContentData(contentPayload) as ImageData;
        return {
            data: contentData,
            fitToBackground: true,
            layerKind: ELLayerKind.pixel
        };
    }

    public async getUpdateContentData(contentPayload: ELCreationContentPayload): Promise<unknown> {
        if (contentPayload.contentData === undefined) {
            throw new Error("GraphicsDataManager: getUpdateContentData: No content data provided");
        }
        const backgroundData = contentPayload.contentData as CollageBackgroundData;

        return ImageUtils.createImageData(backgroundData.contentUrl);
    }

    async getBackgroundLayerPayload(backgroundLayerPayloadRequestParams: BackgroundLayerRequestParams): Promise<ELStageLayerData> {
        if (backgroundLayerPayloadRequestParams.backgroundContentData === undefined) {
            throw new Error("GraphicsDataManager: getBackgroundLayerPayload: No content data provided");
        }
        const imageData = await this.getUpdateContentData(backgroundLayerPayloadRequestParams.backgroundContentData) as ImageData;

        const backgroundLayerPayload: ELStageLayerData = backgroundLayerPayloadRequestParams.layerDataOptionsList ?
            {
                data: imageData,
                ...backgroundLayerPayloadRequestParams.layerDataOptionsList[1]
            } :
            {
                data: imageData,
                fitToBackground: true,
                layerKind: ELLayerKind.pixel
            };

        return Promise.resolve(backgroundLayerPayload);
    }

}