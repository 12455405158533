/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Adobe Internal
import { IBaseInfo, OutputType, Resolution } from "@elements/elementswebcommon";

//Application Specific
import { IClientCreationRequestParams } from "./ELCreationsJobTypes";
import { ELAdobeAsset } from "../storage/AssetTypes";
import { GenericContentDataType, GenericContentSource } from "../../../modules/contenDatatManager/ContentDataManagerTypes";
import { StockDialogTriggerSource } from "../services/StockServiceTypes";

export interface ELReplaceBackgroundCreationRequestParams extends IClientCreationRequestParams {
    backgroundId: string[],
    outputTypes: OutputType[],
    contentResolution: Resolution,
    contentDataType: GenericContentDataType,
    contentData?: IBaseInfo,
    contentSource?: GenericContentSource,
}

export enum ELReplaceBackgroundProjectActionMode {
    regular = "regular",
    openInDesktop = "openInDesktop"
}

export interface ELReplaceBackgroundUpdateDocumentPayload {
    mapOfTemplateIdToAsset: Map<string, ELAdobeAsset>,
    overlayId: string,
}

export enum LayerOperation {
    REMOVE_LAYER = "removeLayer",
    REPLACE_LAYER = "replaceLayer",
    UPDATE_LAYER = "updateLayer",
    ADD_LAYER = "addLayer",
}

export interface DialogContext {
    showAdobeStockLicenseDialog: boolean,
    triggerSource?: StockDialogTriggerSource,
    data?: unknown
}

const transparentPreviousOperations = new Map<GenericContentDataType, LayerOperation>([
    [GenericContentDataType.http, LayerOperation.REMOVE_LAYER],
    [GenericContentDataType.userDefined, LayerOperation.REMOVE_LAYER],
    [GenericContentDataType.recommendation, LayerOperation.REMOVE_LAYER],
    [GenericContentDataType.graphics, LayerOperation.REMOVE_LAYER],
]);

const httpPreviousOperations = new Map<GenericContentDataType, LayerOperation>([
    [GenericContentDataType.transparent, LayerOperation.ADD_LAYER],
    [GenericContentDataType.http, LayerOperation.UPDATE_LAYER],
    [GenericContentDataType.userDefined, LayerOperation.REPLACE_LAYER],
    [GenericContentDataType.recommendation, LayerOperation.UPDATE_LAYER],
    [GenericContentDataType.graphics, LayerOperation.UPDATE_LAYER],
]);

const recommendationPreviousOperations = httpPreviousOperations;
const graphicsPreviousOperations = httpPreviousOperations;

const userDefinedPreviousOperations = new Map<GenericContentDataType, LayerOperation>([
    [GenericContentDataType.transparent, LayerOperation.ADD_LAYER],
    [GenericContentDataType.http, LayerOperation.REPLACE_LAYER],
    [GenericContentDataType.userDefined, LayerOperation.UPDATE_LAYER],
    [GenericContentDataType.recommendation, LayerOperation.REPLACE_LAYER],
    [GenericContentDataType.graphics, LayerOperation.REPLACE_LAYER],
]);

export const LayerOperationsForCurrentType: Map<GenericContentDataType, Map<GenericContentDataType, LayerOperation>> = new Map([
    [GenericContentDataType.transparent, transparentPreviousOperations],
    [GenericContentDataType.http, httpPreviousOperations],
    [GenericContentDataType.userDefined, userDefinedPreviousOperations],
    [GenericContentDataType.recommendation, recommendationPreviousOperations],
    [GenericContentDataType.graphics, graphicsPreviousOperations],
]);