/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { RenderedShapesName } from "../../../common/interfaces/renderer/RendererTypes";
import { ELStageImageObject, ELStageObject, ELStageObjectOptions, ELStageShapesType } from "../../../common/interfaces/stage/StageTypes";
import Logger, { LogLevel } from "../../../utils/Logger";
import IGraphicsStage from "../IGraphicsStage";
import IStageShapes from "./IStageShapes";

export default class ELStageImageFromURI extends IStageShapes {
    private _clipPath?: ELStageObject;

    constructor() {
        super(ELStageShapesType.imageFromURI);
    }

    isErrorInDrawing(): boolean {
        if (!this.object)
            return false;
        return (this.object.width === 0 || this.object.height === 0);
    }

    async drawOrRemoveError<T extends ELStageObjectOptions>(stage: IGraphicsStage, options: T): Promise<void> {
        if (this.isErrorInDrawing() && this.errorShapeAndOptionsList.length !== 0) {
            if (this.errorObject) {
                Logger.log(LogLevel.INFO, "ELStageImageFromURI - (drawOrRemoveError)", "erroObject already present");
                return;
            }

            const errorObjectList: ELStageObject[] = [];
            const subTargetList: ELStageObject[] = [];

            for (const shapeAndOptions of this.errorShapeAndOptionsList) {
                const errorObject = await shapeAndOptions.shape.draw(stage, shapeAndOptions.objectOptions);
                errorObjectList.push(errorObject);

                if (shapeAndOptions.objectOptions.isSubTarget) {
                    subTargetList.push(errorObject);
                }
            }

            this.errorObject = await stage.addToGroup(errorObjectList, {
                ...options,
                fitToClipShape: true,
                clipPath: this._clipPath,
                addToStage: true,
                selectable: false,
                name: RenderedShapesName.imageLoadError,
                subTargets: errorObjectList.filter((obj) => {
                    return subTargetList.includes(obj) === true;
                })
            });
        } else {
            this.removeError(stage);
        }
    }

    async update<T extends ELStageObjectOptions>(stage: IGraphicsStage, options: T): Promise<ELStageObject> {
        this.object = await stage.replaceImage(this.object as ELStageImageObject, options);
        return this.object;
    }

    async draw<T extends ELStageObjectOptions>(stage: IGraphicsStage, options: T): Promise<ELStageObject> {
        this._clipPath = options.clipPath;
        this.object = await stage.addImageFromURI(options.image as string, options);
        return this.object;
    }
}
