/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2025 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React from "react";

//Adobe Internal
import { Content, Dialog, DialogContainer, Divider, Flex, Heading, Provider, defaultTheme } from "@adobe/react-spectrum";

//Application Specific
import ELButton from "../../atoms/el-button/ELButtonView";

//CSS
import "./ELStockLicenseDialog.scss";

export interface ELStockLicenseDialogProps {
    heading: string,
    headingDescription: string,
    dialogDescription: string,
    discardButtonText: string,
    licenseButtonText: string,
    onDismiss: () => void,
    onLicenseForFree: () => void,
    onDiscard: () => void,
}

const ELStockLicenseDialog = (props: ELStockLicenseDialogProps): React.ReactElement => {


    const renderDialogHeader = (): React.ReactElement => (
        <>
            <Heading>
                <div data-testid="stock-license-dialog-heading" className="stock-license-dialog__heading">
                    {props.heading}
                </div>
            </Heading>
            <div data-testid="stock-license-dialog-heading-description" className="stock-license-dialog__heading-description">
                {props.headingDescription}
            </div>
        </>
    );

    const renderDialogContent = (): React.ReactElement => (
        <div data-testid="stock-license-dialog-description" className="stock-license-dialog__description">
            {props.dialogDescription}
        </div>
    );

    const renderDialogActions = (): React.ReactElement => (
        <Flex gap="size-100" alignSelf="end">
            <ELButton data-testid="stock-license-dialog-dont-save-button" variant="primary" onClick={props.onDiscard}>
                <div className="stock-license-dialog__button-text">
                    {props.discardButtonText}
                </div>
            </ELButton>
            <ELButton data-testid="stock-license-dialog-save-button" variant="cta" onClick={props.onLicenseForFree}>
                <div className="stock-license-dialog__button-text">
                    {props.licenseButtonText}
                </div>
            </ELButton>
        </Flex>
    );

    return (
        <Provider theme={defaultTheme} colorScheme="light">
            <DialogContainer onDismiss={props.onDismiss} isDismissable>
                <Dialog data-testid="stock-license-dialog" UNSAFE_className="stock-license-dialog__container">
                    <Content>
                        {renderDialogHeader()}
                        <Divider data-testid="stock-license-dialog-divider" size="S" margin={"4px 0px 4px 0px"} />
                        <Flex gap="size-500" direction="column" alignItems={"center"}>
                            {renderDialogContent()}
                            <Divider data-testid="stock-license-dialog-inner-divider" size="S" />
                            {renderDialogActions()}
                        </Flex>
                    </Content>
                </Dialog>
            </DialogContainer>
        </Provider>
    );
};

export default ELStockLicenseDialog;