/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import _ from "lodash";
import React, { ReactNode, useEffect, useReducer, useRef } from "react";

//adobe internal
import { Grid, repeat } from "@adobe/react-spectrum";
import { usePress } from "@react-aria/interactions";

//Application Specific
import { ViewAction } from "../../../IBaseController";
import ELPanelHeader from "../../atoms/el-panel-header/ELPanelHeader";
import ELScrollPanel from "../../atoms/el-scroll-panel/ELScrollPanel";
import ELColorPickerView from "../../organism/el-color-picker/ELColorPickerView";
import { ELTransparentCard } from "../../organism/el-transparent-card/ELTransparentCard";
import { ASSETS_ENDPOINT, CollageBackgroundData } from "../../../../common/interfaces/creations/ElementsContentTypes";
import IViewController from "../../../IViewController";
import Logger, { LogLevel } from "../../../../utils/Logger";
import {
    ELBackgroundContentPanelControllerAction,
    ELBackgroundContentPanelViewAction
} from "../../../../common/interfaces/creations/templates/ELBackgroundContentPanelTypes";
import { useDynamicLayout } from "../../../../utils/hooks/useDynamicLayout";

import "./ELBackgroundContentPanel.scss";
import { TRANSPARENT_BACKGROUND_ID, TRANSPARENT_BACKGROUND_NAME } from "../../../../common/interfaces/creations/CreationTypes";
import { ELPreviewCreationThumbData } from "../../../../common/interfaces/creations/ELRecommendationsWorkflowTypes";

export interface ELBackgroundContentPanelViewProps {
    controller: IViewController,
    panelHeader: string,
    showNoneOption: boolean,
}

interface ELBackgroundContentPanelState {
    graphicBackgroundData: CollageBackgroundData[],
    selectedGraphicId: string,
    selectedColor: string,
    isTransparentSelected: boolean,
}

const initialState: ELBackgroundContentPanelState = {
    graphicBackgroundData: [],
    selectedGraphicId: "",
    selectedColor: "",
    isTransparentSelected: false,
}

interface GraphicBackgroundCardProps {
    id: string,
    name: string,
    thumbnailUrl: string,
    contentUrl: string,
    onPress: (id: string, contentUrl: string) => void,
    selectedGraphicId: string,
    width: number,
    height: number
}

const GraphicBackgroundCard = (props: GraphicBackgroundCardProps): React.ReactElement => {

    const { pressProps } = usePress({
        onPress: () => { props.onPress(props.id, props.contentUrl); }
    });
    
    return (
        <div data-testid="basic-background-card" {...pressProps} title={props.name} className={props.id === props.selectedGraphicId ? "basic-background-card-selected" : "basic-background-card"}
            style={{ width: props.width, height: props.height }}>
            <img crossOrigin="anonymous" className="basic-background-image" src={process.env.REACT_APP_ELEMENTS_URL + ASSETS_ENDPOINT + props.thumbnailUrl} alt={props.name} />
        </div>
    )
}

const COLOR_CHANGE_THROTTLE = 100;

const ELBackgroundContentPanelView = (props: ELBackgroundContentPanelViewProps): React.ReactElement => {
    const { controller, panelHeader, showNoneOption } = props;
    const specLayoutConfig = {
        TILE_W: 5,
        TILE_H: 5,
        DIST_BETWEEN_TILE: 1
    };
    const gridRef = useRef(null);
    const scrollParentRef = useRef(null);
    const viewLayoutConfig = useDynamicLayout({
        specLayoutConfig: specLayoutConfig,
        observerRef: scrollParentRef,
        gridRef: gridRef
    });

    const handleColorChange = _.debounce((color: string): void => {
        if (color !== state.selectedColor) {
            Logger.log(LogLevel.INFO, "Background color: ", color);
            viewDispatch({ type: ELBackgroundContentPanelViewAction.setSelectedColorValue, payload: color });
            controller.notify({ type: ELBackgroundContentPanelControllerAction.colorBackgroundChanged, payload: color });
        }
    }, COLOR_CHANGE_THROTTLE);

    const handleBackgroundChange = (id: string, contentUrl: string): void => {
        if (id !== state.selectedGraphicId) {
            Logger.log(LogLevel.INFO, "Background Content Link: ", id, process.env.REACT_APP_ELEMENTS_URL + ASSETS_ENDPOINT + contentUrl);
            const backgroundURL = process.env.REACT_APP_ELEMENTS_URL + ASSETS_ENDPOINT + contentUrl;
            const graphicData: CollageBackgroundData = { id: id, contentUrl: backgroundURL, name: "", thumbnailUrl: "" };
            viewDispatch({ type: ELBackgroundContentPanelViewAction.setSlectedGraphicId, payload: id });
            controller.notify({ type: ELBackgroundContentPanelControllerAction.imageBackgroundChanged, payload: graphicData });
        }
    }

    const reducer = (state: ELBackgroundContentPanelState, action: ViewAction): ELBackgroundContentPanelState => {
        switch (action.type) {
            case ELBackgroundContentPanelViewAction.graphicBackgroundData:
                return {
                    ...state,
                    graphicBackgroundData: action.payload as CollageBackgroundData[]
                };
            case ELBackgroundContentPanelViewAction.setSlectedGraphicId:
                return {
                    ...state,
                    selectedGraphicId: action.payload as string
                }
            case ELBackgroundContentPanelViewAction.setSelectedColorValue:
                return {
                    ...state,
                    selectedColor: action.payload as string
                }
            case ELBackgroundContentPanelViewAction.setIsTransparentSelected:
                return {
                    ...state,
                    isTransparentSelected: action.payload as boolean
                }
            default:
                return state;
        }
    }

    const [state, viewDispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        controller.initialize(viewDispatch);

        //clean up
        return () => {
            controller.destroy();
        }
    }, [controller]);

    const transparentCardClicked = (): void => {
        if (!state.isTransparentSelected) {
            const payload: ELPreviewCreationThumbData = { id: TRANSPARENT_BACKGROUND_ID, name: TRANSPARENT_BACKGROUND_NAME };
            controller.notify({ type: ELBackgroundContentPanelControllerAction.transparentClicked, payload : payload });
        }
    }

    const getBackgroundList = (): ReactNode => {
        return (
            state.graphicBackgroundData.map((item, index) =>
                <GraphicBackgroundCard key={index} id={item.id} name={item.name}
                    thumbnailUrl={item.thumbnailUrl} contentUrl={item.contentUrl} onPress={(id, contentUrl) => handleBackgroundChange(id, contentUrl)}
                    selectedGraphicId={state.selectedGraphicId} width={viewLayoutConfig.TILE_W} height={viewLayoutConfig.TILE_H} />
            )
        )
    }

    return (
        <div className="basic-panel-box">
            <ELPanelHeader title={panelHeader} alignTitle="start" />
            <ELScrollPanel forwardedRef={scrollParentRef} UNSAFE_className="basic-background-scroll" scrollY={true} width="100%">
                <div className="basic-panel__content-box">
                    <div ref={gridRef}>
                        <Grid marginTop={"1rem"} columns={repeat("auto-fit", viewLayoutConfig.TILE_W)}
                            justifyContent="center"
                            autoRows={viewLayoutConfig.TILE_W}
                            gap={viewLayoutConfig.DIST_BETWEEN_TILE}>
                            { showNoneOption && (
                                <ELTransparentCard width={viewLayoutConfig.TILE_W} height={viewLayoutConfig.TILE_H}
                                    onPress={() => transparentCardClicked()}
                                    selected={state.isTransparentSelected} />
                            )}
                            <ELColorPickerView width={viewLayoutConfig.TILE_W} height={viewLayoutConfig.TILE_H} color={state.selectedColor} showText={true} onChange={(e) => { handleColorChange(e.target.value); }} />
                        </Grid>
                        <div className="basic-panel__content-box-divider"></div>
                        <Grid marginTop={"1rem"} columns={repeat("auto-fit", viewLayoutConfig.TILE_W)}
                            justifyContent="center"
                            gap={viewLayoutConfig.DIST_BETWEEN_TILE}>
                            {getBackgroundList()}
                        </Grid>
                    </div>
                </div>
            </ELScrollPanel>
        </div>
    )
}

export default ELBackgroundContentPanelView;
