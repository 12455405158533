/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { ContentEntity, ContentType } from "@elements/elementswebcommon";
import { ELContentCacheDownloader } from "../../workspaces/creations/utils/ELContentCacheDownloader";
import ImageUtils from "../../utils/ImageUtils";
import { ContentDataType, ContentSource, IBaseInfo } from "@elements/elementswebcommon";
import { ContentDataManager } from "./ContentDataManager";
import { BackgroundLayerRequestParams } from "./ContentDataManagerTypes";
import { ELStageLayerData, ELLayerKind } from "../../common/interfaces/editing/layer/ELStageLayerTypes";
import { ELCreationContentPayload } from "../../common/interfaces/creations/ELRecommendationsWorkflowTypes";

//Currently we are returning undefined in every method as it is not used as of now but can be used in future if new functionality comes.
export class RecommendationDataManager extends ContentDataManager {

    async getBackgroundContentData(): Promise<IBaseInfo | undefined> {
        return Promise.resolve(undefined);
    }

    getContentSource(): ContentSource | undefined {
        return undefined;
    }

    getContentDataType(): ContentDataType | undefined {
        return undefined;
    }

    public async getContentLayerData(contentPayload: ELCreationContentPayload): Promise<ELStageLayerData> {
        const contentData = await this.getUpdateContentData(contentPayload) as ImageData;
        return {
            data: contentData,
            fitToBackground: true,
            layerKind: ELLayerKind.pixel
        };
    }

    public async getUpdateContentData(contentPayload: ELCreationContentPayload): Promise<unknown> {
        return this._getContentDataFromContentId(contentPayload.backgroundId);
    }

    async getBackgroundLayerPayload(backgroundLayerPayloadRequestParams: BackgroundLayerRequestParams): Promise<ELStageLayerData> {
        if (backgroundLayerPayloadRequestParams.backgroundContentData === undefined) {
            throw new Error("RecommendationDataManager: getBackgroundLayerPayload: Invalid contentPayload is passed");
        }
        const imageData = await this._getContentDataFromContentId(backgroundLayerPayloadRequestParams.backgroundContentData.backgroundId);

        const backgroundLayerPayload: ELStageLayerData = backgroundLayerPayloadRequestParams.layerDataOptionsList ?
            {
                data: imageData,
                ...backgroundLayerPayloadRequestParams.layerDataOptionsList[1]
            } :
            {
                data: imageData,
                fitToBackground: true,
                layerKind: ELLayerKind.pixel
            };

        return Promise.resolve(backgroundLayerPayload);
    }

    private async _getContentDataFromContentId(contentID?: string): Promise<ImageData> {
        const contentData = await ELContentCacheDownloader.getContentForContentType(ContentType.replaceBackground);
        const matchingContent = contentData.filter((content) => content.props.id === contentID)[0];
        if (matchingContent) {
            const imageData = (await this._getContentsData([matchingContent]))[0];
            return imageData;
        }
        return Promise.reject("RecommendationDataManager: _getContentDataFromContentId: contentID invalid");
    }

    private async _getContentsData(contentData: ContentEntity[]): Promise<ImageData[]> {
        const contentDataPromises = [];

        for (const content of contentData) {
            const contentURL = await content.getContentURL("");
            contentDataPromises.push(ImageUtils.createImageData(contentURL));
        }

        const contentsData = await Promise.all(contentDataPromises);

        return Promise.resolve(contentsData);
    }
}