/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

export enum ELBackgroundContentPanelControllerAction {
    imageBackgroundChanged = "IMAGE_BACKGROUND_CHANGED",
    colorBackgroundChanged = "COLOR_BACKGROUND_CHANGED",
    transparentClicked = "TRANSPARENT_CLICKED",
    setSelectedGraphicId = "SET_SELECTED_GRAPHIC_ID",
    setSelectedColorValue = "SET_SELECTED_COLOR_VALUE",
    setIsTransparentSelected = "SET_IS_TRANSPARENT_SELECTED",
}

export enum ELBackgroundContentPanelViewAction {
    graphicBackgroundData = "BACKGROUND_DATA",
    setSlectedGraphicId = "SET_SELECTED_GRAPHIC_ID",
    setSelectedColorValue = "SET_SELECTED_COLOR_VALUE",
    setIsTransparentSelected = "SET_IS_TRANSPARENT_SELECTED",
}

export const DEFAULT_SELECTED_COLOR = "#ffffff";