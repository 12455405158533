/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Adobe Internal
import {
    LinkRelation,
    PageOptions,
    AdobeDirectoryData
} from "@dcx/assets";
import { getLinkHref } from "@dcx/util";
import {
    API, ELRenditionType, ERROR_GIVEN_PATH_NOT_FOUND, RepoDirListData,
    RenditionSource, ERROR_ROOT_PATH_DOES_NOT_EXIST
} from "@elements/elementswebcommon";
import { RenditionType } from "@dcx/common-types";

//Application Specific
import { ELAdobeAsset, elDeserializeAsset } from "../common/interfaces/storage/AssetTypes";
import { EmbeddedMetadataType } from "../common/interfaces/storage/StorageTypes";
import IMS from "../services/IMS";
import { StorageService } from "../services/StorageServiceWrapper";
import MediaMetadataAction, { MetadataPayload } from "../stores/actions/mediaMetadataAction";
import store from "../stores/store";
import Utils from "./Utils";
import Logger, { LogLevel } from "./Logger";
import { StorageQuota } from "../modules/storageQuota/StorageQuota";
import Constants, { MediaThumbSize } from "./Constants/Constants";
import MediaThumbAction from "../stores/actions/mediaThumbAction";
import { SIVUtils } from "./SIVUtils";
import CollageUtils from "../workspaces/creations/workflows/collage/utils/CollageUtils";
import ELAdobeAssetDataResolver, { DocumentDataType } from "../editors/document/dataResolver/ELAdobeAssetDataResolver";
import FullResMediaAction from "../stores/actions/FullResMediaAction";
import ImageUtils from "./ImageUtils";
import { ELSize } from "../common/interfaces/geometry/ELGeometry";
import ELClientUploadHandler from "../modules/clientUploadHandler/ELClientUploadHandler";

export type DataResponseType = "blob" | "json" | "text" | "arraybuffer" | "stream";
const TEN_MEGABYTES = 10000000;
export type MediaDataResponse = {
    data: unknown,
    statusCode: number,
    responseType: DataResponseType;
};

export class AssetStorageUtils {

    /** Gets assets name of ELAdobeAsset
     *   @returns string
    **/
    static getAssetName(asset: ELAdobeAsset): string {
        //TODO --ssen Fetch extension
        const deserializedAsset = elDeserializeAsset(asset);
        return deserializedAsset.name ?? "";
    }


    /** Gets assets ID of ELAdobeAssetID
     *   @returns string
    **/
    static getAssetID(asset: ELAdobeAsset): string {
        const deserializedAsset = elDeserializeAsset(asset);
        return deserializedAsset.assetId ?? "";
    }

    /** checks and returns true if asset id is valid 
     *   @returns boolean
    **/
    static isValidAssetId(assetId: string | undefined | null): boolean {
        return assetId ? assetId !== "" : false;
    }

    /** checks and returns true if asset is valid 
     *   @returns boolean
    **/
    static isValidAsset(asset: ELAdobeAsset): boolean {
        const isValid = (AssetStorageUtils.isLocalAsset(asset) && asset.url !== undefined) || AssetStorageUtils.isValidAssetId(asset.assetId);
        return isValid;
    }

    /** checks and returns true if asset is valid local Asset
    *   @returns boolean
   **/
    static isValidLocalAsset(asset: ELAdobeAsset): boolean {
        const isValid = (AssetStorageUtils.isLocalAsset(asset) && asset.url !== undefined);
        return isValid;
    }

    /** checks and returns true if asset is temporary asset created on client 
     *   @returns boolean
    **/
    static isLocalAsset(asset: ELAdobeAsset): boolean {
        return (asset.isLocal === true);
    }

    /** Gets assets type of ELAdobeAssetID
     *   @returns string
    **/
    static getAssetFormat(asset: ELAdobeAsset): string {
        const deserializedAsset = elDeserializeAsset(asset);
        return deserializedAsset.format ?? "";
    }

    static isLargeSizeGif(asset: ELAdobeAsset): boolean {
        if (Utils.isGifMimeType(asset) && ((asset.size ?? 0) > TEN_MEGABYTES)) {
            return true;
        }
        return false;
    }

    static getRenditionData(asset: ELAdobeAsset, response: MediaDataResponse): string {
        if (AssetStorageUtils.isLargeSizeGif(asset)) {
            const objectURL = (response.data as any).href;
            return objectURL;
        }
        const sharedArrayImgData = response.data as SharedArrayBuffer;
        const objectURL = URL.createObjectURL(new Blob([sharedArrayImgData]));
        return objectURL;
    }

    static async resolveAssetInfo(asset: ELAdobeAsset): Promise<ELAdobeAsset> {
        if (asset && asset.links) {
            const assetLink = getLinkHref(asset.links, LinkRelation.REPO_METADATA);
            const assetAPIClient = new API(assetLink);
            const headerConfig = {
                headers: {
                    "Authorization": "Bearer " + IMS.getInstance().getUserAccessToken(),
                    "x-api-key": `${process.env.REACT_APP_REPO_API_KEY}`
                }
            };
            const assetResponse = await assetAPIClient.get("", headerConfig);
            return elDeserializeAsset(assetResponse.data);
        } else {
            return Promise.reject(new Error("AssetStorageUtils:resolveAssetInfo: failed to get full asset data"));
        }
    }

    /**
    * @param  {string[]} array of ELAdobeAsset Array
    * @param  {string} asset
    * @returns true if the asset is present in Array
    */
    static isAssetPresentInArray(assetArr: { assetId?: string; }[], assetId?: string): boolean {
        if (!AssetStorageUtils.isValidAssetId(assetId))
            return false;

        for (let idx = 0; idx < assetArr.length; idx++) {
            if (assetArr[idx].assetId === assetId) {
                return true;
            }
        }

        return false;
    }
    /**
     * @param  {ELAdobeAsset[]} assets
     * @returns number of photos in the arr
     */
    static parsePhotoCount(assets: ELAdobeAsset[]): number {
        return assets.filter(asset => {
            if (Utils.isVideoMimeType(elDeserializeAsset(asset))) return false;
            return true;
        }).length ?? 0;
    }

    /**
     * @param  {ELAdobeAsset[]} assets
     * @returns number of videos in the arr
     */
    static parseVideoCount(assets: ELAdobeAsset[]): number {
        return assets.filter(asset => {
            if (Utils.isVideoMimeType(elDeserializeAsset(asset))) return true;
            return false;
        }).length ?? 0;
    }

    /**
     * @param  {ELAdobeAsset[]} assets
     * @returns number of videos in the arr
     */
    static getVideoDuration(embeddedMetadata: EmbeddedMetadataType): number {
        try {
            const videoDurationValue = parseInt(embeddedMetadata["xmpDM:duration"]["xmpDM:value"]);
            const videoDurationScale = Utils.getDenominatorFromFraction(embeddedMetadata["xmpDM:duration"]["xmpDM:scale"]);
            const videoDuration = videoDurationValue / videoDurationScale;
            return videoDuration;
        } catch (err) {
            Logger.log(LogLevel.WARN, "AssetStorageUtils:getVideoDuration: ", "failed: ", err);
        }
        return Number.MAX_SAFE_INTEGER;
    }

    static async getDownloadURI(asset: ELAdobeAsset): Promise<string> {
        if (AssetStorageUtils.isLocalAsset(asset) && asset.url) {
            return asset.url;
        }

        if (asset && asset.links) {
            const downloadLink = getLinkHref(asset.links, LinkRelation.BLOCK_DOWNLOAD).split("{")[0];
            const downloadClient = new API(downloadLink);
            const downloadConfig = {
                headers: {
                    "Authorization": "Bearer " + IMS.getInstance().getUserAccessToken(),
                    "x-api-key": `${process.env.REACT_APP_REPO_API_KEY}`
                }
            };
            const downloadResponse = await downloadClient.get("", downloadConfig);
            const downloadURL = downloadResponse.data.href;
            if (downloadResponse.data.size === 0) {
                return Promise.reject("asset size is 0");
            }
            return downloadURL;
        } else if (AssetStorageUtils.isValidLocalAsset(asset)) {
            if (asset.url) {
                return Promise.resolve(asset.url);
            }
        }

        return Promise.reject();
    }

    static async getAssetJsonData(rootPath: string, relativeFilePath: string): Promise<unknown> {
        try {
            const outputJsonData = await StorageService.getInstance().getAssetJsonData(rootPath, relativeFilePath);
            return outputJsonData.response;
        } catch (error) {
            Logger.log(LogLevel.ERROR, "AssetStorageUtils:getAssetJsonData failed :", error);
            return Promise.reject();
        }
    }

    static async getImageAssetDimensions(asset: ELAdobeAsset): Promise<ELSize> {
        try {
            const imageUrl = await AssetStorageUtils.getDownloadURI(asset);
            const size = await ImageUtils.getImageSizeFromURL(imageUrl);
            return size;
        } catch (error) {
            Logger.log(LogLevel.ERROR, "AssetStorageUtils:getImageAssetDimensions failed :", error);
            return Promise.reject();
        }
    }

    static async getAssetIdForAssetPath(path: string): Promise<string | undefined> {
        try {
            const asset = {
                repositoryId: "",
                path: path
            };
            const resolvedAsset = await StorageService.getInstance().resolveAsset(asset, "path");
            return resolvedAsset.assetId;
        } catch (error) {
            Logger.log(LogLevel.ERROR, "AssetStorageUtils:getAssetIdForAssetPath: failed", error);
            return Promise.reject();
        }
    }

    /**
     * @param  {string} assetId
     * @returns rendition URL
     */
    static async getAndStoreAssetRendition(assetId: string): Promise<string> {
        let objectURL = "";
        try {
            const asset = await StorageService.getInstance().resolveAsset({ assetId: assetId }, 'id');

            const thumbnailSize = Number(MediaThumbSize);
            const uiScaledRenditionSize = thumbnailSize / Utils.getBaseFontScale();
            const renditionSize = Math.max(thumbnailSize, Math.floor(uiScaledRenditionSize * Utils.getRenderedFactor()));
            const renditionOpts = {
                size: renditionSize,
                type: ELRenditionType.IMAGE_JPG as RenditionType
            };
            const response = await StorageService.getInstance().getRendition(asset, renditionOpts, "arraybuffer", RenditionSource.native, SIVUtils.hasSIVUrl(window.location.href));
            const sharedArrayImgData = response.result as SharedArrayBuffer;
            objectURL = URL.createObjectURL(new Blob([sharedArrayImgData]));
            store.dispatch(MediaThumbAction.udpateThumb({ assetId: assetId, objectURL: objectURL }));
        } catch (error) {
            Logger.log(LogLevel.ERROR, "AssetStorageUtils:getAndStoreAssetRendition: failed ", error);
            Promise.reject();
        }

        return objectURL;
    }

    static async getAndUpdateMetadata(asset: ELAdobeAsset): Promise<EmbeddedMetadataType> {
        if (!asset.assetId) {
            Logger.log(LogLevel.WARN, "AssetStorageUtils:getAndUpdateMetadata: ", "could not access asset id of an asset");
            return Promise.reject();
        }

        const metadataList = store.getState().mediaMetadataReducer;
        const metadata = metadataList.find((data: MetadataPayload) => {
            return data.assetId === asset.assetId;
        });

        if (metadata) {
            return metadata.embeddedMetadata;
        } else {
            const response: unknown = await StorageService.getInstance().getEmbeddedMetadata(asset);
            const result = response as EmbeddedMetadataType;
            const metadata: MetadataPayload = { assetId: asset.assetId, embeddedMetadata: result };
            store.dispatch(MediaMetadataAction.udpateMetadata(metadata));
            return result;
        }
    }

    static isHEICImageFormat(asset: ELAdobeAsset): boolean {
        const heicImageFormat = "image/heic";
        return asset.format === heicImageFormat;
    }

    static ErrorRepoDirResult = {
        children: [],
        hasNextPage: false,
        repoData: {
            children: []
        },
        hasError: true
    };

    static hasQuotaExceeded(): boolean {
        const quota = StorageQuota.getInstance().getCachedQuota();
        const percentUsed = quota.total && quota.used ? (quota.used / quota.total) * 100 : 0;

        const CRITICAL_THRESHOLD = 100.0;

        if (percentUsed >= CRITICAL_THRESHOLD)
            return true;

        return false;
    }

    static async uploadOrGetAssetId(imageData: ImageData, mimeType: string, assetName: string): Promise<string | undefined> {
        const backgroundImageBlob = await ImageUtils.getBlobFromImageData(imageData);

        const clientUploadHandler = new ELClientUploadHandler();
        const assetImageName = assetName;
        const stockImagePath = (Constants.STOCK_IMAGES_PATH as string) + Constants.DIR_SEPERATOR + assetImageName + ".jpeg";
        const asset = await StorageService.getInstance().resolveAsset({ path: stockImagePath, repositoryId: "" }, "path").catch(() => {
            return undefined;
        });
        if (asset === undefined) {
            const assetId = await clientUploadHandler.upload({
                assetPath: stockImagePath,
                contentType: mimeType,
                saveInfo: { blob: backgroundImageBlob }
            });
            return Promise.resolve(assetId);
        }
        return Promise.resolve(asset.assetId);
    }

    static filterRemoveVideoAssets(selectedAssets: ELAdobeAsset[]): ELAdobeAsset[] {
        return selectedAssets.filter((element) => Utils.isVideoMimeType(element) === false);
    }

    static filterRemoveGifAssets(selectedAssets: ELAdobeAsset[]): ELAdobeAsset[] {
        return selectedAssets.filter((element) => Utils.isGifMimeType(element) === false);
    }

    static filterRemoveHeicAssets(selectedAssets: ELAdobeAsset[]): ELAdobeAsset[] {
        return selectedAssets.filter((element) => AssetStorageUtils.isHEICImageFormat(element) === false);
    }

    static shouldUseAssetRendition(asset: ELAdobeAsset): boolean {
        return AssetStorageUtils.isHEICImageFormat(asset);
    }

    static async getAndStoreAssetData(assetId: string): Promise<string> {
        let objectURL: string | undefined = CollageUtils.getAssetFullResObjectURL(assetId ?? "");

        if (!objectURL) {
            try {
                const dataResolver = new ELAdobeAssetDataResolver();
                const asset = await StorageService.getInstance().resolveAsset({ assetId: assetId }, 'id');
                const getDataType = AssetStorageUtils.shouldUseAssetRendition(asset) ? DocumentDataType.rendition : DocumentDataType.fullRes;
                objectURL = await dataResolver.getData(asset, getDataType);
                store.dispatch(FullResMediaAction.updateData({ assetId: assetId, objectURL: objectURL }));
                return Promise.resolve(objectURL);
            } catch (error) {
                Logger.log(LogLevel.WARN, "AssetStorageUtils::getAndStoreAssetData", error);
                return Promise.reject();
            }
        }

        return Promise.resolve(objectURL);
    }

    static isPathNotFoundError(error: Error): boolean {
        // ERROR_ROOT_PATH_DOES_NOT_EXIST when "cloud-content" folder does not exist
        // ERROR_GIVEN_PATH_NOT_FOUND when the given path like "Elements Photos" does not exist
        if ((error.message === ERROR_ROOT_PATH_DOES_NOT_EXIST) || (error.message === ERROR_GIVEN_PATH_NOT_FOUND))
            return true;
        return false;
    }

    static async doesMediaExistForUser(): Promise<boolean> {
        try {
            const MAX_DIR_FETCH_LIMIT = 1; //Represents the maximum number of assets to be fetched for current request
            const REPO_API_ORDER_BY_NAME = "repo:name";
            const response = await StorageService.getInstance().getAdobeAsset(Constants.CLOUD_CONTENT_FOLDER as string, Constants.ELEMENTS_PHOTOS_FOLDER as string);
            const dirData = response as AdobeDirectoryData;
            const pageOpts: PageOptions = {
                orderBy: REPO_API_ORDER_BY_NAME,
                limit: MAX_DIR_FETCH_LIMIT
            };
            const dirList = await StorageService.getInstance().getDirectoryList(dirData, pageOpts) as RepoDirListData;
            return dirList.children.length > 0;
        } catch (error) {
            if (AssetStorageUtils.isPathNotFoundError(error as Error)) {
                return Promise.resolve(false);
            } else {
                Logger.log(LogLevel.ERROR, "AssetStorageUtils::doesMediaExistForUser", error);
                return Promise.reject(error);
            }
        }
    }
}