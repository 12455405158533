/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";
import ReactDOM from "react-dom";
import { Provider as ReactReduxProvider } from "react-redux";

//Application Specific
import IWorkflow from "../../../../workspaces/IWorkflow";
import ITemplateViewController from "../../../ITemplateViewController";
import store from "../../../../stores/store";
import ElementsContentService from "../../../../services/ElementsServices/ElementsContentService";
import ELBackgroundContentPanel from "../el-background-content-panel/ELBackgroundContentPanel";
import ELCollageLayout from "../el-collage-layout/ELCollageLayout";
import SlideshowMedia from "../slideshow-media-panel/SlideshowMedia";
import CollagePanelView from "./ELCollagePanelView";
import { ControllerAction } from "../../../IViewController";
import { ELCollageLayoutControllerActions } from "../../../../common/interfaces/creations/collage/ELCollageLayoutTypes";
import { ELBackgroundContentPanelControllerAction } from "../../../../common/interfaces/creations/templates/ELBackgroundContentPanelTypes";
import { ELCollagePanelWorkflowAction } from "../../../../common/interfaces/creations/ELCollageTypes";
import { ELReactSpectrumV3Provider } from "../../atoms/el-react-spectrum-provider/ELReactSpectrumV3Provider";
import { CollageBackgroundData } from "../../../../common/interfaces/creations/ElementsContentTypes";

class CollagePanel extends ITemplateViewController {
    private _slideshowMedia: SlideshowMedia;
    private _collageLayout: ELCollageLayout;
    private _collageBackground: ELBackgroundContentPanel;

    constructor(owner: IWorkflow) {
        super(owner);
        this._slideshowMedia = new SlideshowMedia(this);
        this._collageLayout = new ELCollageLayout(this, ElementsContentService.getInstance());
        this._collageBackground = new ELBackgroundContentPanel(this, ElementsContentService.getInstance());
    }

    get getCollageMedia(): SlideshowMedia {
        return this._slideshowMedia;
    }

    get getCollageLayout(): ELCollageLayout {
        return this._collageLayout;
    }

    get getCollageBackground(): ELBackgroundContentPanel {
        return this._collageBackground;
    }

    createView(container: HTMLElement): Promise<void> {
        super.createView(container);

        const element = React.createElement(CollagePanelView, {
            controller: this
        });

        /*eslint-disable */
        const provider = React.createElement(ELReactSpectrumV3Provider, {
            children: '',
            height: "100%"
        }, element);
        /*eslint-enable */

        const reduxWrappedProvider = React.createElement(ReactReduxProvider, { store: store }, provider);
        return new Promise((resolve) => {
            ReactDOM.render(reduxWrappedProvider, container, () => { resolve(); });
        });
    }

    private _updateCollageBackgroundPanel(graphicData: CollageBackgroundData): void {
        this.getCollageBackground.notify({
            type: ELBackgroundContentPanelControllerAction.setSelectedGraphicId,
            payload: graphicData.id
        });
    }

    private _updateCollageColorPanel(colorData: string): void {
        this.getCollageBackground.notify({
            type: ELBackgroundContentPanelControllerAction.setSelectedColorValue,
            payload: colorData
        });
        this.getCollageBackground.notify({
            type: ELBackgroundContentPanelControllerAction.setSelectedGraphicId,
            payload: ""
        });
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;

        switch (action.type) {
            case ELCollageLayoutControllerActions.templateChanged:
            case ELCollageLayoutControllerActions.shuffle: {
                this._owner.notify(action);
                handled = true;
                break;
            }
            case ELCollagePanelWorkflowAction.manageMedia: {
                const workflowAction = { type: ELCollagePanelWorkflowAction.manageMedia };
                handled = await this._owner.notify(workflowAction);
                break;
            }
            case ELBackgroundContentPanelControllerAction.imageBackgroundChanged: {
                const graphicData = action.payload as CollageBackgroundData;
                this._updateCollageBackgroundPanel(graphicData);
                this._owner.notify(action);
                handled = true;
                break;
            }
            case ELBackgroundContentPanelControllerAction.colorBackgroundChanged: {
                const colorData = action.payload as string;
                this._updateCollageColorPanel(colorData);
                this._owner.notify(action);
                handled = true;
                break;
            }
        }

        return handled;
    }
}

export default CollagePanel;
