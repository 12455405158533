/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2025 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { useState, useEffect } from "react";

//Adobe Internal
import { Flex } from "@adobe/react-spectrum";

//Application Specific
import ELSwatch from "../../atoms/el-swatch/ELSwatch";
import { ELImageAndText } from "../../molecules/el-image-and-text/ELImageAndText";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { IngestEventSubTypes, IngestEventTypes, IngestWorkflowTypes } from "../../../../utils/IngestConstants";
import { IngestUtils } from "../../../../utils/IngestUtils";
import { IngestLogging } from "../../../../services/IngestWrapper";
import { WorkspacesName } from "../../../../workspaces/IBaseWorkspace";

import "./ELInteractivePreviewActionGroup.scss";


interface PreviewOption {
    src: string;
    swatchSrc: string;
}

interface PreviewGroup {
    name: string;
    iconUrl?: string;
    options?: PreviewOption[];
}

interface ELInteractivePreviewActionProps {
    onPreviewChange: (src: string) => void;
    groups: PreviewGroup[];
}

export const ELInteractivePreviewActionGroup = (props: ELInteractivePreviewActionProps): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();
    const { onPreviewChange, groups } = props;
    const [showSwatches, setShowSwatches] = useState(true);
    const [selectedGroupIndex, setSelectedGroupIndex] = useState<number>(0);
    const [selectedOptionIndex, setSelectedOptionIndex] = useState<number>(0);

    const getCurrentGroup = (): PreviewGroup | undefined => {
        return groups[selectedGroupIndex];
    };

    const getAbsolutePath = (relativePath: string): string => {
        const absolutePathPrefix = window.location.origin + "/" + process.env.PUBLIC_URL;
        return absolutePathPrefix + relativePath;
    };

    const handleGroupClick = (groupIndex: number): void => {
        if (selectedGroupIndex !== groupIndex) {
            setSelectedOptionIndex(0);
        }
        setSelectedGroupIndex(groupIndex);
        IngestLogging.getInstance().logEvent(IngestUtils.addWorkspaceDetail(WorkspacesName.userHome,
            IngestUtils.getPseudoLogObject(IngestWorkflowTypes.interactivePreviewBanner, IngestEventTypes.click,
                groups[groupIndex]?.name ?? "")));
        setShowSwatches(true);
    };

    const handleSwatchClick = (optionIndex: number): void => {
        const currentGroup = getCurrentGroup();
        if (!currentGroup) return;

        setSelectedOptionIndex(optionIndex);
        IngestLogging.getInstance().logEvent(IngestUtils.addWorkspaceDetail(WorkspacesName.userHome,
            IngestUtils.getPseudoLogObject(IngestWorkflowTypes.interactivePreviewBanner, IngestEventTypes.click,
                IngestEventSubTypes.thumbnail, currentGroup.name)));
    };

    const getGroupIconSrc = (group: PreviewGroup): string => {
        return group.iconUrl ? getAbsolutePath(group.iconUrl) : "";
    }

    const getGroups = (): React.ReactElement => (
        <Flex UNSAFE_className="el-interactive-preview-action__group" direction="column" gap="size-100" width="auto">
            {groups.map((group, groupIndex) => (
                <ELImageAndText key={group.name} imageSrc={getGroupIconSrc(group)} text={intlHandler.formatMessage(group.name)}
                    onClick={() => handleGroupClick(groupIndex)} altText={`${group.name} preview`} isSelected={selectedGroupIndex === groupIndex} />))}
        </Flex>
    );

    const getSwatches = (): React.ReactElement => (
        <Flex UNSAFE_className="el-interactive-preview-action__swatches" direction="column" gap="size-100">
            {getCurrentGroup()?.options?.map((option, optionIndex) => (
                <ELSwatch key={optionIndex} imageSrc={getAbsolutePath(option.swatchSrc)} alt={`${getCurrentGroup()?.name} option ${optionIndex + 1}`} isSelected={optionIndex === selectedOptionIndex}
                    onClick={() => handleSwatchClick(optionIndex)} dataTestId={`${getCurrentGroup()?.name}-swatch-${optionIndex}`} size="M" />))}
        </Flex>
    );

    useEffect(() => {
        const currentGroup = groups[selectedGroupIndex];
        const currentOption = currentGroup?.options?.[selectedOptionIndex];

        if (currentOption?.src) {
            onPreviewChange(getAbsolutePath(currentOption.src));
        }
    }, [selectedGroupIndex, selectedOptionIndex]);

    return (
        <Flex UNSAFE_className="el-interactive-preview-action__container">
            {getGroups()}
            {showSwatches && getCurrentGroup() && getSwatches()}
        </Flex>
    );
};