/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

type Key = string;
type Value = string | number;

const Constants: Record<Key, Value> = {
    "HTTP_RESPONSE_FORBIDDEN_403": 403,
    "HTTP_RESPONSE_NOT_FOUND_404": 404,
    "HTTP_RESPONSE_OK_200": 200,
    "HTTP_RESPONSE_NO_CONTENT_204": 204,
    "HTTP_CREATED": 201,
    "HTTP_RESPONSE_ACCEPTED_202": 202,
    "MAX_RETRIES": 6,
    "ECONNABORTED": "ECONNABORTED",
    "DIR_SEPERATOR": "/",
    "UNDERSCORE": "_",
    "PERCENT_SYMBOL": "%",
    "ZERO": 0,
    "ELEMENTS_PHOTOS_PATH": "/cloud-content/Elements Photos",
    "ELEMENTS_CREATIONS_PATH": "/cloud-content/Elements Creation",
    "STOCK_IMAGES_PATH": "/cloud-content/Stock Images",
    "CLOUD_CONTENT_FOLDER": "cloud-content",
    "CLOUD_TEMP_FOLDER": "temp",
    "CLOUD_DOCUMENTS_FOLDER": "cloud-documents",
    "ELEMENTS_PHOTOS_FOLDER": "Elements Photos",
    "ELEMENTS_SHARE_ENDPOINT": "share",
    "TOAST_DEFAULT_MEDIUM_TIME_OUT_LIMIT": 2000,
    "TOAST_DEFAULT_TIME_OUT_LIMIT": 4000,
    "TOAST_NO_TIMEOUT": 0,
    "EMPTY_STRING": ""
}

export enum LinkParams {
    ELEMENTS_COLLECTION_ID_PARAM = "collectionId",
    ELEMENTS_SHARE_MODE_PARAM = "shareMode",
    ELEMENTS_MEDIA_ID_PARAM = "id",
    ELEMENTS_SIV_ROUTE_URL_PARAM = "sivRouteId",
    ELEMENTS_LINK_SOURCE_PARAM = "linkSource",
    ELEMENTS_REDIRECT_PARAM = "redirect",
    ELEMENTS_WORKFLOW = "workflow"
}

export enum UrlOpenTargets {
    blank = "_blank",
    parent = "_parent",
    self = "_self",
    top = "_top"
}

export default Constants;

export const IMSServiceCode = {
    kCreativeCloud: "creative_cloud"
}

export const EnvConstants = {
    true: "1",
    false: "0"
}

export const LocalStorageKeys = {
    kForceLog: "FORCE_LOG",
    kRenditionData: "RENDITION_DATA",
    kMaxTrialDDays: "MAX_TRIAL_DAYS",
    kShareConsent: "SHOW_SHARE_CONSENT",
    kDeviceGuid: "DEVICE_GUID"
}

export const MediaThumbSize = "220";
export const PHOTOSHOP_ELEMENTS_APP_NAME = "Adobe Elements (Beta)";
export const PROMISE_FULFILLED = "fulfilled";
export const VISIBLE_FAQ_COUNT = 4;
export const ERROR_THUMBDATA = "__##ERROR##__";
export const FETCHING_THUMBDATA = "__##FETCHING##__";
export const PROCESSING_THUMBDATA = "__##PROCESSING##__";
export const MAX_ASSETS_SHARE_LIMIT = 50;
export const TERMS_OF_USE_HREF = "https://www.adobe.com/legal/terms.html";
export const POLICY_HREF = "https://www.adobe.com/privacy/policy-linkfree.html";
export const BROADCAST_LOG_OUT_EVENT = "user logged out";

export const APP_VERSION = "2025";
export const PHOTOSHOP_ELEMENTS_APP_FULL_NAME = "Photoshop Elements " + APP_VERSION;
export const PREMIERE_ELEMENTS_APP_FULL_NAME = "Premiere Elements " + APP_VERSION;

export const PHOTOSHOP_ELEMENTS_APPLE_MOBILE_APP_LINK = "https://apple.co/3P13a4E";
export const PHOTOSHOP_ELEMENTS_GOOGLE_MOBILE_APP_LINK = "https://bit.ly/3F1tAi8";

export const WAIT_FOR_BLUR_EVENT = 8000;

export const WHITE_COLOR_HEXA_VALUE = "#FFFFFF"
