/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from 'react';

//Application Specific
import Logger, { LogLevel } from '../../../../utils/Logger';
import { ViewAction } from '../../../IBaseController';
import IViewController, { ControllerAction } from '../../../IViewController';
import BaseContentService, { ContentType } from '../../../../services/ElementsServices/BaseContentService';
import { CollageBackgroundData } from '../../../../common/interfaces/creations/ElementsContentTypes';
import { DEFAULT_SELECTED_COLOR, ELBackgroundContentPanelControllerAction, ELBackgroundContentPanelViewAction } from '../../../../common/interfaces/creations/templates/ELBackgroundContentPanelTypes';
import ITemplateViewController from '../../../ITemplateViewController';
import ELBackgroundContentPanelView from "./ELBackgroundContentPanelView";
import store from "../../../../stores/store";
import { ELPreviewCreationThumbData } from "../../../../common/interfaces/creations/ELRecommendationsWorkflowTypes";
import { RecommendationWorkflowAction } from "../../../../stores/actions/RecommendationWorkflowAction";



interface ELBackgroundContentPanelProps {
    panelHeader: string,
    showNoneOption: boolean,
}

export default class ELBackgroundContentPanel extends ITemplateViewController {
    private _contentService: BaseContentService;
    private _backgroundList: CollageBackgroundData[] = [];
    private _selectedGraphicId: string;
    private _selectedColor: string;
    private _isTransparentSelected: boolean;

    constructor(owner: IViewController, contentService: BaseContentService) {
        super(owner);
        this._contentService = contentService;
        this._fetchBackgroundList();
        this._selectedGraphicId = "";
        this._selectedColor = DEFAULT_SELECTED_COLOR;
        this._isTransparentSelected = false;
    }

    private async _fetchBackgroundList(): Promise<void> {
        try {
            this._backgroundList = await this._contentService.getContent(ContentType.collageBackground) as CollageBackgroundData[];
            this._updateBackgroundList();
        } catch (error) {
            Logger.log(LogLevel.WARN, "GraphicPanel:_fetchBackgroundList: ", error);
        }
    }

    private _updateSelectedGraphicId(): void {
        this.viewDispatcher?.({
            type: ELBackgroundContentPanelViewAction.setSlectedGraphicId,
            payload: this._selectedGraphicId
        });
    }

    private _updateSelectedColor(): void {
        this.viewDispatcher?.({
            type: ELBackgroundContentPanelViewAction.setSelectedColorValue,
            payload: this._selectedColor
        });
    }

    private _updateSelectedTransparent(): void {
        this.viewDispatcher?.({
            type: ELBackgroundContentPanelViewAction.setIsTransparentSelected,
            payload: this._isTransparentSelected
        });
    }

    private _updateBackgroundList(): void {
        this.viewDispatcher?.({
            type: ELBackgroundContentPanelViewAction.graphicBackgroundData,
            payload: this._backgroundList
        });
    }

    get getBackgroundList(): CollageBackgroundData[] {
        return this._backgroundList;
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
        this._updateBackgroundList();
        this._updateSelectedGraphicId();
        this._updateSelectedColor();
        this._updateSelectedTransparent();
    }

    destroy(): void {
        super.destroy();
    }

    getView(viewProps?: unknown): React.ReactNode {
        const graphicPanelConfig = viewProps as ELBackgroundContentPanelProps;
        return React.createElement(ELBackgroundContentPanelView, {
            controller: this,
            panelHeader: graphicPanelConfig.panelHeader,
            showNoneOption: graphicPanelConfig.showNoneOption,
        });
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case ELBackgroundContentPanelControllerAction.imageBackgroundChanged: {
                await this._owner.notify(action);
                handled = true;
                break;
            }
            case ELBackgroundContentPanelControllerAction.colorBackgroundChanged: {
                await this._owner.notify(action);
                handled = true;
                break;
            }
            case ELBackgroundContentPanelControllerAction.transparentClicked: {
                const overlayName = (action.payload as ELPreviewCreationThumbData).name;
                store.dispatch(RecommendationWorkflowAction.updateSelectedOverlayName(overlayName));
                await this._owner.notify(action);
                handled = true;
                break;
            }
            case ELBackgroundContentPanelControllerAction.setSelectedGraphicId: {
                const selectedGraphicId = action.payload as string;
                this._selectedGraphicId = selectedGraphicId;
                this._updateSelectedGraphicId();
                handled = true;
                break;
            }
            case ELBackgroundContentPanelControllerAction.setSelectedColorValue: {
                const selectedColor = action.payload as string;
                this._selectedColor = selectedColor;
                this._updateSelectedColor();
                handled = true;
                break;
            }
            case ELBackgroundContentPanelControllerAction.setIsTransparentSelected: {
                const isTransparentSelected = action.payload as boolean;
                this._isTransparentSelected = isTransparentSelected;
                this._updateSelectedTransparent();
                handled = true;
                break;
            }
        }

        if (!handled) {
            handled = await this._owner.notify(action);
        }

        return handled;
    }
}
