/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import { vec2 } from "gl-matrix";
import { fabric } from "fabric";
import { IEvent } from "fabric/fabric-impl";
import _ from "lodash";

//Application Specific
import DeleteIcon from "./../../assets/icons/EL_delete_N.svg";
import replaceIcon from "./../../assets/icons/EL_asset_type_N.svg";
import rotateIcon from "./../../assets/icons/EL_rotate_N.svg";
import Logger, { LogLevel } from "../../utils/Logger";
import IDoc from "../document/IDoc";
import {
    IMAGE_URI,
    ELStageObject,
    ELStageObjectOptions,
    ELStageImageObject,
    ELStageObjectType,
    CanvasZoomLevelAction,
    CanvasControllerAction,
    ELStageBackgroundOptions,
    ELStageBackgroundMode,
    CanvasViewAction,
    ELImageData,
    ELStageGroupObject,
    ELCornerOffsetDict,
    ELLayoutPanelControllerAction,
    ELFabricInfo,
    ELFabricConfig,
    DefaultELFabricConfig,
    ELStageObjectData,
    ELTextSpread,
    ELStageTextObject,
    CanvasLinkObjectsPayload,
    CanvasUpdateTextPropertyPayload,
    ELStageTextProperty,
    ELStageRectObject
} from "../../common/interfaces/stage/StageTypes";
import IGraphicsStage from "./IGraphicsStage";
import { CanvasId, DocumentActions } from "../../common/interfaces/document/DocumentTypes";
import { ELColorStops, ELPoint, ELSize } from "../../common/interfaces/geometry/ELGeometry";
import { ControllerAction } from "../../view/IViewController";
import { RenderedShapesName } from "../../common/interfaces/renderer/RendererTypes";
import { ReplaceMediaManagerWorkflowAction } from "../../common/interfaces/workflows/ReplaceMediaManagerTypes";
import { ZoomDropdownValues } from "../../common/interfaces/stage/StageTypes";
import Utils from "../../utils/Utils";
import { KeyboardKey } from "../../utils/KeyboardConstants";
import IDragDropHandler from "./dragDropHandlers/IDragDropHandler";
import ELFabricDragDropHandler from "./dragDropHandlers/ELFabricDragDropHandler";
import ELFabricLayoutHandler from "./layoutHandlers/ELLayoutHandler";
import store from "../../stores/store";
import CanvasAction, { CanvasMode } from "../../stores/actions/CanvasAction";
import { StageUtils } from "../../utils/stage/StageUtils";
import ELPatternCreator from "./patternCreator/ELPatternCreator";
import { ELPatternType } from "../../common/interfaces/stage/ELFabricPatternTypes";
import ILinkHandler from "./linkHandler/ILinkHandler";
import ELFabricLinkHandler from "./linkHandler/ELFabricLinkHandler";
import { StageTextUtils } from "../../utils/stage/StageTextUtils";

import './Stage.scss';

enum FabricActions {
    objectAdded = "object:added",
    objectRemoved = "object:removed",
    objectModified = "object:modified",
    objectMoving = "object:moving",
    objectRotating = "object:rotating",
    objectScaling = "object:scaling",
    textChanged = "text:changed",
    textEditingEntered = "text:editing:entered",
    textEditingExited = "text:editing:exited",
    textSelectionChanged = "text:selection:changed",
    mouseUp = "mouse:up",
    mouseDown = "mouse:down",
    mouseMove = "mouse:move",
    mouseOver = "mouse:over",
    mouseOut = "mouse:out",
    mouseDoubleClick = "mouse:dblclick",
    selectionUpdated = "selection:updated",
    selectionCleared = "selection:cleared",
    selectionCreated = "selection:created"
}

export enum ZoomLevel {
    default = 1,
}

const RESIZE_DEBOUNCE_TIME = 200;
const THROTTLE_DELAY = 3000;
const DEFAULT_FONT = "Adobe Clean";
fabric.Object.NUM_FRACTION_DIGITS = 8;

export default class ELFabricStage extends IGraphicsStage {
    private _fabric!: fabric.Canvas;
    private _imageAreaTransformOffset: vec2;
    private _imageAreaTransformScale: number;
    private _imageArea: fabric.Rect | null;
    private _containerResizeObserver?: ResizeObserver;
    private _snapObject?: ELStageObject;
    private _spaceKeyPressed: boolean;
    private _mouseOnePressed: boolean;
    private _dragDropHandler: IDragDropHandler<fabric.Canvas, IEvent>;
    private _layoutHandler: ELFabricLayoutHandler;
    private _canvasResizeCallback?: _.DebouncedFunc<() => Promise<void>>;
    private _linkHandler: ILinkHandler<fabric.Canvas, ELStageObject>;
    private readonly _initialImageAreaPadding: number;
    private readonly _baseLayerIndex = 1;
    private _editCanvas?: HTMLCanvasElement;
    private readonly _textSpreadMargin = 0.05;

    constructor(doc: IDoc, payload?: ELFabricConfig) {
        super(doc);
        this._spaceKeyPressed = false;
        this._mouseOnePressed = false;
        this._imageAreaTransformOffset = vec2.create();
        this._imageAreaTransformScale = 1.0;
        this._initialImageAreaPadding = 0.1;
        this._imageArea = null;
        this._dragDropHandler = new ELFabricDragDropHandler();
        this._layoutHandler = new ELFabricLayoutHandler();
        this._linkHandler = new ELFabricLinkHandler();
        this.config = payload ?? DefaultELFabricConfig;
    }

    private async _findAndSetImageAreaTransformData(shouldUseOriginalSize?: boolean): Promise<void> {
        if (!this.container)
            return;

        const { width, height } = shouldUseOriginalSize ? await this.doc.getOriginalSize() : await this.doc.getSize();

        const imageAreaRatio = (1 - 2 * this._initialImageAreaPadding);

        const factorToFitWidth = (imageAreaRatio * this.containerSize[0]) / width;
        const factorToFitHeight = (imageAreaRatio * this.containerSize[1]) / height;

        this._imageAreaTransformScale = Math.min(factorToFitWidth, factorToFitHeight);

        this._imageAreaTransformOffset[0] = (this.containerSize[0] - width * this._imageAreaTransformScale) / 2.0;
        this._imageAreaTransformOffset[1] = (this.containerSize[1] - height * this._imageAreaTransformScale) / 2.0;
    }

    private _transformCanvas(canvas: HTMLCanvasElement): void {
        canvas.style.imageRendering = this.transformScale >= 2.0 ? 'pixelated' : 'auto';
        canvas.style.transformOrigin = `${0} ${0}`;
        canvas.style.transform = `scale(${this.transformScale})`;
    }

    private _addCustomControl(): void {
        function renderIcon(icon: HTMLImageElement) {
            return function renderIcon(ctx: CanvasRenderingContext2D, left: number, top: number, styleOverride: any, fabricObject: any) {
                if (fabric.Object.prototype.cornerSize) {
                    const size = fabric.Object.prototype.cornerSize;
                    ctx.save();
                    ctx.translate(left, top);
                    fabric.Object.prototype.angle && ctx.rotate(fabric.util.degreesToRadians(fabric.Object.prototype.angle));
                    ctx.fillStyle = "white";
                    ctx.beginPath();
                    ctx.ellipse(0, 0, size, size, 0, 0, 2 * Math.PI);
                    ctx.fill();
                    ctx.drawImage(icon, -0.75 * size, -0.75 * size, 1.5 * size, 1.5 * size);
                    ctx.restore();
                }
            };
        }

        if ((this.config as ELFabricConfig).showDeleteButton) {
            const deleteImg = document.createElement('img');
            deleteImg.src = DeleteIcon;

            fabric.Object.prototype.controls.deleteControl = new fabric.Control({
                x: 0.5,
                y: 0,
                offsetX: 45,
                cursorStyle: 'pointer',
                withConnection: true,
                mouseUpHandler: (eventData: MouseEvent, transformData: fabric.Transform, x: number, y: number): boolean => { this._deleteActiveObject(); return false; },
                render: renderIcon(deleteImg)
            });
        }

        if ((this.config as ELFabricConfig).showReplaceMediaButton) {
            const replaceImg = document.createElement('img');
            replaceImg.src = replaceIcon;

            fabric.Object.prototype.controls.replaceControl = new fabric.Control({
                x: 0,
                y: 0.5,
                offsetY: 45,
                cursorStyle: 'pointer',
                withConnection: true,
                mouseUpHandler: (eventData: MouseEvent, transformData: fabric.Transform, x: number, y: number): boolean => { this._replaceActiveImage(); return false; },
                render: renderIcon(replaceImg)
            });
        }

        const rotateImg = document.createElement('img');
        rotateImg.src = rotateIcon;

        fabric.Object.prototype.controls.mtr.offsetY = -45;
        fabric.Object.prototype.controls.mtr.render = renderIcon(rotateImg);
        fabric.Object.prototype.controls.mtr.cursorStyle = "pointer";
    }

    private _createGradient(colorStops: ELColorStops[]): any {
        const anglePI = 0;
        const angleCoords = {
            x1: (Math.round(50 + Math.sin(anglePI) * 50)) / 100,
            y1: (Math.round(50 + Math.cos(anglePI) * 50)) / 100,
            x2: (Math.round(50 + Math.sin(anglePI + Math.PI) * 50)) / 100,
            y2: (Math.round(50 + Math.cos(anglePI + Math.PI) * 50)) / 100,
        };
        const gradient = {
            type: 'linear',
            gradientUnits: 'percentage',
            coords: {
                x1: angleCoords.x1 || 0,
                y1: angleCoords.y1 || 0,
                x2: angleCoords.x2 || 0,
                y2: angleCoords.y2 || 0,
            },
            colorStops: colorStops
        };

        return gradient;
    }

    private _deleteActiveObject(): void {
        this.doc.notify({ type: CanvasControllerAction.deleteActiveObject, payload: this._fabric.getActiveObject().data });
    }

    private _replaceActiveImage(): void {
        this.doc.notify({ type: ReplaceMediaManagerWorkflowAction.replaceActiveMedia, payload: this._fabric.getActiveObject().data });
    }

    private async _adjustObjectWithImageArea(imageAreaWithImage: fabric.Object): Promise<void> {
        if (imageAreaWithImage.left && imageAreaWithImage.top && imageAreaWithImage.width && imageAreaWithImage.height) {
            const docSize = await this.doc.getOriginalSize();
            const scale = Math.max(docSize.width / imageAreaWithImage.width, docSize.height / imageAreaWithImage.height);
            const layoutInfo = this.doc.getLayoutInfo;

            if (layoutInfo) {
                const left = imageAreaWithImage.left - layoutInfo.left * layoutInfo.scaleX * docSize.width * this._imageAreaTransformScale;
                const top = imageAreaWithImage.top - layoutInfo.top * layoutInfo.scaleY * docSize.height * this._imageAreaTransformScale;
                imageAreaWithImage.set({
                    left: left,
                    top: top,
                    scaleX: scale * layoutInfo.scaleX * this._imageAreaTransformScale,
                    scaleY: scale * layoutInfo.scaleY * this._imageAreaTransformScale
                });
            } else if (this._imageArea && this._imageArea.width && this._imageArea.height) {
                // Center align imageAreaWithImage with this._imageArea
                const hRatio = this._imageArea.width / imageAreaWithImage.width;
                const vRatio = this._imageArea.height / imageAreaWithImage.height;
                const ratio = Math.max(hRatio, vRatio);
                const centerShift_x = (this._imageArea.width - imageAreaWithImage.width * ratio) / 2;
                const centerShift_y = (this._imageArea.height - imageAreaWithImage.height * ratio) / 2;

                imageAreaWithImage.set({
                    left: imageAreaWithImage.left + centerShift_x * this._imageAreaTransformScale,
                    top: imageAreaWithImage.top + centerShift_y * this._imageAreaTransformScale,
                    scaleX: scale * this._imageAreaTransformScale,
                    scaleY: scale * this._imageAreaTransformScale
                });
            }
        }
    }

    private _createImageAreaWithImageURI(options: ELStageBackgroundOptions): Promise<ELStageObject> {
        return new Promise((resolve, reject) => {
            const imageAreaTransformOffset = this._imageAreaTransformOffset;
            fabric.util.loadImage(options.background.value, function (img) {
                if (img === null) {
                    reject("image couldn't be loaded");
                } else {
                    fabric.Image.fromURL(options.background.value, (image) => {
                        image.set({
                            name: options.name,
                            objectCaching: false,
                            originX: "left",
                            originY: "top",
                            left: imageAreaTransformOffset[0],
                            top: imageAreaTransformOffset[1],
                            clipPath: options.clipPath,
                            absolutePositioned: true,
                            selectable: false,
                            crossOrigin: "anonymous"
                        });

                        resolve(image);
                    });
                }
            }, { crossOrigin: "anonymous" });
        });
    }

    private async _createImageArea(options: ELStageBackgroundOptions): Promise<ELStageObject> {
        let imageArea: ELStageObject;
        let imageAreaWithImage: ELStageObject | null = null;

        switch (options.background.mode) {
            case ELStageBackgroundMode.image: {
                imageArea = new fabric.Rect({
                    objectCaching: false,
                    originX: "left",
                    originY: "top",
                    left: this._imageAreaTransformOffset[0],
                    top: this._imageAreaTransformOffset[1],
                    width: options.width,
                    height: options.height,
                    absolutePositioned: true,
                    selectable: false,
                });
                imageArea.scale(this._imageAreaTransformScale);
                options.clipPath = imageArea;
                imageAreaWithImage = await this._createImageAreaWithImageURI(options);
                this._fabric.add(imageAreaWithImage);
                imageAreaWithImage.moveTo(1);
                break;
            }
            case ELStageBackgroundMode.transparent: {
                imageArea = new fabric.Rect({
                    name: options.name,
                    fill: options.background.value,
                    objectCaching: false,
                    originX: "left",
                    originY: "top",
                    left: this._imageAreaTransformOffset[0],
                    top: this._imageAreaTransformOffset[1],
                    width: options.width,
                    height: options.height,
                    absolutePositioned: true,
                    selectable: false,
                    excludeFromExport: true
                });
                const patternCreator = new ELPatternCreator();
                const pattern = await patternCreator.getPattern(ELPatternType.checkBoard, { squareSize: 10 * (1 / this._imageAreaTransformScale) });
                imageArea.scale(this._imageAreaTransformScale);
                imageArea.fill = pattern;

                this._fabric.add(imageArea);
                imageArea.moveTo(1);
                break;
            }
            default:
            case ELStageBackgroundMode.color: {
                imageArea = new fabric.Rect({
                    name: options.name,
                    fill: options.background.value,
                    objectCaching: false,
                    originX: "left",
                    originY: "top",
                    left: this._imageAreaTransformOffset[0],
                    top: this._imageAreaTransformOffset[1],
                    width: options.width,
                    height: options.height,
                    absolutePositioned: true,
                    selectable: false,
                });
                imageArea.scale(this._imageAreaTransformScale);
                this._fabric.add(imageArea);
                imageArea.moveTo(1);
                break;
            }
        }

        this._imageArea = imageArea;

        if (imageAreaWithImage) {
            this._fitImageToClipPath(imageAreaWithImage, options);
            this._centerAlignImage(imageAreaWithImage, imageAreaWithImage.clipPath);
            this._bottomAlignObject(imageAreaWithImage, imageAreaWithImage.clipPath);
        }

        this._fabric.clipPath = imageArea;
        this._fabric.controlsAboveOverlay = true;

        this._fabric.renderAll();

        return imageAreaWithImage ? imageAreaWithImage : this._imageArea;
    }

    private async _createImageAreaWithLayout(options: ELStageBackgroundOptions): Promise<ELStageObject> {
        const imageArea = new fabric.Rect({
            objectCaching: false,
            originX: "left",
            originY: "top",
            left: this._imageAreaTransformOffset[0],
            top: this._imageAreaTransformOffset[1],
            width: options.width,
            height: options.height,
            absolutePositioned: true,
            selectable: false,
        });
        imageArea.scale(this._imageAreaTransformScale);
        const imageAreaWithImage = await this._createImageAreaWithImageURI(options);
        this._fabric.add(imageAreaWithImage);
        imageAreaWithImage.moveTo(1);

        this._imageArea = imageArea;

        await this._adjustObjectWithImageArea(imageAreaWithImage);

        this._fabric.clipPath = imageArea;
        this._fabric.controlsAboveOverlay = true;

        this._fabric.renderAll();

        return imageAreaWithImage ? imageAreaWithImage : this._imageArea;
    }

    private _shouldNotifyOnCanvasChanged(): boolean {
        if (!StageUtils.isLayoutModeOn()) {
            return true;
        }
        return false;
    }

    private _onCanvasChanged(e: IEvent): void {
        if (e.target) {
            this._linkHandler.updateLinkedObjects(this._fabric, e.target);
        }

        if (this._shouldNotifyOnCanvasChanged()) {
            this.doc.notify({ type: DocumentActions.documentUpdated, payload: this.getObjects() });

            // Notify for current active object
            this.doc.notify({ type: CanvasControllerAction.activeObjectChange, payload: this._fabric.getActiveObject()?.data });
        }
    }

    private _onActiveObjectChange(event: IEvent): void {
        if (this._shouldNotifyOnCanvasChanged()) {
            this.doc.notify({ type: CanvasControllerAction.activeObjectChange, payload: this._fabric.getActiveObject()?.data });
        }
    }

    private async _removeCurrentLayout(): Promise<void> {
        await this._findAndSetImageAreaTransformData(true);
        const imageObject = this._fabric.getObjects().filter((obj) => obj.name === RenderedShapesName.imageAreaWithLayout)[0];
        if (imageObject) {
            imageObject.set({
                left: this._imageAreaTransformOffset[0],
                top: this._imageAreaTransformOffset[1],
                scaleX: this._imageAreaTransformScale,
                scaleY: this._imageAreaTransformScale
            });
            this._imageArea?.set({
                left: this._imageAreaTransformOffset[0],
                top: this._imageAreaTransformOffset[1],
                width: imageObject.width,
                height: imageObject.height,
                scaleX: this._imageAreaTransformScale,
                scaleY: this._imageAreaTransformScale
            });
        }
    }

    private async _startLayoutWorkflow(): Promise<void> {
        const docSize = await this.doc.getOriginalSize();
        await this._removeCurrentLayout();
        const fabricInfo: ELFabricInfo = {
            imageAreaTransformOffset: this._imageAreaTransformOffset,
            imageAreaTransformScale: this._imageAreaTransformScale,
            docSize: docSize,
            layoutInfo: this.doc.getLayoutInfo
        };
        store.dispatch(CanvasAction.updateMode(CanvasMode.layout));
        this._layoutHandler.startLayoutWorkflow(this._fabric, fabricInfo);
    }

    private _commitLayoutWorkflow(): void {
        const layoutInfo = this._layoutHandler.getLayoutInfo(this._fabric);
        if (layoutInfo) {
            this.doc.notify({
                type: DocumentActions.commitLayout,
                payload: layoutInfo
            });
        }
    }

    private async _cancelLayoutWorkflow(): Promise<void> {
        await this.doc.notify({ type: DocumentActions.commitLayout });
    }

    private async _revertLayoutWorkflow(): Promise<void> {
        if (!StageUtils.isLayoutModeOn()) {
            await this._startLayoutWorkflow();
        }
        const docSize = await this.doc.getOriginalSize();
        const fabricInfo: ELFabricInfo = {
            imageAreaTransformOffset: this._imageAreaTransformOffset,
            imageAreaTransformScale: this._imageAreaTransformScale,
            docSize: docSize,
            layoutInfo: this.doc.getLayoutInfo
        };
        this._layoutHandler.revertLayoutWorkflow(this._fabric, fabricInfo);
    }

    private _pointInGlobalSpace(point: ELPoint): ELPoint {
        const transformedPoint = point;
        transformedPoint.x = ((transformedPoint.x - this._imageAreaTransformOffset[0]) / this._imageAreaTransformScale);
        transformedPoint.y = ((transformedPoint.y - this._imageAreaTransformOffset[1]) / this._imageAreaTransformScale);

        return transformedPoint;
    }

    private _pointInImageArea(point: ELPoint): ELPoint {
        const transformedPoint = point;
        transformedPoint.x = transformedPoint.x * this._imageAreaTransformScale + this._imageAreaTransformOffset[0];
        transformedPoint.y = transformedPoint.y * this._imageAreaTransformScale + this._imageAreaTransformOffset[1];

        return transformedPoint;
    }

    private _scaleToGlobalSpace(scale: number): number {
        return (scale / this._imageAreaTransformScale);
    }

    private _scaleToImageArea(scale: number): number {
        return scale * this._imageAreaTransformScale;
    }

    private _fitImageToClipPath(image: ELStageObject, options: ELStageObjectOptions): void {
        if (image.width && image.height && options.height && options.width) {
            const width = options.strokeStyle ? options.strokeStyle.width + options.width : options.width;
            const height = options.strokeStyle ? options.strokeStyle.width + options.height : options.height;
            const scale = Math.max(height / image.height, width / image.width);
            image.scale(scale * this._imageAreaTransformScale);
        }
    }

    private _centerAlignImage(image: ELStageObject, clipPath?: ELStageObject): void {
        if (clipPath) {
            const alignX = (clipPath.getCenterPoint().x - image.getCenterPoint().x);
            const alignY = (clipPath.getCenterPoint().y - image.getCenterPoint().y);
            if (image.left && image.top) {
                image.set({
                    left: image.left + alignX,
                    top: image.top + alignY
                });
            }
        }
    }

    private _bottomAlignObject(object: ELStageObject, clipPath?: ELStageObject): void {
        if (clipPath && clipPath.top) {
            if (object.top) {
                object.set({
                    top: object.top - (clipPath.top - object.top)
                });
            }
        }
    }

    private _fitImageToCorner(image: ELStageObject, options: ELStageObjectOptions): void {
        if (image.left && image.top && image.width && image.height && options.height && options.width && this._imageArea && (options.fitToCorner !== undefined)) {
            const scale = Math.min(options.height / image.height, options.width / image.width);
            image.scale(scale * this._imageAreaTransformScale);

            const offsetDistance = this._imageArea.getCenterPoint().subtract(image.getCenterPoint()).multiply(2);
            offsetDistance.x *= (ELCornerOffsetDict[options.fitToCorner][0] ? 1 : 0);
            offsetDistance.y *= (ELCornerOffsetDict[options.fitToCorner][1] ? 1 : 0);

            image.set({
                left: image.left + offsetDistance.x,
                top: image.top + offsetDistance.y
            });
        }
    }

    private _throttleMove = _.throttle((e: IEvent) => {
        if (this._shouldNotifyOnCanvasChanged()) {
            const object = _.cloneDeep(e.target);
            if (object) {
                this._checkAndTransformObjectToImageArea(object);
                const clipPathObject = object.clipPath;
                if (clipPathObject) {
                    this._checkAndTransformObjectToImageArea(clipPathObject);
                }
            }
            this.doc.notify({ type: DocumentActions.objectMoved, payload: object });
        }
    }, THROTTLE_DELAY);

    private _throttleRotate = _.throttle((e: IEvent) => {
        if (this._shouldNotifyOnCanvasChanged()) {
            const object = _.cloneDeep(e.target);
            if (object) {
                this._checkAndTransformObjectToImageArea(object);
                const clipPathObject = object.clipPath;
                if (clipPathObject) {
                    this._checkAndTransformObjectToImageArea(clipPathObject);
                }
            }
            this.doc.notify({ type: DocumentActions.objectRotated, payload: object });
        }
    }, THROTTLE_DELAY);

    private _throttleScale = _.throttle((e: IEvent) => {
        if (this._shouldNotifyOnCanvasChanged()) {
            const object = _.cloneDeep(e.target);
            if (object) {
                this._checkAndTransformObjectToImageArea(object);
                const clipPathObject = object.clipPath;
                if (clipPathObject) {
                    this._checkAndTransformObjectToImageArea(clipPathObject);
                }
            }
            this.doc.notify({ type: DocumentActions.objectScaled, payload: object });
        }
    }, THROTTLE_DELAY);

    private _onObjectMoving(e: IEvent): void {
        if (e.target) {
            this._linkHandler.updateLinkedObjects(this._fabric, e.target);
        }

        const canvasMode = store.getState().canvasReducer.mode;
        switch (canvasMode) {
            case CanvasMode.render:
                {
                    if (e.target?.clipPath && !e.target?.intersectsWithObject(e.target.clipPath)) {
                        this._snapObject = e.target;
                    }
                    this._dragDropHandler.startDragging(this._fabric, e);
                    break;
                }
            case CanvasMode.layout:
                {
                    if (e.target) {
                        this._layoutHandler.onBackgroundImageMoving(this._fabric);
                    }
                    break;
                }
            default:
                {
                    Logger.log(LogLevel.WARN, "ELFabricStage::_onObjectMovingInvalid", "Invalid Canvas Mode");
                }
        }
    }

    private _onTextEditingEntered(e: IEvent): void {
        if (e.target) {
            this.doc.notify({ type: DocumentActions.textEditingEntered, payload: e.target });
        }
    }

    private _onTextEditingExited(e: IEvent): void {
        if (e.target) {
            this.doc.notify({ type: DocumentActions.textEditingExited, payload: e.target });
        }
    }
    
    private _onTextSelectionChanged(e: IEvent): void {
        if (e.target) {
            this.doc.notify({ type: DocumentActions.textSelectionChanged, payload: e.target });
        }
    }

    private _shouldApplyBorderOnHover(object: fabric.Object): boolean {
        if (object.data && object.data.showBorderOnHover && object !== this._fabric.getActiveObject()) {
            return true;
        }
        return false;
    }

    private _onMouseOver(e: fabric.IEvent<Event>): void {
        if (e.target && this._shouldApplyBorderOnHover(e.target)) {
            e.target.stroke = (this.config as ELFabricConfig).objectHoverColor;
            e.target.strokeWidth = 4;
            this._fabric.requestRenderAll();
        }
    }

    private _onMouseOut(e: fabric.IEvent<Event>): void {
        if (e.target && this._shouldApplyBorderOnHover(e.target)) {
            this._removeStroke(e.target);
            this._fabric.requestRenderAll();
        }
    }

    private _onMouseDoubleClick(e: fabric.IEvent<Event>): void {
        this.doc.notify({ type: DocumentActions.mouseDoubleClick, payload: e.target });
    }

    private _addCanvasChangedHandlers(): void {
        this._fabric.on(FabricActions.objectModified, this._onCanvasChanged.bind(this));
        this._fabric.on(FabricActions.objectAdded, this._onCanvasChanged.bind(this));
        this._fabric.on(FabricActions.objectRemoved, this._onCanvasChanged.bind(this));
        this._fabric.on(FabricActions.objectMoving, this._onObjectMoving.bind(this));
        this._fabric.on(FabricActions.textChanged, this._onCanvasChanged.bind(this));
        this._fabric.on(FabricActions.textEditingEntered, this._onTextEditingEntered.bind(this));
        this._fabric.on(FabricActions.textEditingExited, this._onTextEditingExited.bind(this));
        this._fabric.on(FabricActions.textSelectionChanged, this._onTextSelectionChanged.bind(this));

        this._fabric.on(FabricActions.objectRotating, this._throttleRotate.bind(this));
        this._fabric.on(FabricActions.objectMoving, this._throttleMove.bind(this));
        this._fabric.on(FabricActions.objectScaling, this._throttleScale.bind(this));

        this._fabric.on(FabricActions.mouseOver, this._onMouseOver.bind(this));
        this._fabric.on(FabricActions.mouseOut, this._onMouseOut.bind(this));
        this._fabric.on(FabricActions.mouseDoubleClick, this._onMouseDoubleClick.bind(this));

        this._fabric.on(FabricActions.selectionCreated, this._onActiveObjectChange.bind(this));
        this._fabric.on(FabricActions.selectionCleared, this._onActiveObjectChange.bind(this));
    }

    private _removeCanvasChangedHandlers(): void {
        this._fabric.off(FabricActions.objectModified, this._onCanvasChanged.bind(this));
        this._fabric.off(FabricActions.objectAdded, this._onCanvasChanged.bind(this));
        this._fabric.off(FabricActions.objectRemoved, this._onCanvasChanged.bind(this));
        this._fabric.off(FabricActions.objectMoving, this._onObjectMoving.bind(this));
        this._fabric.off(FabricActions.textChanged, this._onCanvasChanged.bind(this));
        this._fabric.off(FabricActions.textEditingEntered, this._onTextEditingEntered.bind(this));
        this._fabric.off(FabricActions.textEditingExited, this._onTextEditingExited.bind(this));
        this._fabric.off(FabricActions.textSelectionChanged, this._onTextSelectionChanged.bind(this));

        this._fabric.off(FabricActions.objectRotating, this._throttleRotate.bind(this));
        this._fabric.off(FabricActions.objectMoving, this._throttleMove.bind(this));
        this._fabric.off(FabricActions.objectScaling, this._throttleScale.bind(this));

        this._fabric.off(FabricActions.mouseOver, this._onMouseOver.bind(this));
        this._fabric.off(FabricActions.mouseOut, this._onMouseOut.bind(this));
        this._fabric.off(FabricActions.mouseDoubleClick, this._onMouseDoubleClick.bind(this));

        this._fabric.off(FabricActions.selectionCreated, this._onActiveObjectChange.bind(this));
        this._fabric.off(FabricActions.selectionCleared, this._onActiveObjectChange.bind(this));
    }

    private _sendDocumentCreatedUpdate(): void {
        this.doc.notify({ type: DocumentActions.documentCreated, payload: this.getObjects() });
    }

    private _updateImageAreaTransformData(imageAreaTransformOffset: vec2 | null = null): void {
        if (imageAreaTransformOffset) {
            this._imageAreaTransformOffset = imageAreaTransformOffset;
        } else {
            const imageAreaObject = this._imageArea;

            if (!imageAreaObject) {
                Logger.log(LogLevel.WARN, "ELFabricStage - (_updateImageAreaTransformData)", "image area not set");
                return;
            }

            if (imageAreaObject.left && imageAreaObject.top) {
                this._imageAreaTransformOffset[0] = imageAreaObject.left;
                this._imageAreaTransformOffset[1] = imageAreaObject.top;
            }
        }
    }

    private _transformEditCanvas(top: number, left: number, animate?: boolean, animateOptions?: KeyframeAnimationOptions): void {
        if (this._editCanvas) {
            if (animate) {
                this._editCanvas.animate(
                    [
                        { top: this._editCanvas.style.top, left: this._editCanvas.style.left },
                        { top: this._imageAreaTransformOffset[1] + "px", left: this._imageAreaTransformOffset[0] + "px" }
                    ],
                    {
                        duration: animateOptions?.duration ?? 100,
                        easing: animateOptions?.easing ?? "cubic-bezier(0.5, 0, 0.75, 0)",
                        fill: animateOptions?.fill ?? "forwards"
                    }
                );
            } else {
                this._editCanvas.style.top = top + "px";
                this._editCanvas.style.left = left + "px";
            }
        }
    }

    private _resetViewportTransform(): number[] {
        const defaultViewportTransform = [1, 0, 0, 1, 0, 0];
        let transform = defaultViewportTransform;

        if (this._fabric.viewportTransform) {
            transform = this._fabric.viewportTransform.slice();
        }

        this._fabric.viewportTransform = defaultViewportTransform;
        return transform;
    }

    private _restoreViewportTransform(viewportTransform: number[]): void {
        this._fabric.viewportTransform = viewportTransform;
    }

    private _onGroupPressed(data: unknown, name?: string): void {
        switch (name) {
            case RenderedShapesName.imageLoadError: {
                this.doc.notify({ type: DocumentActions.replaceErrorImage, payload: data });
                break;
            }
        }
    }

    private _zoomInEventHandler(): void {
        const currZoom = this._fabric.getZoom();
        let changeZoomValueTo = Utils.getNumberFromPercentageString(ZoomDropdownValues[0].keyName);
        ZoomDropdownValues.forEach((obj) => {
            const keyValue: number = Utils.getNumberFromPercentageString(obj.keyName);
            if (keyValue > currZoom) {
                changeZoomValueTo = keyValue;
            }
        });
        this.changeZoomLevel(changeZoomValueTo);
    }

    private _zoomOutEventHandler(): void {
        const currZoom = this._fabric.getZoom();
        let changeZoomValueTo = Utils.getNumberFromPercentageString(ZoomDropdownValues[ZoomDropdownValues.length - 1].keyName);
        let foundFlag = false;
        ZoomDropdownValues.forEach((obj) => {
            const keyValue = Utils.getNumberFromPercentageString(obj.keyName);
            if (!foundFlag && keyValue < currZoom) {
                changeZoomValueTo = keyValue;
                foundFlag = true;
            }
        });
        this.changeZoomLevel(changeZoomValueTo);
    }

    private _removeStroke(object: fabric.Object): void {
        object.stroke = "";
        object.strokeWidth = 0;
    }

    private _mouseDown(): void {
        this._mouseOnePressed = true;
        const activeObject = this._fabric.getActiveObject();
        if (activeObject?.data?.showBorderOnHover) {
            this._removeStroke(activeObject);
            this._fabric.requestRenderAll();
        }
        if (this._shouldNotifyOnCanvasChanged()) {
            this.doc.notify({ type: DocumentActions.activeObjectChange, payload: activeObject?.data });
        }
    }

    private _draggingCanvasEvent(opt: IEvent<MouseEvent>): void {
        if (this._mouseOnePressed) {
            const evt = opt.e;
            const deltaX = evt.movementX;
            const deltaY = evt.movementY;
            const panByOffset = new fabric.Point(deltaX, deltaY);
            this._fabric.relativePan(panByOffset);
        }
    }

    private _disablePanning(): void {
        if (this._fabric.viewportTransform !== undefined)
            this._fabric.setViewportTransform(this._fabric.viewportTransform);
        this._mouseOnePressed = false;
    }

    private _keyDownEventCallback(e: KeyboardEvent): void {
        if (e.key === KeyboardKey.space && this._spaceKeyPressed === false) {
            this._spaceKeyPressed = true;
            this._fabric.on(FabricActions.mouseMove, this._draggingCanvasEvent.bind(this));
        }
    }

    private _keyUpEventCallback(e: KeyboardEvent): void {
        if (e.key === KeyboardKey.space) {
            this._spaceKeyPressed = false;
            this._fabric.off(FabricActions.mouseMove);
        }
    }

    private _handleSnapping(): void {
        if (this._snapObject && this._snapObject.clipPath) {
            this._centerAlignImage(this._snapObject, this._snapObject?.clipPath);
            this._snapObject = undefined;
        }
    }

    private _handleDrop(e: IEvent): void {
        const dropData = this._dragDropHandler.onDrop(this._fabric, e);
        if (dropData.payload) {
            this.doc.notify({ type: DocumentActions.swapAssets, payload: dropData.payload });
        }
    }

    private _onMouseUp(e: IEvent): void {
        this._handleSnapping();
        this._disablePanning();
        this._handleDrop(e);
    }

    /**
     * returns array with size 2, where 1st element is zoomFactorToFIT
     * and 2nd element is ZoomFactorToFILL
     */
    private _getZoomFactorToFitFill(): vec2 {
        const { _imageArea: imageArea, _fabric: { width: w = 0, height: h = 0 } } = this;
        const { width, height } = imageArea?.getBoundingRect() ?? {};
        const factorToFitWidth = (w > 0 ? w : 0) / (width || 1);
        const factorToFitHeight = (h > 0 ? h : 0) / (height || 1);
        const factorToFit = Math.min(factorToFitHeight, factorToFitWidth);
        const factorToFill = Math.max(factorToFitHeight, factorToFitWidth);
        return (factorToFit && factorToFill) ? [factorToFit, factorToFill] : [ZoomLevel.default, ZoomLevel.default];
    }

    async createView(container: HTMLElement): Promise<void> {
        await super.createView(container);

        const stageContainer = this.createAndGetStageContainer(container) as HTMLDivElement;
        const canvasContainer = stageContainer.firstChild as HTMLCanvasElement;

        this._fabric = new fabric.Canvas(canvasContainer, {
            width: this.containerSize[0],
            height: this.containerSize[1],
            preserveObjectStacking: true,
            perPixelTargetFind: true
        });

        if ((this.config as ELFabricConfig).addCanvasHandlers) {
            this._addCanvasChangedHandlers();
        }

        const fabricCanvas = this._fabric.getSelectionElement();

        if (fabricCanvas.parentElement) {
            fabricCanvas.parentElement.style.width = "100%";
            fabricCanvas.parentElement.style.height = "100%";
        }

        fabric.Object.prototype.cornerColor = "white";
        fabric.Object.prototype.cornerStrokeColor = "#5358dc";
        fabric.Object.prototype.cornerStyle = "circle";
        fabric.Object.prototype.transparentCorners = false;
        fabric.Object.prototype.cornerSize = 12;
        fabric.Object.prototype.borderScaleFactor = 1.6;
        fabric.Object.prototype.centeredScaling = true;
        fabric.Object.prototype.centeredRotation = true;

        this._fabric.selection = false;


        /**
         * controls height of rotate line
         */
        //slot.controls.mtr.offsetY += 25;

        await this._findAndSetImageAreaTransformData();
        this._editCanvas = await this.createNewEditCanvas();
        this._addCustomControl();

        this._fabric.on(FabricActions.mouseUp, this._onMouseUp.bind(this));
        this._fabric.on(FabricActions.mouseDown, this._mouseDown.bind(this));

        this._canvasResizeCallback = _.debounce(async (): Promise<void> => {

            const allFabricObjects = this._fabric.getObjects();

            const imageAreaObject = this._imageArea;

            if (!imageAreaObject) {
                Logger.log(LogLevel.WARN, "ELFabricStage - (this._canvasResizeCallback)", "image area not set");
                return;
            }

            const transform = this._resetViewportTransform();

            const newCanvasWidth = container.clientWidth;
            const newCanvasHeight = container.clientHeight;

            this.setContainerSize = [newCanvasWidth, newCanvasHeight];

            this._fabric.setWidth(newCanvasWidth);
            this._fabric.setHeight(newCanvasHeight);

            const getUpdatedOffset = async (obj: fabric.Object): Promise<vec2> => {
                if (obj.left && obj.top) {
                    const { width, height } = await this.doc.getSize();
                    const updatedImgAreaOffset: vec2 = [(newCanvasWidth - width * this._imageAreaTransformScale) / 2.0, (newCanvasHeight - height * this._imageAreaTransformScale) / 2.0];
                    const changedOffset: vec2 = [updatedImgAreaOffset[0] - obj.left, updatedImgAreaOffset[1] - obj.top];
                    return changedOffset;
                }
                return [0, 0];
            };

            const newOffset = await getUpdatedOffset(imageAreaObject);		// [dx, dy] ---> new object should be displaced by this from its current position.

            const updateObjectPosition = (object: fabric.Object): void => {
                if (object.left && object.top) {
                    const newLeft = object.left + newOffset[0];
                    const newTop = object.top + newOffset[1];

                    object.animate({ left: newLeft, top: newTop }, {
                        duration: 100,
                        onComplete: this._fabric.requestRenderAll.bind(this._fabric),
                        easing: fabric.util.ease.easeInQuart
                    });
                }

                const clipObject = object.clipPath;
                if (clipObject && clipObject.left && clipObject.top) {
                    const newLeft = clipObject.left + newOffset[0];
                    const newTop = clipObject.top + newOffset[1];

                    clipObject.animate({ left: newLeft, top: newTop }, {
                        duration: 100,
                        onComplete: this._fabric.requestRenderAll.bind(this._fabric),
                        easing: fabric.util.ease.easeInQuart
                    });
                }
            };

            allFabricObjects.map((obj) => updateObjectPosition(obj));
            updateObjectPosition(imageAreaObject);

            if (imageAreaObject.left && imageAreaObject.top) {
                const upatedImageAreaOffset: vec2 = [imageAreaObject.left + newOffset[0], imageAreaObject.top + newOffset[1]];
                this._updateImageAreaTransformData(upatedImageAreaOffset);
                this._transformEditCanvas(this._imageAreaTransformOffset[1], this._imageAreaTransformOffset[0], true, { duration: 100 });
            }
            this._restoreViewportTransform(transform);
        }, RESIZE_DEBOUNCE_TIME);

        document.addEventListener("keydown", this._keyDownEventCallback.bind(this));
        document.addEventListener("keyup", this._keyUpEventCallback.bind(this));

        if (this.container) {
            this._containerResizeObserver = new ResizeObserver(this._canvasResizeCallback);
            this._containerResizeObserver.observe(this.container);
            this.container.addEventListener("wheel", this.canvasWheelPanning.bind(this));
        }
    }

    private _shouldPan(panByOffset: fabric.Point): boolean {
        if (this._fabric.viewportTransform && this._fabric.width && this._fabric.height) {
            const updatedPanX = Math.abs(this._fabric.viewportTransform[4] + panByOffset.x);
            const updatedPanY = Math.abs(this._fabric.viewportTransform[5] + panByOffset.y);
            const zoomLevel = this._fabric.getZoom();
            if (updatedPanX > this._fabric.width * zoomLevel || updatedPanY > this._fabric.height * zoomLevel) {
                return false;
            }
        }
        return true;
    }

    canvasWheelPanning(evt: WheelEvent): void {
        const deltaX = -evt.deltaX;
        const deltaY = -evt.deltaY;
        const panByOffset = new fabric.Point(deltaX, deltaY);

        if (this._shouldPan(panByOffset)) {
            this._fabric.relativePan(panByOffset);
        }

        if (this._editCanvas) {
            this._transformEditCanvas((parseInt(this._editCanvas.style.top) + deltaY), (parseInt(this._editCanvas.style.left) + deltaX));
        }
    }

    destroy(): void {
        this._imageArea = null;
        if (this._fabric) {
            this._fabric.off(FabricActions.mouseUp, this._onMouseUp.bind(this));
            this._fabric.off(FabricActions.mouseDown, this._mouseDown.bind(this));
            this._removeCanvasChangedHandlers();

            this._fabric.clear();
            this._fabric.dispose();
        }

        document.removeEventListener("keydown", this._keyDownEventCallback.bind(this));
        document.removeEventListener("keyup", this._keyUpEventCallback.bind(this));

        if (this.container) {
            this.container.removeEventListener("wheel", this.canvasWheelPanning.bind(this));
            const stageDocContainer = this.createAndGetStageContainer(this.container);
            stageDocContainer.innerHTML = '';
            this._containerResizeObserver?.unobserve(this.container);
        }
    }

    async getImageDataURL(imageData: ELImageData): Promise<string> {
        return new Promise((resolve, reject) => {
            const json = this._fabric.toJSON(); //include objects for which excludeFromExport!==true 
            const cloneCanvas = new fabric.Canvas('cloneCanvas');
            cloneCanvas.loadFromJSON(json, () => {
                if (!this._imageArea) {
                    return reject("Image area not defined");
                }
                cloneCanvas.viewportTransform = [1, 0, 0, 1, 0, 0];
                const width = Math.floor(this._imageArea.getScaledWidth());
                const height = Math.floor(this._imageArea.getScaledHeight());
                const imageForDownload = cloneCanvas.toDataURL({
                    left: this._imageArea.left,
                    top: this._imageArea.top,
                    width: width,
                    height: height,
                    multiplier: 1 / this._imageAreaTransformScale,
                    format: imageData.format,
                    quality: imageData.quality
                });
                resolve(imageForDownload);
            });
        });
    }

    private _changeFabricZoomTo(zoomLevel: number, transformEditCanvas = true): void {
        const vpt = this._fabric.viewportTransform;
        if (vpt !== undefined)
            vpt[4] = vpt[5] = 0;
        if (this._fabric.width && this._fabric.height) {
            this._fabric.zoomToPoint(new fabric.Point(this._fabric.width / 2, this._fabric.height / 2), zoomLevel);
        }
        this._updateImageAreaTransformData();
        if (this._editCanvas && transformEditCanvas) {
            this._editCanvas.style.transform = `scaleY(-1) scale(${zoomLevel})`;
            this._transformEditCanvas(this._imageAreaTransformOffset[1], this._imageAreaTransformOffset[0]);
        }
    }

    changeZoomLevel(zoomLevel: number): void {
        this._changeFabricZoomTo(ZoomLevel.default);
        this._changeFabricZoomTo(zoomLevel);
        this.doc.notify({ type: DocumentActions.zoomModified, payload: zoomLevel });
    }

    private getZoomValueToFit(): number {
        return this._getZoomFactorToFitFill()[0];
    }

    private getZoomValueToFill(): number {
        return this._getZoomFactorToFitFill()[1];
    }

    private async _scaleImage(val: number): Promise<void> {
        const docSize = await this.doc.getSize();
        const fabricInfo: ELFabricInfo = {
            imageAreaTransformOffset: this._imageAreaTransformOffset,
            imageAreaTransformScale: this._imageAreaTransformScale,
            docSize: docSize
        };
        this._layoutHandler.scale(this._fabric, fabricInfo, val);
    }

    private async _changeLayoutWorkflow(aspectRatio: ELSize): Promise<void> {
        if (!StageUtils.isLayoutModeOn()) {
            await this._startLayoutWorkflow();
        }
        const docSize = await this.doc.getSize();
        const fabricInfo: ELFabricInfo = {
            imageAreaTransformOffset: this._imageAreaTransformOffset,
            imageAreaTransformScale: this._imageAreaTransformScale,
            docSize: docSize
        };
        this._layoutHandler.changeLayoutWorkflow(this._fabric, fabricInfo, aspectRatio);
    }

    private _updateTextProperty(textObject: ELStageTextObject, key: ELStageTextProperty, value: unknown): void {

        const applyStyles = (textObject: ELStageTextObject, propertyJson: Record<string, unknown>): void => {
            const selectionStart = textObject.selectionStart;
            const selectionEnd = textObject.selectionEnd;
            const textLastIdx = textObject.text ? textObject.text.length : 0;

            const allCharsSelected = (selectionStart === 0 && selectionEnd === textLastIdx);
            const applyPropertyToPartialChars = (selectionStart !== selectionEnd && !allCharsSelected);
            const applyPropertyToAllChars = ((selectionStart === selectionEnd) || allCharsSelected);

            if (applyPropertyToPartialChars) {
                textObject.setSelectionStyles(propertyJson);
            } else if (applyPropertyToAllChars) {
                textObject.setSelectionStyles(propertyJson, 0, textLastIdx);
                textObject.set(propertyJson);
            }
        };

        if (key === ELStageTextProperty.spread) {
            this._applyTextSpread(textObject, value as ELTextSpread);
        } else if (key === ELStageTextProperty.fontFamily) {
            const propertyJson = { [key]: value as string };
            applyStyles(textObject, propertyJson);
        } else if (key === ELStageTextProperty.fontSize) {
            const propertyJson = { [key]: value as number, scaleX: this._imageAreaTransformScale, scaleY: this._imageAreaTransformScale };
            applyStyles(textObject, propertyJson);
        } else {
            const propertyJson = { [key]: value };
            textObject.set(propertyJson);
        }
        this._fabric.requestRenderAll();
        const eventData = { target: textObject };
        window.requestAnimationFrame(() => { this._fabric.fire(FabricActions.objectModified, eventData); });
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;

        switch (action.type) {
            case CanvasViewAction.addCanvasChangedHandlers: {
                this._addCanvasChangedHandlers();
                handled = true;
                break;
            }
            case CanvasViewAction.sendDocumentCreatedUpdate: {
                this._sendDocumentCreatedUpdate();
                handled = true;
                break;
            }
            case CanvasZoomLevelAction.changeZoomValue: {
                const zoomValue = Utils.getNumberFromPercentageString(action.payload as string);
                this.changeZoomLevel(zoomValue);
                handled = true;
                break;
            }
            case CanvasZoomLevelAction.zoomToFill: {
                this.changeZoomLevel(this.getZoomValueToFill());
                handled = true;
                break;
            }
            case CanvasZoomLevelAction.zoomToFit: {
                this.changeZoomLevel(this.getZoomValueToFit());
                handled = true;
                break;
            }
            case CanvasZoomLevelAction.zoomInEvent: {
                this._zoomInEventHandler();
                handled = true;
                break;
            }
            case CanvasZoomLevelAction.zoomOutEvent: {
                this._zoomOutEventHandler();
                handled = true;
                break;
            }
            case ELLayoutPanelControllerAction.scale: {
                const val = action.payload as number;
                this._scaleImage(val);
                handled = true;
                break;
            }
            case ELLayoutPanelControllerAction.change: {
                this._changeLayoutWorkflow(action.payload as ELSize);
                handled = true;
                break;
            }
            case ELLayoutPanelControllerAction.commit: {
                await this._commitLayoutWorkflow();
                handled = true;
                break;
            }
            case ELLayoutPanelControllerAction.cancel: {
                await this._cancelLayoutWorkflow();
                handled = true;
                break;
            }
            case ELLayoutPanelControllerAction.revert: {
                await this._revertLayoutWorkflow();
                handled = true;
                break;
            }
            case CanvasControllerAction.linkObjects: {
                const payload = action.payload as CanvasLinkObjectsPayload;
                this._linkHandler.linkObjects(payload.objects, payload.linkType);
                handled = true;
                break;
            }
            case CanvasControllerAction.updateTextProperty: {
                const payload = action.payload as CanvasUpdateTextPropertyPayload;
                this._updateTextProperty(payload.object, payload.key, payload.value);
                handled = true;
                break;
            }
            default:
                break;
        }

        return handled;
    }

    getActiveObject(): ELStageObject | undefined {
        if (this._fabric.getActiveObject()) {
            const object = _.cloneDeep(this._fabric.getActiveObject());

            const data = object.data;
            if (data.transformedToImageArea) {
                const objectPoint = this._pointInGlobalSpace({ x: object.left ?? 0, y: object.top ?? 0 });
                object.left = Math.floor(objectPoint.x);
                object.top = Math.floor(objectPoint.y);
                object.scaleX = this._scaleToGlobalSpace(object.scaleX ?? 1);
                object.scaleY = this._scaleToGlobalSpace(object.scaleY ?? 1);
            }

            return object;
        }

        return undefined;
    }

    private _isObjectTransformedToImageArea(object: ELStageObject): boolean {
        return object.data && object.data.transformedToImageArea;
    }

    private _transformObjectToGlobalSpace(object: ELStageObject): void {
        const objectPoint = this._pointInGlobalSpace({ x: object.left ?? 0, y: object.top ?? 0 });
        object.left = objectPoint.x;
        object.top = objectPoint.y;
        object.scaleX = this._scaleToGlobalSpace(object.scaleX ?? 1);
        object.scaleY = this._scaleToGlobalSpace(object.scaleY ?? 1);
    }

    private _checkAndTransformObjectToImageArea(object: ELStageObject): void {
        if (this._isObjectTransformedToImageArea(object)) {
            this._transformObjectToGlobalSpace(object);
        }
    }

    getObjects(): ELStageObject[] {
        const objects = _.cloneDeep(this._fabric.getObjects());
        const modifiedObjects = [];

        for (let index = 0; index < objects.length; index++) {
            this._checkAndTransformObjectToImageArea(objects[index]);
            const clipPathObject = objects[index].clipPath;
            if (clipPathObject) {
                this._checkAndTransformObjectToImageArea(clipPathObject);
            }
            modifiedObjects.push(objects[index]);
        }

        return modifiedObjects;
    }

    updateTransform(): void {
        if (this.canvas) {
            this._transformCanvas(this.canvas);
        }

        const fabricCanvas = this._fabric.getSelectionElement();
        this._transformCanvas(fabricCanvas);
    }

    setActiveObject(object: fabric.Object): void {
        this._fabric.setActiveObject(object);
        if (StageTextUtils.isTextObject(object)) {
            (object as fabric.IText).enterEditing();
        }
    }

    setBackground(color: string): void {
        this._fabric.setBackgroundColor(color, () => {
            Logger.log(LogLevel.INFO, "ELFabricStage - setStageBackground color changed to: ", color);
            this._fabric.requestRenderAll();
        });
    }

    async addImageArea(options: ELStageBackgroundOptions): Promise<ELStageObject> {
        const imageArea = await this._createImageArea(options);

        return new Promise((resolve, reject) => {
            //needed to position image on canvas correctly
            window.requestAnimationFrame(() => {
                if (this._canvasResizeCallback) {
                    this._canvasResizeCallback();
                }
                return resolve(imageArea);
            });
        });
    }

    async addImageAreaWithLayout(options: ELStageBackgroundOptions): Promise<ELStageObject> {
        const imageArea = await this._createImageAreaWithLayout(options);

        return new Promise((resolve, reject) => {
            //needed to position image on canvas correctly
            window.requestAnimationFrame(() => {
                if (this._canvasResizeCallback) {
                    this._canvasResizeCallback();
                }
                return resolve(imageArea);
            });
        });
    }

    addImageFromElement(element: HTMLImageElement, options: ELStageObjectOptions): Promise<ELStageObject> {
        return Promise.reject();
    }

    async addText(options: ELStageObjectOptions): Promise<ELStageObject> {
        if (!options.text) {
            return Promise.reject("ELFabricStage - (addText): text options not provided");
        }

        const shapePositionPoint = this._pointInImageArea({ x: options.left ?? 0, y: options.top ?? 0 });

        const textObject = new fabric.IText(options.text, {
            originX: options.originX ?? "left",
            originY: options.originY ?? "top",
            type: ELStageObjectType.text,
            name: options.name,
            data: options.data,
            left: shapePositionPoint.x,
            top: shapePositionPoint.y,
            width: options.width,
            height: options.height,
            absolutePositioned: true,
            selectable: options.selectable ?? true,
            perPixelTargetFind: false,
            fontFamily: options.fontFamily ?? DEFAULT_FONT,
            fill: options.fill ?? "black",
            fontSize: options.fontSize ?? 128,
            cursorColor: "blue",
            cursorWidth: 1,
            editable: true,
            strokeWidth: options.strokeWidth ?? 0,
            stroke: options.stroke ?? "",
            textAlign: options.textAlign ?? "left",
            opacity: options.opacity ?? 1,
            angle: options.angle ?? 0,
            scaleX: options.scaleX ? this._scaleToImageArea(options.scaleX) : 1,
            scaleY: options.scaleY ? this._scaleToImageArea(options.scaleY) : 1,
            flipX: options.flipX ?? false,
            flipY: options.flipY ?? false,
            visible: options.visible ?? true,
            underline: options.underline ?? false
        });

        if (options.shadow) {
            textObject.set({ shadow: options.shadow });
        }

        if (options.textSpread) {
            this._applyTextSpread(textObject, options.textSpread);
        }

        if (options.addToStage) {
            if (options.layerIndex) {
                this.moveStageObject(textObject, options.layerIndex);
            } else {
                this.addObjectToStage(textObject);
            }
        }

        if (options.styles) {
            textObject.styles = { ...options.styles };
        }

        return Promise.resolve(textObject);
    }

    async replaceText(textObject: ELStageTextObject, options: ELStageObjectOptions): Promise<ELStageTextObject> {
        if (!options.text) {
            return Promise.reject("ELFabricStage - (replaceText): text options not provided");
        }

        const shapePositionPoint = this._pointInImageArea({ x: options.left ?? 0, y: options.top ?? 0 });

        textObject.set({
            originX: options.originX ?? "left",
            originY: options.originY ?? "top",
            text: options.text,
            type: ELStageObjectType.text,
            name: options.name,
            data: options.data,
            left: shapePositionPoint.x,
            top: shapePositionPoint.y,
            width: options.width,
            height: options.height,
            absolutePositioned: true,
            selectable: options.selectable ?? true,
            perPixelTargetFind: false,
            fontFamily: options.fontFamily ?? DEFAULT_FONT,
            fill: options.fill ?? "black",
            fontSize: options.fontSize ?? 128,
            cursorColor: "blue",
            cursorWidth: 1,
            editable: true,
            strokeWidth: options.strokeWidth ?? 0,
            stroke: options.stroke ?? "",
            textAlign: options.textAlign ?? "left",
            opacity: options.opacity ?? 1,
            scaleX: options.scaleX ? this._scaleToImageArea(options.scaleX) : 1,
            scaleY: options.scaleY ? this._scaleToImageArea(options.scaleY) : 1,
            flipX: options.flipX ?? false,
            flipY: options.flipY ?? false,
            visible: options.visible ?? true,
            underline: options.underline ?? false
        });

        if (options.shadow) {
            textObject.set({ shadow: options.shadow });
        }

        if (options.textSpread) {
            this._applyTextSpread(textObject, options.textSpread);
        }

        this._fabric.requestRenderAll();

        window.requestAnimationFrame(() => { this._fabric.fire(FabricActions.objectModified); });

        return Promise.resolve(textObject);
    }

    private _applyTextSpread(textObject: fabric.IText, textSpread: ELTextSpread): void {
        if (!this._imageArea?.width || !this._imageArea?.height || !textObject.width || !textObject.height) {
            throw new Error("ELFabricStage - (_applyTextSpread): object not set or width/height not defined");
        }

        const scaleX = this._scaleToImageArea((this._imageArea.width * (1 - this._textSpreadMargin)) / textObject.width);
        const scaleY = this._scaleToImageArea((this._imageArea.height * (1 - this._textSpreadMargin)) / textObject.height);

        switch (textSpread) {
            case ELTextSpread.fill: {
                textObject.set({ "scaleX": scaleX, "scaleY": scaleY });
                break;
            }
            case ELTextSpread.fit: {
                const scale = Math.min(scaleX, scaleY);
                textObject.set({ "scaleX": scale, "scaleY": scale });
                break;
            }
        }

        textObject.set({ "angle": 0 });
        textObject.setPositionByOrigin(this._imageArea.getCenterPoint(), "center", "center");
    }

    addToGroup(objectList: ELStageObject[], options: ELStageObjectOptions): ELStageObject {
        const shapePositionPoint = this._pointInImageArea({ x: options.left ?? 0, y: options.top ?? 0 });

        const group = new fabric.Group(objectList, {
            data: options.data,
            name: options.name,
            left: shapePositionPoint.x,
            top: shapePositionPoint.y,
            width: options.width,
            height: options.height,
            originX: options.originX ?? "center",
            originY: options.originY ?? "center",
            selectable: options.selectable ?? false,
            subTargetCheck: options.subTargetCheck ?? true,
            objectCaching: false
        });

        group.on('mousedown', (e) => {
            if (e.subTargets) {
                for (let index = 0; index < e.subTargets.length; index++) {
                    const object = e.subTargets[index];
                    if (options.subTargets?.includes(object)) {
                        this._onGroupPressed(options.data, options.name);
                        break;
                    }
                }
            }
        });

        if (options.fitToClipShape) {
            this._fitImageToClipPath(group, options);
            this._centerAlignImage(group, options.clipPath);
        }

        if (options.addToStage) {
            this._fabric.add(group);
        }

        return group;
    }

    addRect(options: ELStageObjectOptions): ELStageObject {
        const shapePositionPoint = this._pointInImageArea({ x: options.left ?? 0, y: options.top ?? 0 });

        const rect = new fabric.Rect({
            name: options.name,
            stroke: options.stroke ?? "",
            strokeWidth: options.strokeWidth ?? 0,
            fill: options.fill ?? "transparent",
            rx: options.rx,
            ry: options.ry,
            width: options.width,
            height: options.height,
            left: shapePositionPoint.x,
            top: shapePositionPoint.y,
            hoverCursor: options.hoverCursor ?? "pointer",
            scaleX: options.scaleX ? this._scaleToImageArea(options.scaleX) : 1,
            scaleY: options.scaleY ? this._scaleToImageArea(options.scaleY) : 1,
            lockMovementX: options.lockMovementX ?? false,
            lockMovementY: options.lockMovementY ?? false,
            lockRotation: options.lockRotation ?? false,
            opacity: options.opacity ?? 1,
            hasControls: false,
            borderColor: options.borderColor,
            data: options.data,
            objectCaching: false,
            selectable: options.selectable ?? true,
            visible: options.visible ?? true
        });

        if (options.fitToImageArea) {
            this._adjustObjectWithImageArea(rect);
        }

        if (options.addToStage) {
            if (options.layerIndex) {
                this.moveStageObject(rect, options.layerIndex);
            } else {
                this._fabric.add(rect);
            }
            this._fabric.requestRenderAll();
        }

        return rect;
    }

    replaceRect(rectObject: ELStageRectObject, options: ELStageObjectOptions): ELStageRectObject {
        const shapePositionPoint = this._pointInImageArea({ x: options.left ?? 0, y: options.top ?? 0 });

        rectObject.set({
            name: options.name,
            stroke: options.stroke ?? "",
            strokeWidth: options.strokeWidth ?? 0,
            fill: options.fill ?? "transparent",
            rx: options.rx,
            ry: options.ry,
            width: options.width,
            height: options.height,
            left: shapePositionPoint.x,
            top: shapePositionPoint.y,
            hoverCursor: options.hoverCursor ?? "pointer",
            scaleX: options.scaleX ? this._scaleToImageArea(options.scaleX) : 1,
            scaleY: options.scaleY ? this._scaleToImageArea(options.scaleY) : 1,
            lockMovementX: options.lockMovementX ?? false,
            lockMovementY: options.lockMovementY ?? false,
            lockRotation: options.lockRotation ?? false,
            opacity: options.opacity ?? 1,
            hasControls: false,
            borderColor: options.borderColor,
            data: options.data,
            objectCaching: false,
            selectable: options.selectable ?? true,
            visible: options.visible ?? true
        });

        if (options.fitToImageArea) {
            this._adjustObjectWithImageArea(rectObject);
        }

        this._fabric.requestRenderAll();

        window.requestAnimationFrame(() => { this._fabric.fire(FabricActions.objectModified); });

        return rectObject;
    }

    async addImageFromURI(uri: IMAGE_URI, options: ELStageObjectOptions): Promise<ELStageObject> {
        return new Promise((resolve) => {
            fabric.Image.fromURL(uri, (image) => {
                const shapePositionPoint = this._pointInImageArea({ x: options.left ?? 0, y: options.top ?? 0 });

                image.set({
                    name: options.name,
                    data: options.data,
                    left: (options.left !== undefined) ? shapePositionPoint.x : 0,
                    top: (options.top !== undefined) ? shapePositionPoint.y : 0,
                    objectCaching: false,
                    hoverCursor: 'default',
                    absolutePositioned: options.absolutePositioned ?? true,
                    selectable: options.selectable ?? true,
                    clipPath: options.clipPath,
                    perPixelTargetFind: options.perPixelTargetFind ?? true,
                    crossOrigin: "anonymous",
                    borderColor: options.borderColor ?? "white",
                    angle: options.angle ?? 0,
                    scaleX: options.scaleX ? this._scaleToImageArea(options.scaleX) : 1,
                    scaleY: options.scaleY ? this._scaleToImageArea(options.scaleY) : 1,
                    flipX: options.flipX ?? false,
                    flipY: options.flipY ?? false,
                    visible: options.visible ?? true
                });

                if (options.originX && options.originY) {
                    image.set({
                        originX: options.originX,
                        originY: options.originY
                    });
                }

                if (options.width && options.height && !options.fitToClipShape && (options.fitToCorner === undefined)) {
                    image.set({
                        width: options.width,
                        height: options.height
                    });
                }

                if (options.fitToClipShape) {
                    this._fitImageToClipPath(image, options);
                    this._centerAlignImage(image, options.clipPath);
                } else if (options.fitToCorner !== undefined) {
                    this._fitImageToCorner(image, options);
                } else if (options.fitToImageArea) {
                    this._adjustObjectWithImageArea(image);
                }

                if (options.layerIndex) {
                    this.moveStageObject(image, options.layerIndex);
                } else {
                    this.addObjectToStage(image);
                }

                this._fabric.requestRenderAll();

                if (image.data) {
                    (image.data as ELStageObjectData).initialCenter = { horizontal: image.getCenterPoint().x, vertical: image.getCenterPoint().y };
                }

                return resolve(image);
            }, { crossOrigin: "anonymous" });
        });
    }

    addPath(options: ELStageObjectOptions): ELStageObject {
        const shapePositionPoint = this._pointInImageArea({ x: options.left ?? 0, y: options.top ?? 0 });

        const path = new fabric.Path(`d=${options.svgPath}`, {
            name: options.name,
            data: options.data,
            originX: options.originX ?? "left",
            originY: options.originY ?? "top",
            left: (options.left !== undefined) ? shapePositionPoint.x : 0,
            top: (options.top !== undefined) ? shapePositionPoint.y : 0,
            fill: options.fill,
            objectCaching: false,
            width: options.width,
            height: options.height,
            absolutePositioned: options.absolutePositioned ?? true,
            selectable: options.selectable ?? true,
            angle: options.angle,
            scaleX: options.scaleX ? this._scaleToImageArea(options.scaleX) : 1,
            scaleY: options.scaleY ? this._scaleToImageArea(options.scaleY) : 1,
            hoverCursor: options.hoverCursor
        });

        if (options.strokeStyle) {
            path.set({
                stroke: options.strokeStyle.colorStops.length === 1 ? options.strokeStyle.colorStops[0].color :
                    new fabric.Gradient(this._createGradient(options.strokeStyle.colorStops)) as unknown as string,
                strokeWidth: options.strokeStyle.width,
                strokeLineJoin: "round"
            });
        }

        if (options.width)
            path.scaleToWidth(options.width * this._imageAreaTransformScale);

        if (options.height)
            path.scaleToHeight(options.height * this._imageAreaTransformScale);

        if (options.addToStage) {
            this._fabric.add(path);
        }

        return path;
    }

    replaceActiveImage(options: ELStageObjectOptions): Promise<ELStageObject> {
        const activeObject = this._fabric.getActiveObject() as ELStageImageObject;

        return new Promise((resolve, reject) => {
            if (activeObject.type !== ELStageObjectType.image || typeof options.image !== "string") {
                Logger.log(LogLevel.WARN, "ELFabricStage:replaceActiveImage: ", "Active object not of image type or options.image is not valid");
                return reject(activeObject);
            }

            activeObject.setSrc(options.image, () => {
                const shapePositionPoint = this._pointInImageArea({ x: options.left ?? 0, y: options.top ?? 0 });

                activeObject.set({
                    name: options.name,
                    data: options.data,
                    originX: options.originX ?? "left",
                    originY: options.originY ?? "top",
                    left: (options.left !== undefined) ? shapePositionPoint.x : 0,
                    top: (options.top !== undefined) ? shapePositionPoint.y : 0,
                    objectCaching: false,
                    hoverCursor: 'default',
                    absolutePositioned: options.absolutePositioned ?? true,
                    selectable: options.selectable ?? true,
                    clipPath: options.clipPath,
                    perPixelTargetFind: options.perPixelTargetFind ?? true,
                    crossOrigin: "anonymous",
                    borderColor: options.borderColor ?? "white",
                    angle: options.angle ?? 0,
                    scaleX: options.scaleX ? this._scaleToImageArea(options.scaleX) : 1,
                    scaleY: options.scaleY ? this._scaleToImageArea(options.scaleY) : 1
                });

                if (options.fitToClipShape) {
                    this._fitImageToClipPath(activeObject, options);
                    this._centerAlignImage(activeObject, options.clipPath);
                }

                activeObject.setCoords();

                this._fabric.requestRenderAll();

                window.requestAnimationFrame(() => {
                    this._fabric.fire(FabricActions.objectModified);
                });

                return resolve(activeObject);
            });
        });
    }

    async replaceImage(imageObject: ELStageImageObject, options: ELStageObjectOptions): Promise<ELStageObject> {
        const imageOptions = options;

        return new Promise((resolve, reject) => {
            if (typeof imageOptions.image !== "string") {
                Logger.log(LogLevel.WARN, "ELFabricStage:replaceImage : image object not of image type or options.image is not valid");
                return reject(imageObject);
            }

            imageObject.setSrc(imageOptions.image, () => {
                if (imageObject.clipPath) {
                    imageObject.set({
                        clipPath: imageObject.clipPath
                    });
                }

                const shapePositionPoint = this._pointInImageArea({ x: options.left ?? 0, y: options.top ?? 0 });

                imageObject.set({
                    name: options.name,
                    data: options.data,
                    originX: options.originX ?? "left",
                    originY: options.originY ?? "top",
                    left: (options.left !== undefined) ? shapePositionPoint.x : 0,
                    top: (options.top !== undefined) ? shapePositionPoint.y : 0,
                    objectCaching: false,
                    hoverCursor: 'default',
                    absolutePositioned: options.absolutePositioned ?? true,
                    selectable: options.selectable ?? true,
                    perPixelTargetFind: options.perPixelTargetFind ?? true,
                    crossOrigin: "anonymous",
                    borderColor: options.borderColor ?? "white",
                    angle: options.angle ?? 0,
                    scaleX: options.scaleX ? this._scaleToImageArea(options.scaleX) : 1,
                    scaleY: options.scaleY ? this._scaleToImageArea(options.scaleY) : 1,
                    flipX: options.flipX ?? false,
                    flipY: options.flipY ?? false,
                    visible: options.visible ?? true
                });

                if (options.fitToClipShape) {
                    this._fitImageToClipPath(imageObject, options);
                    this._centerAlignImage(imageObject, options.clipPath);
                } else if (options.fitToImageArea) {
                    this._adjustObjectWithImageArea(imageObject);
                }

                imageObject.setCoords();

                this._fabric.requestRenderAll();

                window.requestAnimationFrame(() => {
                    this._fabric.fire(FabricActions.objectModified);
                });

                return resolve(imageObject);
            });
        });
    }

    removeObject(object: ELStageObject): void {
        if (object.type === ELStageObjectType.group) {
            const group = (object as ELStageGroupObject);
            group.forEachObject((groupObj) => {
                group.remove(groupObj);
                this._fabric.remove(groupObj);
            });
            group.destroy();
            this._fabric.remove(group);
        } else {
            this._fabric.remove(object);
        }
    }

    clearCanvas(): void {
        const objects = this._fabric.getObjects();
        for (let i = 0; i < objects.length; i++) {
            this.removeObject(objects[i]);
        }
    }

    addObjectToStage(object: ELStageObject): void {
        this._fabric.add(object);
    }

    moveStageObject(object: ELStageObject, layerIndex: number): void {
        this._fabric.moveTo(object, this._baseLayerIndex + layerIndex);
    }

    async ensure2DEditCanvas(): Promise<void> {
        if (this._editCanvas && this._editCanvas.getContext("2d") !== null) return;

        this._editCanvas = await this.createNewEditCanvas();
        this._editCanvas.getContext("2d");
    }

    async ensureWebGLEditCanvas(options?: WebGLContextAttributes): Promise<void> {
        if (!options && this._editCanvas && this._editCanvas.getContext("webgl") !== null) return;

        this._editCanvas = await this.createNewEditCanvas();
        this._editCanvas.getContext("webgl", options);
    }

    async createNewEditCanvas(): Promise<HTMLCanvasElement> {
        // Create a new canvas element to overlay on top of the Fabric.js canvas
        if (this.container) {
            const stageContainer = this.createAndGetStageContainer(this.container) as HTMLDivElement;
            const canvasContainer = stageContainer.firstChild as HTMLCanvasElement;
            const editCanvas = document.createElement("canvas");
            editCanvas.id = "edit-canvas";
            editCanvas.width = canvasContainer.width;
            editCanvas.height = canvasContainer.height;
            editCanvas.style.position = "absolute";
            editCanvas.style.top = "0";
            editCanvas.style.left = "0";
            editCanvas.style.pointerEvents = "none"; // Ensure it doesn't interfere with Fabric.js canvas events
            editCanvas.style.top = this._imageAreaTransformOffset[1] + "px";
            editCanvas.style.left = this._imageAreaTransformOffset[0] + "px";
            editCanvas.style.width = (await this.doc.getSize()).width * this._imageAreaTransformScale + "px";
            editCanvas.style.height = (await this.doc.getSize()).height * this._imageAreaTransformScale + "px";
            //HACK_FIX - image was getting flipped on applying adjustments, so flipping mask canvas to fix it
            editCanvas.style.transform = "scaleY(-1)";
            if (this._editCanvas && this._fabric.getSelectionElement().parentElement?.contains(this._editCanvas)) {
                this._fabric.getSelectionElement().parentElement?.replaceChild(editCanvas, this._editCanvas);
            } else {
                this._fabric.getSelectionElement().parentElement?.appendChild(editCanvas);
            }
            return editCanvas;
        } else {
            return Promise.reject("ELFabricStage - createNewEditCanvas: container not defined");
        }
    }

    getEditCanvas(): HTMLCanvasElement | undefined {
        return this._editCanvas;
    }

    removeEditCanvas(): void {
        if (this._editCanvas) {
            this._editCanvas.width = 0;
            this._editCanvas.height = 0;
            this._editCanvas.remove();
            this._editCanvas = undefined;
        }
    }

    bringCanvasToFront(canvasId: CanvasId): void {
        if (this._editCanvas) {
            this._editCanvas.style.display = canvasId === CanvasId.edit ? "block" : "none";
        }
        (this._fabric as any).lowerCanvasEl.style.display = canvasId === CanvasId.main ? "block" : "none";
    }
}
