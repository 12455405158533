/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Adobe Internal
import { PIE } from "@piewasm/pie-web-npm-package";

//Application Internal
import { ELPoint, ELSize } from "../../../common/interfaces/geometry/ELGeometry";
import { CanvasChangedMode, CanvasChangedPayload, ELDownloadData, ELStageObject } from "../../../common/interfaces/stage/StageTypes";
import IWorkflow from "../../../workspaces/IWorkflow";
import Utils from "../../../utils/Utils";
import { ControllerData } from "../../../common/interfaces/editing/ControllerTypes";
import { ELPIELayer } from "./ELPIELayer";
import ILayer from "../../document/layer/ILayer";
import { DocumentActions, DocumentFormat, ELSaveOptions } from "../../../common/interfaces/document/DocumentTypes";
import ELPIEImageFormatFactory from "../formats/ELPIEImageFormatFactory";
import IDoc from "../../document/IDoc";
import { ELPIEDocActions, ELPIEDocPayload } from "../../../common/interfaces/editing/pie/ELPIEDocTypes";
import { ELPIEBlendOptions, ELPIELayerEffects } from "../../../common/interfaces/editing/pie/PIETypes";
import PIEUtils from "../utils/PIEUtils";
import Logger, { LogLevel } from "../../../utils/Logger";
import { ELStageLayerData } from "../../../common/interfaces/editing/layer/ELStageLayerTypes";
import { PIEEditingEngine } from "../../editingEngines/PIEEditingEngine";

export default class ELPIEDoc extends IDoc {
    private _initialized: boolean;
    private _pieImage?: any;
    private _pieInstance?: PIE;
    private _pieFS?: any;

    constructor(owner: IWorkflow) {
        super(owner);
        this._initialized = false;
    }

    protected async onObjectModified(object: ELStageObject): Promise<void> {
        const { width, height, top, left, scaleX, scaleY, data } = object;
        const layer = await this.getLayerById(data.payload as string);

        if (width && height && scaleX && scaleY) {
            const scaledWidth = Math.floor(width * scaleX);
            const scaledHeight = Math.floor(height * scaleY);

            layer.scaleLayer({ width: scaledWidth, height: scaledHeight });
        }

        if (left && top) {
            layer.setLastPosition({ x: left, y: top });
        }
    }

    protected async onObjectRotation(object: ELStageObject): Promise<void> {
        const { top, left, angle, data } = object;

        const layer = await this.getLayerById(data.payload as string);

        if (left && top && angle) {
            layer.rotateLayer(angle);
            layer.setLastOriginalSize(await layer.getSize());
            layer.setLastPosition({ x: left, y: top });
        }
    }

    protected async onObjectMoved(object: ELStageObject): Promise<void> {
        const { top, left, data } = object;
        const layer = await this.getLayerById(data.payload as string);

        if (left && top) {
            layer.moveLayer({ x: left, y: top });
        }
    }

    protected async updateDocument(payload: CanvasChangedPayload): Promise<void> {
        if (!payload.activeObject) {
            Logger.log(LogLevel.WARN, "ELPIEDoc - updateDocument: ", "activeObject is not defined");
            return;
        }
        switch (payload.mode) {
            case CanvasChangedMode.objectRotated: {
                this.onObjectRotation(payload.activeObject);
                break;
            }
            case CanvasChangedMode.objectModified: {
                this.onObjectModified(payload.activeObject);
                break;
            }
            case CanvasChangedMode.objectMoved: {
                this.onObjectMoved(payload.activeObject);
                break;
            }
        }
    }

    async initialize<T>(payload: T): Promise<boolean> {
        const { filename, buffer, engine } = (payload as unknown as ELPIEDocPayload);

        if (this._initialized) {
            return Promise.resolve(true);
        }

        this.engine = engine;

        const randomName = Utils.getRandomUUID();
        this.fileName = filename ?? randomName;

        this._pieInstance = (this.engine.getModule() as PIE);
        this._pieFS = this._pieInstance.FS as any;

        const stream = this._pieFS.open(this.fileName, "w");
        (this._pieFS).write(
            stream,
            buffer,
            0,
            buffer.length,
            0
        );
        this._pieFS.close(stream);

        return new Promise<boolean>((resolve) => {
            //this._pieFS.syncfs(false, async (err: any) => {
            //  if (err) {
            //    throw new Error(err);
            //} else {
            if (this._pieInstance) {
                this._pieImage = new (this._pieInstance.image)(this.fileName ?? randomName);
                this._initialized = true;
                resolve(true);
            } else {
                throw new Error("PIE instance invalid while intializing PIE doc");
            }
            //}
            //});
        });
    }

    destroy(): void {
        this.renderer?.destroy();
        this.layers?.forEach(layer => {
            (layer as unknown as ELPIELayer).destroy();
        });
        this._pieImage = undefined;
    }

    async addLayer(data: ELStageLayerData): Promise<string> {
        return new Promise((resolve, reject) => {
            if (!this._pieInstance) {
                reject("PIE instance is invalid!");
                return;
            }

            const layerParams = data.layerParams;

            if (!layerParams) {
                reject("layerParams is invalid!");
                return;
            }

            PIEUtils.getPIEPixelsFromArrayBuffer(data.data as ArrayBuffer, this._pieInstance, this._pieFS).then(async (pixels) => {
                if (!this._pieInstance) {
                    reject("PIE instance is invalid!");
                    return;
                }

                const index = this._pieImage.get_layer_count();

                const blendMode = this._pieInstance.blend_mode_e.normal;//(layerParams.layerBlendMode === ELLayerBlendModes.screen) ? this._pieInstance.blend_mode_e.screen : this._pieInstance.blend_mode_e.lighten;
                const pieLayerParams = new this._pieInstance.layer_params_t();

                // eslint-disable-next-line no-eval
                pieLayerParams.f_layer_blend_mode = eval(blendMode as unknown as string);
                pieLayerParams.f_group = layerParams.group ?? false;
                pieLayerParams.f_fill_neutral = layerParams.fillNeutral ?? false;
                pieLayerParams.f_opacity = layerParams.opacity ?? 100;
                pieLayerParams.f_layer_name = layerParams.name ?? "Layer " + (index + 1);

                const layer = this._pieImage.create_layer(pieLayerParams, index);
                layer.set_pixels(pixels);

                ELPIELayer.createLayer(layer, this, this.engine).then(async (pieLayer) => {
                    this.layers.push(pieLayer as unknown as ILayer);
                    const pivot = await pieLayer.getCenter();

                    if (data.editInfo?.transform?.flipY) {
                        await pieLayer.flipYLayer();
                    }

                    if (data.editInfo?.transform?.flipX) {
                        await pieLayer.flipXLayer();
                    }

                    if (data.editInfo && data.editInfo.transform) {
                        await pieLayer.scaleLayer({ width: data.editInfo.transform.quadrilateral.left_bottom * data.editInfo.transform.scale.horizontal, height: data.editInfo.transform.quadrilateral.right_bottom * data.editInfo.transform.scale.vertical });
                    }

                    if (data.editInfo?.transform?.center) {
                        await pieLayer.moveLayer({ x: (data.editInfo?.transform.center.horizontal - pivot.x), y: (data.editInfo?.transform.center.vertical - pivot.y) });
                    }

                    if (data.editInfo?.transform?.rotate) {
                        await pieLayer.rotateLayer(data.editInfo?.transform.rotate);
                    }

                    if (data.visible === false) {
                        await pieLayer.setVisibility(false);
                    }

                    resolve("0");

                    if (data.redraw) {
                        this.renderer?.update({ type: ELPIEDocActions.layerAdded, payload: pieLayer });
                    }
                });
            });
        });
    }

    async setLayerPixels(layerId: number, data: ArrayBuffer, redraw = false): Promise<boolean> {
        const layer = await this.getLayerById(layerId.toString());
        await layer.setPixels(data);
        if (redraw) {
            this.renderer?.update({ type: ELPIEDocActions.layerUpdated, payload: layer });
        }
        return true;
    }

    async setLayerBlendOptions(layerId: number, blendOptions: ELPIEBlendOptions, redraw = false): Promise<boolean> {
        const layer = await this.getLayerById(layerId.toString());
        await layer.setBlendOptions(blendOptions);

        if (redraw) {
            this.renderer?.update({ type: ELPIEDocActions.layerUpdated, payload: layer });
        }

        return true;
    }

    async setLayerEffects(layerId: number, effects: ELPIELayerEffects, redraw = false): Promise<boolean> {
        const layer = await this.getLayerById(layerId.toString());
        await layer.setEffects(effects);

        if (redraw) {
            this.renderer?.update({ type: ELPIEDocActions.layerUpdated, payload: layer });
        }

        return true;
    }

    async setLayerVisibility(layerIndex: number, visible: boolean, redraw = false): Promise<boolean> {
        const layer = await this.getLayerByIndex(layerIndex);
        await layer.setVisibility(visible);

        if (redraw) {
            this.renderer?.update({ type: ELPIEDocActions.layerUpdated, payload: layer });
        }

        return true;
    }

    async moveLayer(layerId: number, position: ELPoint, redraw = false): Promise<boolean> {
        const layer = await this.getLayerById(layerId.toString());
        await layer.moveLayer(position);

        if (redraw) {
            this.renderer?.update({ type: ELPIEDocActions.layerUpdated, payload: layer });
        }

        return true;
    }

    async rotateLayer(layerId: number, angle: number, redraw = false): Promise<boolean> {
        const layer = await this.getLayerById(layerId.toString());
        await layer.rotateLayer(angle);

        if (redraw) {
            this.renderer?.update({ type: ELPIEDocActions.layerUpdated, payload: layer });
        }

        return true;
    }

    async scaleLayer(layerId: number, newSize: ELSize, redraw = false): Promise<boolean> {
        const layer = await this.getLayerById(layerId.toString());
        await layer.scaleLayer(newSize);

        if (redraw) {
            this.renderer?.update({ type: ELPIEDocActions.layerUpdated, payload: layer });
        }

        return true;
    }

    async notify(action: ControllerData): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case DocumentActions.documentUpdated: {
                //this.updateDocument(action.payload as CanvasChangedPayload);
                this.dataParser?.parseDocUpdatedData(action.payload);
                handled = true;
                break;
            }
        }

        return handled;
    }

    save(saveOptions?: ELSaveOptions): void {
        const format = saveOptions?.imageData.format ?? DocumentFormat.PSD;
        const imageFormat = ELPIEImageFormatFactory.createFormat(format);

        imageFormat.writeFormat(this, this.engine as PIEEditingEngine, saveOptions);
    }

    async render(container: HTMLElement): Promise<void> {
        await this.renderer?.render(container);
    }

    close(): Promise<void> {
        throw new Error("Method not implemented.");
    }

    getOriginalSize(): Promise<ELSize> {
        return this.getSize();
    }

    getData(): unknown {
        return this._pieImage;
    }

    getImageData(): Promise<ImageData> {
        return this._pieImage;
    }

    setData(data: unknown): void {
        this._pieImage = data;
    }

    getSize(): Promise<ELSize> {
        return Promise.resolve({
            width: Number(this._pieImage.get_composite().get_bounds().width()),
            height: Number(this._pieImage.get_composite().get_bounds().height())
        });
    }

    documentUpdated(payload: unknown): void {
        throw new Error("Method not implemented.");
    }

    async download(downloadData: ELDownloadData): Promise<void> {
        this.downloader?.download(downloadData);
    }

    async getLayerById(id: string): Promise<ILayer> {
        const layers = await this.getLayers();
        const layer = layers.filter((layer) => {
            if (layer.getId() === id) {
                return true;
            }
            return false;
        })[0];

        return layer;
    }

    async getLayerByIndex(index: number): Promise<ILayer> {
        const layers = await this.getLayers();
        return layers[index];
    }

    async getLayers(): Promise<ILayer[]> {
        if (this.layers.length > 0) {
            return this.layers;
        }

        let layerCount = this._pieImage.get_layer_count();

        if (layerCount === 0 && this._pieImage.can_convert_background_to_layer()) {
            this._pieImage.convert_background_to_layer();
            layerCount = 1;
        }

        for (let layerIndex = layerCount - 1; layerIndex >= 0; layerIndex--) {
            const layer = this._pieImage.get_layer(layerIndex);
            const pieLayer = await ELPIELayer.createLayer(layer, this, this.getEngine());
            await pieLayer.initialize(this._pieImage.get_layer(layerIndex));
            this.layers.push(pieLayer as unknown as ILayer);
        }

        return this.layers.reverse();
    }
}