/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { View } from "@adobe/react-spectrum";
import React, { ChangeEvent, useRef } from "react";
import { IconType } from "../../../../assets/IconConstants";
import { FILE_TYPE_FILTER } from "../../../../common/interfaces/storage/FileTypes";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { IngestEventContentNameTypes, IngestEventCountTypes, IngestEventSubTypes, IngestEventTypes, IngestLogObjectKey, IngestWorkflowTypes } from "../../../../utils/IngestConstants";
import { IngestUtils } from "../../../../utils/IngestUtils";
import { Theme } from "../../../../utils/Theme";
import { WorkflowActionType } from "../../../../workspaces/IWorkflow";
import IViewController from "../../../IViewController";
import ELButton from "../../atoms/el-button/ELButtonView";
import ELCustomIcon from "../../molecules/el-custom-icon/ELCustomIcon";
import ELIconButton from "../../molecules/el-icon-button/ELIconButtonView";

import "./ELImportButton.scss";

export enum ELImportButtonAction {
    startImport = "START_IMPORT",
    changeImportProgressState = "CHANGE_IMPORT_PROGRESS_STATE",
    changeFilterEnableState = "CHANGE_FILTER_STATE"
}

interface ImportButtonProps {
    controller: IViewController,
    isDisabled: boolean,
    variant: "add" | "add-from-computer" | "upload-media",
}

const ImportButton = (props: ImportButtonProps): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();
    const importButtonRef = useRef<HTMLInputElement>(null);

    const openFilePicker = (): void => {
        if (importButtonRef.current) {
            importButtonRef.current.click();
        }
        props.controller.notify({
            type: WorkflowActionType.ingest,
            payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.mediaGrid, IngestEventTypes.click,
                IngestEventSubTypes.import, true)
        });
    }

    const startImportWorkflow = (event: ChangeEvent<HTMLInputElement>): void => {
        if (event.target.files?.length && event.target.files.length > 0) {
            props.controller.notify({
                type: ELImportButtonAction.startImport,
                payload: event.target.files
            });
        }
        event.target.value = '';
        if (props.variant === "upload-media") {
            const additionalLogInfo: Record<string, string> = {};
            additionalLogInfo[IngestLogObjectKey.contentName] = IngestEventContentNameTypes.importMode;
            additionalLogInfo[IngestLogObjectKey.eventCount] = IngestEventCountTypes.addButton;
            props.controller.notify({
                type: WorkflowActionType.ingest,
                payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations, IngestEventTypes.success,
                    IngestEventSubTypes.import, undefined, additionalLogInfo)
            });
        }
    }

    const getButtonText = (): string => {
        switch (props.variant) {
            case "upload-media":
                return intlHandler.formatMessage("upload-media");
            case "add-from-computer":
                return intlHandler.formatMessage("add-from-computer");
            case "add":
            default:
                return intlHandler.formatMessage("add");
        }
    };

    const getAddVariantButtonView = (): React.ReactNode => {
        return (
            <>
                <View UNSAFE_className="media-import-mobile__cta">
                    <ELCustomIcon variant="independent" iconkey={IconType.import} color={Theme.global.controls_color_N}
                        onPress={openFilePicker} isDisabled={props.isDisabled}
                        hoverColor={Theme.global.controls_color_H} width={"2.25rem"} height={"2.25rem"} />
                </View>
                <View UNSAFE_className="media-import-desktop__cta">
                    <ELIconButton size="L" iconkey={IconType.import} iconColor={Theme.dark.gray_controls_color_N}
                        iconHoverColor={Theme.dark.gray_controls_color_N} iconWidth={"1.125rem"} iconHeight={"1.125rem"}
                        onClick={openFilePicker} isDisabled={props.isDisabled}>
                        <span className="media-import-button-text">{getButtonText()}</span>
                    </ELIconButton>
                </View>
            </>
        );
    };

    const getAddFromComputerVariantButtonView = (): React.ReactNode => {
        return (
            <>
                <ELButton size="L" onClick={openFilePicker} isDisabled={props.isDisabled}>
                    <span className="add-from-computer dialog__cta-text"> {getButtonText()} </span>
                </ELButton>
            </>
        );
    };

    const getUploadMediaVariantButtonView = (): React.ReactNode => {
        return (
            <ELCustomIcon variant="independent" iconkey={IconType.UploadImageIcon} isDisabled={props.isDisabled}
                hoverColor={Theme.global.controls_color_H} color={Theme.global.controls_color_N}
                width={"3.6rem"} height={"3.6rem"} cursor={"pointer"} onPress={openFilePicker} />
        );
    };

    const getButtonView = (): React.ReactNode => {
        switch (props.variant) {
            case "upload-media":
                return getUploadMediaVariantButtonView();
            case "add-from-computer":
                return getAddFromComputerVariantButtonView();
            case "add":
            default:
                return getAddVariantButtonView();
        }
    }

    return (
        <>
            <input type="file" id="import-input" className="hidden-input-container"
                multiple accept={FILE_TYPE_FILTER.join(", ")}
                onChange={startImportWorkflow} ref={importButtonRef} />
            {getButtonView()}
        </>
    );
}

ImportButton.defaultProps = {
    variant: "add"
}

export default ImportButton;
