/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { ELStageObject } from "../../../common/interfaces/stage/StageTypes";
import IStageObjectToJsonConverter from "./IStageObjectToJsonConverter";

export class ELStageObjectToJsonConverterByTextLayer extends IStageObjectToJsonConverter {
    toJson(stageObject: ELStageObject): unknown {
        const json = stageObject.toObject();
        const properties = ["top", "left", "width", "height", "scaleX", "scaleY", "flipX", "flipY", "angle",
            "text", "fontFamily", "fontSize", "textAlign", "stroke", "strokeWidth", "shadow", "underline", "styles"];
        const filteredJson: any = {};
        properties.forEach((property) => {
            if (Object.prototype.hasOwnProperty.call(json, property)) {
                filteredJson[property] = json[property];
            }
        })
        return filteredJson;
    }
}