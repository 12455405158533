/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import { fabric } from "fabric";

//Application Specific
import { CSLayerEditInfo, CSLayerTransformInfo, CSLayerTextInfo } from "../../../common/interfaces/editing/layer/ELStageLayerTypes";
import { ELPIEPoint, ELPIEQuad } from "../../../common/interfaces/geometry/ELGeometry";
import { RenderedShapesName } from "../../../common/interfaces/renderer/RendererTypes";
import { ELStageBackgroundMode, ELStageBackgroundOptions, ELStageShapeAndObjectOptions, ELStageShapesType } from "../../../common/interfaces/stage/StageTypes";
import ILayer from "../../document/layer/ILayer";
import IDocDataParser from "../../document/parser/IDocDataParser";
import ShapesFactory from "../../stage/shapes/ShapesFactory";
import ELStageObjectUtils from "../../../utils/ELStageObjectUtils";
import { StageTextUtils } from "../../../utils/stage/StageTextUtils";
import Logger, { LogLevel } from "../../../utils/Logger";

export default class ELStageDocDataParser extends IDocDataParser {

    private async _createImageArea(): Promise<ELStageShapeAndObjectOptions> {
        const shape = ShapesFactory.createShape(ELStageShapesType.imageArea);
        const { width, height } = await this.doc.getSize();

        const backgroundOptions: ELStageBackgroundOptions = {
            name: RenderedShapesName.imageArea,
            width: width,
            height: height,
            background: { mode: ELStageBackgroundMode.transparent, value: "" },
        };
        const stageAndObjectOptions: ELStageShapeAndObjectOptions = { shape: shape, objectOptions: backgroundOptions };

        return stageAndObjectOptions;
    }

    private async _createLayerData(): Promise<ELStageShapeAndObjectOptions[]> {
        const layers = await this.doc.getLayers();

        const stageAndObjectOptionsList: ELStageShapeAndObjectOptions[] = [];

        for (const layer of layers) {
            stageAndObjectOptionsList.push(await this.getStageData(layer));
        }

        return stageAndObjectOptionsList;
    }

    private async _getLayerStageOptions(layer: ILayer): Promise<Record<string, unknown>> {
        const stageType = layer.getStageShapeType();
        switch (stageType) {
            case ELStageShapesType.path: {
                return { svgPath: await layer.getStageData() };
            }
            case ELStageShapesType.text: {
                return { text: await layer.getStageData() };
            }
            case ELStageShapesType.rect: {
                return { fill: layer.getFill() };
            }
            case ELStageShapesType.imageFromURI:
            default: {
                return { image: await layer.getStageData() };
            }
        }
    }

    private _getTransformInfo(object: fabric.Object): CSLayerTransformInfo {
        const { width, height, top, left, scaleX, scaleY, angle, flipX, flipY } = object;

        const quadrilateral: ELPIEQuad = {
            left_top: left ?? 0,
            right_top: top ?? 0,
            left_bottom: width ?? 0,
            right_bottom: height ?? 0
        };

        const scale: ELPIEPoint = {
            horizontal: scaleX ?? 1,
            vertical: scaleY ?? 1
        };

        const offset: ELPIEPoint = {
            horizontal: left ?? 0,
            vertical: top ?? 0
        };

        const center: ELPIEPoint = { horizontal: object.getCenterPoint().x, vertical: object.getCenterPoint().y };

        const rotate = angle ?? 0;

        const layerTransformInfo: CSLayerTransformInfo = {
            quadrilateral: quadrilateral,
            scale: scale,
            rotate: rotate,
            offset: offset,
            center: center,
            flipX: flipX,
            flipY: flipY
        };

        return layerTransformInfo;
    }

    private _getTextInfo(object: fabric.Object): CSLayerTextInfo | undefined {
        let textOptions: CSLayerTextInfo | undefined;
        if (StageTextUtils.isTextObject(object)) {
            const textObject = object as fabric.Text;
            if (textObject.text && textObject.fontFamily && textObject.fontSize) {
                textOptions = {
                    text: textObject.text,
                    fontFamily: textObject.fontFamily,
                    fontSize: textObject.fontSize,
                    stroke: textObject.stroke,
                    strokeWidth: textObject.strokeWidth,
                    textAlign: textObject.textAlign,
                    underline: textObject.underline,
                    styles: textObject.styles,
                };
                if (textObject.shadow instanceof fabric.Shadow) {
                    textOptions.shadowBlur = textObject.shadow?.blur;
                    textOptions.shadowColor = textObject.shadow?.color;
                }
            }
        }
        return textOptions;
    }

    async getStageData<T>(data: T): Promise<ELStageShapeAndObjectOptions> {
        const layer = data as unknown as ILayer;

        const shape = ShapesFactory.createShape(layer.getStageShapeType());
        const objectOptions = await ELStageObjectUtils.getStageObjectOptions(layer);

        const stageAndObjectOptions: ELStageShapeAndObjectOptions = {
            shape: shape,
            objectOptions: { ...objectOptions, ...await this._getLayerStageOptions(layer) },
        };

        if (layer.getIsClipped()) {
            const clipShapeType = layer.getClipPathStageShapeType();
            stageAndObjectOptions.clipShape = ShapesFactory.createShape(clipShapeType);

            const clipLayerOptions = layer.getClipLayerOptions();
            if (clipLayerOptions) {
                stageAndObjectOptions.clipObjectOptions = ELStageObjectUtils.getStageClipObjectOptions(clipLayerOptions);
            } else {
                Logger.log(LogLevel.WARN, "layer.getIsClipped() is true but clipLayerOptions is undefined");
            }
        }

        return stageAndObjectOptions;
    }

    async parseDocData(): Promise<ELStageShapeAndObjectOptions[]> {
        const stageAndObjectsOptions: ELStageShapeAndObjectOptions[] = [];
        stageAndObjectsOptions.push(await this._createImageArea());
        stageAndObjectsOptions.push(...(await this._createLayerData()));
        return stageAndObjectsOptions;
    }

    parseDocUpdatedData(data: unknown): unknown {
        const objectList = data as fabric.Object[];
        const layerEditInfoList: CSLayerEditInfo[] = [];

        objectList.forEach((object) => {
            if (object.name !== RenderedShapesName.layer) {
                return;
            }

            const transformInfo = this._getTransformInfo(object);
            const textInfo = this._getTextInfo(object);

            const layerEditInfo: CSLayerEditInfo = {
                transform: transformInfo,
                opacity: object.opacity,
                fill: object.fill,
                textInfo: textInfo
            };

            layerEditInfoList.push(layerEditInfo);
        });

        return layerEditInfoList;
    }

    parseDocUpdatedClipData(data: unknown): unknown {
        const objectList = data as fabric.Object[];
        const layerClipDataEditInfoList: (CSLayerEditInfo | undefined)[] = [];

        objectList.forEach((object) => {
            if (object.name !== RenderedShapesName.layer) {
                return;
            }

            const clipObject = object.clipPath;
            if (clipObject) {
                const transformInfo = this._getTransformInfo(clipObject);
                const textInfo = this._getTextInfo(clipObject);

                const layerEditInfo: CSLayerEditInfo = {
                    transform: transformInfo,
                    opacity: object.opacity,
                    fill: object.fill,
                    textInfo: textInfo
                };
                layerClipDataEditInfoList.push(layerEditInfo);
            } else {
                layerClipDataEditInfoList.push(undefined);
            }
        });

        return layerClipDataEditInfoList;
    }
}