/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

// Third party
import React, { useEffect, useReducer, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Provider, useSelector } from "react-redux";

// Adobe internal
import { Button, Flex, Item, TabList, Tabs, Icon } from "@adobe/react-spectrum";
import { Text } from "@adobe/react-spectrum";
import { usePress } from "@react-aria/interactions";
import { PressEvent } from '@react-types/shared';
import Banner from "@react/react-spectrum/Banner";

//Utils
import Utils from "../../../../utils/Utils";
import { ShareUtils } from "../../../../utils/ShareUtils";
import { TrialUtils } from "../../../../utils/TrialUtils";
import { PlatformUtils } from "../../../../utils/PlatformUtils";
import { IngestUtils } from "../../../../utils/IngestUtils";
import { useViewport } from "../../../../utils/hooks/useViewport";
import { IngestEventSubTypes, IngestEventTypes, IngestWorkflowTypes } from "../../../../utils/IngestConstants";
import { UserUtils } from "../../../../utils/UserUtils";

//Application specific
import { IconType } from "../../../../assets/IconConstants";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import IconAndDescriptor from "../../molecules/el-icon-and-text/ELIconAndText";
import Navbar from "./ELNavbar";
import ELUniversalNav from "../../molecules/universalnav/ELUniversalNav";
import ELStorageQuotaNav from "../../molecules/el-storage-quota-nav/ELStorageQuotaNavView";
import { Routes } from "../../../../app/AppRoute";
import { ELIcon } from "../../atoms/el-icon/ELIconView";
import { PHOTOSHOP_ELEMENTS_APP_NAME } from "../../../../utils/Constants/Constants";
import { ViewAction } from "../../../IBaseController";
import { ELNavBarControllerAction, ELNavBarViewAction } from "../../../../common/interfaces/navbar/ELNavBarTypes";
import { TrialState } from "../../../../common/interfaces/trial/TrialTypes";
import store, { RootState } from "../../../../stores/store";
import { WorkflowActionType } from "../../../../workspaces/IWorkflow";
import { ControllerAction } from "../../../IViewController";
import { ELReactSpectrumV2Provider } from "../../atoms/el-react-spectrum-provider/ELReactSpectrumV2Provider";
import { ELReactSpectrumV3Provider } from "../../atoms/el-react-spectrum-provider/ELReactSpectrumV3Provider";
import { AccessProfileManager } from "../../../../modules/accessProfile/AccessProfile";
import IMS from "../../../../services/IMS";

import "./ELNavbarView.scss";

interface NavbarViewProps {
	controller: Navbar,
	signedIn: boolean,
	canStartFreeTrial: boolean
}

interface NavbarTabsPanelProps {
	defaultSelectedKey: string,
	highlightedKeys: Routes[],
	onTabChange: (key: React.Key) => void
	notify: (_: ViewAction) => void
}

interface TabOptionProps {
	text: string,
	icon: IconType,
	highlighted: boolean,
	isSelected?: boolean
}

const RANDOM_NAVBAR_OPTIONS_SEED_KEY = 10977;

const TabOption = (props: TabOptionProps): React.ReactElement => {
	const TabOptionTextClass = `tab-option__text ${props.isSelected ? "tab-option__bold-text" : ""}`;
	const textRef = useRef<HTMLSpanElement>(null);

	useEffect(() => {
		if (!textRef.current) { return; }
		const dropdownMenuItem = textRef.current.closest("._spectrum-Menu-itemGrid_7c360");
		if (!dropdownMenuItem) { return; }
		// This is a popover option now
		dropdownMenuItem.setAttribute("style", "align-items:center");
		const checkMark = dropdownMenuItem.querySelector("._spectrum-Menu-checkmark_7c360")
		if (checkMark) {
			checkMark.setAttribute("style", "align-self: auto");
		}
	}, [textRef.current]);


	return (<>
		<Icon>
			<ELIcon iconkey={props.icon} className="tab-option__icon" />
		</Icon>
		{props.highlighted ? <div className="circle"></div> : <></>}
		<Text UNSAFE_className={TabOptionTextClass}>
			<span ref={textRef}>{props.text}</span>
		</Text>
	</>
	);
}

const NavbarTabsPanel = (props: NavbarTabsPanelProps): React.ReactElement => {
	const intlHandler = IntlHandler.getInstance();
	const [navbarData, setNavbarData] = useState([
		{ icon: IconType.home, text: intlHandler.formatMessage("home"), linkTo: Routes.HOME, isSelected: false },
		{ icon: IconType.media, text: intlHandler.formatMessage("media"), linkTo: Routes.MEDIA, isSelected: false },
		{ icon: IconType.creation, text: intlHandler.formatMessage("creations"), linkTo: Routes.CREATIONS, isSelected: false },
	]);
	const [selectedTab, setSelectedTab] = useState(props.defaultSelectedKey);
	const [tabsKey, setTabsKey] = useState(RANDOM_NAVBAR_OPTIONS_SEED_KEY);
	const history = useHistory();
	const { width, height } = useViewport();

	useEffect(() => {
		if (selectedTab !== props.defaultSelectedKey) {
			setSelectedTab(props.defaultSelectedKey);
			return;
		}
		setNavbarData(navbarData.map(item => {
			item.linkTo === selectedTab ? item.isSelected = true : item.isSelected = false
			return item;
		}));
		setTabsKey(tabsKey ^ 1);
		props.notify({
			type: WorkflowActionType.ingest,
			payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.tabs, IngestEventTypes.click,
				IngestEventSubTypes.success, props.defaultSelectedKey)
		});
	}, [selectedTab, props.defaultSelectedKey]);

	useEffect(() => {
		const isMobileDevice = PlatformUtils.isMobileDevice((width as number), (height as number));
		if (isMobileDevice) {
			const mobileNavBarData = navbarData.filter((ele) => ele.icon !== IconType.creation).map((ele) => {
				return ele;
			});
			setNavbarData(mobileNavBarData);
		} else if (!navbarData.some(item => item.linkTo === Routes.CREATIONS ? true : false)) {
			setNavbarData([...navbarData,
			{ icon: IconType.creation, text: intlHandler.formatMessage("creations"), linkTo: Routes.CREATIONS, isSelected: false }]);
		}
	}, [width, height]);

	const onSelectionChange = (key: React.Key): void => {
		history.push(key.toString());
		setSelectedTab(key.toString());
		props.onTabChange(key);
	}

	return (
		<Tabs defaultSelectedKey={selectedTab} key={tabsKey} onSelectionChange={(key) => { onSelectionChange(key) }}>
			<TabList height="100%">
				{
					navbarData.map((item) => <Item key={item.linkTo} textValue="item">
						<TabOption icon={item.icon} text={item.text} isSelected={item.isSelected}
							highlighted={props.highlightedKeys.includes(item.linkTo) && selectedTab !== item.linkTo} />
					</Item>)}
			</TabList>
		</Tabs >
	);
}

const useUserProfile = (signedIn: boolean): boolean => {
	const [hasUserProfile, setHasUserProfile] = useState(false);

	useEffect(() => {
		// Check initial profile state
		const profile = IMS.getInstance().getUserProfile();
		setHasUserProfile(!!profile);

		// If signed in but no profile, set up an interval to check
		if (signedIn && !profile) {
			const intervalId = setInterval(() => {
				const profile = IMS.getInstance().getUserProfile();
				if (profile) {
					setHasUserProfile(true);
					clearInterval(intervalId);
				}
			}, 100);

			// Cleanup interval on unmount or when dependencies change
			return () => clearInterval(intervalId);
		}

		// Reset state when signed out
		if (!signedIn) {
			setHasUserProfile(false);
		}

		return undefined;
	}, [signedIn]);

	return hasUserProfile;
};

const GenInfoDiv = (props: { isPreview: boolean, signedIn: boolean, login: () => void, notify: (_: ControllerAction) => void, canStartFreeTrial: boolean, showCreationButton: boolean })
	: React.ReactElement => {
	const hasUserProfile = useUserProfile(props.signedIn);

	const handleStartTrialClick = (): void => {
		props.notify({ type: ELNavBarControllerAction.startFreeTrial });
	}

	const handleCreateWithElementsClick = () : void => {
		props.notify({ type: ELNavBarControllerAction.createWithElements });
	}

	const ingestUtilityNavClick = usePress({
		onPress: () => props.notify({
			type: WorkflowActionType.ingest,
			payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.utilityNav, IngestEventTypes.click, IngestEventSubTypes.icon, true)
		})
	});

	const daysInTrial = useSelector((state: RootState) => state.appReducer.daysInTrial);
	const { width, height } = useViewport();

	const getTrialExpireBannerMessage = (): string => {
		if (AccessProfileManager.getInstance().isTermExpired())
			return intlHandler.formatMessage("term-expired");
		else
			return intlHandler.formatMessage("trial-expired");
	}

	const getTrialBanner = (): React.ReactElement => {
		const isMobileDevice = PlatformUtils.isMobileDevice((width as number), (height as number));
		if (props.isPreview || UserUtils.isUserPaid() || isMobileDevice || PlatformUtils.isIpadOS())
			return <></>;

		const trialState = TrialUtils.getTrialState(daysInTrial);
		switch (trialState) {
			case TrialState.inProgress:
				return <Banner content={intlHandler.formatMessage("num-trial-days-left", { count: TrialUtils.getDaysLeftForTrial(daysInTrial) })} variant={TrialUtils.isTrialGoingToExpire(daysInTrial) ? "error" : "info"} />;
			case TrialState.expired:
				return <Banner content={getTrialExpireBannerMessage()} variant="error" />;
			case TrialState.notStarted:
				return <></>
		}
	}

	const getStorageQuotaDiv = (): React.ReactElement => {
		const storageQuotaDiv = !props.isPreview ?
			<div className="gen-info-div__storage-nav-parent" >
				<ELStorageQuotaNav />
			</div >
			: <></>;
		return storageQuotaDiv;
	}

	const getCreationButton = (): React.ReactElement => {
		const creationButton = props.showCreationButton ?
			<Button variant="cta" onPress={handleCreateWithElementsClick} UNSAFE_className={"navbar__share-page-button"}>
				<Text UNSAFE_className="anonymous-user-cta-text">
					{intlHandler.formatMessage("create-with-elements")}
				</Text>
			</Button>
			: <></>
		return creationButton;
	}

	const intlHandler = IntlHandler.getInstance();
	if (!props.signedIn) {
		return (
			<Flex gap="1.25rem" UNSAFE_className={"navbar__cta-container"}>
				{getCreationButton()}
				<Button variant="primary" onPress={props.login} UNSAFE_className={"navbar-button-large"}>
					<Text UNSAFE_className="anonymous-user-cta-text">
						{intlHandler.formatMessage("sign-in")}
					</Text>
				</Button>
				{props.canStartFreeTrial ? <Button variant="cta" onPress={handleStartTrialClick} UNSAFE_className={"navbar-button-large"}>
					<Text UNSAFE_className="anonymous-user-cta-text">
						{intlHandler.formatMessage("start-free-trial")}
					</Text>
				</Button> : <></>}
			</Flex>

		);
	}

	return (
		<Flex direction="row" gap="1.2rem" alignItems="center" justifyContent="center">
			<ELReactSpectrumV2Provider>
				{getTrialBanner()}
			</ELReactSpectrumV2Provider>
			<div id="whats-new-icon-container" />
			<div id="feedback-icon-container" />
			<div id="helpmenu-container" />
			{getStorageQuotaDiv()}
			{getCreationButton()}
			{props.signedIn && hasUserProfile &&
				<div className="gen-info-div__utility-nav-parent" {...ingestUtilityNavClick.pressProps}>
					<ELUniversalNav signedIn theme={"light"} containerDivId="el-util-nav-container" />
				</div>
			}
		</Flex >
	);
}

const makeValidRoute = (path: string): string => {
	const transformedPath = path.split("/")[1].toLowerCase();
	return "/" + transformedPath;
}

function getDefaultSelectedKey(path: string): string {
	const pathRegEx = new RegExp(Routes.VIEW, "gi");
	const isViewPath = path.match(pathRegEx);

	const sivPathRegEx = new RegExp(Routes.SIV, "gi");
	const isSIVPath = path.match(sivPathRegEx);

	if (isViewPath || isSIVPath)
		return "/" + Routes.MEDIA?.split("/")[1];

	return "/" + path?.split("/")[1];

}

interface ELNavBarState {
	highlightNavBarList: Routes[]
}

const initialState: ELNavBarState = {
	highlightNavBarList: []
}

const NavbarView = (props: NavbarViewProps): React.ReactElement => {
	// Since nav bar is dependent on the url // path param for preview is "view" as of now
	const location = useLocation();
	const defaultSelectedKey = getDefaultSelectedKey(makeValidRoute(location.pathname.toLowerCase()));
	const reducer = (state: ELNavBarState, action: ViewAction): ELNavBarState => {
		switch (action.type) {
			case ELNavBarViewAction.appendHighlightNavBar: {
				const route = action.payload as Routes;
				if (state.highlightNavBarList.includes(route))
					return state;
				const updatedHighlightList = [...state.highlightNavBarList, route];
				return {
					...state,
					highlightNavBarList: updatedHighlightList
				};
			}
			case ELNavBarViewAction.removeHighlightNavBar: {
				const route = action.payload as Routes;
				const updatedHighlightList = state.highlightNavBarList.filter((ele) => {
					return ele !== route;
				});
				return {
					...state,
					highlightNavBarList: updatedHighlightList
				};
			}
			default:
				return state;
		}
	}
	const [state, viewDispatch] = useReducer(reducer, initialState);
	const daysInTrial = useSelector((state: RootState) => state.appReducer.daysInTrial);
	const minimalOptions = props.controller.shouldShowMinimalOptions(daysInTrial);
	let shouldAllowNavigation = props.controller.shouldAllowNavigation();
	const { signedIn } = props;
	const history = useHistory();
	const notify = props.controller.notify.bind(props.controller);

	useEffect(() => {
		props.controller.initialize(viewDispatch);
		return () => {
			props.controller.destroy();
		}
	}, [props.controller])

	useEffect(() => {
		props.controller.notify({ type: ELNavBarControllerAction.tabSelectionChanged, payload: defaultSelectedKey });
	}, [defaultSelectedKey])

	useEffect(() => {
		shouldAllowNavigation = props.controller.shouldAllowNavigation();
	}, [daysInTrial]);

	const handleAppTitleBarClick = (e: PressEvent): void => {
		if (minimalOptions) {
			Utils.openInNewTab(Routes.MEDIA)
		} else {
			history.push(Routes.MEDIA);
		}
		notify({
			type: WorkflowActionType.ingest,
			payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.branding, IngestEventTypes.click, IngestEventSubTypes.icon, true)
		});
	}

	const onTabChange = (key: React.Key): void => {
		viewDispatch({
			type: ELNavBarViewAction.removeHighlightNavBar,
			payload: key as Routes
		})

		props.controller.notify({ type: ELNavBarControllerAction.tabSelectionChanged, payload: key });
	}

	return (
		<Provider store={store}>
			<ELReactSpectrumV3Provider width="95%" alignSelf={"stretch"} >
				{/* Light theme is overrides by system settings in mac*/}
				<Flex height="100%" UNSAFE_className="navbar">
					<Flex UNSAFE_className={ShareUtils.isLinkPreviewWorkflow() ? "navbar__app-share-logo-box" : "navbar__app-logo-box"}>
						<Button variant="secondary" onPress={handleAppTitleBarClick} alignSelf="center"
							UNSAFE_style={{ backgroundColor: "transparent", border: 0 }}
						>
							<IconAndDescriptor icon={IconType.appLogo} iconClass="navbar__app-logo"
								text={PHOTOSHOP_ELEMENTS_APP_NAME} textClass="navbar__app-name" />
							{ShareUtils.isLinkPreviewWorkflow() ? <ELIcon iconkey={IconType.popout} width="2rem" height="2rem" /> : <></>}
						</Button>
					</Flex>
					<Flex UNSAFE_className="navbar__tabs-panel-container" alignItems="end">
						{shouldAllowNavigation ? <NavbarTabsPanel notify={notify} highlightedKeys={state.highlightNavBarList}
							onTabChange={(key) => onTabChange(key)} defaultSelectedKey={defaultSelectedKey} /> : <></>}
					</Flex>
					<Flex UNSAFE_className="navbar__userinfo-container" alignItems="center">
						<GenInfoDiv isPreview={minimalOptions} signedIn={signedIn} login={props.controller.login.bind(props.controller)} canStartFreeTrial={props.canStartFreeTrial}
							notify={notify} showCreationButton={ShareUtils.isLinkPreviewWorkflow()} />
					</Flex>
				</Flex>
			</ELReactSpectrumV3Provider >
		</Provider>
	);
}

export default NavbarView;
