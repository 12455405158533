/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { ContentDataType, ContentSource, IBaseInfo } from "@elements/elementswebcommon";
import { ELStageLayerData } from "../../common/interfaces/editing/layer/ELStageLayerTypes";
import { ELCreationContentPayload } from "../../common/interfaces/creations/ELRecommendationsWorkflowTypes";
import { BackgroundLayerRequestParams } from "./ContentDataManagerTypes";

//TODO @gmahal : if we need to store ELCreationContentPayload in the class itself 
export abstract class ContentDataManager {

    abstract getUpdateContentData(contentPayload: ELCreationContentPayload): Promise<unknown>;
    abstract getContentLayerData(contentPayload: ELCreationContentPayload): Promise<ELStageLayerData>;
    abstract getBackgroundContentData(contentPayload: ELCreationContentPayload): Promise<IBaseInfo | undefined>;
    abstract getContentDataType(): ContentDataType | undefined;
    abstract getContentSource(): ContentSource | undefined;
    abstract getBackgroundLayerPayload(backgroundLayerPayloadRequestParams: BackgroundLayerRequestParams): Promise<ELStageLayerData>;
}