/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { useReducer, useEffect } from "react";
import { useDispatch } from "react-redux";

//Adobe internal
import { Text, Flex, ProgressCircle } from "@adobe/react-spectrum";

//Application specific
import { ShareOptions } from "./ELShareOptions";
import { ELOption } from "../../atoms/el-option/ELOptionView";
import { ELIcon } from "../../atoms/el-icon/ELIconView";
import { ShareAction } from "../../../../stores/actions/ShareAction";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { ViewAction } from "../../../IBaseController";
import { ELShareOptionsViewAction, QRShareOptionsData } from "../../../../common/interfaces/share/ShareTypes";
import { FeaturesManager } from "../../../../modules/floodgate/Featuresmanager";
import { FeatureName } from "../../../../services/Floodgate/FloodgateConstants";

import "./ELShareOptionsView.scss";

export enum ShareOptionsActionType {
    openShareWorkflow = "OPEN_SHARE_WORKFLOW",
    openShareWorkspace = "OPEN_SHARE_WORKSPACE",
    createShareQR = "CREATE_SHARE_QR"
}

interface ShareOptionsViewProps {
    controller: ShareOptions,
    onPress: () => void,
    showQRView: boolean
}

interface ELShareOptionsState {
    qrLink?: string,
    qrData?: QRShareOptionsData[]
}

const ShareOptionsView = (props: ShareOptionsViewProps): React.ReactElement => {
    const { controller } = props;
    const data = controller.getOptions;
    const dispatch = useDispatch();
    const intlHandler = IntlHandler.getInstance();

    const reducer = (state: ELShareOptionsState, action: ViewAction): ELShareOptionsState => {
        switch (action.type) {
            case ELShareOptionsViewAction.qrLink:
                return {
                    ...state,
                    qrLink: action.payload as string
                };
            case ELShareOptionsViewAction.qrData:
                return {
                    ...state,
                    qrData: action.payload as QRShareOptionsData[]
                };
            default:
                return state;
        }
    }
    const [state, viewDispatch] = useReducer(reducer, {});

    useEffect(() => {
        props.controller.initialize(viewDispatch);

        //clean up
        return () => {
            props.controller.destroy();
        }
    }, [props.controller]);

    const progressCircleThumb = (
        <div className="el-share-option-panel__qr-progress-loader">
            <ProgressCircle isIndeterminate size="M" margin="auto"
                aria-label={intlHandler.formatMessage("progress-circle")} />
        </div>
    );

    const getQRView = (): React.ReactNode => {
        return (
            <Flex UNSAFE_className="el-share-option-panel__qr-view">
                <Flex UNSAFE_className="el-share-option-panel-qr-box">
                    <Text UNSAFE_className="el-share-option-panel-qr-text-1">{intlHandler.formatMessage("scan-qr-code")}</Text>
                    <Flex direction={"row"} marginBottom={"1rem"} gap={"0.5rem"}>
                        {
                            state.qrData?.map((option, index) => <img crossOrigin="anonymous" key={index} src={option.src} className="el-share-option-panel__qr-icon" alt="QR Icon"/>)
                        }
                    </Flex>
                    {
                        state.qrLink ?
                            <img src={state.qrLink} className="el-share-option-panel-qr-image" alt="QR Link"/> :
                            progressCircleThumb
                    }
                </Flex>
            </Flex>
        )
    }

    const isQRShareViewEnabled = (): boolean => {
        return FeaturesManager.getInstance().IsFeatureActive(FeatureName.eQRShareView) && props.showQRView;
    }

    const getShareView = (): React.ReactNode => {
        return (
            <Flex UNSAFE_className="el-share-option-panel__view" data-testid="el-share-option-panel__view">
                <Flex UNSAFE_className="el-share-option-panel-option-1">
                    {
                        data.map((option) =>
                            <ELOption key={option.key} direction="column" alignItems="center" gap="size-100" width="size-900"
                                marginTop="size-100" marginBottom="size-100"
                                onPress={() => {
                                    dispatch(ShareAction.updateShareMode(option.shareMode));
                                    props.onPress();
                                }}
                                data-testid={option.key}>
                                <ELIcon iconkey={option.src} className="el-share-option-panel__icon" />
                                <Text>{intlHandler.formatMessage(option.text)}</Text>
                            </ELOption>
                        )
                    }
                </Flex>
                {isQRShareViewEnabled() && <div className="el-share-option-panel-line" />}
                {isQRShareViewEnabled() && getQRView()}
            </Flex>
        )
    }

    return (
        <Flex UNSAFE_className="el-share-option-panel">
            {getShareView()}
        </Flex>
    );
}

export { ShareOptionsView };
