/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import IBaseController from "../view/IBaseController";
import IWorkflow, { WorkflowAction, WorkflowPayload, WorkflowsName } from "./IWorkflow";
import IModalWorkspace, { ModalWorkspaceName } from "./IModalWorkspace";
import Logger, { LogLevel } from "../utils/Logger";
import { IngestLogging } from "../services/IngestWrapper";
import { IngestUtils } from "../utils/IngestUtils";
import Utils from "../utils/Utils";
import IWorkspace from "./IWorkspace";

export enum WorkspacesName {
	home = "HOME",
	creations = "CREATIONS",
	media = "MEDIA",
	learn = "LEARN",
	editor = "EDITOR",
	linkPreview = "SHARED LINK",
	userHome = "HOME",
	organizer = "ORGANIZER",
	downloadDesktop = "Download Desktop",
	edit = "Edit",
	ipn = "IPN"
}

export enum WorkspaceActionType {
	startWorkflow = "START_WORKFLOW",
	startModalWorkspace = "START_MODAL_WORKSPACE",
	endModalWorkspace = "END_MODAL_WORKSPACE",
	endWorkspace = "END_WORKSPACE",
	endWorkflow = "END_WORLFLOW",
	startPreviousWorkflow = "START_PREVIOUS_WORKFLOW",
	startDefaultWorkflow = "START_DEFAULT_WORKFLOW",
	switchModalWorkflow = "SWITCH_MODAL_WORKFLOW",
	ingest = "INGEST"
}

export interface WorkspacePayload extends WorkflowPayload {
	startWorkflow?: WorkflowsName,
	startModalWorkspace?: ModalWorkspaceName
}

export interface WorkspaceAction extends WorkspacePayload {
	type: string
}

export default abstract class IBaseWorkspace extends IBaseController {
	protected workspaceName!: WorkspacesName;
	protected currentWorkflow?: IWorkflow;
	protected modalWorkspace?: IModalWorkspace;

	get getWorkspaceName(): WorkspacesName {
		return this.workspaceName;
	}

	get getWorkflow(): IWorkflow | undefined {
		return this.currentWorkflow;
	}

	set setWorkflow(workflow: IWorkflow | undefined) {
		this.currentWorkflow = workflow;
	}

	lazyInitialize(): void { return; }
	setToDefaults(): void { return; }
	readPrefs(): void { return; }
	writePrefs(): void { return; }

	/**
	 *
	 * @param containerId : id of html element where worflow will be 'mounted'
	 * @param workflow : Workflow to be started
	 * @param payload : any info that needs to be pass on to the workflow
	 */
	protected startWorkflow<T extends WorkflowAction>(containerId: string, workflow: IWorkflow, action?: T): void {
		const prevWorkflow = this.currentWorkflow; //hold a reference to set last prevWorkflow for input workflow
		this.endCurrentWorkflow();

		workflow.startWorkflow<T>(containerId, prevWorkflow, action);
	}

	/**
	 *
	 * @param containerId : id of html element where worflow will be 'mounted'
	 * @param payload : any info that needs to be pass on to the workflow
	 * @returns
	 */
	protected startPrevWorkflow<T extends WorkflowAction>(containerId: string, action?: T): void {
		if (!this.currentWorkflow?.getPrevWorkflow)
			return; //no op

		const currentWorkflow = this.currentWorkflow;
		const prevWorkflow = this.endCurrentWorkflow();

		prevWorkflow?.startWorkflow<T>(containerId, currentWorkflow, action);
	}

	/**
	 *
	 * @returns previous Workflow instance
	 */
	protected endCurrentWorkflow(): IWorkflow | undefined {
		const prevWorkflow = this.currentWorkflow?.getPrevWorkflow;
		this.currentWorkflow?.endWorkflow();

		return prevWorkflow;
	}

	/**
	 *
	 * @returns modal workspace name if present, else undefined
	 */
	get getModalWorkspaceName(): ModalWorkspaceName | undefined {
		return this.modalWorkspace?.getModalWorkspaceName;
	}

	/*
	* Ends current workflow on destruction
	*/
	destroy(): void {
		this.modalWorkspace?.endWorkspace();
		this.endCurrentWorkflow();
		this.setWorkflow = undefined;
		super.destroy();
	}

	protected endModalWorkspace(): void {
		this.modalWorkspace?.endWorkspace();
		this.modalWorkspace = undefined;
	}

	get getModalWorkspace(): IModalWorkspace | undefined {
		return this.modalWorkspace;
	}

	abstract get getNonModalWorkspace(): IWorkspace;

	async notify<T extends WorkspaceAction>(action: T): Promise<boolean> {
		let handled = false;
		switch (action.type) {
			case WorkspaceActionType.ingest:
				{
					if (!Utils.isInputWellDefined(action.payload))
						return handled;
					let eventPayload = action.payload as Record<string, string>;
					if (!IngestUtils.hasEventWorkflow(action.payload as Record<string, string>)) {
						eventPayload = IngestUtils.addWorkspaceDetail(this.workspaceName, eventPayload);
					}
					IngestLogging.getInstance().logEvent(eventPayload);
					handled = true;
					break;
				}
			default:
				Logger.log(LogLevel.WARN, "IBaseWorkspace:notify: ", "Invalid action type for workspace", action);
				break;
		}
		return handled;
	}
}
