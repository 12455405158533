/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import { v4 as uuid } from "uuid";

//Application Specific
import { BackgroundChangedStatus, ELCollageAssetInfo, ELCollageTemplate, ELCollageTemplateBackground, ELCollageTemplateBackgroundMode, ELSavedCollageVersion, ReplaceAssetInfo, ReplaceMediaStatus } from "../../common/interfaces/creations/ELCollageTypes";
import { DocumentActions, DocumentDirty } from "../../common/interfaces/document/DocumentTypes";
import { ELSize } from "../../common/interfaces/geometry/ELGeometry";
import { RendererUpdateData } from "../../common/interfaces/renderer/RendererTypes";
import { ELAdobeAsset } from "../../common/interfaces/storage/AssetTypes";
import { ControllerAction } from "../../view/IViewController";
import { WorkflowAction, WorkflowActionType } from "../../workspaces/IWorkflow";
import IDoc from "../document/IDoc";
import { StorageService } from "../../services/StorageServiceWrapper";
import { CanvasViewAction, ELStageBackground, ELStageBackgroundMode, ELDownloadData, CanvasControllerAction } from "../../common/interfaces/stage/StageTypes";
import CollageUtils from "../../workspaces/creations/workflows/collage/utils/CollageUtils";
import { CreationWorkflowActions, CreationsStatus, CreationsJobProjectSubType } from "../../common/interfaces/creations/CreationTypes";
import { ELCollageDocActions, ELCollageDocPayload } from "../../common/interfaces/creations/collage/ELCollageDocTypes";
import { ELBackgroundContentPanelControllerAction } from "../../common/interfaces/creations/templates/ELBackgroundContentPanelTypes";
import Logger, { LogLevel } from "../../utils/Logger";
import ImageUtils from "../../utils/ImageUtils";
import { MediaManagerControllerAction } from "../../common/interfaces/workflows/MediaManagerTypes";
import { CollageBackgroundData } from "../../common/interfaces/creations/ElementsContentTypes";
import { ReplaceMediaManagerWorkflowAction } from "../../common/interfaces/workflows/ReplaceMediaManagerTypes";
import store from "../../stores/store";
import { AssetStorageUtils } from "../../utils/AssetStorageUtils";
import { IngestEventSubTypes, IngestEventTypes, IngestLogObjectKey, IngestWorkflowTypes } from "../../utils/IngestConstants";
import { IngestUtils } from "../../utils/IngestUtils";
import DocActions from "../../stores/actions/DocActions";
import Utils from "../../utils/Utils";
import SelectedMediaListAction from "../../stores/actions/selectedMediaListActions";
import { ELCollageShapeData } from "../../common/interfaces/creations/ELCollageTypes";
import { ToastUtils } from "../../utils/ToastUtils";
import { IntlHandler } from "../../modules/intlHandler/IntlHandler";
import ELAdobeAssetDataResolver, { DocumentDataType } from "../document/dataResolver/ELAdobeAssetDataResolver";
import FullResMediaAction from "../../stores/actions/FullResMediaAction";
import ELClientUploadHandler from "../../modules/clientUploadHandler/ELClientUploadHandler";

export default class ELCollageDoc extends IDoc {
    private _template: ELCollageTemplate = {} as ELCollageTemplate;
    private _assetInfoList: ELCollageAssetInfo[] = [];
    private _background: ELCollageTemplateBackground = {} as ELCollageTemplateBackground;
    private _assetList?: ELAdobeAsset[];
    private _assetPath?: string;
    private readonly invalidIndex = -1;

    private _showRenderError(renderError: boolean): void {
        if (this.renderer) {
            this.renderer.setRenderError = renderError;
        }
    }

    private async _renderUpdatedAsset(index: number, objectURL: string): Promise<void> {
        const assetInfo: ELCollageAssetInfo = {
            ...this._assetInfoList[index],
            asset: {
                ...this._assetInfoList[index].asset,
                objectURL: objectURL
            }
        };

        this._assetInfoList[index] = assetInfo;

        const updateData: RendererUpdateData = { type: ELCollageDocActions.assetUpdated, payload: assetInfo };
        await this.renderer?.update(updateData);
    }

    private async _renderOriginalAsset(): Promise<void> {
        this._showRenderError(true);

        try {
            const promiseDataList: { index: number, assetId: string, promise: Promise<string> }[] = [];
            const dataResolver = new ELAdobeAssetDataResolver();
            for (let index = 0; index < this._assetInfoList?.length; index++) {
                const assetId = this._assetInfoList[index].asset.assetURN;

                const objectURL: string | undefined = CollageUtils.getAssetFullResObjectURL(assetId ?? "");

                if (objectURL) {
                    await this._renderUpdatedAsset(index, objectURL);
                } else {
                    try {
                        const asset = await StorageService.getInstance().resolveAsset({ assetId: assetId }, 'id');
                        const getDataType = AssetStorageUtils.shouldUseAssetRendition(asset) ? DocumentDataType.rendition : DocumentDataType.fullRes;
                        const promise = dataResolver.getData(asset, getDataType);
                        promiseDataList.push({ index: index, assetId: assetId, promise: promise });
                    } catch (error) {
                        await this._renderUpdatedAsset(index, "");
                    }
                }
            }

            for (const promiseData of promiseDataList) {
                try {
                    const objectURL = await promiseData.promise;
                    if (objectURL) {
                        store.dispatch(FullResMediaAction.updateData({ assetId: promiseData.assetId, objectURL: objectURL }));
                        await this._renderUpdatedAsset(promiseData.index, objectURL);
                    }
                } catch (error) {
                    await this._renderUpdatedAsset(promiseData.index, "");
                }
            }

            window.requestAnimationFrame(() => { //let canvas drawing complete
                this.owner.notify({ type: CreationWorkflowActions.creationRenderStatus, payload: CreationsStatus.success });
                this.renderer?.notify({ type: CanvasViewAction.addCanvasChangedHandlers });
            });
        } catch (error) {
            this.owner.notify({ type: CreationWorkflowActions.creationRenderStatus, payload: CreationsStatus.error });
        }
    }

    private async _onBackgroundImageChanged(graphicData: CollageBackgroundData): Promise<void> {
        const toBeSavedBackground = { mode: ELCollageTemplateBackgroundMode.image, value: graphicData.id };
        this._background = toBeSavedBackground;

        const newBackground: ELStageBackground = { mode: ELStageBackgroundMode.image, value: graphicData.contentUrl };
        const updateData: RendererUpdateData = { type: ELCollageDocActions.backgroundChanged, payload: newBackground };

        try {
            await this.renderer?.update(updateData);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ELCollageDoc:_onBackgroundImageChanged: ", error);
            this.owner.notify({ type: CreationWorkflowActions.backgroundChanged, payload: BackgroundChangedStatus.error });
            return;
        }

        this.owner.notify({ type: CreationWorkflowActions.backgroundChanged, payload: BackgroundChangedStatus.success });
    }

    private _onBackgroundColorChanged(hexColor: string): void {
        const newBackground: ELCollageTemplateBackground = { mode: ELCollageTemplateBackgroundMode.color, value: hexColor };

        this._background = newBackground;

        const updateData: RendererUpdateData = { type: ELCollageDocActions.backgroundChanged, payload: newBackground };
        this.renderer?.update(updateData);
    }

    private async _updateStoreSelectedMedia(): Promise<void> {
        const selectedMediaList = store.getState().selectedMediaListReducer;
        const newAssetList: ELAdobeAsset[] = [];
        for (const assetInfo of this._assetInfoList) {
            const assetId = assetInfo.asset.assetURN;
            if (!selectedMediaList.find(asset => asset.assetId === assetId)) {
                try {
                    const asset = await StorageService.getInstance().resolveAsset({ assetId: assetId }, "id");
                    newAssetList.push(asset);
                } catch (error) {
                    Logger.log(LogLevel.INFO, "ELCollageDoc - (_updateStoreSelectedMedia) failed to resolve asset", error);
                }
            }
        }

        store.dispatch(SelectedMediaListAction.updateSelectedMediaList([...selectedMediaList, ...newAssetList]));
    }

    private _findIndexToReplace(replaceAssetInfo: ReplaceAssetInfo): number {
        for (let index = 0; index < this._assetInfoList.length && index < this._template.layouts.length; index++) {
            if (replaceAssetInfo.assetIdToReplace === this._assetInfoList[index].asset.assetURN && index === replaceAssetInfo.data) {
                return index;
            }
        }

        return this.invalidIndex;
    }

    private async _replaceImage(replaceAssetInfo: ReplaceAssetInfo): Promise<void> {
        try {
            const assetIdToReplaceWith = replaceAssetInfo.assetToReplaceWith.assetId as string;
            let objectURL: string | undefined = CollageUtils.getAssetFullResObjectURL(assetIdToReplaceWith ?? "");

            if (!objectURL) {
                const asset = await StorageService.getInstance().resolveAsset({ assetId: assetIdToReplaceWith }, 'id');
                const getDataType = AssetStorageUtils.shouldUseAssetRendition(asset) ? DocumentDataType.rendition : DocumentDataType.fullRes;
                const dataResolver = new ELAdobeAssetDataResolver();
                objectURL = await dataResolver.getData(asset, getDataType);
                store.dispatch(FullResMediaAction.updateData({ assetId: assetIdToReplaceWith, objectURL: objectURL }));
            }

            const indexToReplace = this._findIndexToReplace(replaceAssetInfo);

            if (indexToReplace === this.invalidIndex) {
                Logger.log(LogLevel.WARN, "ELCollageDoc:_replaceImage: ", "invalid index for replace image");
                this.owner.notify({ type: CreationWorkflowActions.replaceMediaStatus, payload: ReplaceMediaStatus.error });
                return;
            }

            const assetToReplaceWithInfo: ELCollageAssetInfo = {
                ...this._assetInfoList[indexToReplace],
                asset: {
                    ...this._assetInfoList[indexToReplace].asset,
                    id: uuid(),
                    assetURN: replaceAssetInfo.assetToReplaceWith.assetId ?? "",
                    mimeType: replaceAssetInfo.assetToReplaceWith.format ?? "",
                    objectURL: objectURL
                }
            };
            this._assetInfoList[indexToReplace] = assetToReplaceWithInfo;

            await this._updateStoreSelectedMedia();

            const updateData: RendererUpdateData = {
                type: ELCollageDocActions.assetReplaced,
                payload: {
                    assetToReplaceWith: assetToReplaceWithInfo.asset,
                    assetIdToReplace: replaceAssetInfo.assetIdToReplace,
                    layoutId: indexToReplace
                }
            };
            this.renderer?.update(updateData);

            this.owner.notify({ type: CreationWorkflowActions.replaceMediaStatus, payload: ReplaceMediaStatus.success });
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ELCollageDoc - (_replaceImage)", error);
            this.owner.notify({ type: CreationWorkflowActions.replaceMediaStatus, payload: ReplaceMediaStatus.error });
        }
    }

    private _isPayloadInvalidForSwap(payload: ELCollageShapeData[]): boolean {
        return payload[0].asset.objectURL?.length === 0 || payload[1].asset.objectURL?.length === 0;
    }

    private _isMissingMedia(payload: ELCollageShapeData[]): boolean {
        if (this._isPayloadInvalidForSwap(payload)) {
            const intlHandler = IntlHandler.getInstance();
            const message = intlHandler.formatMessage("collage-missing-photo-error");
            ToastUtils.error(message);
            return true;
        }
        return false;
    }

    private _swapAssets(payload: ELCollageShapeData[]): void {

        if (payload.length !== 2 || payload[0].layoutId === payload[1].layoutId) {
            Logger.log(LogLevel.WARN, "ELCollageDoc:_swapAssets: ", "invalid indexes for swap image");
            return;
        }

        if (this._isMissingMedia(payload)) {
            return;
        }

        const layoutIds = [payload[0].layoutId, payload[1].layoutId];

        //clearing the edit on the assets and swap
        const tempAsset = this._assetInfoList[layoutIds[0]].asset;
        this._assetInfoList[layoutIds[0]] = { asset: this._assetInfoList[layoutIds[1]].asset };
        this._assetInfoList[layoutIds[1]] = { asset: tempAsset };

        const updateDataFirstAsset: RendererUpdateData = {
            type: ELCollageDocActions.assetReplaced,
            payload: {
                assetToReplaceWith: this._assetInfoList[layoutIds[0]].asset,
                assetIdToReplace: this._assetInfoList[layoutIds[1]].asset.assetURN,
                layoutId: layoutIds[0]
            }
        };
        this.renderer?.update(updateDataFirstAsset);

        const updateDataSecondAsset = {
            type: ELCollageDocActions.assetReplaced,
            payload: {
                assetToReplaceWith: this._assetInfoList[layoutIds[1]].asset,
                assetIdToReplace: this._assetInfoList[layoutIds[0]].asset.assetURN,
                layoutId: layoutIds[1]
            }
        };
        this.renderer?.update(updateDataSecondAsset);
    }

    private _ingest(payload: Record<string, string>): void {
        this.owner.notify({
            type: WorkflowActionType.ingest,
            payload: payload
        });
    }

    get getTemplate(): ELCollageTemplate {
        return this._template;
    }

    get getAssetInfoList(): ELCollageAssetInfo[] {
        return this._assetInfoList ?? [];
    }

    async initialize<T>(payload?: T): Promise<boolean> {
        const collageDocPayload = payload as unknown as ELCollageDocPayload;
        this._template = collageDocPayload.template;
        this._assetInfoList = collageDocPayload.assetInfoList;
        this._assetList = collageDocPayload.assetList;
        this._background = collageDocPayload.background;

        this.data = collageDocPayload;

        return Promise.resolve(true);
    }

    documentUpdated(payload: unknown): void {
        const assetInfoList = this.dataParser?.parseDocUpdatedData(payload) as ELCollageAssetInfo[];

        assetInfoList.forEach((assetInfo, index) => {
            this._assetInfoList[index] = {
                ...this._assetInfoList[index],
                edit: assetInfo.edit
            };
        });

        this.markAndNotifyDocumentDirty(DocumentDirty.DIRTY);

        Logger.log(LogLevel.INFO, "document dirty: ", this.isDirty);
    }

    async save(): Promise<void> {
        Logger.log(LogLevel.INFO, "ELCollageDoc - (save)", "Saving doc!");

        if (this.hasRenderingError()) {
            Logger.log(LogLevel.WARN, "ELCollageDoc:save: ", "document has rendering error");
            return;
        }

        if (!this._assetPath) {
            Logger.log(LogLevel.ERROR, "ELCollageDoc:save: ", "_assetPath not set for document");
            return;
        }

        if (this.isDirty === DocumentDirty.NON_DIRTY) {
            Logger.log(LogLevel.WARN, "ELCollageDoc:save: ", "save request came without document being dirty");
            return;
        }

        try {
            const dataURL = await this.downloader?.getDataURL();

            if (dataURL) {
                const blob = await ImageUtils.createBlob(dataURL, "image/jpeg", 1);
                const templateData = { version: ELSavedCollageVersion.version_1_0, templateId: this._template.id, background: this._background };
                const jsonDoc = { templateData, assetInfoList: this._assetInfoList };

                const clientUploadHandler = new ELClientUploadHandler();
                await clientUploadHandler.upload({
                    assetPath: this._assetPath,
                    contentType: "image/jpeg",
                    saveInfo: { blob: blob, metaData: jsonDoc }
                });

                await this.owner.notify({ type: CreationWorkflowActions.updateCreationStatus, payload: CreationsStatus.success });

                this.markAndNotifyDocumentDirty(DocumentDirty.NON_DIRTY);
            }
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ELCollageDoc:save: ", error);
            return Promise.reject("Couldn't save collage document");
        }
    }

    close(): Promise<void> {
        return Promise.resolve();
    }

    download(downloadData: ELDownloadData): void {
        this.downloader?.download(downloadData);
    }

    destroy(): void {
        this.close();
        this.renderer?.destroy();
    }

    async render(container: HTMLElement): Promise<void> {
        await this.renderer?.render(container);
        await this._renderOriginalAsset();
    }

    async getImageData(): Promise<ImageData> {
        const imageData: ImageData = new ImageData(this._template.bounds.width, this._template.bounds.height);
        return Promise.resolve(imageData);
    }

    async getSize(): Promise<ELSize> {
        return await this.getOriginalSize();
    }

    async getOriginalSize(): Promise<ELSize> {
        return { width: this._template.bounds.width, height: this._template.bounds.height };
    }

    hasRenderingError(): boolean {
        return this.renderer ? this.renderer.hasRenderingError() : super.hasRenderingError();
    }

    getData(): unknown {
        const payload: ELCollageDocPayload = {
            template: this._template,
            assetInfoList: this._assetInfoList,
            assetList: this._assetList,
            background: this._background
        };
        return payload;
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;

        switch (action.type) {
            case DocumentActions.download: {
                const downloadData = action.payload as ELDownloadData;
                this.download(downloadData);
                handled = true;
                break;
            }
            case DocumentActions.documentUpdated: {
                this.documentUpdated(action.payload);
                handled = true;
                break;
            }
            case DocumentActions.updateCloudAssetPath: {
                this._assetPath = action.payload as string;
                handled = true;
                break;
            }
            case DocumentActions.replaceErrorImage: {
                handled = await this.owner.notify(action);
                break;
            }
            case DocumentActions.objectMoved: {
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.collage, IngestEventTypes.click, IngestEventSubTypes.move));
                handled = true;
                break;
            }
            case DocumentActions.objectRotated: {
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
                    IngestEventTypes.click, IngestEventSubTypes.rotate, CreationsJobProjectSubType.photoCollage));

                handled = true;
                break;
            }
            case DocumentActions.zoomModified: {
                const additionalLogInfo: Record<string, string> = {};
                additionalLogInfo[IngestLogObjectKey.eventCount] = Utils.getPercentageFromNumber(action.payload as number);
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
                    IngestEventTypes.click, IngestEventSubTypes.zoom, CreationsJobProjectSubType.photoCollage, additionalLogInfo));

                store.dispatch(DocActions.updateZoomLevel(action.payload as number));
                handled = true;
                break;
            }
            case DocumentActions.swapAssets: {
                this._swapAssets(action.payload as ELCollageShapeData[]);
                handled = true;
                break;
            }
            case ELBackgroundContentPanelControllerAction.imageBackgroundChanged: {
                const backgroundValue = (action.payload as CollageBackgroundData).id as string;
                const additionalLogInfo: Record<string, string> = {};
                additionalLogInfo[IngestLogObjectKey.eventCount] = backgroundValue;
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
                    IngestEventTypes.click, IngestEventSubTypes.background, CreationsJobProjectSubType.photoCollage, additionalLogInfo));

                this._onBackgroundImageChanged(action.payload as CollageBackgroundData);
                handled = true;
                break;
            }
            case ELBackgroundContentPanelControllerAction.colorBackgroundChanged: {
                const color = action.payload as string;
                const additionalLogInfo: Record<string, string> = {};
                additionalLogInfo[IngestLogObjectKey.eventCount] = color;
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
                    IngestEventTypes.click, IngestEventSubTypes.colorPicker, CreationsJobProjectSubType.photoCollage, additionalLogInfo));

                this._onBackgroundColorChanged(action.payload as string);
                handled = true;
                break;
            }
            case ELCollageDocActions.replaceMedia: {
                this._replaceImage(action.payload as ReplaceAssetInfo);
                handled = true;
                break;
            }
            case CanvasControllerAction.deleteActiveObject: {
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
                    IngestEventTypes.click, IngestEventSubTypes.delete, CreationsJobProjectSubType.photoCollage));

                handled = await this.owner.notify({
                    type: MediaManagerControllerAction.mediaDeleted,
                    payload: action.payload
                });
                break;
            }
            case ReplaceMediaManagerWorkflowAction.replaceActiveMedia: {
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
                    IngestEventTypes.click, IngestEventSubTypes.replace, CreationsJobProjectSubType.photoCollage));

                handled = await this.owner.notify(action);
                break;
            }
            case CanvasControllerAction.activeObjectChange: {
                handled = await this.owner.notify({
                    type: DocumentActions.activeObjectChange,
                    payload: action.payload
                });
                break;
            }
        }

        if (!handled) {
            handled = await super.notify(action);
        }

        if (!handled)
            handled = await this.owner.notify(action as WorkflowAction);

        return handled;
    }
}
