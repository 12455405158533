/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

//Application specific
import ITemplateViewController from "../../../ITemplateViewController";
import ELLooksPanelView from "./ELLooksPanelView";
import { ViewAction } from "../../../IBaseController";
import AdjustmentsPresets from "../../../../editors/adjustments/AdjustmentsPresets";
import { ControllerAction } from "../../../IViewController";
import { ELLooksPanelControllerActions } from "../../../../common/interfaces/editing/adjustments/ELLooksPanelTypes";
import Logger, { LogLevel } from "../../../../utils/Logger";
import { DocumentActions } from "../../../../common/interfaces/document/DocumentTypes";
import { DEFAULT_INTENSITY_VALUE, DEFAULT_PRESET_ID } from "../../../../common/interfaces/editing/adjustments/AdjustmentsEditorTypes";
import { Edit } from "../../../../common/interfaces/editing/EditorTypes";
import { ApplyLooksPresetPayload } from "../../../../common/interfaces/editing/editWorkspace/EditWorkspaceTypes";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { AdjustmentsPresetsPreviewGenerator } from "../../../../workspaces/edit/workflows/AdjustmentsPresetsPreviewGenerator";
import { URLConstants } from "../../../../utils/Constants/URLConstants";
import { ELTabPanelActions } from "../../../../common/interfaces/tabpanel/ELTabPanelTypes";
import { ELAdobeAsset } from "../../../../common/interfaces/storage/AssetTypes";
import store from "../../../../stores/store";
import AdjustmentsAction from "../../../../stores/actions/AdjustmentsActions";

export class ELLooksPanel extends ITemplateViewController {
    private readonly _timeOutForResponsiveUI: number = 100;
    private _adjustmentsPresetsPreviewGenerator?: AdjustmentsPresetsPreviewGenerator;
    private _currentMedia?: ELAdobeAsset;

    private async _applyPreset(looksPresetPayload: ApplyLooksPresetPayload): Promise<void> {
        const { presetId, intensity } = looksPresetPayload as ApplyLooksPresetPayload;
        const preset = AdjustmentsPresets.getById(Number(presetId));
        const adjustmentsParams = preset.params;
        const editParams = { params: adjustmentsParams, intensity: intensity ?? DEFAULT_INTENSITY_VALUE };
        setTimeout(() => {
            this.notify({ type: DocumentActions.applyEdit, payload: { editName: Edit.adjustments, config: editParams } });
        }, 10);
    }

    private _resetLooksPanelURLs(): void {
        AdjustmentsPresets.getAdjustmentsPresets().forEach((preset) => {
            store.dispatch(AdjustmentsAction.updatePresetPreviewURL({ id: preset.id, previewUrl: undefined }));
        });
    }

    private async _fetchPresetPreviewURLs(): Promise<void> {
        const intlHandler = IntlHandler.getInstance();
        this._adjustmentsPresetsPreviewGenerator = new AdjustmentsPresetsPreviewGenerator();
        try {
            if (this._currentMedia) {
                await this._adjustmentsPresetsPreviewGenerator.initialize(this._currentMedia);
            }
        } catch (error: unknown) {
            this.notify({
                type: ELLooksPanelControllerActions.showErrorDialog,
                payload: {
                    heading: intlHandler.formatMessage("webgl-not-supported-error-heading"),
                    description: intlHandler.formatMessage("webgl-not-supported-error-desc"),
                    learnMoreLink: URLConstants.GoURL.WEBGL_TROUBLESHOOT
                }
            });
            return;
        }
        if (this._currentMedia) {
            return this._adjustmentsPresetsPreviewGenerator.generateAndSetPreviewURLs();
        }
    }

    private async _handleMediaUpdate(): Promise<void> {
        await this.notify({ type: ELTabPanelActions.initWorkflow });
        await this.notify({ type: ELLooksPanelControllerActions.fetchPresetPreviewURLs });
        await this.notify({ type: ELLooksPanelControllerActions.updateDataToEdit, payload: Edit.adjustments });
    }

    createView(container: HTMLElement): void {
        super.createView(container);

        const looksPanel = React.createElement(ELLooksPanelView, {
            controller: this,
            adjustmentsPresets: AdjustmentsPresets.getAdjustmentsPresets()
        });
        const reactReduxProviderHydratedLooksPanel = React.createElement(Provider, { store: store }, looksPanel);
        ReactDOM.render(reactReduxProviderHydratedLooksPanel, container);
    }

    destroyView(): void {
        if (this.container) {
            ReactDOM.unmountComponentAtNode(this.container);
        }
        super.destroyView();
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
        setTimeout(() => {
            this.notify({ type: ELLooksPanelControllerActions.initializeEdit, payload: Edit.adjustments });
        }, this._timeOutForResponsiveUI);
    }

    destroy(): void {
        super.destroy();
        setTimeout(() => {
            try {
                this.notify({ type: ELLooksPanelControllerActions.deInitializeEdit, payload: Edit.adjustments });
            } catch (error) {
                Logger.log(LogLevel.ERROR, "ELLooksPanel: destroy: Error deinitializing edit" + error);
            }
        }, this._timeOutForResponsiveUI);
    }

    getView(viewProps?: unknown): React.ReactNode {
        return React.createElement(ELLooksPanelView, {
            controller: this,
            adjustmentsPresets: AdjustmentsPresets.getAdjustmentsPresets()
        });
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case ELLooksPanelControllerActions.fetchPresetPreviewURLs: {
                setTimeout(async () => {
                    try {
                        await this._fetchPresetPreviewURLs();
                    } catch (error) {
                        Logger.log(LogLevel.ERROR, "ELLooksPanel: notify: Error fetching preset preview URLs" + error);
                    }
                }, this._timeOutForResponsiveUI);
                handled = true;
                break;
            }
            case ELLooksPanelControllerActions.applyPreset: {
                const looksPresetPayload = action.payload as ApplyLooksPresetPayload;
                await this._applyPreset(looksPresetPayload);
                break;
            }
            case ELTabPanelActions.updateCurrentMedia: {
                this._currentMedia = action.payload as ELAdobeAsset;
                await this._handleMediaUpdate();
                handled = true;
                break;
            }
            case ELTabPanelActions.initWorkflow: {
                this._resetLooksPanelURLs();
                store.dispatch(AdjustmentsAction.updateSelectedPresetId(DEFAULT_PRESET_ID));
                store.dispatch(AdjustmentsAction.updateIntensity(DEFAULT_INTENSITY_VALUE));
                handled = true;
                break;
            }
            default: {
                handled = await this._owner.notify(action);
            }
        }

        if (!handled)
            handled = await this._owner.notify(action);

        return handled;
    }
}
