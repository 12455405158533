/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React, { ReactElement, useEffect, useReducer, useRef, useState } from "react";

//Adobe Internal
import { Flex, ProgressCircle, Text } from "@adobe/react-spectrum";

//Application Specific
import { ELAdobeStockPanel } from "./ELAdobeStockPanel";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import ELPanelHeader from "../../atoms/el-panel-header/ELPanelHeader";
import ELScrollPanel from "../../atoms/el-scroll-panel/ELScrollPanel";
import { TRANSPARENT_BACKGROUND_ID, TRANSPARENT_BACKGROUND_NAME } from "../../../../common/interfaces/creations/CreationTypes";
import { ELRecommendationWorkflowControllerActions } from "../../../../common/interfaces/creations/ELRecommendationsWorkflowTypes";
import ELCustomIcon from "../../molecules/el-custom-icon/ELCustomIcon";
import { IconType } from "../../../../assets/IconConstants";
import { StockServiceUtils } from "../../../../utils/StockServiceUtils";
import Utils from "../../../../utils/Utils";
import { ELStockWorkflowActions } from "../../../../common/interfaces/services/StockServiceTypes";
import ELButton from "../../atoms/el-button/ELButtonView";
import { ToastUtils } from "../../../../utils/ToastUtils";
import { Theme } from "../../../../utils/Theme";
import { GenericContentDataType, GenericContentSource } from "../../../../modules/contenDatatManager/ContentDataManagerTypes";
import { ViewAction } from "../../../IBaseController";
import Constants from "../../../../utils/Constants/Constants";

//CSS
import "./ELAdobeStockPanel.scss";

interface ELAdobeStockPanelViewProps {
    controller: ELAdobeStockPanel
}

interface ELAdobeStockPanelState {
    selectedStockId: string,
    isActionRegionDisabled: boolean
}

enum ELAdobeStockPanelViewMode {
    DEFAULT = "default",
    SEARCH_RESULTS = "searchResults"
}

const initialState: ELAdobeStockPanelState = {
    selectedStockId: "",
    isActionRegionDisabled: true
}

const ELAdobeStockPanelView = (props: ELAdobeStockPanelViewProps): React.ReactElement => {

    const emptyString = Constants.EMPTY_STRING as string;
    const intlHandler = IntlHandler.getInstance();

    const scrollParentRef = useRef(null);

    const [viewMode, setViewMode] = useState<ELAdobeStockPanelViewMode.DEFAULT | ELAdobeStockPanelViewMode.SEARCH_RESULTS>(ELAdobeStockPanelViewMode.DEFAULT);
    const [searchQuery, setSearchQuery] = useState<string>(emptyString);
    const [searchResults, setSearchResults] = useState<any[]>([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [featuredBackgrounds, setFeaturedBackgrounds] = useState<any[]>([]);
    const [recentSearches, setRecentSearches] = useState<string[]>([]);
    const [isLoadingBackgrounds, setIsLoadingBackgrounds] = useState(false);
    const [offset, setOffset] = useState<number>(0);
    const [totalResults, setTotalResults] = useState<number>(0);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const limit = 64;

    useEffect(() => {
        const loadRecentSearches = (): void => {
            const storedSearches = JSON.parse(localStorage.getItem("RECENT_SEARCHES_KEY") || "[]");
            setRecentSearches(storedSearches);
        };

        props.controller.initialize(viewDispatch);

        const initializeAndFetchBackgrounds = async (): Promise<void> => {
            props.controller.initialize();
            await fetchFeaturedBackgrounds();
        };

        loadRecentSearches();
        initializeAndFetchBackgrounds();


        return () => {
            props.controller.destroy();
        };
    }, [props.controller]);

    const reducer = (state: ELAdobeStockPanelState, action: ViewAction): ELAdobeStockPanelState => {
        switch (action.type) {
            case ELStockWorkflowActions.setSelectedStockId:
                return {
                    ...state,
                    selectedStockId: action.payload as string
                };
            case ELStockWorkflowActions.updateActionRegionState:
                return {
                    ...state,
                    isActionRegionDisabled: action.payload as boolean
                };
            default:
                return state;
        }
    }

    const [state, viewDispatch] = useReducer(reducer, initialState);

    const transparentCardClicked = (): void => {
        const payload = { id: TRANSPARENT_BACKGROUND_ID, name: TRANSPARENT_BACKGROUND_NAME };
        props.controller.notify({ type: ELRecommendationWorkflowControllerActions.overlayClicked, payload });
    };

    const handleSearchChange = async (value: string): Promise<void> => {
        setSearchQuery(value);
        if (!value.trim()) {
            setSearchResults(featuredBackgrounds);
            return;
        }
        setShowSuggestions(!!value.trim() || recentSearches.length > 0);
    };

    const handleSearchSubmit = async (query: string): Promise<void> => {
        const initialOffset = 0;
        setOffset(initialOffset);
        setViewMode(ELAdobeStockPanelViewMode.SEARCH_RESULTS);
        setShowSuggestions(false);
        setSearchQuery(query);
        setIsLoadingBackgrounds(true);
        const response = await StockServiceUtils.fetchImages(query, Utils.getCurrentLocaleInSnakeCase(), initialOffset, limit);
        setIsLoadingBackgrounds(false);
        setSearchResults(response.files);
        setOffset(initialOffset + limit);
        setTotalResults(response.nb_results);
        setShowSuggestions(false);

        const updatedSearches = [query, ...recentSearches.filter((item) => item !== query)].slice(0, 5);
        setRecentSearches(updatedSearches);
        localStorage.setItem("RECENT_SEARCHES_KEY", JSON.stringify(updatedSearches));

        props.controller.notify({ type: ELStockWorkflowActions.stockSearchTriggered, payload: query })
    };

    const handleEnterClick = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (e.key === "Enter" && searchQuery) {
            handleSearchSubmit(searchQuery);
        }
    }

    const handleImageClick = async (imageInfo: any): Promise<void> => {
        props.controller.notify({ type: ELStockWorkflowActions.stockImageClicked });
        const isImageLicensed = await StockServiceUtils.isImageLicensed(imageInfo.id);
        viewDispatch({ type: ELStockWorkflowActions.updateActionRegionState, payload: isImageLicensed });
        viewDispatch({ type: ELStockWorkflowActions.setSelectedStockId, payload: imageInfo.id });
        if (isImageLicensed) {
            props.controller.notify({
                type: ELStockWorkflowActions.applyStockLicensedImage,
                payload: {
                    contentData: {
                        id: imageInfo.id
                    },
                    contentDataType: GenericContentDataType.http,
                    contentSource: GenericContentSource.adobeStock
                }
            });
        } else {
            props.controller.notify({
                type: ELStockWorkflowActions.stockThumbnailClicked,
                payload: {
                    contentDataType: GenericContentDataType.http,
                    contentSource: GenericContentSource.adobeStockPreview,
                    contentData: {
                        id: imageInfo.id,
                        url: imageInfo.thumbnail_url
                    }
                }
            });
        }
    };

    const handleLearnMoreClick = (): void => {
        Utils.openInNewTab(process.env.REACT_APP_ADOBE_STOCK_URL ? process.env.REACT_APP_ADOBE_STOCK_URL : "https://stock.adobe.com");
    }

    const handleViewAllClick = (): void => {
        setViewMode(ELAdobeStockPanelViewMode.SEARCH_RESULTS);
        setShowSuggestions(false);
    }

    const handleDiscardClick = async (): Promise<void> => {
        setViewMode(ELAdobeStockPanelViewMode.DEFAULT);
        setSearchQuery(emptyString);
        viewDispatch({ type: ELStockWorkflowActions.updateActionRegionState, payload: true });
        setSearchResults(featuredBackgrounds);
        transparentCardClicked();
        props.controller.notify({ type: ELStockWorkflowActions.discardLicense });
    }

    const handleLicenseForFreeClick = async (): Promise<void> => {
        viewDispatch({ type: ELStockWorkflowActions.updateActionRegionState, payload: true });
        await props.controller.notify({ type: ELStockWorkflowActions.licenseForFree, payload: state.selectedStockId });
        await props.controller.notify({
            type: ELStockWorkflowActions.applyStockLicensedImage,
            payload: {
                contentData: {
                    id: state.selectedStockId
                },
                contentDataType: GenericContentDataType.http,
                contentSource: GenericContentSource.adobeStock
            }
        })
        ToastUtils.success(intlHandler.formatMessage("adobe-stock-photo-licensed"));
    };

    const handleBackToDefault = (): void => {
        setShowSuggestions(false);
        setViewMode(ELAdobeStockPanelViewMode.DEFAULT);
        setSearchQuery(emptyString);
        setSearchResults(featuredBackgrounds);
        props.controller.notify({ type: ELStockWorkflowActions.stockBackButtonClicked });
    };

    const fetchFeaturedBackgrounds = async (): Promise<void> => {
        setIsLoadingBackgrounds(true);
        try {
            const response = await StockServiceUtils.fetchImages("backgrounds", Utils.getCurrentLocaleInSnakeCase(), 0, limit);
            setTotalResults(response.nb_results)
            setFeaturedBackgrounds(response.files);
            setSearchResults(response.files);
        } catch (error) {
            console.error("Error fetching featured backgrounds:", error);
        } finally {
            setIsLoadingBackgrounds(false);
        }
    };

    const renderSearchSuggestions = (): ReactElement => (
        <div className="adobe-stock-content-panel__search-suggestions" onMouseDown={(e) => e.preventDefault()}>
            {searchQuery && (
                <div
                    className="adobe-stock-content-panel__search-suggestion-item"
                    onClick={() => handleSearchSubmit(searchQuery)}
                >
                    Search for &quot;{searchQuery}&quot;
                </div>
            )}
            {recentSearches.length > 0 && (
                <>
                    {recentSearches.map((query) => (
                        <div
                            key={query}
                            className="adobe-stock-content-panel__search-suggestion-item"
                            onClick={() => handleSearchSubmit(query)}
                        >
                            {query}
                        </div>
                    ))}
                </>
            )}
        </div>
    );

    const getFeaturedFreeBackgroundItemClass = (id: string): string => {
        return `adobe-stock-content-panel__featured-free-item ${id === state.selectedStockId ? "selected" : emptyString}`;
    }

    const renderPoweredBySection = (): ReactElement => (
        <div className="adobe-stock-content-panel__powered-by">
            <Text>{intlHandler.formatMessage("adobe-stock-powered-photos")}</Text>
            <text className="adobe-stock-content-panel__learn-more" onClick={handleLearnMoreClick}>{intlHandler.formatMessage("learn-more")}</text>
        </div>
    );

    const renderActionRegion = (): ReactElement => (
        <div className={`adobe-stock-content-panel__action-region ${state.isActionRegionDisabled ? "disabled" : emptyString}`}>
            <hr className="adobe-stock-content-panel__bottom-section-divider" />
            <Text UNSAFE_className="adobe-stock-content-panel__action-region-text">{intlHandler.formatMessage("license-region-text")}</Text>
            <div className="adobe-stock-content-panel__action-region-buttons">
                <ELButton variant="primary" onClick={() => handleDiscardClick()} aria-disabled={state.isActionRegionDisabled}>
                    {intlHandler.formatMessage("discard")}
                </ELButton>
                <ELButton variant="cta" onClick={() => handleLicenseForFreeClick()} aria-disabled={state.isActionRegionDisabled}>
                    {intlHandler.formatMessage("license-free")}
                </ELButton>
            </div>
        </div>
    );

    const fetchBackgrounds = async (query: string, newOffset: number): Promise<void> => {

        const allBackgroundsFetched = newOffset >= totalResults;
        if (allBackgroundsFetched) {
            return;
        }
        try {
            setIsFetching(true);
            const response = await StockServiceUtils.fetchImages(query, Utils.getCurrentLocaleInSnakeCase(), newOffset, limit);
            setSearchResults((prevResults) => [...prevResults, ...response.files]);
            setOffset(newOffset + limit);
            setTotalResults(response.nb_results);
        } catch (error) {
            console.error("Error fetching backgrounds:", error);
        } finally {
            setIsFetching(false);
        }
    };

    const handleScroll = (e: React.UIEvent<HTMLDivElement>): void => {
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        const buffer = 200;
        if (scrollTop + clientHeight + buffer >= scrollHeight && !isFetching) {
            fetchBackgrounds(searchQuery, offset);
        }
    };

    const renderFooter = (): ReactElement => (
        <div className="adobe-stock-content-panel__footer">
            {renderPoweredBySection()}
            {renderActionRegion()}
        </div>
    );

    const renderSearchResultsHeader = (): ReactElement => (
        <>
            <div className="adobe-stock-content-panel__header">
                <div className="adobe-stock-content-panel__back-button">
                    <ELCustomIcon variant="independent" iconkey={IconType.creationsHeaderBack} color={Theme.dark.gray_controls_color_N}
                        hoverColor={Theme.dark.gray_controls_color_N} width={"2rem"} height={"2rem"}
                        onPress={handleBackToDefault} />
                </div>
                <div className="adobe-stock-content-panel__header-content">
                    <ELCustomIcon variant="independent" iconkey={IconType.adobeStockIconText} width={"10rem"} height={"3rem"} />
                </div>
            </div>
            <div className="adobe-stock-content-panel__header-divider"></div>
        </>
    );

    const renderSearchBar = (): ReactElement => (
        <div className="adobe-stock-content-panel__stock-search-bar-container">
            <input
                aria-label={intlHandler.formatMessage("search-backgrounds")}
                placeholder={intlHandler.formatMessage("search-backgrounds")}
                value={searchQuery}
                onChange={(e) => handleSearchChange(e.target.value)}
                onFocus={() => setShowSuggestions(true)}
                onBlur={() => setShowSuggestions(false)}
                onKeyDown={(e) => handleEnterClick(e)}
            />
            {searchQuery && (
                <button
                    className="adobe-stock-content-panel__stock-search-clear-button"
                    onClick={() => {
                        setSearchQuery(emptyString);
                        fetchFeaturedBackgrounds();
                    }}
                >
                    ✕
                </button>
            )}
            {showSuggestions && renderSearchSuggestions()}
        </div>
    );

    const renderSearchResultsText = (): ReactElement => (
        <Text UNSAFE_className="adobe-stock-content-panel__search-results-text">
            Results for &quot;{searchQuery}&quot;
        </Text>
    );

    const getSearchResultItemClass = (id: string): string => {
        return `adobe-stock-content-panel__search-results-item ${id === state.selectedStockId ? "selected" : emptyString}`;
    }

    const renderSearchResultsGrid = (): ReactElement => (
        <div onScroll={handleScroll} className="adobe-stock-content-panel__search-results-grid">
            {isLoadingBackgrounds ? (
                <div className="adobe-stock-content-panel__loader">
                    <ProgressCircle
                        aria-label={intlHandler.formatMessage("fetching-backgrounds")}
                        isIndeterminate
                        size="L"
                    />
                </div>
            ) : (
                searchResults.map((result) => (
                    <div
                        key={result.id}
                        className={getSearchResultItemClass(result.id)}>
                        <img
                            src={result.thumbnail_url}
                            alt={result.title}
                            onClick={() => handleImageClick(result)}
                            crossOrigin="anonymous"
                        />
                    </div>
                ))
            )}
        </div>
    );

    const renderFeaturedFreeHeader = (): ReactElement => (
        <div className="adobe-stock-content-panel__featured-free-header">
            <Text UNSAFE_className="adobe-stock-content-panel__featured-free-text">{intlHandler.formatMessage("featured-free")}</Text>
            <text className="adobe-stock-content-panel__view-all" onClick={() => handleViewAllClick()}>{intlHandler.formatMessage("view-all")}</text>
        </div>
    );

    const renderFeaturedFreeBackgrounds = (): ReactElement => (
        <div onScroll={handleScroll} className="adobe-stock-content-panel__featured-free-grid">
            {isLoadingBackgrounds ? (
                <div className="adobe-stock-content-panel__loader">
                    <ProgressCircle
                        aria-label={intlHandler.formatMessage("fetching-backgrounds")}
                        isIndeterminate
                        size="L"
                    />
                </div>
            ) : (
                (searchResults.length > 0 ? searchResults : featuredBackgrounds).slice(0, 20).map((background) => (
                    <div
                        key={background.id}
                        className={getFeaturedFreeBackgroundItemClass(background.id)}>
                        <img
                            src={background.thumbnail_url}
                            alt={background.title}
                            onClick={() => handleImageClick(background)}
                            crossOrigin="anonymous"
                        />
                    </div>
                ))
            )}
        </div>
    );

    const renderDefaultView = (): ReactElement => (
        <>
            <ELPanelHeader title={intlHandler.formatMessage("adobe-stock")} alignTitle="center" />
            <ELScrollPanel
                forwardedRef={scrollParentRef}
                UNSAFE_className="adobe-stock-content-panel-scroll"
                scrollY={true}
                width="100%">
                <Flex direction="column" height="100%">
                    {renderSearchBar()}
                    {renderFeaturedFreeHeader()}
                    {renderFeaturedFreeBackgrounds()}
                    {renderFooter()}
                </Flex>
            </ELScrollPanel>
        </>
    );

    const renderSearchResultsView = (): ReactElement => (
        <>
            {renderSearchResultsHeader()}
            <ELScrollPanel
                forwardedRef={scrollParentRef}
                UNSAFE_className="adobe-stock-content-panel-scroll"
                scrollY={true}
                width="100%"
            >
                <Flex direction="column" height="100%">
                    {renderSearchBar()}
                    {searchQuery.length > 0 && renderSearchResultsText()}
                    {renderSearchResultsGrid()}
                    {renderFooter()}
                </Flex>
            </ELScrollPanel>
        </>
    );

    return (
        <div className="adobe-stock-content-panel-box">
            {viewMode === ELAdobeStockPanelViewMode.DEFAULT && renderDefaultView()}
            {viewMode === ELAdobeStockPanelViewMode.SEARCH_RESULTS && renderSearchResultsView()}
        </div>
    )
};

export { ELAdobeStockPanelView };