/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application specific
import IWorkflow, { WorkflowsName } from "./IWorkflow";
import CreationHome from "./creations/workflows/home/CreationHome";
import Slideshow from "./creations/workflows/slideshow/Slideshow";
import CreationPreview from "./creations/workflows/preview/CreationPreview";
import { Consent } from "./dialog/shareDialog/workflows/consent/Consent";
import { LinkGeneration } from "./dialog/shareDialog/workflows/linkGeneration/LinkGeneration";
import { EmailShare } from "./dialog/shareDialog/workflows/emailShare/EmailShare";
import MediaGridWorkflow from "./organizer/workflows/mediaGrid/MediaGrid";
import { SharedMedia } from "./LinkPreview/workflows/sharedmedia/SharedMedia";
import { FacebookShare } from "./dialog/shareDialog/workflows/facebookShare/FacebookShare";
import { LinkShare } from "./dialog/shareDialog/workflows/linkShare/LinkShare";
import { LinkGenerationError } from "./dialog/shareDialog/workflows/linkGenererationError/LinkGenerationError";
import MediaManager from "./common/workflows/mediaManager/MediaManager";
import { SingleImage } from "./organizer/workflows/singleImage/SingleImage";
import { UserHome } from "./Home/workflows/UserHome";
import { NoPageFound } from "./Home/workflows/NoPageFound";
import Collage from "./creations/workflows/collage/Collage";
import IBaseWorkspace from "./IBaseWorkspace";
import ReplaceMediaManager from "./common/workflows/replaceMediaManager/ReplaceMediaManager";
import PatternOverlay from "./creations/workflows/pattern-overlay/PatternOverlay";
import PeekThrough from "./creations/workflows/peekThrough/PeekThrough";
import MovingOverlay from "./creations/workflows/moving-overlay/MovingOverlay";
import ReplaceBackground from "./creations/workflows/replace-background/ReplaceBackground";
import { TryThisInDesktop } from "./Home/workflows/tryThisInDesktop/TryThisInDesktop";
import ImageEditWorkflow from "./edit/workflows/ImageEditWorkflow";
import { SampleMediaManager } from "./common/workflows/sampleMediaManager/SampleMediaManager";
import PhotoText from "./creations/workflows/photoText/PhotoText";


export const WorkflowMap: { [key in WorkflowsName]: any } = {
    [WorkflowsName.creationsHome]: CreationHome,
    [WorkflowsName.slideshow]: Slideshow,
    [WorkflowsName.creationsPreview]: CreationPreview,
    [WorkflowsName.consent]: Consent,
    [WorkflowsName.linkGeneration]: LinkGeneration,
    [WorkflowsName.facebookShare]: FacebookShare,
    [WorkflowsName.linkShare]: LinkShare,
    [WorkflowsName.emailShare]: EmailShare,
    [WorkflowsName.linkGenerationError]: LinkGenerationError,
    [WorkflowsName.mediaGrid]: MediaGridWorkflow,
    [WorkflowsName.sharedMedia]: SharedMedia,
    [WorkflowsName.mediaManager]: MediaManager,
    [WorkflowsName.singleImageView]: SingleImage,
    [WorkflowsName.userHome]: UserHome,
    [WorkflowsName.noPageFound]: NoPageFound,
    [WorkflowsName.collage]: Collage,
    [WorkflowsName.replaceMediaManager]: ReplaceMediaManager,
    [WorkflowsName.patternOverlay]: PatternOverlay,
    [WorkflowsName.peekThrough]: PeekThrough,
    [WorkflowsName.movingOverlay]: MovingOverlay,
    [WorkflowsName.replaceBackground]: ReplaceBackground,
    [WorkflowsName.tryThisInDesktop]: TryThisInDesktop,
    [WorkflowsName.imageEditWorkflow]: ImageEditWorkflow,
    [WorkflowsName.sampleMediaManager]: SampleMediaManager,
    [WorkflowsName.photoText]: PhotoText
};

export default abstract class WorkflowFactory {
    static createWorkflow(workflow: WorkflowsName, workspace: IBaseWorkspace): IWorkflow {
        return new WorkflowMap[workflow](workspace);
    }
}
